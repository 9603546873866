import React, { useState, useEffect, useContext, useRef } from "react";
import { Link, withRouter } from 'react-router-dom';
import Financials from "./Financials";
import logo from './styles/logo.png'

import { DCFContext } from './contexts/DCFContext'

import ReactGA from 'react-ga';

ReactGA.initialize('G-KBTP6M20F2');

const Sidebar = () => {

    const { showSuccessAlert, editSuccessAlert, showErrorAlert, editErrorAlert, disableNavbar, editDisableNavbar } = useContext(DCFContext)


    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

    })

    return (

        disableNavbar ?


            <nav style={{ "z-index": "99" }} class="navbar">
                <ul class="navbar-nav">
                    <li class="logo">




                        <div class="navLinkSidebar" aria-disabled='true'>

                            <img src={logo} class="link-text logo-text"
                            ></img>

                            <div className="navArrow">
                                <svg
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fad"
                                    data-icon="angle-double-right"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512"
                                    class="svg-inline--fa fa-angle-double-right fa-w-14 fa-5x"
                                >
                                    <g class="fa-group">
                                        <path
                                            fill="currentColor"
                                            d="M224 273L88.37 409a23.78 23.78 0 0 1-33.8 0L32 386.36a23.94 23.94 0 0 1 0-33.89l96.13-96.37L32 159.73a23.94 23.94 0 0 1 0-33.89l22.44-22.79a23.78 23.78 0 0 1 33.8 0L223.88 239a23.94 23.94 0 0 1 .1 34z"
                                            class="fa-secondary"
                                        ></path>
                                        <path
                                            fill="currentColor"
                                            d="M415.89 273L280.34 409a23.77 23.77 0 0 1-33.79 0L224 386.26a23.94 23.94 0 0 1 0-33.89L320.11 256l-96-96.47a23.94 23.94 0 0 1 0-33.89l22.52-22.59a23.77 23.77 0 0 1 33.79 0L416 239a24 24 0 0 1-.11 34z"
                                            class="fa-primary"
                                        ></path>
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </li>



                    <li class="navItemSidebar">
                        <div class="navLinkSidebar" aria-disabled='true'>
                            <div id="ResearchIcon" class="sidebar-img-btn"></div>
                            <span class="link-text">Research</span>
                        </div>
                    </li>

                    <li class="navItemSidebar">
                        <div class="navLinkSidebar" aria-disabled='true'>
                            <div id="ValuationIcon" class="sidebar-img-btn"></div>
                            <span class="link-text">Saved Valuations</span>
                        </div>
                    </li>

                    <li class="navItemSidebar">
                        <div class="navLinkSidebar" aria-disabled='true'>
                            <div id="SuperinvestorsIcon" class="sidebar-img-btn"></div>
                            <span class="link-text">Superinvestors</span>
                        </div>
                    </li>

                    <li class="navItemSidebar">
                        <div class="navLinkSidebar" aria-disabled='true'>
                            <div id="ForumIcon" class="sidebar-img-btn"></div>
                            <span class="link-text">Forum</span>
                        </div>
                    </li>



                    <li class="navItemSidebar">
                        <div class="navLinkSidebar" aria-disabled='true'>
                            <div id="ServicesIcon" class="sidebar-img-btn"></div>
                            <span class="link-text">Services</span>
                        </div>
                    </li>



                    <li class="navItemSidebar">
                        <div class="navLinkSidebar" aria-disabled='true'>
                            <div id="AccountIcon" class="sidebar-img-btn"></div>
                            <span class="link-text">Account</span>
                        </div>


                    </li>

                </ul>
            </nav>


            :


            <nav style={{ "z-index": "99" }} class="navbar">
                <ul class="navbar-nav">
                    <li class="logo">




                        <Link to="/" class="navLinkSidebar" >

                            <img src={logo} class="link-text logo-text"
                            ></img>

                            <div className="navArrow">
                                <svg
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fad"
                                    data-icon="angle-double-right"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512"
                                    class="svg-inline--fa fa-angle-double-right fa-w-14 fa-5x"
                                >
                                    <g class="fa-group">
                                        <path
                                            fill="currentColor"
                                            d="M224 273L88.37 409a23.78 23.78 0 0 1-33.8 0L32 386.36a23.94 23.94 0 0 1 0-33.89l96.13-96.37L32 159.73a23.94 23.94 0 0 1 0-33.89l22.44-22.79a23.78 23.78 0 0 1 33.8 0L223.88 239a23.94 23.94 0 0 1 .1 34z"
                                            class="fa-secondary"
                                        ></path>
                                        <path
                                            fill="currentColor"
                                            d="M415.89 273L280.34 409a23.77 23.77 0 0 1-33.79 0L224 386.26a23.94 23.94 0 0 1 0-33.89L320.11 256l-96-96.47a23.94 23.94 0 0 1 0-33.89l22.52-22.59a23.77 23.77 0 0 1 33.79 0L416 239a24 24 0 0 1-.11 34z"
                                            class="fa-primary"
                                        ></path>
                                    </g>
                                </svg>
                            </div>
                        </Link>
                    </li>



                    <li class="navItemSidebar">
                        <Link to={"/research"} class="navLinkSidebar" >
                            <div id="ResearchIcon" class="sidebar-img-btn"></div>
                            <span class="link-text">Research</span>
                        </Link>
                    </li>

                    <li class="navItemSidebar">
                        <Link to="/valuation" class="navLinkSidebar" >
                            <div id="ValuationIcon" class="sidebar-img-btn"></div>
                            <span class="link-text">Saved Valuations</span>
                        </Link>
                    </li>

                    <li class="navItemSidebar">
                        <Link to="/superinvestors" class="navLinkSidebar" >
                            <div id="SuperinvestorsIcon" class="sidebar-img-btn"></div>
                            <span class="link-text">Superinvestors</span>
                        </Link>
                    </li>

                    <li class="navItemSidebar">
                        <Link to="/blog" class="navLinkSidebar" >
                            <div id="ForumIcon" class="sidebar-img-btn"></div>
                            <span class="link-text">Forum</span>
                        </Link>
                    </li>



                    <li class="navItemSidebar">
                        <Link to="/services" class="navLinkSidebar" >
                            <div id="ServicesIcon" class="sidebar-img-btn"></div>
                            <span class="link-text">Services</span>
                        </Link>
                    </li>



                    <li class="navItemSidebar">
                        <Link to="/account" class="navLinkSidebar" >
                            <div id="AccountIcon" class="sidebar-img-btn"></div>
                            <span class="link-text">Account</span>
                        </Link>


                    </li>

                </ul>
            </nav>
    )
}

export default withRouter(Sidebar)


