import { PayPalButton } from "react-paypal-button-v2";
import React from 'react';
 
const Premium = (props) => {
//  
   const { amount, currency, createSubscription, onApprove, catchError, onError, onCancel } = props;
   const paypalKey = process.env.REACT_APP_DEFAULT_PAYPAL_CLIENTID
 
   return (
       <PayPalButton
           amount={amount}
           currency={currency}
           createSubscription={(data, details) => createSubscription(data, details)}
           onApprove={(data, details) => onApprove(data, details)}
           onError={(err) => onError(err)}
           catchError={(err) => catchError(err)}
           onCancel={(err) => onCancel(err)}
           options={{
               clientId: paypalKey,
               vault: true
           }}
           style={{
             

           }}
       />
   );
}
export default Premium
