import React, { useEffect, useState, useRef } from 'react'
import './styles/superinvestors.css'
import superinvestors from './superinvestor.json'
import { useHistory, useLocation } from 'react-router-dom';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const Superinvestors = () => {

    const history = useHistory();

    let superinvestorProfile = (investor) => {

        console.log(investor);


        history.push('/superinvestors/' + investor.split(" ")[0] + "_" + investor.split(" ")[1] + '/0')
        // window.location.reload()


    }

    return (
        <div>

            <div style={{ fontSize: '30px', fontWeight: 'bold', margin: '10px', color: 'white' }} >
                Superinvestors
            </div>

            {
                superinvestors.map((investor) => {
                    return (
                        <div className='superinvestor-container' onClick={() => superinvestorProfile(investor.investor)}>
                            <div className="row">
                                <div className="col-sm-2" style={{ alignSelf: 'center' }}>
                                    <div className='superinvestor-pic' style={{ background: 'url(' + investor.image + ')' }}>
                                    </div>
                                </div>

                                <div className="col-sm-5" style={{ alignSelf: 'center' }}>
                                    <div style={{ color: 'white', fontWeight: 'bold' }}>
                                        {investor.investor}
                                    </div>
                                    <div style={{ color: 'white', fontStyle: 'italic' }}>
                                        {investor.name}
                                    </div>
                                </div>
                                <div className="col-sm-5" style={{ padding: '0 30px', alignSelf: 'center' }}>
                                    <div className='investor-stats'>
                                        <div className='row align-items-center'>
                                            <div className="col-sm-6" style={{ color: 'white', marginBottom: '5px' }}>
                                                Performance
                                            </div>
                                            <div className="col-sm-6">
                                                <LinearProgress variant='determinate' value={investor.performance} />
                                            </div>
                                        </div>
                                        <div className='row align-items-center'>
                                            <div className="col-sm-6" style={{ color: 'white', marginBottom: '5px' }}>
                                                Popularity
                                            </div>
                                            <div className="col-sm-6">
                                                <LinearProgress variant='determinate' value={investor.popularity} />
                                            </div>
                                        </div>
                                        <div className='row align-items-center'>
                                            <div className="col-sm-6" style={{ color: 'white' }}>
                                                Portfolio Turnover
                                            </div>
                                            <div className="col-sm-6">
                                                <LinearProgress variant='determinate' value={investor.turnover} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className='see-portfolio-btn'>See Portfolio</div>
                            </div>
                        </div>)
                })
            }



        </div >
    )
}
export default Superinvestors