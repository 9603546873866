// when press reset it should make value of year1 year 2 equal initial * fiveyeargrowth ...
import { DCFContext } from './contexts/DCFContext'
import React, { useState, useContext, useEffect } from 'react'
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import HelpIcon from '@mui/icons-material/Help';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import TabContainer from 'react-bootstrap/TabContainer'
import TabContent from 'react-bootstrap/TabContent'

import bull from './styles/bullTest.png'
import bear from './styles/bear.png'
import fair from './styles/fair.png'

import Alert from '@mui/material/Alert';

import ValuationDetails from './ValuationDetails'

import TabPane from 'react-bootstrap/TabPane'


const WeightedDCF = (props) => {

    const [isAdvanced, setIsAdvanced] = useState(false);
    const [isAdvancedBull, setIsAdvancedBull] = useState(false);
    const [isAdvancedBear, setIsAdvancedBear] = useState(false);


    const { cases, weightedDCF, bearIntrinsicValue, editBearIntrinsicValueContext, bullIntrinsicValue, editBullIntrinsicValueContext, normalIntrinsicValue, editNormalIntrinsicValueContext, returnWeight } = useContext(DCFContext)

    const currentYear = 2022

    //////////// Normal //////////////
    const [fiveYearGrowth, setFiveYearGrowth] = useState(0);
    const [sixYearGrowth, setSixYearGrowth] = useState(0);
    const [discountRate, setDiscountRate] = useState(0);
    const [terminalMultiple, setTerminalMultiple] = useState(0);
    const [initial, setInitial] = useState(0);
    const [hiddenValue, setHiddenValue] = useState(0);
    const [cashReturned, setCashReturned] = useState(100);



    const [year1, setYear1] = useState(initial * ((1 + parseFloat(fiveYearGrowth) / 100) ** 1))
    const [year2, setYear2] = useState(initial * ((1 + parseFloat(fiveYearGrowth) / 100) ** 2))
    const [year3, setYear3] = useState(initial * ((1 + parseFloat(fiveYearGrowth) / 100) ** 3))
    const [year4, setYear4] = useState(initial * ((1 + parseFloat(fiveYearGrowth) / 100) ** 4))
    const [year5, setYear5] = useState(initial * ((1 + parseFloat(fiveYearGrowth) / 100) ** 5))
    const [year6, setYear6] = useState((initial * ((1 + parseFloat(fiveYearGrowth) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowth / 100)) ** 1))
    const [year7, setYear7] = useState((initial * ((1 + parseFloat(fiveYearGrowth) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowth / 100)) ** 2))
    const [year8, setYear8] = useState((initial * ((1 + parseFloat(fiveYearGrowth) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowth / 100)) ** 3))
    const [year9, setYear9] = useState((initial * ((1 + parseFloat(fiveYearGrowth) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowth / 100)) ** 4))
    const [year10, setYear10] = useState((initial * ((1 + parseFloat(fiveYearGrowth) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowth / 100)) ** 5))
    const [year10Terminal, setYear10Terminal] = useState((initial * ((1 + parseFloat(fiveYearGrowth) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowth / 100)) ** 4) + (parseFloat(terminalMultiple - 1) * ((initial * ((1 + parseFloat(fiveYearGrowth / 100)) ** 5)) * ((1 + parseFloat(sixYearGrowth / 100)) ** 4))))

    var dYear1 = (year1 / ((1 + parseFloat(discountRate / 100)) ** 1)) * cashReturned / 100
    var dYear2 = (year2 / ((1 + parseFloat(discountRate / 100)) ** 2)) * cashReturned / 100
    var dYear3 = (year3 / ((1 + parseFloat(discountRate / 100)) ** 3)) * cashReturned / 100
    var dYear4 = (year4 / ((1 + parseFloat(discountRate / 100)) ** 4)) * cashReturned / 100
    var dYear5 = (year5 / ((1 + parseFloat(discountRate / 100)) ** 5)) * cashReturned / 100
    var dYear6 = (year6 / ((1 + parseFloat(discountRate / 100)) ** 6)) * cashReturned / 100
    var dYear7 = (year7 / ((1 + parseFloat(discountRate / 100)) ** 7)) * cashReturned / 100
    var dYear8 = (year8 / ((1 + parseFloat(discountRate / 100)) ** 8)) * cashReturned / 100
    var dYear9 = (year9 / ((1 + parseFloat(discountRate / 100)) ** 9)) * cashReturned / 100
    var dYear10 = (year10 / ((1 + parseFloat(discountRate / 100)) ** 10)) * cashReturned / 100
    var dYear10Terminal = year10Terminal / ((1 + parseFloat(discountRate / 100)) ** 10)

    useEffect(() => {

        console.log('year1 check useeffect  : ', year1)
        console.log('year2 check useeffect  : ', year2)

        setYear1(initial * ((1 + parseFloat(fiveYearGrowth) / 100) ** 1))
        setYear2(initial * ((1 + parseFloat(fiveYearGrowth) / 100) ** 2))
        setYear3(initial * ((1 + parseFloat(fiveYearGrowth) / 100) ** 3))
        setYear4(initial * ((1 + parseFloat(fiveYearGrowth) / 100) ** 4))
        setYear5(initial * ((1 + parseFloat(fiveYearGrowth) / 100) ** 5))
        setYear6((initial * ((1 + parseFloat(fiveYearGrowth) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowth / 100)) ** 1))
        setYear7((initial * ((1 + parseFloat(fiveYearGrowth) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowth / 100)) ** 2))
        setYear8((initial * ((1 + parseFloat(fiveYearGrowth) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowth / 100)) ** 3))
        setYear9((initial * ((1 + parseFloat(fiveYearGrowth) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowth / 100)) ** 4))
        setYear10((initial * ((1 + parseFloat(fiveYearGrowth) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowth / 100)) ** 5))
        setYear10Terminal((initial * ((1 + parseFloat(fiveYearGrowth) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowth / 100)) ** 4) + (parseFloat(terminalMultiple - 1) * ((initial * ((1 + parseFloat(fiveYearGrowth / 100)) ** 5)) * ((1 + parseFloat(sixYearGrowth / 100)) ** 4))))

        console.log('fiveYearGrowth checkcheck', fiveYearGrowth)
        // setYear10Terminal((initial * ((1 + parseFloat(fiveYearGrowth) / 100) ** 4)) * ((1 + parseFloat(sixYearGrowth / 100)) ** 5) + (parseFloat(terminalMultiple - 1) * ((initial * ((1 + parseFloat(fiveYearGrowth / 100)) ** 4)) * ((1 + parseFloat(sixYearGrowth / 100)) ** 5))))



    }, [fiveYearGrowth, sixYearGrowth, initial, terminalMultiple]);


    const zero = () => {
        setYear1(0)
        setYear2(0)
        setYear3(0)
        setYear4(0)
        setYear5(0)
        setYear6(0)
        setYear7(0)
        setYear8(0)
        setYear9(0)
        setYear10(0)

    }


    const reset = () => {
        setYear1(initial * ((1 + parseFloat(fiveYearGrowth) / 100) ** 1))
        setYear2(initial * ((1 + parseFloat(fiveYearGrowth) / 100) ** 2))
        setYear3(initial * ((1 + parseFloat(fiveYearGrowth) / 100) ** 3))
        setYear4(initial * ((1 + parseFloat(fiveYearGrowth) / 100) ** 4))
        setYear5(initial * ((1 + parseFloat(fiveYearGrowth) / 100) ** 5))
        setYear6((initial * ((1 + parseFloat(fiveYearGrowth) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowth / 100)) ** 1))
        setYear7((initial * ((1 + parseFloat(fiveYearGrowth) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowth / 100)) ** 2))
        setYear8((initial * ((1 + parseFloat(fiveYearGrowth) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowth / 100)) ** 3))
        setYear9((initial * ((1 + parseFloat(fiveYearGrowth) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowth / 100)) ** 4))
        setYear10((initial * ((1 + parseFloat(fiveYearGrowth) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowth / 100)) ** 5))
        setYear10Terminal((initial * ((1 + parseFloat(fiveYearGrowth) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowth / 100)) ** 4) + (parseFloat(terminalMultiple - 1) * ((initial * ((1 + parseFloat(fiveYearGrowth / 100)) ** 5)) * ((1 + parseFloat(sixYearGrowth / 100)) ** 4))))

    }

    //////////// Bull //////////////

    const [fiveYearGrowthBull, setFiveYearGrowthBull] = useState(0);
    const [sixYearGrowthBull, setSixYearGrowthBull] = useState(0);
    const [discountRateBull, setDiscountRateBull] = useState(0);
    const [terminalMultipleBull, setTerminalMultipleBull] = useState(0);
    const [initialBull, setInitialBull] = useState(0);
    const [hiddenValueBull, setHiddenValueBull] = useState(0);
    const [cashReturnedBull, setCashReturnedBull] = useState(100);

    const [year1Bull, setYear1Bull] = useState(0)
    const [year2Bull, setYear2Bull] = useState(0)
    const [year3Bull, setYear3Bull] = useState(0)
    const [year4Bull, setYear4Bull] = useState(0)
    const [year5Bull, setYear5Bull] = useState(0)
    const [year6Bull, setYear6Bull] = useState(0)
    const [year7Bull, setYear7Bull] = useState(0)
    const [year8Bull, setYear8Bull] = useState(0)
    const [year9Bull, setYear9Bull] = useState(0)
    const [year10Bull, setYear10Bull] = useState(0)
    const [year10TerminalBull, setYear10TerminalBull] = useState(0)


    var dYear1Bull = (year1Bull / ((1 + parseFloat(discountRateBull / 100)) ** 1)) * cashReturnedBull / 100
    var dYear2Bull = (year2Bull / ((1 + parseFloat(discountRateBull / 100)) ** 2)) * cashReturnedBull / 100
    var dYear3Bull = (year3Bull / ((1 + parseFloat(discountRateBull / 100)) ** 3)) * cashReturnedBull / 100
    var dYear4Bull = (year4Bull / ((1 + parseFloat(discountRateBull / 100)) ** 4)) * cashReturnedBull / 100
    var dYear5Bull = (year5Bull / ((1 + parseFloat(discountRateBull / 100)) ** 5)) * cashReturnedBull / 100
    var dYear6Bull = (year6Bull / ((1 + parseFloat(discountRateBull / 100)) ** 6)) * cashReturnedBull / 100
    var dYear7Bull = (year7Bull / ((1 + parseFloat(discountRateBull / 100)) ** 7)) * cashReturnedBull / 100
    var dYear8Bull = (year8Bull / ((1 + parseFloat(discountRateBull / 100)) ** 8)) * cashReturnedBull / 100
    var dYear9Bull = (year9Bull / ((1 + parseFloat(discountRateBull / 100)) ** 9)) * cashReturnedBull / 100
    var dYear10Bull = (year10Bull / ((1 + parseFloat(discountRateBull / 100)) ** 10)) * cashReturnedBull / 100
    var dYear10TerminalBull = year10TerminalBull / ((1 + parseFloat(discountRateBull / 100)) ** 10)

    useEffect(() => {



        setYear1Bull(initialBull * ((1 + parseFloat(fiveYearGrowthBull) / 100) ** 1))
        setYear2Bull(initialBull * ((1 + parseFloat(fiveYearGrowthBull) / 100) ** 2))
        setYear3Bull(initialBull * ((1 + parseFloat(fiveYearGrowthBull) / 100) ** 3))
        setYear4Bull(initialBull * ((1 + parseFloat(fiveYearGrowthBull) / 100) ** 4))
        setYear5Bull(initialBull * ((1 + parseFloat(fiveYearGrowthBull) / 100) ** 5))
        setYear6Bull((initialBull * ((1 + parseFloat(fiveYearGrowthBull) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowthBull / 100)) ** 1))
        setYear7Bull((initialBull * ((1 + parseFloat(fiveYearGrowthBull) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowthBull / 100)) ** 2))
        setYear8Bull((initialBull * ((1 + parseFloat(fiveYearGrowthBull) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowthBull / 100)) ** 3))
        setYear9Bull((initialBull * ((1 + parseFloat(fiveYearGrowthBull) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowthBull / 100)) ** 4))
        setYear10Bull((initialBull * ((1 + parseFloat(fiveYearGrowthBull) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowthBull / 100)) ** 5))
        // setYear10TerminalBull((initialBull * ((1 + parseFloat(fiveYearGrowthBull) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowthBull / 100)) ** 5) + (parseFloat(terminalMultipleBull - 1) * ((initialBull * ((1 + parseFloat(fiveYearGrowthBull / 100)) ** 5)) * ((1 + parseFloat(sixYearGrowthBull / 100)) ** 5))))
        setYear10TerminalBull((initialBull * ((1 + parseFloat(fiveYearGrowthBull) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowthBull / 100)) ** 4) + (parseFloat(terminalMultipleBull - 1) * ((initialBull * ((1 + parseFloat(fiveYearGrowthBull / 100)) ** 5)) * ((1 + parseFloat(sixYearGrowthBull / 100)) ** 4))))




    }, [fiveYearGrowthBull, sixYearGrowthBull, initialBull, terminalMultipleBull]);



    const resetBull = () => {

        setYear1Bull(initialBull * ((1 + parseFloat(fiveYearGrowthBull) / 100) ** 1))
        setYear2Bull(initialBull * ((1 + parseFloat(fiveYearGrowthBull) / 100) ** 2))
        setYear3Bull(initialBull * ((1 + parseFloat(fiveYearGrowthBull) / 100) ** 3))
        setYear4Bull(initialBull * ((1 + parseFloat(fiveYearGrowthBull) / 100) ** 4))
        setYear5Bull(initialBull * ((1 + parseFloat(fiveYearGrowthBull) / 100) ** 5))
        setYear6Bull((initialBull * ((1 + parseFloat(fiveYearGrowthBull) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowthBull / 100)) ** 1))
        setYear7Bull((initialBull * ((1 + parseFloat(fiveYearGrowthBull) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowthBull / 100)) ** 2))
        setYear8Bull((initialBull * ((1 + parseFloat(fiveYearGrowthBull) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowthBull / 100)) ** 3))
        setYear9Bull((initialBull * ((1 + parseFloat(fiveYearGrowthBull) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowthBull / 100)) ** 4))
        setYear10Bull((initialBull * ((1 + parseFloat(fiveYearGrowthBull) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowthBull / 100)) ** 5))
        // setYear10TerminalBull((initialBull * ((1 + parseFloat(fiveYearGrowthBull) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowthBull / 100)) ** 5) + (parseFloat(terminalMultipleBull - 1) * ((initialBull * ((1 + parseFloat(fiveYearGrowthBull / 100)) ** 5)) * ((1 + parseFloat(sixYearGrowthBull / 100)) ** 5))))
        setYear10TerminalBull((initialBull * ((1 + parseFloat(fiveYearGrowthBull) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowthBull / 100)) ** 4) + (parseFloat(terminalMultipleBull - 1) * ((initialBull * ((1 + parseFloat(fiveYearGrowthBull / 100)) ** 5)) * ((1 + parseFloat(sixYearGrowthBull / 100)) ** 4))))

    }

    const zeroBull = () => {
        setYear1Bull(0)
        setYear2Bull(0)
        setYear3Bull(0)
        setYear4Bull(0)
        setYear5Bull(0)
        setYear6Bull(0)
        setYear7Bull(0)
        setYear8Bull(0)
        setYear9Bull(0)
        setYear10Bull(0)

    }

    //////////// Bear //////////////

    const [fiveYearGrowthBear, setFiveYearGrowthBear] = useState(0);
    const [sixYearGrowthBear, setSixYearGrowthBear] = useState(0);
    const [discountRateBear, setDiscountRateBear] = useState(0);
    const [terminalMultipleBear, setTerminalMultipleBear] = useState(0);
    const [initialBear, setInitialBear] = useState(0);
    const [hiddenValueBear, setHiddenValueBear] = useState(0);
    const [cashReturnedBear, setCashReturnedBear] = useState(100);

    const [year1Bear, setYear1Bear] = useState(0)
    const [year2Bear, setYear2Bear] = useState(0)
    const [year3Bear, setYear3Bear] = useState(0)
    const [year4Bear, setYear4Bear] = useState(0)
    const [year5Bear, setYear5Bear] = useState(0)
    const [year6Bear, setYear6Bear] = useState(0)
    const [year7Bear, setYear7Bear] = useState(0)
    const [year8Bear, setYear8Bear] = useState(0)
    const [year9Bear, setYear9Bear] = useState(0)
    const [year10Bear, setYear10Bear] = useState(0)
    const [year10TerminalBear, setYear10TerminalBear] = useState(0)

    var dYear1Bear = (year1Bear / ((1 + parseFloat(discountRateBear / 100)) ** 1)) * cashReturnedBear / 100
    var dYear2Bear = (year2Bear / ((1 + parseFloat(discountRateBear / 100)) ** 2)) * cashReturnedBear / 100
    var dYear3Bear = (year3Bear / ((1 + parseFloat(discountRateBear / 100)) ** 3)) * cashReturnedBear / 100
    var dYear4Bear = (year4Bear / ((1 + parseFloat(discountRateBear / 100)) ** 4)) * cashReturnedBear / 100
    var dYear5Bear = (year5Bear / ((1 + parseFloat(discountRateBear / 100)) ** 5)) * cashReturnedBear / 100
    var dYear6Bear = (year6Bear / ((1 + parseFloat(discountRateBear / 100)) ** 6)) * cashReturnedBear / 100
    var dYear7Bear = (year7Bear / ((1 + parseFloat(discountRateBear / 100)) ** 7)) * cashReturnedBear / 100
    var dYear8Bear = (year8Bear / ((1 + parseFloat(discountRateBear / 100)) ** 8)) * cashReturnedBear / 100
    var dYear9Bear = (year9Bear / ((1 + parseFloat(discountRateBear / 100)) ** 9)) * cashReturnedBear / 100
    var dYear10Bear = (year10Bear / ((1 + parseFloat(discountRateBear / 100)) ** 10)) * cashReturnedBear / 100
    var dYear10TerminalBear = year10TerminalBear / ((1 + parseFloat(discountRateBear / 100)) ** 10)

    useEffect(() => {




        setYear1Bear(initialBear * ((1 + parseFloat(fiveYearGrowthBear) / 100) ** 1))
        setYear2Bear(initialBear * ((1 + parseFloat(fiveYearGrowthBear) / 100) ** 2))
        setYear3Bear(initialBear * ((1 + parseFloat(fiveYearGrowthBear) / 100) ** 3))
        setYear4Bear(initialBear * ((1 + parseFloat(fiveYearGrowthBear) / 100) ** 4))
        setYear5Bear(initialBear * ((1 + parseFloat(fiveYearGrowthBear) / 100) ** 5))
        setYear6Bear((initialBear * ((1 + parseFloat(fiveYearGrowthBear) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowthBear / 100)) ** 1))
        setYear7Bear((initialBear * ((1 + parseFloat(fiveYearGrowthBear) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowthBear / 100)) ** 2))
        setYear8Bear((initialBear * ((1 + parseFloat(fiveYearGrowthBear) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowthBear / 100)) ** 3))
        setYear9Bear((initialBear * ((1 + parseFloat(fiveYearGrowthBear) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowthBear / 100)) ** 4))
        setYear10Bear((initialBear * ((1 + parseFloat(fiveYearGrowthBear) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowthBear / 100)) ** 5))
        // setYear10TerminalBear((initialBear * ((1 + parseFloat(fiveYearGrowthBear) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowthBear / 100)) ** 5) + (parseFloat(terminalMultipleBear - 1) * ((initialBear * ((1 + parseFloat(fiveYearGrowthBear / 100)) ** 5)) * ((1 + parseFloat(sixYearGrowthBear / 100)) ** 5))))
        setYear10TerminalBear((initialBear * ((1 + parseFloat(fiveYearGrowthBear) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowthBear / 100)) ** 4) + (parseFloat(terminalMultipleBear - 1) * ((initialBear * ((1 + parseFloat(fiveYearGrowthBear / 100)) ** 5)) * ((1 + parseFloat(sixYearGrowthBear / 100)) ** 4))))



    }, [fiveYearGrowthBear, sixYearGrowthBear, initialBear, terminalMultipleBear]);



    const resetBear = () => {

        setYear1Bear(initialBear * ((1 + parseFloat(fiveYearGrowthBear) / 100) ** 1))
        setYear2Bear(initialBear * ((1 + parseFloat(fiveYearGrowthBear) / 100) ** 2))
        setYear3Bear(initialBear * ((1 + parseFloat(fiveYearGrowthBear) / 100) ** 3))
        setYear4Bear(initialBear * ((1 + parseFloat(fiveYearGrowthBear) / 100) ** 4))
        setYear5Bear(initialBear * ((1 + parseFloat(fiveYearGrowthBear) / 100) ** 5))
        setYear6Bear((initialBear * ((1 + parseFloat(fiveYearGrowthBear) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowthBear / 100)) ** 1))
        setYear7Bear((initialBear * ((1 + parseFloat(fiveYearGrowthBear) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowthBear / 100)) ** 2))
        setYear8Bear((initialBear * ((1 + parseFloat(fiveYearGrowthBear) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowthBear / 100)) ** 3))
        setYear9Bear((initialBear * ((1 + parseFloat(fiveYearGrowthBear) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowthBear / 100)) ** 4))
        setYear10Bear((initialBear * ((1 + parseFloat(fiveYearGrowthBear) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowthBear / 100)) ** 5))
        // setYear10TerminalBear((initialBear * ((1 + parseFloat(fiveYearGrowthBear) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowthBear / 100)) ** 5) + (parseFloat(terminalMultipleBear - 1) * ((initialBear * ((1 + parseFloat(fiveYearGrowthBear / 100)) ** 5)) * ((1 + parseFloat(sixYearGrowthBear / 100)) ** 5))))
        setYear10TerminalBear((initialBear * ((1 + parseFloat(fiveYearGrowthBear) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowthBear / 100)) ** 4) + (parseFloat(terminalMultipleBear - 1) * ((initialBear * ((1 + parseFloat(fiveYearGrowthBear / 100)) ** 5)) * ((1 + parseFloat(sixYearGrowthBear / 100)) ** 4))))

    }

    const zeroBear = () => {
        setYear1Bear(0)
        setYear2Bear(0)
        setYear3Bear(0)
        setYear4Bear(0)
        setYear5Bear(0)
        setYear6Bear(0)
        setYear7Bear(0)
        setYear8Bear(0)
        setYear9Bear(0)
        setYear10Bear(0)

    }

    // 

    const [normalWeight, setNormalWeight] = useState(70)
    const [bearWeight, setBearWeight] = useState(15)
    const [bullWeight, setBullWeight] = useState(15)



    const { showMore, editShowMore, tickerContext, editTickerContext } = useContext(DCFContext)
    const { showSuccessAlert, editSuccessAlert, showErrorAlert, editErrorAlert } = useContext(DCFContext)



    const valuationClick = () => {
        editShowMore(true)
        console.log('tickk check : ', props.tickerEntry)

        editTickerContext(props.tickerEntry)

        console.log('tickerContext check : ', tickerContext)

    }


    return (


        <div class="row">




            <Tabs defaultActiveKey="normal" id="uncontrolled-tab-example" className="mb-3" >
                <Tab eventKey="normal" title={<span> <img class="normal-image" src={fair}></img> Normal Case </span>}>

                    <div class="row">
                        <div class="col-lg-3" >

                            <div class="form">
                                {/* <h4 style={{ color: 'white', marginTop: '15px', fontWeight: 'bolder' }}>Valuation Calculator</h4> */}
                                <div class="input-container ic1">
                                    <input id="initalFcf" class="input"
                                        type="number"
                                        placeholder=" "
                                        value={initial}
                                        onChange={e => setInitial(e.target.value)}
                                    />
                                    <div class="cut"></div>
                                    <label for="initalFcf" class="placeholder">Initial FCF</label>

                                    <div>

                                        <OverlayTrigger
                                            key="top"
                                            placement="top"

                                            overlay={
                                                <Tooltip>
                                                    The starting value. Depending on the business, you can use the Trailing twelve Months earnings per share, Trailing twelve Months free cash flow per share, or the 'owner's earnings'
                                                </Tooltip>
                                            }

                                        >
                                            <HelpIcon class='help-icon'></HelpIcon>

                                        </OverlayTrigger>

                                    </div>

                                </div>
                                <div class="input-container ic2">
                                    <input id="oneFiveYearGrowth" class="input" placeholder=" "
                                        type="number"
                                        value={fiveYearGrowth}
                                        onChange={e => setFiveYearGrowth(e.target.value)}
                                    />
                                    <div class="cut"></div>
                                    <label for="oneFiveYearGrowth" class="placeholder">1-5 Year growth rate (%)</label>

                                    <div>

                                        <OverlayTrigger
                                            key="top"
                                            placement="top"

                                            overlay={
                                                <Tooltip>
                                                    Growth rate between year 1 to year 5
                                                </Tooltip>
                                            }

                                        >
                                            <HelpIcon class='help-icon'></HelpIcon>

                                        </OverlayTrigger>

                                    </div>

                                </div>
                                <div class="input-container ic2">
                                    <input id="sixTenYearGrowth" class="input" placeholder=" "
                                        type="number"
                                        value={sixYearGrowth}
                                        onChange={e => setSixYearGrowth(e.target.value)}
                                    />
                                    <div class="cut cut-short"></div>
                                    <label for="sixTenYearGrowth" class="placeholder">6-10 Year growth rate (%)</label>

                                    <div>
                                        <OverlayTrigger
                                            key="top"
                                            placement="top"

                                            overlay={
                                                <Tooltip>
                                                    Growth rate between year 6 to year 10
                                                </Tooltip>
                                            }

                                        >
                                            <HelpIcon class='help-icon'></HelpIcon>

                                        </OverlayTrigger>                            </div>

                                </div>
                                <div class="input-container ic2">
                                    <input id="discountRate" class="input" placeholder=" "
                                        type="number"
                                        value={discountRate}
                                        onChange={e => setDiscountRate(e.target.value)}
                                    />
                                    <div class="cut cut-short"></div>
                                    <label for="discountRate" class="placeholder">Discount Rate (%)</label>

                                    <div>
                                        <OverlayTrigger
                                            key="top"
                                            placement="top"

                                            overlay={
                                                <Tooltip>
                                                    The rate of return you're looking for. (10% is good given the current environment)
                                                </Tooltip>
                                            }

                                        >
                                            <HelpIcon class='help-icon'></HelpIcon>

                                        </OverlayTrigger>

                                    </div>

                                </div>
                                <div class="input-container ic2">
                                    <input id="terminalMultiple" class="input" placeholder=" "
                                        type="number"
                                        value={terminalMultiple}
                                        onChange={e => setTerminalMultiple(e.target.value)}
                                    />
                                    <div class="cut cut-short"></div>
                                    <label for="terminalMultiple" class="placeholder">Terminal Multiple</label>

                                    <div>

                                        <OverlayTrigger
                                            key="top"
                                            placement="top"

                                            overlay={
                                                <Tooltip>
                                                    The multiple at which you can sell the business in year 10. (For beginners: use the median historical P/E Ratio, don't go over 35x)
                                                </Tooltip>
                                            }

                                        >
                                            <HelpIcon class='help-icon'></HelpIcon>

                                        </OverlayTrigger>
                                    </div>

                                </div>
                            </div>


                        </div>

                        <div class="col-lg-9">
                            <div className="overflow-container">
                                <div className="dcfOverflow" >
                                    <h4 className="cfHeader">CF:</h4>
                                    <h4 className="dcfHeader"> DCF:</h4>


                                    <div className="year1">

                                        {currentYear}
                                    </div>

                                    <div className="year1Arrow">
                                        <svg

                                            data-icon="angle-double-right"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512"
                                            class="svg-inline--fa fa-angle-double-right fa-w-14 fa-5x"
                                        >
                                            <g class="fa-group">
                                                <path
                                                    fill="currentColor"
                                                    d="M224 273L88.37 409a23.78 23.78 0 0 1-33.8 0L32 386.36a23.94 23.94 0 0 1 0-33.89l96.13-96.37L32 159.73a23.94 23.94 0 0 1 0-33.89l22.44-22.79a23.78 23.78 0 0 1 33.8 0L223.88 239a23.94 23.94 0 0 1 .1 34z"
                                                    class="arrow1"
                                                ></path>
                                                <path
                                                    fill="currentColor"
                                                    d="M415.89 273L280.34 409a23.77 23.77 0 0 1-33.79 0L224 386.26a23.94 23.94 0 0 1 0-33.89L320.11 256l-96-96.47a23.94 23.94 0 0 1 0-33.89l22.52-22.59a23.77 23.77 0 0 1 33.79 0L416 239a24 24 0 0 1-.11 34z"
                                                    class="arrow2"
                                                ></path>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="year2Arrow">
                                        <svg

                                            data-icon="angle-double-right"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512"
                                            class="svg-inline--fa fa-angle-double-right fa-w-14 fa-5x"
                                        >
                                            <g class="fa-group">
                                                <path
                                                    fill="currentColor"
                                                    d="M224 273L88.37 409a23.78 23.78 0 0 1-33.8 0L32 386.36a23.94 23.94 0 0 1 0-33.89l96.13-96.37L32 159.73a23.94 23.94 0 0 1 0-33.89l22.44-22.79a23.78 23.78 0 0 1 33.8 0L223.88 239a23.94 23.94 0 0 1 .1 34z"
                                                    class="arrow1"
                                                ></path>
                                                <path
                                                    fill="currentColor"
                                                    d="M415.89 273L280.34 409a23.77 23.77 0 0 1-33.79 0L224 386.26a23.94 23.94 0 0 1 0-33.89L320.11 256l-96-96.47a23.94 23.94 0 0 1 0-33.89l22.52-22.59a23.77 23.77 0 0 1 33.79 0L416 239a24 24 0 0 1-.11 34z"
                                                    class="arrow2"
                                                ></path>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="year3Arrow">
                                        <svg

                                            data-icon="angle-double-right"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512"
                                            class="svg-inline--fa fa-angle-double-right fa-w-14 fa-5x"
                                        >
                                            <g class="fa-group">
                                                <path
                                                    fill="currentColor"
                                                    d="M224 273L88.37 409a23.78 23.78 0 0 1-33.8 0L32 386.36a23.94 23.94 0 0 1 0-33.89l96.13-96.37L32 159.73a23.94 23.94 0 0 1 0-33.89l22.44-22.79a23.78 23.78 0 0 1 33.8 0L223.88 239a23.94 23.94 0 0 1 .1 34z"
                                                    class="arrow1"
                                                ></path>
                                                <path
                                                    fill="currentColor"
                                                    d="M415.89 273L280.34 409a23.77 23.77 0 0 1-33.79 0L224 386.26a23.94 23.94 0 0 1 0-33.89L320.11 256l-96-96.47a23.94 23.94 0 0 1 0-33.89l22.52-22.59a23.77 23.77 0 0 1 33.79 0L416 239a24 24 0 0 1-.11 34z"
                                                    class="arrow2"
                                                ></path>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="year4Arrow">
                                        <svg

                                            data-icon="angle-double-right"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512"
                                            class="svg-inline--fa fa-angle-double-right fa-w-14 fa-5x"
                                        >
                                            <g class="fa-group">
                                                <path
                                                    fill="currentColor"
                                                    d="M224 273L88.37 409a23.78 23.78 0 0 1-33.8 0L32 386.36a23.94 23.94 0 0 1 0-33.89l96.13-96.37L32 159.73a23.94 23.94 0 0 1 0-33.89l22.44-22.79a23.78 23.78 0 0 1 33.8 0L223.88 239a23.94 23.94 0 0 1 .1 34z"
                                                    class="arrow1"
                                                ></path>
                                                <path
                                                    fill="currentColor"
                                                    d="M415.89 273L280.34 409a23.77 23.77 0 0 1-33.79 0L224 386.26a23.94 23.94 0 0 1 0-33.89L320.11 256l-96-96.47a23.94 23.94 0 0 1 0-33.89l22.52-22.59a23.77 23.77 0 0 1 33.79 0L416 239a24 24 0 0 1-.11 34z"
                                                    class="arrow2"
                                                ></path>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="year5Arrow">
                                        <svg

                                            data-icon="angle-double-right"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512"
                                            class="svg-inline--fa fa-angle-double-right fa-w-14 fa-5x"
                                        >
                                            <g class="fa-group">
                                                <path
                                                    fill="currentColor"
                                                    d="M224 273L88.37 409a23.78 23.78 0 0 1-33.8 0L32 386.36a23.94 23.94 0 0 1 0-33.89l96.13-96.37L32 159.73a23.94 23.94 0 0 1 0-33.89l22.44-22.79a23.78 23.78 0 0 1 33.8 0L223.88 239a23.94 23.94 0 0 1 .1 34z"
                                                    class="arrow1"
                                                ></path>
                                                <path
                                                    fill="currentColor"
                                                    d="M415.89 273L280.34 409a23.77 23.77 0 0 1-33.79 0L224 386.26a23.94 23.94 0 0 1 0-33.89L320.11 256l-96-96.47a23.94 23.94 0 0 1 0-33.89l22.52-22.59a23.77 23.77 0 0 1 33.79 0L416 239a24 24 0 0 1-.11 34z"
                                                    class="arrow2"
                                                ></path>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="year6Arrow">
                                        <svg

                                            data-icon="angle-double-right"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512"
                                            class="svg-inline--fa fa-angle-double-right fa-w-14 fa-5x"
                                        >
                                            <g class="fa-group">
                                                <path
                                                    fill="currentColor"
                                                    d="M224 273L88.37 409a23.78 23.78 0 0 1-33.8 0L32 386.36a23.94 23.94 0 0 1 0-33.89l96.13-96.37L32 159.73a23.94 23.94 0 0 1 0-33.89l22.44-22.79a23.78 23.78 0 0 1 33.8 0L223.88 239a23.94 23.94 0 0 1 .1 34z"
                                                    class="arrow1"
                                                ></path>
                                                <path
                                                    fill="currentColor"
                                                    d="M415.89 273L280.34 409a23.77 23.77 0 0 1-33.79 0L224 386.26a23.94 23.94 0 0 1 0-33.89L320.11 256l-96-96.47a23.94 23.94 0 0 1 0-33.89l22.52-22.59a23.77 23.77 0 0 1 33.79 0L416 239a24 24 0 0 1-.11 34z"
                                                    class="arrow2"
                                                ></path>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="year7Arrow">
                                        <svg

                                            data-icon="angle-double-right"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512"
                                            class="svg-inline--fa fa-angle-double-right fa-w-14 fa-5x"
                                        >
                                            <g class="fa-group">
                                                <path
                                                    fill="currentColor"
                                                    d="M224 273L88.37 409a23.78 23.78 0 0 1-33.8 0L32 386.36a23.94 23.94 0 0 1 0-33.89l96.13-96.37L32 159.73a23.94 23.94 0 0 1 0-33.89l22.44-22.79a23.78 23.78 0 0 1 33.8 0L223.88 239a23.94 23.94 0 0 1 .1 34z"
                                                    class="arrow1"
                                                ></path>
                                                <path
                                                    fill="currentColor"
                                                    d="M415.89 273L280.34 409a23.77 23.77 0 0 1-33.79 0L224 386.26a23.94 23.94 0 0 1 0-33.89L320.11 256l-96-96.47a23.94 23.94 0 0 1 0-33.89l22.52-22.59a23.77 23.77 0 0 1 33.79 0L416 239a24 24 0 0 1-.11 34z"
                                                    class="arrow2"
                                                ></path>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="year8Arrow">
                                        <svg

                                            data-icon="angle-double-right"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512"
                                            class="svg-inline--fa fa-angle-double-right fa-w-14 fa-5x"
                                        >
                                            <g class="fa-group">
                                                <path
                                                    fill="currentColor"
                                                    d="M224 273L88.37 409a23.78 23.78 0 0 1-33.8 0L32 386.36a23.94 23.94 0 0 1 0-33.89l96.13-96.37L32 159.73a23.94 23.94 0 0 1 0-33.89l22.44-22.79a23.78 23.78 0 0 1 33.8 0L223.88 239a23.94 23.94 0 0 1 .1 34z"
                                                    class="arrow1"
                                                ></path>
                                                <path
                                                    fill="currentColor"
                                                    d="M415.89 273L280.34 409a23.77 23.77 0 0 1-33.79 0L224 386.26a23.94 23.94 0 0 1 0-33.89L320.11 256l-96-96.47a23.94 23.94 0 0 1 0-33.89l22.52-22.59a23.77 23.77 0 0 1 33.79 0L416 239a24 24 0 0 1-.11 34z"
                                                    class="arrow2"
                                                ></path>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="year9Arrow">
                                        <svg

                                            data-icon="angle-double-right"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512"
                                            class="svg-inline--fa fa-angle-double-right fa-w-14 fa-5x"
                                        >
                                            <g class="fa-group">
                                                <path
                                                    fill="currentColor"
                                                    d="M224 273L88.37 409a23.78 23.78 0 0 1-33.8 0L32 386.36a23.94 23.94 0 0 1 0-33.89l96.13-96.37L32 159.73a23.94 23.94 0 0 1 0-33.89l22.44-22.79a23.78 23.78 0 0 1 33.8 0L223.88 239a23.94 23.94 0 0 1 .1 34z"
                                                    class="arrow1"
                                                ></path>
                                                <path
                                                    fill="currentColor"
                                                    d="M415.89 273L280.34 409a23.77 23.77 0 0 1-33.79 0L224 386.26a23.94 23.94 0 0 1 0-33.89L320.11 256l-96-96.47a23.94 23.94 0 0 1 0-33.89l22.52-22.59a23.77 23.77 0 0 1 33.79 0L416 239a24 24 0 0 1-.11 34z"
                                                    class="arrow2"
                                                ></path>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="year10Arrow">
                                        <svg

                                            data-icon="angle-double-right"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512"
                                            class="svg-inline--fa fa-angle-double-right fa-w-14 fa-5x"
                                        >
                                            <g class="fa-group">
                                                <path
                                                    fill="currentColor"
                                                    d="M224 273L88.37 409a23.78 23.78 0 0 1-33.8 0L32 386.36a23.94 23.94 0 0 1 0-33.89l96.13-96.37L32 159.73a23.94 23.94 0 0 1 0-33.89l22.44-22.79a23.78 23.78 0 0 1 33.8 0L223.88 239a23.94 23.94 0 0 1 .1 34z"
                                                    class="arrow1"
                                                ></path>
                                                <path
                                                    fill="currentColor"
                                                    d="M415.89 273L280.34 409a23.77 23.77 0 0 1-33.79 0L224 386.26a23.94 23.94 0 0 1 0-33.89L320.11 256l-96-96.47a23.94 23.94 0 0 1 0-33.89l22.52-22.59a23.77 23.77 0 0 1 33.79 0L416 239a24 24 0 0 1-.11 34z"
                                                    class="arrow2"
                                                ></path>
                                            </g>
                                        </svg>
                                    </div>

                                    <div className="year2">
                                        {currentYear + 1}
                                    </div>


                                    <div className="year3">

                                        {currentYear + 2}
                                    </div>

                                    <div className="year4">

                                        {currentYear + 3}
                                    </div>

                                    <div className="year5">

                                        {currentYear + 4}
                                    </div>

                                    <div className="year6">

                                        {currentYear + 5}
                                    </div>

                                    <div className="year7">

                                        {currentYear + 6}
                                    </div>

                                    <div className="year8">

                                        {currentYear + 7}
                                    </div>

                                    <div className="year9">

                                        {currentYear + 8}
                                    </div>

                                    <div className="year10">

                                        {currentYear + 9}

                                    </div>
                                    <div className="year10Terminal">

                                        {currentYear + 9} Terminal

                                    </div>






                                    <form className="formContainer">

                                        <div className="year1Input">
                                            <input name="Year1 changeable input" class={((year1 === "NaN") || (year1 === '') || parseFloat(year1) === initial * ((1 + parseFloat(fiveYearGrowth) / 100) ** 1)) ? 'inputCF' : 'edited'} type="number" onChange={(e) => setYear1(e.target.value)} value={year1}
                                            ></input>
                                        </div>

                                        <div className="year2Input">
                                            <input name="Year2 changeable input" class={((year2 === "NaN") || (year2 === '') || parseFloat(year2) === initial * ((1 + parseFloat(fiveYearGrowth) / 100) ** 2)) ? 'inputCF' : 'edited'} type="number" onChange={(e) => setYear2(e.target.value)} value={year2}
                                            ></input>
                                        </div>

                                        <div className="year3Input">
                                            <input name="Year3 changeable input" class={((year3 === "NaN") || (year3 === '') || parseFloat(year3) === initial * ((1 + parseFloat(fiveYearGrowth) / 100) ** 3)) ? 'inputCF' : 'edited'} type="number" onChange={(e) => setYear3(e.target.value)} value={year3}
                                            ></input>
                                        </div>

                                        <div className="year4Input">
                                            <input name="Year4 changeable input" class={((year4 === "NaN") || (year4 === '') || parseFloat(year4) === initial * ((1 + parseFloat(fiveYearGrowth) / 100) ** 4)) ? 'inputCF' : 'edited'} type="number" onChange={(e) => setYear4(e.target.value)} value={year4}
                                            ></input>
                                        </div>

                                        <div className="year5Input">
                                            <input name="Year5 changeable input" class={((year5 === "NaN") || (year5 === '') || parseFloat(year5) === initial * ((1 + parseFloat(fiveYearGrowth) / 100) ** 5)) ? 'inputCF' : 'edited'} type="number" onChange={(e) => setYear5(e.target.value)} value={year5}
                                            ></input>
                                        </div>

                                        <div className="year6Input">
                                            <input name="Year6 changeable input" class={((year6 === "NaN") || (year6 === '') || parseFloat(year6) === (initial * ((1 + parseFloat(fiveYearGrowth) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowth / 100)) ** 1)) ? 'inputCF' : 'edited'} type="number" onChange={(e) => setYear6(e.target.value)} value={year6}
                                            ></input>
                                        </div>

                                        <div className="year7Input">
                                            <input name="Year7 changeable input" class={((year7 === "NaN") || (year7 === '') || parseFloat(year7) === (initial * ((1 + parseFloat(fiveYearGrowth) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowth / 100)) ** 2)) ? 'inputCF' : 'edited'} type="number" onChange={(e) => setYear7(e.target.value)} value={year7}
                                            ></input>
                                        </div>

                                        <div className="year8Input">
                                            <input name="Year8 changeable input" class={((year8 === "NaN") || (year8 === '') || parseFloat(year8) === (initial * ((1 + parseFloat(fiveYearGrowth) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowth / 100)) ** 3)) ? 'inputCF' : 'edited'} type="number" onChange={(e) => setYear8(e.target.value)} value={year8}
                                            ></input>
                                        </div>

                                        <div className="year9Input">
                                            <input name="Year9 changeable input" class={((year9 === "NaN") || (year9 === '') || parseFloat(year9) === (initial * ((1 + parseFloat(fiveYearGrowth) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowth / 100)) ** 4)) ? 'inputCF' : 'edited'} type="number" onChange={(e) => setYear9(e.target.value)} value={year9}
                                            ></input>
                                        </div>

                                        <div className="year10Input">
                                            <input name="Year10 changeable input" class={((year10 === "NaN") || (year10 === '') || parseFloat(year10) === (initial * ((1 + parseFloat(fiveYearGrowth) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowth / 100)) ** 5)) ? 'inputCF' : 'edited'} type="number" onChange={(e) => setYear10(e.target.value)} value={year10}
                                            ></input>
                                        </div>

                                        <div className="year10TerminalInput">
                                            <input name="Year10Terminal changeable input" class={((year10Terminal === "NaN") || (year10Terminal === '') || parseFloat(year10Terminal) === (initial * ((1 + parseFloat(fiveYearGrowth) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowth / 100)) ** 4) + (parseFloat(terminalMultiple - 1) * ((initial * ((1 + parseFloat(fiveYearGrowth / 100)) ** 5)) * ((1 + parseFloat(sixYearGrowth / 100)) ** 4)))) ? 'inputCF' : 'edited'} type="number" onChange={(e) => setYear10Terminal(e.target.value)} value={year10Terminal}
                                            ></input>
                                        </div>

                                        <OverlayTrigger
                                            key="top"
                                            placement="top"

                                            overlay={
                                                <Tooltip>
                                                    Reset edited cash flows
                                                </Tooltip>
                                            }

                                        >
                                            <div className="resetDiv">

                                                <input type="reset" defaultValue="Reset" onClick={() => reset()} class="fa fa-undo" class="resetButton" />

                                            </div>
                                        </OverlayTrigger>

                                        {isAdvanced ? 

                                        <>
                                    <div className="advanced-label" onClick={()=>setIsAdvanced(!isAdvanced)}>Advanced <ArrowDropUpIcon></ArrowDropUpIcon></div>

                                        <OverlayTrigger
                                            key="top"
                                            placement="top"

                                            overlay={
                                                <Tooltip>
                                                    Click if no cash will be returned to shareholders
                                                </Tooltip>
                                            }

                                        >
                                            <div className="zeroedDiv" onClick={() => zero()}>
                                                {/* <input defaultValue="Reset" onClick={() => reset()} /> */}
                                                0
                                            </div>
                                        </OverlayTrigger>

                                        <OverlayTrigger
                                            key="top"
                                            placement="top"

                                            overlay={
                                                <Tooltip>
                                                    Percentage of cash returned to shareholders
                                                </Tooltip>
                                            }

                                        >
                                            <div className="customCashReturnedDiv" >
                                                <div className='cashReturnedLabel'>
                                                    Cash returned
                                                </div>
                                                <div style={{ display: 'inline', color: 'white' }}>
                                                    <input className='cashReturnedInput' value={cashReturned} onChange={(e) => setCashReturned(e.target.value)} />
                                                    %
                                                </div>

                                            </div>
                                        </OverlayTrigger>
                                        </>
                                    :
                                    <div className="advanced-label" onClick={()=>setIsAdvanced(!isAdvanced)}>Advanced <ArrowDropDownIcon></ArrowDropDownIcon></div>
                                        }


                                    </form>


                                    <div className="year1Discounted">

                                        {(Math.round(dYear1 * 100) / 100).toFixed(2)}

                                    </div>

                                    <div className="year2Discounted">
                                        {(Math.round(dYear2 * 100) / 100).toFixed(2)}
                                    </div>

                                    <div className="year3Discounted">
                                        {(Math.round(dYear3 * 100) / 100).toFixed(2)}
                                    </div>

                                    <div className="year4Discounted">
                                        {(Math.round(dYear4 * 100) / 100).toFixed(2)}
                                    </div>

                                    <div className="year5Discounted">
                                        {(Math.round(dYear5 * 100) / 100).toFixed(2)}
                                    </div>

                                    <div className="year6Discounted">
                                        {(Math.round(dYear6 * 100) / 100).toFixed(2)}
                                    </div>

                                    <div className="year7Discounted">
                                        {(Math.round(dYear7 * 100) / 100).toFixed(2)}
                                    </div>


                                    <div className="year8Discounted">
                                        {(Math.round(dYear8 * 100) / 100).toFixed(2)}
                                    </div>

                                    <div className="year9Discounted">
                                        {(Math.round(dYear9 * 100) / 100).toFixed(2)}
                                    </div>

                                    <div className="year10Discounted">
                                        {(Math.round(dYear10 * 100) / 100).toFixed(2)}
                                    </div>
                                    <div className="year10TerminalDiscounted">
                                        {(Math.round(dYear10Terminal * 100) / 100).toFixed(2)}
                                    </div>

                                    <div class='row'>
                                        <div class='col'>
                                            <div className=" intrinsicValue" style={{ marginLeft: '2.5%' }}>
                                                Intrinsic Value: {((Math.round((dYear1 + dYear2 + dYear3 + dYear4 + dYear5 + dYear6 + dYear7 + dYear8 + dYear9 + dYear10 + dYear10Terminal) * 100) / 100) - hiddenValue).toFixed(2)}
                                                {sessionStorage.setItem('normal', ((Math.round((dYear1 + dYear2 + dYear3 + dYear4 + dYear5 + dYear6 + dYear7 + dYear8 + dYear9 + dYear10 + dYear10Terminal) * 100) / 100) - hiddenValue).toFixed(2))}
                                            </div>
                                            <div className="cashReturned" style={{ marginLeft: '2.5%' }}>
                                                <div className='row'>
                                                    <div className='hiddenValueContainer'>
                                                        <div className='hiddenValue'>

                                                            Hidden Value


                                                            <OverlayTrigger
                                                                key="top"
                                                                placement="top"

                                                                overlay={
                                                                    <Tooltip>
                                                                        Any additional value you wish to add (Net cash - debt ,  current assets - liabilities, 'moat' value, etc)
                                                                    </Tooltip>
                                                                }

                                                            >
                                                                <HelpIcon style={{
                                                                    'margin-left': '5px',
                                                                    'fill': 'green',
                                                                    'width': '25px'
                                                                }}></HelpIcon>
                                                            </OverlayTrigger>

                                                        </div>
                                                        <div className='hiddenValueInput'>
                                                            <input value={hiddenValue} onChange={(e) => setHiddenValue(e.target.value)} className='hiddenValueInput'></input>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                        <div class='col'>

                                        </div>
                                    </div>


                                </div>



                            </div>
                        </div>
                    </div>


                </Tab>




                <Tab eventKey="bull" title={<span > <img class="bull-image" src={bull}></img> Bull Case </span>}>



                    <div class="row">
                        <div class="col-lg-3" >

                            <div class="form">
                                {/* <h4 style={{ color: 'white', marginTop: '15px', fontWeight: 'bolder' }}>Valuation Calculator</h4> */}
                                <div class="input-container ic1">
                                    <input id="initalFcf" class="input"
                                        type="number"
                                        placeholder=" "
                                        value={initialBull}
                                        onChange={e => setInitialBull(e.target.value)}
                                    />
                                    <div class="cut"></div>
                                    <label for="initalFcf" class="placeholder">Initial FCF</label>

                                    <div>

                                        <OverlayTrigger
                                            key="top"
                                            placement="top"

                                            overlay={
                                                <Tooltip>
                                                    The starting value. Depending on the business, you can use the Trailing twelve Months earnings per share, Trailing twelve Months free cash flow per share, or the 'owner's earnings'
                                                </Tooltip>
                                            }

                                        >
                                            <HelpIcon class='help-icon'></HelpIcon>

                                        </OverlayTrigger>

                                    </div>

                                </div>
                                <div class="input-container ic2">
                                    <input id="oneFiveYearGrowth" class="input" placeholder=" "
                                        type="number"
                                        value={fiveYearGrowthBull}
                                        onChange={e => setFiveYearGrowthBull(e.target.value)}
                                    />
                                    <div class="cut"></div>
                                    <label for="oneFiveYearGrowth" class="placeholder">1-5 Year growth rate (%)</label>

                                    <div>

                                        <OverlayTrigger
                                            key="top"
                                            placement="top"

                                            overlay={
                                                <Tooltip>
                                                    Growth rate between year 1 to year 5
                                                </Tooltip>
                                            }

                                        >
                                            <HelpIcon class='help-icon'></HelpIcon>

                                        </OverlayTrigger>

                                    </div>

                                </div>
                                <div class="input-container ic2">
                                    <input id="sixTenYearGrowth" class="input" placeholder=" "
                                        type="number"
                                        value={sixYearGrowthBull}
                                        onChange={e => setSixYearGrowthBull(e.target.value)}
                                    />
                                    <div class="cut cut-short"></div>
                                    <label for="sixTenYearGrowth" class="placeholder">6-10 Year growth rate (%)</label>

                                    <div>
                                        <OverlayTrigger
                                            key="top"
                                            placement="top"

                                            overlay={
                                                <Tooltip>
                                                    Growth rate between year 6 to year 10
                                                </Tooltip>
                                            }

                                        >
                                            <HelpIcon class='help-icon'></HelpIcon>

                                        </OverlayTrigger>                            </div>

                                </div>
                                <div class="input-container ic2">
                                    <input id="discountRate" class="input" placeholder=" "
                                        type="number"
                                        value={discountRateBull}
                                        onChange={e => setDiscountRateBull(e.target.value)}
                                    />
                                    <div class="cut cut-short"></div>
                                    <label for="discountRate" class="placeholder">Discount Rate (%)</label>

                                    <div>
                                        <OverlayTrigger
                                            key="top"
                                            placement="top"

                                            overlay={
                                                <Tooltip>
                                                    The rate of return you're looking for. (10% is good given the current environment)
                                                </Tooltip>
                                            }

                                        >
                                            <HelpIcon class='help-icon'></HelpIcon>

                                        </OverlayTrigger>

                                    </div>

                                </div>
                                <div class="input-container ic2">
                                    <input id="terminalMultiple" class="input" placeholder=" "
                                        type="number"
                                        value={terminalMultipleBull}
                                        onChange={e => setTerminalMultipleBull(e.target.value)}
                                    />
                                    <div class="cut cut-short"></div>
                                    <label for="terminalMultiple" class="placeholder">Terminal Multiple</label>

                                    <div>

                                        <OverlayTrigger
                                            key="top"
                                            placement="top"

                                            overlay={
                                                <Tooltip>
                                                    The multiple at which you can sell the business in year 10. (For beginners: use the median historical P/E Ratio, don't go over 35x)
                                                </Tooltip>
                                            }

                                        >
                                            <HelpIcon class='help-icon'></HelpIcon>

                                        </OverlayTrigger>
                                    </div>

                                </div>
                            </div>


                        </div>

                        <div class="col-lg-9">
                            <div className="overflow-container">
                                <div className="dcfOverflow" >
                                    <h4 className="cfHeader">CF:</h4>
                                    <h4 className="dcfHeader"> DCF:</h4>


                                    <div className="year1">

                                        {currentYear}
                                    </div>

                                    <div className="year1Arrow">
                                        <svg

                                            data-icon="angle-double-right"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512"
                                            class="svg-inline--fa fa-angle-double-right fa-w-14 fa-5x"
                                        >
                                            <g class="fa-group">
                                                <path
                                                    fill="currentColor"
                                                    d="M224 273L88.37 409a23.78 23.78 0 0 1-33.8 0L32 386.36a23.94 23.94 0 0 1 0-33.89l96.13-96.37L32 159.73a23.94 23.94 0 0 1 0-33.89l22.44-22.79a23.78 23.78 0 0 1 33.8 0L223.88 239a23.94 23.94 0 0 1 .1 34z"
                                                    class="arrow1"
                                                ></path>
                                                <path
                                                    fill="currentColor"
                                                    d="M415.89 273L280.34 409a23.77 23.77 0 0 1-33.79 0L224 386.26a23.94 23.94 0 0 1 0-33.89L320.11 256l-96-96.47a23.94 23.94 0 0 1 0-33.89l22.52-22.59a23.77 23.77 0 0 1 33.79 0L416 239a24 24 0 0 1-.11 34z"
                                                    class="arrow2"
                                                ></path>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="year2Arrow">
                                        <svg

                                            data-icon="angle-double-right"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512"
                                            class="svg-inline--fa fa-angle-double-right fa-w-14 fa-5x"
                                        >
                                            <g class="fa-group">
                                                <path
                                                    fill="currentColor"
                                                    d="M224 273L88.37 409a23.78 23.78 0 0 1-33.8 0L32 386.36a23.94 23.94 0 0 1 0-33.89l96.13-96.37L32 159.73a23.94 23.94 0 0 1 0-33.89l22.44-22.79a23.78 23.78 0 0 1 33.8 0L223.88 239a23.94 23.94 0 0 1 .1 34z"
                                                    class="arrow1"
                                                ></path>
                                                <path
                                                    fill="currentColor"
                                                    d="M415.89 273L280.34 409a23.77 23.77 0 0 1-33.79 0L224 386.26a23.94 23.94 0 0 1 0-33.89L320.11 256l-96-96.47a23.94 23.94 0 0 1 0-33.89l22.52-22.59a23.77 23.77 0 0 1 33.79 0L416 239a24 24 0 0 1-.11 34z"
                                                    class="arrow2"
                                                ></path>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="year3Arrow">
                                        <svg

                                            data-icon="angle-double-right"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512"
                                            class="svg-inline--fa fa-angle-double-right fa-w-14 fa-5x"
                                        >
                                            <g class="fa-group">
                                                <path
                                                    fill="currentColor"
                                                    d="M224 273L88.37 409a23.78 23.78 0 0 1-33.8 0L32 386.36a23.94 23.94 0 0 1 0-33.89l96.13-96.37L32 159.73a23.94 23.94 0 0 1 0-33.89l22.44-22.79a23.78 23.78 0 0 1 33.8 0L223.88 239a23.94 23.94 0 0 1 .1 34z"
                                                    class="arrow1"
                                                ></path>
                                                <path
                                                    fill="currentColor"
                                                    d="M415.89 273L280.34 409a23.77 23.77 0 0 1-33.79 0L224 386.26a23.94 23.94 0 0 1 0-33.89L320.11 256l-96-96.47a23.94 23.94 0 0 1 0-33.89l22.52-22.59a23.77 23.77 0 0 1 33.79 0L416 239a24 24 0 0 1-.11 34z"
                                                    class="arrow2"
                                                ></path>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="year4Arrow">
                                        <svg

                                            data-icon="angle-double-right"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512"
                                            class="svg-inline--fa fa-angle-double-right fa-w-14 fa-5x"
                                        >
                                            <g class="fa-group">
                                                <path
                                                    fill="currentColor"
                                                    d="M224 273L88.37 409a23.78 23.78 0 0 1-33.8 0L32 386.36a23.94 23.94 0 0 1 0-33.89l96.13-96.37L32 159.73a23.94 23.94 0 0 1 0-33.89l22.44-22.79a23.78 23.78 0 0 1 33.8 0L223.88 239a23.94 23.94 0 0 1 .1 34z"
                                                    class="arrow1"
                                                ></path>
                                                <path
                                                    fill="currentColor"
                                                    d="M415.89 273L280.34 409a23.77 23.77 0 0 1-33.79 0L224 386.26a23.94 23.94 0 0 1 0-33.89L320.11 256l-96-96.47a23.94 23.94 0 0 1 0-33.89l22.52-22.59a23.77 23.77 0 0 1 33.79 0L416 239a24 24 0 0 1-.11 34z"
                                                    class="arrow2"
                                                ></path>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="year5Arrow">
                                        <svg

                                            data-icon="angle-double-right"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512"
                                            class="svg-inline--fa fa-angle-double-right fa-w-14 fa-5x"
                                        >
                                            <g class="fa-group">
                                                <path
                                                    fill="currentColor"
                                                    d="M224 273L88.37 409a23.78 23.78 0 0 1-33.8 0L32 386.36a23.94 23.94 0 0 1 0-33.89l96.13-96.37L32 159.73a23.94 23.94 0 0 1 0-33.89l22.44-22.79a23.78 23.78 0 0 1 33.8 0L223.88 239a23.94 23.94 0 0 1 .1 34z"
                                                    class="arrow1"
                                                ></path>
                                                <path
                                                    fill="currentColor"
                                                    d="M415.89 273L280.34 409a23.77 23.77 0 0 1-33.79 0L224 386.26a23.94 23.94 0 0 1 0-33.89L320.11 256l-96-96.47a23.94 23.94 0 0 1 0-33.89l22.52-22.59a23.77 23.77 0 0 1 33.79 0L416 239a24 24 0 0 1-.11 34z"
                                                    class="arrow2"
                                                ></path>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="year6Arrow">
                                        <svg

                                            data-icon="angle-double-right"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512"
                                            class="svg-inline--fa fa-angle-double-right fa-w-14 fa-5x"
                                        >
                                            <g class="fa-group">
                                                <path
                                                    fill="currentColor"
                                                    d="M224 273L88.37 409a23.78 23.78 0 0 1-33.8 0L32 386.36a23.94 23.94 0 0 1 0-33.89l96.13-96.37L32 159.73a23.94 23.94 0 0 1 0-33.89l22.44-22.79a23.78 23.78 0 0 1 33.8 0L223.88 239a23.94 23.94 0 0 1 .1 34z"
                                                    class="arrow1"
                                                ></path>
                                                <path
                                                    fill="currentColor"
                                                    d="M415.89 273L280.34 409a23.77 23.77 0 0 1-33.79 0L224 386.26a23.94 23.94 0 0 1 0-33.89L320.11 256l-96-96.47a23.94 23.94 0 0 1 0-33.89l22.52-22.59a23.77 23.77 0 0 1 33.79 0L416 239a24 24 0 0 1-.11 34z"
                                                    class="arrow2"
                                                ></path>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="year7Arrow">
                                        <svg

                                            data-icon="angle-double-right"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512"
                                            class="svg-inline--fa fa-angle-double-right fa-w-14 fa-5x"
                                        >
                                            <g class="fa-group">
                                                <path
                                                    fill="currentColor"
                                                    d="M224 273L88.37 409a23.78 23.78 0 0 1-33.8 0L32 386.36a23.94 23.94 0 0 1 0-33.89l96.13-96.37L32 159.73a23.94 23.94 0 0 1 0-33.89l22.44-22.79a23.78 23.78 0 0 1 33.8 0L223.88 239a23.94 23.94 0 0 1 .1 34z"
                                                    class="arrow1"
                                                ></path>
                                                <path
                                                    fill="currentColor"
                                                    d="M415.89 273L280.34 409a23.77 23.77 0 0 1-33.79 0L224 386.26a23.94 23.94 0 0 1 0-33.89L320.11 256l-96-96.47a23.94 23.94 0 0 1 0-33.89l22.52-22.59a23.77 23.77 0 0 1 33.79 0L416 239a24 24 0 0 1-.11 34z"
                                                    class="arrow2"
                                                ></path>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="year8Arrow">
                                        <svg

                                            data-icon="angle-double-right"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512"
                                            class="svg-inline--fa fa-angle-double-right fa-w-14 fa-5x"
                                        >
                                            <g class="fa-group">
                                                <path
                                                    fill="currentColor"
                                                    d="M224 273L88.37 409a23.78 23.78 0 0 1-33.8 0L32 386.36a23.94 23.94 0 0 1 0-33.89l96.13-96.37L32 159.73a23.94 23.94 0 0 1 0-33.89l22.44-22.79a23.78 23.78 0 0 1 33.8 0L223.88 239a23.94 23.94 0 0 1 .1 34z"
                                                    class="arrow1"
                                                ></path>
                                                <path
                                                    fill="currentColor"
                                                    d="M415.89 273L280.34 409a23.77 23.77 0 0 1-33.79 0L224 386.26a23.94 23.94 0 0 1 0-33.89L320.11 256l-96-96.47a23.94 23.94 0 0 1 0-33.89l22.52-22.59a23.77 23.77 0 0 1 33.79 0L416 239a24 24 0 0 1-.11 34z"
                                                    class="arrow2"
                                                ></path>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="year9Arrow">
                                        <svg

                                            data-icon="angle-double-right"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512"
                                            class="svg-inline--fa fa-angle-double-right fa-w-14 fa-5x"
                                        >
                                            <g class="fa-group">
                                                <path
                                                    fill="currentColor"
                                                    d="M224 273L88.37 409a23.78 23.78 0 0 1-33.8 0L32 386.36a23.94 23.94 0 0 1 0-33.89l96.13-96.37L32 159.73a23.94 23.94 0 0 1 0-33.89l22.44-22.79a23.78 23.78 0 0 1 33.8 0L223.88 239a23.94 23.94 0 0 1 .1 34z"
                                                    class="arrow1"
                                                ></path>
                                                <path
                                                    fill="currentColor"
                                                    d="M415.89 273L280.34 409a23.77 23.77 0 0 1-33.79 0L224 386.26a23.94 23.94 0 0 1 0-33.89L320.11 256l-96-96.47a23.94 23.94 0 0 1 0-33.89l22.52-22.59a23.77 23.77 0 0 1 33.79 0L416 239a24 24 0 0 1-.11 34z"
                                                    class="arrow2"
                                                ></path>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="year10Arrow">
                                        <svg

                                            data-icon="angle-double-right"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512"
                                            class="svg-inline--fa fa-angle-double-right fa-w-14 fa-5x"
                                        >
                                            <g class="fa-group">
                                                <path
                                                    fill="currentColor"
                                                    d="M224 273L88.37 409a23.78 23.78 0 0 1-33.8 0L32 386.36a23.94 23.94 0 0 1 0-33.89l96.13-96.37L32 159.73a23.94 23.94 0 0 1 0-33.89l22.44-22.79a23.78 23.78 0 0 1 33.8 0L223.88 239a23.94 23.94 0 0 1 .1 34z"
                                                    class="arrow1"
                                                ></path>
                                                <path
                                                    fill="currentColor"
                                                    d="M415.89 273L280.34 409a23.77 23.77 0 0 1-33.79 0L224 386.26a23.94 23.94 0 0 1 0-33.89L320.11 256l-96-96.47a23.94 23.94 0 0 1 0-33.89l22.52-22.59a23.77 23.77 0 0 1 33.79 0L416 239a24 24 0 0 1-.11 34z"
                                                    class="arrow2"
                                                ></path>
                                            </g>
                                        </svg>
                                    </div>

                                    <div className="year2">
                                        {currentYear + 1}
                                    </div>


                                    <div className="year3">

                                        {currentYear + 2}
                                    </div>

                                    <div className="year4">

                                        {currentYear + 3}
                                    </div>

                                    <div className="year5">

                                        {currentYear + 4}
                                    </div>

                                    <div className="year6">

                                        {currentYear + 5}
                                    </div>

                                    <div className="year7">

                                        {currentYear + 6}
                                    </div>

                                    <div className="year8">

                                        {currentYear + 7}
                                    </div>

                                    <div className="year9">

                                        {currentYear + 8}
                                    </div>

                                    <div className="year10">

                                        {currentYear + 9}

                                    </div>

                                    <div className="year10Terminal">

                                        {currentYear + 9} Terminal

                                    </div>





                                    <form className="formContainer">

                                        <div className="year1Input">
                                            <input name="Year1 changeable input" class={((year1Bull === "NaN") || (year1Bull === '') || parseFloat(year1Bull) === initialBull * ((1 + parseFloat(fiveYearGrowthBull) / 100) ** 1)) ? 'inputCF' : 'edited'} type="number" onChange={(e) => setYear1Bull(e.target.value)} value={year1Bull}
                                            ></input>
                                        </div>

                                        <div className="year2Input">
                                            <input name="Year2 changeable input" class={((year2Bull === "NaN") || (year2Bull === '') || parseFloat(year2Bull) === initialBull * ((1 + parseFloat(fiveYearGrowthBull) / 100) ** 2)) ? 'inputCF' : 'edited'} type="number" onChange={(e) => setYear2Bull(e.target.value)} value={year2Bull}
                                            ></input>
                                        </div>

                                        <div className="year3Input">
                                            <input name="Year3 changeable input" class={((year3Bull === "NaN") || (year3Bull === '') || parseFloat(year3Bull) === initialBull * ((1 + parseFloat(fiveYearGrowthBull) / 100) ** 3)) ? 'inputCF' : 'edited'} type="number" onChange={(e) => setYear3Bull(e.target.value)} value={year3Bull}
                                            ></input>
                                        </div>

                                        <div className="year4Input">
                                            <input name="Year4 changeable input" class={((year4Bull === "NaN") || (year4Bull === '') || parseFloat(year4Bull) === initialBull * ((1 + parseFloat(fiveYearGrowthBull) / 100) ** 4)) ? 'inputCF' : 'edited'} type="number" onChange={(e) => setYear4Bull(e.target.value)} value={year4Bull}
                                            ></input>
                                        </div>

                                        <div className="year5Input">
                                            <input name="Year5 changeable input" class={((year5Bull === "NaN") || (year5Bull === '') || parseFloat(year5Bull) === initialBull * ((1 + parseFloat(fiveYearGrowthBull) / 100) ** 5)) ? 'inputCF' : 'edited'} type="number" onChange={(e) => setYear5Bull(e.target.value)} value={year5Bull}
                                            ></input>
                                        </div>

                                        <div className="year6Input">
                                            <input name="Year6 changeable input" class={((year6Bull === "NaN") || (year6Bull === '') || parseFloat(year6Bull) === (initialBull * ((1 + parseFloat(fiveYearGrowthBull) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowthBull / 100)) ** 1)) ? 'inputCF' : 'edited'} type="number" onChange={(e) => setYear6Bull(e.target.value)} value={year6Bull}
                                            ></input>
                                        </div>

                                        <div className="year7Input">
                                            <input name="Year7 changeable input" class={((year7Bull === "NaN") || (year7Bull === '') || parseFloat(year7Bull) === (initialBull * ((1 + parseFloat(fiveYearGrowthBull) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowthBull / 100)) ** 2)) ? 'inputCF' : 'edited'} type="number" onChange={(e) => setYear7Bull(e.target.value)} value={year7Bull}
                                            ></input>
                                        </div>

                                        <div className="year8Input">
                                            <input name="Year8 changeable input" class={((year8Bull === "NaN") || (year8Bull === '') || parseFloat(year8Bull) === (initialBull * ((1 + parseFloat(fiveYearGrowthBull) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowthBull / 100)) ** 3)) ? 'inputCF' : 'edited'} type="number" onChange={(e) => setYear8Bull(e.target.value)} value={year8Bull}
                                            ></input>
                                        </div>

                                        <div className="year9Input">
                                            <input name="Year9 changeable input" class={((year9Bull === "NaN") || (year9Bull === '') || parseFloat(year9Bull) === (initialBull * ((1 + parseFloat(fiveYearGrowthBull) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowthBull / 100)) ** 4)) ? 'inputCF' : 'edited'} type="number" onChange={(e) => setYear9Bull(e.target.value)} value={year9Bull}
                                            ></input>
                                        </div>

                                        <div className="year10Input">
                                            <input name="Year10 changeable input" class={((year10Bull === "NaN") || (year10Bull === '') || parseFloat(year10Bull) === (initialBull * ((1 + parseFloat(fiveYearGrowthBull) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowthBull / 100)) ** 5)) ? 'inputCF' : 'edited'} type="number" onChange={(e) => setYear10Bull(e.target.value)} value={year10Bull}
                                            ></input>
                                        </div>
                                        <div className="year10TerminalInput">
                                            <input name="Year10Terminal changeable input" class={((year10TerminalBull === "NaN") || (year10TerminalBull === '') || parseFloat(year10TerminalBull) === (initialBull * ((1 + parseFloat(fiveYearGrowthBull) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowthBull / 100)) ** 4) + (parseFloat(terminalMultipleBull - 1) * ((initialBull * ((1 + parseFloat(fiveYearGrowthBull / 100)) ** 5)) * ((1 + parseFloat(sixYearGrowthBull / 100)) ** 4)))) ? 'inputCF' : 'edited'} type="number" onChange={(e) => setYear10TerminalBull(e.target.value)} value={year10TerminalBull}
                                            ></input>
                                        </div>

                                        <OverlayTrigger
                                            key="top"
                                            placement="top"

                                            overlay={
                                                <Tooltip>
                                                    Reset edited cash flows
                                                </Tooltip>
                                            }

                                        >
                                            <div className="resetDiv">

                                                <input type="reset" defaultValue="Reset" onClick={() => resetBull()} class="fa fa-undo" class="resetButton" />

                                            </div>
                                        </OverlayTrigger>


                                        {isAdvancedBull ? 

<>
<div className="advanced-label" onClick={()=>setIsAdvancedBull(!isAdvancedBull)}>Advanced <ArrowDropUpIcon></ArrowDropUpIcon></div>

                                        <OverlayTrigger
                                            key="top"
                                            placement="top"

                                            overlay={
                                                <Tooltip>
                                                    Click if no cash will be returned to shareholders
                                                </Tooltip>
                                            }

                                        >
                                            <div className="zeroedDiv" onClick={() => zeroBull()}>
                                                {/* <input defaultValue="Reset" onClick={() => reset()} /> */}
                                                0
                                            </div>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            key="top"
                                            placement="top"

                                            overlay={
                                                <Tooltip>
                                                    Percentage of cash returned to shareholders
                                                </Tooltip>
                                            }

                                        >
                                            <div className="customCashReturnedDiv" >
                                                <div className='cashReturnedLabel'>
                                                    Cash returned
                                                </div>
                                                <div style={{ display: 'inline', color: 'white' }}>
                                                    <input className='cashReturnedInput' value={cashReturnedBull} onChange={(e) => setCashReturnedBull(e.target.value)} />
                                                    %
                                                </div>

                                            </div>
                                        </OverlayTrigger>
                                        </>
                                    :
                                    <div className="advanced-label" onClick={()=>setIsAdvancedBull(!isAdvancedBull)}>Advanced <ArrowDropDownIcon></ArrowDropDownIcon></div>
                                        }


                                    </form>


                                    <div className="year1Discounted">

                                        {(Math.round(dYear1Bull * 100) / 100).toFixed(2)}

                                    </div>

                                    <div className="year2Discounted">
                                        {(Math.round(dYear2Bull * 100) / 100).toFixed(2)}
                                    </div>

                                    <div className="year3Discounted">
                                        {(Math.round(dYear3Bull * 100) / 100).toFixed(2)}
                                    </div>

                                    <div className="year4Discounted">
                                        {(Math.round(dYear4Bull * 100) / 100).toFixed(2)}
                                    </div>

                                    <div className="year5Discounted">
                                        {(Math.round(dYear5Bull * 100) / 100).toFixed(2)}
                                    </div>

                                    <div className="year6Discounted">
                                        {(Math.round(dYear6Bull * 100) / 100).toFixed(2)}
                                    </div>

                                    <div className="year7Discounted">
                                        {(Math.round(dYear7Bull * 100) / 100).toFixed(2)}
                                    </div>


                                    <div className="year8Discounted">
                                        {(Math.round(dYear8Bull * 100) / 100).toFixed(2)}
                                    </div>

                                    <div className="year9Discounted">
                                        {(Math.round(dYear9Bull * 100) / 100).toFixed(2)}
                                    </div>

                                    <div className="year10Discounted">
                                        {(Math.round(dYear10Bull * 100) / 100).toFixed(2)}
                                    </div>
                                    <div className="year10TerminalDiscounted">
                                        {(Math.round(dYear10TerminalBull * 100) / 100).toFixed(2)}
                                    </div>

                                    <div class='row'>
                                        <div class='col' style={{ backgroundColor: 'yellow' }}>

                                            <div className="intrinsicValueBull" style={{ marginLeft: '2.5%' }}>
                                                Intrinsic Value: {((Math.round((dYear1Bull + dYear2Bull + dYear3Bull + dYear4Bull + dYear5Bull + dYear6Bull + dYear7Bull + dYear8Bull + dYear9Bull + dYear10Bull + dYear10TerminalBull) * 100) / 100) - hiddenValueBull).toFixed(2)}
                                                {sessionStorage.setItem('bull', ((Math.round((dYear1Bull + dYear2Bull + dYear3Bull + dYear4Bull + dYear5Bull + dYear6Bull + dYear7Bull + dYear8Bull + dYear9Bull + dYear10Bull + dYear10TerminalBull) * 100) / 100) - hiddenValueBull).toFixed(2))}
                                            </div>
                                            <div className="cashReturned" style={{ marginLeft: '2.5%' }}>
                                                <div className='row'>
                                                    <div className='hiddenValueContainer'>
                                                        <div className='hiddenValue'>

                                                            Hidden Value


                                                            <OverlayTrigger
                                                                key="top"
                                                                placement="top"

                                                                overlay={
                                                                    <Tooltip>
                                                                        Any additional value you wish to add (Net cash - debt ,  current assets - liabilities, 'moat' value, etc)
                                                                    </Tooltip>
                                                                }

                                                            >
                                                                <HelpIcon style={{
                                                                    'margin-left': '5px',
                                                                    'fill': 'green',
                                                                    'width': '25px'
                                                                }}></HelpIcon>
                                                            </OverlayTrigger>

                                                        </div>
                                                        <div className='hiddenValueInput'>
                                                            <input value={hiddenValueBull} onChange={(e) => setHiddenValueBull(e.target.value)} className='hiddenValueInput'></input>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div class='col'>


                                        </div>
                                    </div>


                                </div>



                            </div>
                        </div>
                    </div>



                </Tab>





                <Tab eventKey="bear" title={<span> <img class="bear-image" src={bear}></img> Bear Case </span>}>

                    <div class="row">
                        <div class="col-lg-3" >

                            <div class="form">
                                {/* <h4 style={{ color: 'white', marginTop: '15px', fontWeight: 'bolder' }}>Valuation Calculator</h4> */}
                                <div class="input-container ic1">
                                    <input id="initalFcf" class="input"
                                        type="number"
                                        placeholder=" "
                                        value={initialBear}
                                        onChange={e => setInitialBear(e.target.value)}
                                    />
                                    <div class="cut"></div>
                                    <label for="initalFcf" class="placeholder">Initial FCF</label>

                                    <div>

                                        <OverlayTrigger
                                            key="top"
                                            placement="top"

                                            overlay={
                                                <Tooltip>
                                                    The starting value. Depending on the business, you can use the Trailing twelve Months earnings per share, Trailing twelve Months free cash flow per share, or the 'owner's earnings'
                                                </Tooltip>
                                            }

                                        >
                                            <HelpIcon class='help-icon'></HelpIcon>

                                        </OverlayTrigger>

                                    </div>

                                </div>
                                <div class="input-container ic2">
                                    <input id="oneFiveYearGrowth" class="input" placeholder=" "
                                        type="number"
                                        value={fiveYearGrowthBear}
                                        onChange={e => setFiveYearGrowthBear(e.target.value)}
                                    />
                                    <div class="cut"></div>
                                    <label for="oneFiveYearGrowth" class="placeholder">1-5 Year growth rate (%)</label>

                                    <div>

                                        <OverlayTrigger
                                            key="top"
                                            placement="top"

                                            overlay={
                                                <Tooltip>
                                                    Growth rate between year 1 to year 5
                                                </Tooltip>
                                            }

                                        >
                                            <HelpIcon class='help-icon'></HelpIcon>

                                        </OverlayTrigger>

                                    </div>

                                </div>
                                <div class="input-container ic2">
                                    <input id="sixTenYearGrowth" class="input" placeholder=" "
                                        type="number"
                                        value={sixYearGrowthBear}
                                        onChange={e => setSixYearGrowthBear(e.target.value)}
                                    />
                                    <div class="cut cut-short"></div>
                                    <label for="sixTenYearGrowth" class="placeholder">6-10 Year growth rate (%)</label>

                                    <div>
                                        <OverlayTrigger
                                            key="top"
                                            placement="top"

                                            overlay={
                                                <Tooltip>
                                                    Growth rate between year 6 to year 10
                                                </Tooltip>
                                            }

                                        >
                                            <HelpIcon class='help-icon'></HelpIcon>

                                        </OverlayTrigger>                            </div>

                                </div>
                                <div class="input-container ic2">
                                    <input id="discountRate" class="input" placeholder=" "
                                        type="number"
                                        value={discountRateBear}
                                        onChange={e => setDiscountRateBear(e.target.value)}
                                    />
                                    <div class="cut cut-short"></div>
                                    <label for="discountRate" class="placeholder">Discount Rate (%)</label>

                                    <div>
                                        <OverlayTrigger
                                            key="top"
                                            placement="top"

                                            overlay={
                                                <Tooltip>
                                                    The rate of return you're looking for. (10% is good given the current environment)
                                                </Tooltip>
                                            }

                                        >
                                            <HelpIcon class='help-icon'></HelpIcon>

                                        </OverlayTrigger>

                                    </div>

                                </div>
                                <div class="input-container ic2">
                                    <input id="terminalMultiple" class="input" placeholder=" "
                                        type="number"
                                        value={terminalMultipleBear}
                                        onChange={e => setTerminalMultipleBear(e.target.value)}
                                    />
                                    <div class="cut cut-short"></div>
                                    <label for="terminalMultiple" class="placeholder">Terminal Multiple</label>

                                    <div>

                                        <OverlayTrigger
                                            key="top"
                                            placement="top"

                                            overlay={
                                                <Tooltip>
                                                    The multiple at which you can sell the business in year 10. (For beginners: use the median historical P/E Ratio, don't go over 35x)
                                                </Tooltip>
                                            }

                                        >
                                            <HelpIcon class='help-icon'></HelpIcon>

                                        </OverlayTrigger>
                                    </div>

                                </div>
                            </div>


                        </div>

                        <div class="col-lg-9">
                            <div className="overflow-container">
                                <div className="dcfOverflow" >
                                    <h4 className="cfHeader">CF:</h4>
                                    <h4 className="dcfHeader"> DCF:</h4>


                                    <div className="year1">

                                        {currentYear}
                                    </div>

                                    <div className="year1Arrow">
                                        <svg

                                            data-icon="angle-double-right"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512"
                                            class="svg-inline--fa fa-angle-double-right fa-w-14 fa-5x"
                                        >
                                            <g class="fa-group">
                                                <path
                                                    fill="currentColor"
                                                    d="M224 273L88.37 409a23.78 23.78 0 0 1-33.8 0L32 386.36a23.94 23.94 0 0 1 0-33.89l96.13-96.37L32 159.73a23.94 23.94 0 0 1 0-33.89l22.44-22.79a23.78 23.78 0 0 1 33.8 0L223.88 239a23.94 23.94 0 0 1 .1 34z"
                                                    class="arrow1"
                                                ></path>
                                                <path
                                                    fill="currentColor"
                                                    d="M415.89 273L280.34 409a23.77 23.77 0 0 1-33.79 0L224 386.26a23.94 23.94 0 0 1 0-33.89L320.11 256l-96-96.47a23.94 23.94 0 0 1 0-33.89l22.52-22.59a23.77 23.77 0 0 1 33.79 0L416 239a24 24 0 0 1-.11 34z"
                                                    class="arrow2"
                                                ></path>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="year2Arrow">
                                        <svg

                                            data-icon="angle-double-right"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512"
                                            class="svg-inline--fa fa-angle-double-right fa-w-14 fa-5x"
                                        >
                                            <g class="fa-group">
                                                <path
                                                    fill="currentColor"
                                                    d="M224 273L88.37 409a23.78 23.78 0 0 1-33.8 0L32 386.36a23.94 23.94 0 0 1 0-33.89l96.13-96.37L32 159.73a23.94 23.94 0 0 1 0-33.89l22.44-22.79a23.78 23.78 0 0 1 33.8 0L223.88 239a23.94 23.94 0 0 1 .1 34z"
                                                    class="arrow1"
                                                ></path>
                                                <path
                                                    fill="currentColor"
                                                    d="M415.89 273L280.34 409a23.77 23.77 0 0 1-33.79 0L224 386.26a23.94 23.94 0 0 1 0-33.89L320.11 256l-96-96.47a23.94 23.94 0 0 1 0-33.89l22.52-22.59a23.77 23.77 0 0 1 33.79 0L416 239a24 24 0 0 1-.11 34z"
                                                    class="arrow2"
                                                ></path>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="year3Arrow">
                                        <svg

                                            data-icon="angle-double-right"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512"
                                            class="svg-inline--fa fa-angle-double-right fa-w-14 fa-5x"
                                        >
                                            <g class="fa-group">
                                                <path
                                                    fill="currentColor"
                                                    d="M224 273L88.37 409a23.78 23.78 0 0 1-33.8 0L32 386.36a23.94 23.94 0 0 1 0-33.89l96.13-96.37L32 159.73a23.94 23.94 0 0 1 0-33.89l22.44-22.79a23.78 23.78 0 0 1 33.8 0L223.88 239a23.94 23.94 0 0 1 .1 34z"
                                                    class="arrow1"
                                                ></path>
                                                <path
                                                    fill="currentColor"
                                                    d="M415.89 273L280.34 409a23.77 23.77 0 0 1-33.79 0L224 386.26a23.94 23.94 0 0 1 0-33.89L320.11 256l-96-96.47a23.94 23.94 0 0 1 0-33.89l22.52-22.59a23.77 23.77 0 0 1 33.79 0L416 239a24 24 0 0 1-.11 34z"
                                                    class="arrow2"
                                                ></path>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="year4Arrow">
                                        <svg

                                            data-icon="angle-double-right"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512"
                                            class="svg-inline--fa fa-angle-double-right fa-w-14 fa-5x"
                                        >
                                            <g class="fa-group">
                                                <path
                                                    fill="currentColor"
                                                    d="M224 273L88.37 409a23.78 23.78 0 0 1-33.8 0L32 386.36a23.94 23.94 0 0 1 0-33.89l96.13-96.37L32 159.73a23.94 23.94 0 0 1 0-33.89l22.44-22.79a23.78 23.78 0 0 1 33.8 0L223.88 239a23.94 23.94 0 0 1 .1 34z"
                                                    class="arrow1"
                                                ></path>
                                                <path
                                                    fill="currentColor"
                                                    d="M415.89 273L280.34 409a23.77 23.77 0 0 1-33.79 0L224 386.26a23.94 23.94 0 0 1 0-33.89L320.11 256l-96-96.47a23.94 23.94 0 0 1 0-33.89l22.52-22.59a23.77 23.77 0 0 1 33.79 0L416 239a24 24 0 0 1-.11 34z"
                                                    class="arrow2"
                                                ></path>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="year5Arrow">
                                        <svg

                                            data-icon="angle-double-right"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512"
                                            class="svg-inline--fa fa-angle-double-right fa-w-14 fa-5x"
                                        >
                                            <g class="fa-group">
                                                <path
                                                    fill="currentColor"
                                                    d="M224 273L88.37 409a23.78 23.78 0 0 1-33.8 0L32 386.36a23.94 23.94 0 0 1 0-33.89l96.13-96.37L32 159.73a23.94 23.94 0 0 1 0-33.89l22.44-22.79a23.78 23.78 0 0 1 33.8 0L223.88 239a23.94 23.94 0 0 1 .1 34z"
                                                    class="arrow1"
                                                ></path>
                                                <path
                                                    fill="currentColor"
                                                    d="M415.89 273L280.34 409a23.77 23.77 0 0 1-33.79 0L224 386.26a23.94 23.94 0 0 1 0-33.89L320.11 256l-96-96.47a23.94 23.94 0 0 1 0-33.89l22.52-22.59a23.77 23.77 0 0 1 33.79 0L416 239a24 24 0 0 1-.11 34z"
                                                    class="arrow2"
                                                ></path>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="year6Arrow">
                                        <svg

                                            data-icon="angle-double-right"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512"
                                            class="svg-inline--fa fa-angle-double-right fa-w-14 fa-5x"
                                        >
                                            <g class="fa-group">
                                                <path
                                                    fill="currentColor"
                                                    d="M224 273L88.37 409a23.78 23.78 0 0 1-33.8 0L32 386.36a23.94 23.94 0 0 1 0-33.89l96.13-96.37L32 159.73a23.94 23.94 0 0 1 0-33.89l22.44-22.79a23.78 23.78 0 0 1 33.8 0L223.88 239a23.94 23.94 0 0 1 .1 34z"
                                                    class="arrow1"
                                                ></path>
                                                <path
                                                    fill="currentColor"
                                                    d="M415.89 273L280.34 409a23.77 23.77 0 0 1-33.79 0L224 386.26a23.94 23.94 0 0 1 0-33.89L320.11 256l-96-96.47a23.94 23.94 0 0 1 0-33.89l22.52-22.59a23.77 23.77 0 0 1 33.79 0L416 239a24 24 0 0 1-.11 34z"
                                                    class="arrow2"
                                                ></path>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="year7Arrow">
                                        <svg

                                            data-icon="angle-double-right"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512"
                                            class="svg-inline--fa fa-angle-double-right fa-w-14 fa-5x"
                                        >
                                            <g class="fa-group">
                                                <path
                                                    fill="currentColor"
                                                    d="M224 273L88.37 409a23.78 23.78 0 0 1-33.8 0L32 386.36a23.94 23.94 0 0 1 0-33.89l96.13-96.37L32 159.73a23.94 23.94 0 0 1 0-33.89l22.44-22.79a23.78 23.78 0 0 1 33.8 0L223.88 239a23.94 23.94 0 0 1 .1 34z"
                                                    class="arrow1"
                                                ></path>
                                                <path
                                                    fill="currentColor"
                                                    d="M415.89 273L280.34 409a23.77 23.77 0 0 1-33.79 0L224 386.26a23.94 23.94 0 0 1 0-33.89L320.11 256l-96-96.47a23.94 23.94 0 0 1 0-33.89l22.52-22.59a23.77 23.77 0 0 1 33.79 0L416 239a24 24 0 0 1-.11 34z"
                                                    class="arrow2"
                                                ></path>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="year8Arrow">
                                        <svg

                                            data-icon="angle-double-right"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512"
                                            class="svg-inline--fa fa-angle-double-right fa-w-14 fa-5x"
                                        >
                                            <g class="fa-group">
                                                <path
                                                    fill="currentColor"
                                                    d="M224 273L88.37 409a23.78 23.78 0 0 1-33.8 0L32 386.36a23.94 23.94 0 0 1 0-33.89l96.13-96.37L32 159.73a23.94 23.94 0 0 1 0-33.89l22.44-22.79a23.78 23.78 0 0 1 33.8 0L223.88 239a23.94 23.94 0 0 1 .1 34z"
                                                    class="arrow1"
                                                ></path>
                                                <path
                                                    fill="currentColor"
                                                    d="M415.89 273L280.34 409a23.77 23.77 0 0 1-33.79 0L224 386.26a23.94 23.94 0 0 1 0-33.89L320.11 256l-96-96.47a23.94 23.94 0 0 1 0-33.89l22.52-22.59a23.77 23.77 0 0 1 33.79 0L416 239a24 24 0 0 1-.11 34z"
                                                    class="arrow2"
                                                ></path>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="year9Arrow">
                                        <svg

                                            data-icon="angle-double-right"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512"
                                            class="svg-inline--fa fa-angle-double-right fa-w-14 fa-5x"
                                        >
                                            <g class="fa-group">
                                                <path
                                                    fill="currentColor"
                                                    d="M224 273L88.37 409a23.78 23.78 0 0 1-33.8 0L32 386.36a23.94 23.94 0 0 1 0-33.89l96.13-96.37L32 159.73a23.94 23.94 0 0 1 0-33.89l22.44-22.79a23.78 23.78 0 0 1 33.8 0L223.88 239a23.94 23.94 0 0 1 .1 34z"
                                                    class="arrow1"
                                                ></path>
                                                <path
                                                    fill="currentColor"
                                                    d="M415.89 273L280.34 409a23.77 23.77 0 0 1-33.79 0L224 386.26a23.94 23.94 0 0 1 0-33.89L320.11 256l-96-96.47a23.94 23.94 0 0 1 0-33.89l22.52-22.59a23.77 23.77 0 0 1 33.79 0L416 239a24 24 0 0 1-.11 34z"
                                                    class="arrow2"
                                                ></path>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="year10Arrow">
                                        <svg

                                            data-icon="angle-double-right"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512"
                                            class="svg-inline--fa fa-angle-double-right fa-w-14 fa-5x"
                                        >
                                            <g class="fa-group">
                                                <path
                                                    fill="currentColor"
                                                    d="M224 273L88.37 409a23.78 23.78 0 0 1-33.8 0L32 386.36a23.94 23.94 0 0 1 0-33.89l96.13-96.37L32 159.73a23.94 23.94 0 0 1 0-33.89l22.44-22.79a23.78 23.78 0 0 1 33.8 0L223.88 239a23.94 23.94 0 0 1 .1 34z"
                                                    class="arrow1"
                                                ></path>
                                                <path
                                                    fill="currentColor"
                                                    d="M415.89 273L280.34 409a23.77 23.77 0 0 1-33.79 0L224 386.26a23.94 23.94 0 0 1 0-33.89L320.11 256l-96-96.47a23.94 23.94 0 0 1 0-33.89l22.52-22.59a23.77 23.77 0 0 1 33.79 0L416 239a24 24 0 0 1-.11 34z"
                                                    class="arrow2"
                                                ></path>
                                            </g>
                                        </svg>
                                    </div>

                                    <div className="year2">
                                        {currentYear + 1}
                                    </div>


                                    <div className="year3">

                                        {currentYear + 2}
                                    </div>

                                    <div className="year4">

                                        {currentYear + 3}
                                    </div>

                                    <div className="year5">

                                        {currentYear + 4}
                                    </div>

                                    <div className="year6">

                                        {currentYear + 5}
                                    </div>

                                    <div className="year7">

                                        {currentYear + 6}
                                    </div>

                                    <div className="year8">

                                        {currentYear + 7}
                                    </div>

                                    <div className="year9">

                                        {currentYear + 8}
                                    </div>

                                    <div className="year10">

                                        {currentYear + 9}

                                    </div>
                                    <div className="year10Terminal">

                                        {currentYear + 9} Terminal

                                    </div>






                                    <form className="formContainer">

                                        <div className="year1Input">
                                            <input name="Year1 changeable input" class={((year1Bear === "NaN") || (year1Bear === '') || parseFloat(year1Bear) === initialBear * ((1 + parseFloat(fiveYearGrowthBear) / 100) ** 1)) ? 'inputCF' : 'edited'} type="number" onChange={(e) => setYear1Bear(e.target.value)} value={year1Bear}
                                            ></input>
                                        </div>

                                        <div className="year2Input">
                                            <input name="Year2 changeable input" class={((year2Bear === "NaN") || (year2Bear === '') || parseFloat(year2Bear) === initialBear * ((1 + parseFloat(fiveYearGrowthBear) / 100) ** 2)) ? 'inputCF' : 'edited'} type="number" onChange={(e) => setYear2Bear(e.target.value)} value={year2Bear}
                                            ></input>
                                        </div>

                                        <div className="year3Input">
                                            <input name="Year3 changeable input" class={((year3Bear === "NaN") || (year3Bear === '') || parseFloat(year3Bear) === initialBear * ((1 + parseFloat(fiveYearGrowthBear) / 100) ** 3)) ? 'inputCF' : 'edited'} type="number" onChange={(e) => setYear3Bear(e.target.value)} value={year3Bear}
                                            ></input>
                                        </div>

                                        <div className="year4Input">
                                            <input name="Year4 changeable input" class={((year4Bear === "NaN") || (year4Bear === '') || parseFloat(year4Bear) === initialBear * ((1 + parseFloat(fiveYearGrowthBear) / 100) ** 4)) ? 'inputCF' : 'edited'} type="number" onChange={(e) => setYear4Bear(e.target.value)} value={year4Bear}
                                            ></input>
                                        </div>

                                        <div className="year5Input">
                                            <input name="Year5 changeable input" class={((year5Bear === "NaN") || (year5Bear === '') || parseFloat(year5Bear) === initialBear * ((1 + parseFloat(fiveYearGrowthBear) / 100) ** 5)) ? 'inputCF' : 'edited'} type="number" onChange={(e) => setYear5Bear(e.target.value)} value={year5Bear}
                                            ></input>
                                        </div>

                                        <div className="year6Input">
                                            <input name="Year6 changeable input" class={((year6Bear === "NaN") || (year6Bear === '') || parseFloat(year6Bear) === (initialBear * ((1 + parseFloat(fiveYearGrowthBear) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowthBear / 100)) ** 1)) ? 'inputCF' : 'edited'} type="number" onChange={(e) => setYear6Bear(e.target.value)} value={year6Bear}
                                            ></input>
                                        </div>

                                        <div className="year7Input">
                                            <input name="Year7 changeable input" class={((year7Bear === "NaN") || (year7Bear === '') || parseFloat(year7Bear) === (initialBear * ((1 + parseFloat(fiveYearGrowthBear) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowthBear / 100)) ** 2)) ? 'inputCF' : 'edited'} type="number" onChange={(e) => setYear7Bear(e.target.value)} value={year7Bear}
                                            ></input>
                                        </div>

                                        <div className="year8Input">
                                            <input name="Year8 changeable input" class={((year8Bear === "NaN") || (year8Bear === '') || parseFloat(year8Bear) === (initialBear * ((1 + parseFloat(fiveYearGrowthBear) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowthBear / 100)) ** 3)) ? 'inputCF' : 'edited'} type="number" onChange={(e) => setYear8Bear(e.target.value)} value={year8Bear}
                                            ></input>
                                        </div>

                                        <div className="year9Input">
                                            <input name="Year9 changeable input" class={((year9Bear === "NaN") || (year9Bear === '') || parseFloat(year9Bear) === (initialBear * ((1 + parseFloat(fiveYearGrowthBear) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowthBear / 100)) ** 4)) ? 'inputCF' : 'edited'} type="number" onChange={(e) => setYear9Bear(e.target.value)} value={year9Bear}
                                            ></input>
                                        </div>

                                        <div className="year10Input">
                                            <input name="Year10 changeable input" class={((year10Bear === "NaN") || (year10Bear === '') || parseFloat(year10Bear) === (initialBear * ((1 + parseFloat(fiveYearGrowthBear) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowthBear / 100)) ** 5)) ? 'inputCF' : 'edited'} type="number" onChange={(e) => setYear10Bear(e.target.value)} value={year10Bear}
                                            ></input>
                                        </div>

                                        <div className="year10TerminalInput">
                                            <input name="Year10Terminal changeable input" class={((year10TerminalBear === "NaN") || (year10TerminalBear === '') || parseFloat(year10TerminalBear) === (initialBear * ((1 + parseFloat(fiveYearGrowthBear) / 100) ** 5)) * ((1 + parseFloat(sixYearGrowthBear / 100)) ** 4) + (parseFloat(terminalMultipleBear - 1) * ((initialBear * ((1 + parseFloat(fiveYearGrowthBear / 100)) ** 5)) * ((1 + parseFloat(sixYearGrowthBear / 100)) ** 4)))) ? 'inputCF' : 'edited'} type="number" onChange={(e) => setYear10TerminalBear(e.target.value)} value={year10TerminalBear}
                                            ></input>
                                        </div>

                                        <OverlayTrigger
                                            key="top"
                                            placement="top"

                                            overlay={
                                                <Tooltip>
                                                    Reset edited cash flows
                                                </Tooltip>
                                            }

                                        >
                                            <div className="resetDiv">

                                                <input type="reset" defaultValue="Reset" onClick={() => resetBear()} class="fa fa-undo" class="resetButton" />

                                            </div>
                                        </OverlayTrigger>

                                        {isAdvancedBear ? 

<>
<div className="advanced-label" onClick={()=>setIsAdvancedBear(!isAdvancedBear)}>Advanced <ArrowDropUpIcon></ArrowDropUpIcon></div>

                                        <OverlayTrigger
                                            key="top"
                                            placement="top"

                                            overlay={
                                                <Tooltip>
                                                    Click if no cash will be returned to shareholders
                                                </Tooltip>
                                            }

                                        >
                                            <div className="zeroedDiv" onClick={() => zeroBear()}>
                                                {/* <input defaultValue="Reset" onClick={() => reset()} /> */}
                                                0
                                            </div>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            key="top"
                                            placement="top"

                                            overlay={
                                                <Tooltip>
                                                    Percentage of cash returned to shareholders
                                                </Tooltip>
                                            }

                                        >
                                            <div className="customCashReturnedDiv" >
                                                <div className='cashReturnedLabel'>
                                                    Cash returned
                                                </div>
                                                <div style={{ display: 'inline', color: 'white' }}>
                                                    <input className='cashReturnedInput' value={cashReturnedBear} onChange={(e) => setCashReturnedBear(e.target.value)} />
                                                    %
                                                </div>

                                            </div>
                                        </OverlayTrigger>
                                        </>
                                    :
                                    <div className="advanced-label" onClick={()=>setIsAdvancedBear(!isAdvancedBear)}>Advanced <ArrowDropDownIcon></ArrowDropDownIcon></div>
                                        }



                                    </form>


                                    <div className="year1Discounted">

                                        {(Math.round(dYear1Bear * 100) / 100).toFixed(2)}

                                    </div>

                                    <div className="year2Discounted">
                                        {(Math.round(dYear2Bear * 100) / 100).toFixed(2)}
                                    </div>

                                    <div className="year3Discounted">
                                        {(Math.round(dYear3Bear * 100) / 100).toFixed(2)}
                                    </div>

                                    <div className="year4Discounted">
                                        {(Math.round(dYear4Bear * 100) / 100).toFixed(2)}
                                    </div>

                                    <div className="year5Discounted">
                                        {(Math.round(dYear5Bear * 100) / 100).toFixed(2)}
                                    </div>

                                    <div className="year6Discounted">
                                        {(Math.round(dYear6Bear * 100) / 100).toFixed(2)}
                                    </div>

                                    <div className="year7Discounted">
                                        {(Math.round(dYear7Bear * 100) / 100).toFixed(2)}
                                    </div>


                                    <div className="year8Discounted">
                                        {(Math.round(dYear8Bear * 100) / 100).toFixed(2)}
                                    </div>

                                    <div className="year9Discounted">
                                        {(Math.round(dYear9Bear * 100) / 100).toFixed(2)}
                                    </div>

                                    <div className="year10Discounted">
                                        {(Math.round(dYear10Bear * 100) / 100).toFixed(2)}
                                    </div>

                                    <div className="year10TerminalDiscounted">
                                        {(Math.round(dYear10TerminalBear * 100) / 100).toFixed(2)}
                                    </div>

                                    <div class='row'>
                                        <div class='col' style={{ backgroundColor: 'yellow' }}>
                                            <div className=" intrinsicValue" style={{ marginLeft: '2.5%' }}>
                                                Intrinsic Value: {((Math.round((dYear1Bear + dYear2Bear + dYear3Bear + dYear4Bear + dYear5Bear + dYear6Bear + dYear7Bear + dYear8Bear + dYear9Bear + dYear10Bear + dYear10TerminalBear) * 100) / 100) - hiddenValueBear).toFixed(2)}
                                                {sessionStorage.setItem('bear', ((Math.round((dYear1Bear + dYear2Bear + dYear3Bear + dYear4Bear + dYear5Bear + dYear6Bear + dYear7Bear + dYear8Bear + dYear9Bear + dYear10Bear + dYear10TerminalBear) * 100) - hiddenValueBear) / 100).toFixed(2))}
                                            </div>
                                            <div className="cashReturned" style={{ marginLeft: '2.5%' }}>
                                                <div className='row'>
                                                    <div className='hiddenValueContainer'>
                                                        <div className='hiddenValue'>

                                                            Hidden Value


                                                            <OverlayTrigger
                                                                key="top"
                                                                placement="top"

                                                                overlay={
                                                                    <Tooltip>
                                                                        Any additional value you wish to add (Net cash - debt ,  current assets - liabilities, 'moat' value, etc)
                                                                    </Tooltip>
                                                                }

                                                            >
                                                                <HelpIcon style={{
                                                                    'margin-left': '5px',
                                                                    'fill': 'green',
                                                                    'width': '25px'
                                                                }}></HelpIcon>
                                                            </OverlayTrigger>

                                                        </div>
                                                        <div className='hiddenValueInput'>
                                                            <input value={hiddenValueBear} onChange={(e) => setHiddenValueBear(e.target.value)} className='hiddenValueInput'></input>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div class='col'>

                                        </div>
                                    </div>


                                </div>



                            </div>
                        </div>
                    </div>

                </Tab>
            </Tabs>


            <div class="weighted-box">
                <div class="row" >
                    <div class="col-8">
                        <div class="row">
                            <div class="col" style={{ color: 'white', fontSize: 'small' }}>
                                Case
                            </div>
                            <div class="col" style={{ color: 'white', fontSize: 'small' }}>
                                Price
                            </div>
                            <div class="col" style={{ color: 'white', fontSize: 'small' }}>
                                Probability
                            </div>
                        </div>

                        <div class="row" style={{ marginTop: '5px', marginBottom: '5px' }}>
                            <div class="col">

                                <img class="normal-image" src={fair}></img>
                            </div>

                            <div class="col">
                                <div class="weightedYellow">

                                    {((Math.round((dYear1 + dYear2 + dYear3 + dYear4 + dYear5 + dYear6 + dYear7 + dYear8 + dYear9 + dYear10 + dYear10Terminal) * 100) / 100) - hiddenValue).toFixed(2)}
                                </div>

                            </div>

                            <div class="col" style={{ color: 'white', display: 'inherit' }}>

                                {/* <div className="year5Input"> */}
                                <input name="Year5 changeable input" class='weightage' type="number" value={normalWeight} onChange={(e) => setNormalWeight(e.target.value)}
                                ></input>
                                %
                                {/* </div> */}

                            </div>

                        </div>

                        <div class="row">
                            <div class="col">

                                <img class="bull-image" src={bull}></img>
                            </div>

                            <div class="col">
                                <div class="weightedGreen">

                                    {((Math.round((dYear1Bull + dYear2Bull + dYear3Bull + dYear4Bull + dYear5Bull + dYear6Bull + dYear7Bull + dYear8Bull + dYear9Bull + dYear10Bull + dYear10TerminalBull) * 100) / 100) - hiddenValueBull).toFixed(2)}
                                </div>

                            </div>

                            <div class="col" style={{ color: 'white', display: 'inherit' }}>

                                <input name="Year5 changeable input" class='weightage' type="number" value={bullWeight} onChange={(e) => setBullWeight(e.target.value)}
                                ></input>
                                %
                            </div>

                        </div>

                        <div class="row">
                            <div class="col" style={{ marginTop: '-5px' }}>

                                <img class="bear-image" src={bear}></img>
                            </div>

                            <div class="col">
                                <div class="weightedRed">
                                    {((Math.round((dYear1Bear + dYear2Bear + dYear3Bear + dYear4Bear + dYear5Bear + dYear6Bear + dYear7Bear + dYear8Bear + dYear9Bear + dYear10Bear + dYear10TerminalBear) * 100) / 100) - hiddenValueBear).toFixed(2)}
                                </div>
                            </div>

                            <div class="col" style={{ color: 'white', display: 'inherit' }}>

                                <input name="Year5 changeable input" class='weightage' type="number" value={bearWeight} onChange={(e) => setBearWeight(e.target.value)}
                                ></input>
                                %
                            </div>

                        </div>
                    </div>

                    {/* <div class="col-1">
        </div> */}


                    <div class="col-3">
                        <div class="row">

                            <div class="col" style={{ color: 'white' }}>
                                Fair Price
                            </div>
                        </div>

                        <div class="row">
                            <div class='weighted-value'>
                                {((((Math.round((dYear1 + dYear2 + dYear3 + dYear4 + dYear5 + dYear6 + dYear7 + dYear8 + dYear9 + dYear10 + dYear10Terminal) * 100) / 100) - hiddenValue).toFixed(2)) * normalWeight / 100 + (((Math.round((dYear1Bull + dYear2Bull + dYear3Bull + dYear4Bull + dYear5Bull + dYear6Bull + dYear7Bull + dYear8Bull + dYear9Bull + dYear10Bull + dYear10TerminalBull) * 100) / 100) - hiddenValueBull).toFixed(2)) * bullWeight / 100 + (((Math.round((dYear1Bear + dYear2Bear + dYear3Bear + dYear4Bear + dYear5Bear + dYear6Bear + dYear7Bear + dYear8Bear + dYear9Bear + dYear10Bear + dYear10TerminalBear) * 100) / 100).toFixed(2)) * bearWeight / 100) - hiddenValueBear).toFixed(2)}
                            </div>
                        </div>
                        <div class="row">
                            <div class='col' >


                                <OverlayTrigger
                                    key="top"
                                    placement="top"

                                    overlay={
                                        <Tooltip>
                                            Save valuation
                                        </Tooltip>
                                    }

                                >
                                    <div id="SaveValuationIcon" class="SaveValuationIcon-wrap" onClick={() => { valuationClick() }}></div>

                                </OverlayTrigger>

                            </div>
                        </div>
                    </div>
                </div>



            </div>



            {showMore ? <div class='add-valuation-form' style={{ "z-index": 10 }}>
                <ValuationDetails
                    // cfArrBull={}
                    // dcfArrBull={}
                    // cfArrBear={ }
                    // dcfArrBear={ }
                    cfArr={[year1, year2, year3, year4, year5, year6, year7, year8, year9, year10, year10Terminal]}
                    dcfArr={
                        [year1 / ((1 + parseFloat(discountRate / 100)) ** 1),
                        year2 / ((1 + parseFloat(discountRate / 100)) ** 2),
                        year3 / ((1 + parseFloat(discountRate / 100)) ** 3),
                        year4 / ((1 + parseFloat(discountRate / 100)) ** 4),
                        year5 / ((1 + parseFloat(discountRate / 100)) ** 5),
                        year6 / ((1 + parseFloat(discountRate / 100)) ** 6),
                        year7 / ((1 + parseFloat(discountRate / 100)) ** 7),
                        year8 / ((1 + parseFloat(discountRate / 100)) ** 8),
                        year9 / ((1 + parseFloat(discountRate / 100)) ** 9),
                        year10 / ((1 + parseFloat(discountRate / 100)) ** 10),
                        year10Terminal / ((1 + parseFloat(discountRate / 100)) ** 10)]
                    }
                    cfArrBull={[year1Bull, year2Bull, year3Bull, year4Bull, year5Bull, year6Bull, year7Bull, year8Bull, year9Bull, year10Bull, year10TerminalBull]}
                    dcfArrBull={
                        [year1Bull / ((1 + parseFloat(discountRateBull / 100)) ** 1),
                        year2Bull / ((1 + parseFloat(discountRateBull / 100)) ** 2),
                        year3Bull / ((1 + parseFloat(discountRateBull / 100)) ** 3),
                        year4Bull / ((1 + parseFloat(discountRateBull / 100)) ** 4),
                        year5Bull / ((1 + parseFloat(discountRateBull / 100)) ** 5),
                        year6Bull / ((1 + parseFloat(discountRateBull / 100)) ** 6),
                        year7Bull / ((1 + parseFloat(discountRateBull / 100)) ** 7),
                        year8Bull / ((1 + parseFloat(discountRateBull / 100)) ** 8),
                        year9Bull / ((1 + parseFloat(discountRateBull / 100)) ** 9),
                        year10Bull / ((1 + parseFloat(discountRateBull / 100)) ** 10),
                        year10TerminalBear / ((1 + parseFloat(discountRateBull / 100)) ** 10)]

                    }
                    cfArrBear={[year1Bear, year2Bear, year3Bear, year4Bear, year5Bear, year6Bear, year7Bear, year8Bear, year9Bear, year10Bear, year10TerminalBear]}
                    dcfArrBear={
                        [year1Bear / ((1 + parseFloat(discountRateBear / 100)) ** 1),
                        year2Bear / ((1 + parseFloat(discountRateBear / 100)) ** 2),
                        year3Bear / ((1 + parseFloat(discountRateBear / 100)) ** 3),
                        year4Bear / ((1 + parseFloat(discountRateBear / 100)) ** 4),
                        year5Bear / ((1 + parseFloat(discountRateBear / 100)) ** 5),
                        year6Bear / ((1 + parseFloat(discountRateBear / 100)) ** 6),
                        year7Bear / ((1 + parseFloat(discountRateBear / 100)) ** 7),
                        year8Bear / ((1 + parseFloat(discountRateBear / 100)) ** 8),
                        year9Bear / ((1 + parseFloat(discountRateBear / 100)) ** 9),
                        year10Bear / ((1 + parseFloat(discountRateBear / 100)) ** 10),
                        year10TerminalBear / ((1 + parseFloat(discountRateBear / 100)) ** 10)]

                    }
                    discountRateBear={discountRateBear}
                    discountRateBull={discountRateBull}
                    discountRate={discountRate}

                    fiveYearGrowth={fiveYearGrowth}
                    sixYearGrowth={sixYearGrowth}
                    terminalMultiple={terminalMultiple}

                    fiveYearGrowthBull={fiveYearGrowthBull}
                    sixYearGrowthBull={sixYearGrowthBull}
                    terminalMultipleBull={terminalMultipleBull}

                    fiveYearGrowthBear={fiveYearGrowthBear}
                    sixYearGrowthBear={sixYearGrowthBear}
                    terminalMultipleBear={terminalMultipleBear}

                    normalWeight={normalWeight}
                    bearWeight={bearWeight}
                    bullWeight={bullWeight}

                    hiddenValueBull={hiddenValueBull}
                    hiddenValueBear={hiddenValueBear}
                    hiddenValue={hiddenValue}

                    cashReturned={cashReturned}
                    cashReturnedBull={cashReturnedBull}
                    cashReturnedBear={cashReturnedBear}


                    intrinsicValue={(((Math.round((dYear1 + dYear2 + dYear3 + dYear4 + dYear5 + dYear6 + dYear7 + dYear8 + dYear9 + dYear10 + dYear10Terminal) * 100) / 100) - hiddenValue).toFixed(2)) * normalWeight / 100 + (((Math.round((dYear1Bull + dYear2Bull + dYear3Bull + dYear4Bull + dYear5Bull + dYear6Bull + dYear7Bull + dYear8Bull + dYear9Bull + dYear10Bull + dYear10TerminalBull) * 100) / 100) - hiddenValueBull).toFixed(2)) * bullWeight / 100 + (((Math.round((dYear1Bear + dYear2Bear + dYear3Bear + dYear4Bear + dYear5Bear + dYear6Bear + dYear7Bear + dYear8Bear + dYear9Bear + dYear10Bear + dYear10TerminalBear) * 100) / 100) - hiddenValueBear).toFixed(2)) * bearWeight / 100}
                    style={{ "z-index": 10 }}></ValuationDetails>

            </div>

                : <div></div>}

            {showMore ? <div class="blurred-background" onClick={() => editShowMore(false)} style={{
                "z-index": 9, backgroundColor: 'rgba(0, 0, 0, 0.55)'
            }}>
            </div> : <div></div>}

            {showSuccessAlert ? <Alert variant="filled" severity="success" style={{ position: 'fixed', top: '0px', marginLeft: '', zIndex: '1000' }}>
                Valuation updated successfully!
            </Alert> : <div />}


        </div>




    )
}

export default WeightedDCF
