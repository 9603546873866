import React from "react";

import Financials from "./Financials";

import { Radar, Line, Bar } from "react-chartjs-2"

import './styles/quickStatsStyles.css'

//OFFICIAL metric names 
//CF : Free Cash Flow	, Free Cash Flow Growth, Free Cash Flow Per Share
//IS : Revenue, Net Income, Revenue Growth, Shares Outstanding (Basic), Gross Margin, Operating Margin, Profit Margin, FCF Margin, EPS Growth, EPS (Diluted), Dividend Per Share
//R  : Market Capitalization (this Year), PE Ratio, PS Ratio, PB Ratio, P/FCF Ratio, EV/EBITDA Ratio, EV/FCF Ratio, Return on Capital (ROIC), Return on Equity (ROE), Return on Assets (ROA), Debt / Equity Ratio [inverse for radar], Market Capitalization (cagr for radar)
//BS : Total Current Assets	- Total Liabilities

// Radar chart over time : roic,roe,roa, 1/DtoE, cagr return, margin growth over time, management, employee sentiment
export default class QuickStats extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            r: this.props.r,
            cf: this.props.cf,
            is: this.props.is,
            bs: this.props.bs,

            revenueCagr: '0'
        };
    }




    renderQuick() {

        try {

            var currentYear = 2020;
            var returnArr = []



            //      parseFloat((this.state.r[12][2020]) )/ 100.0;



            var RevenueIndex = 0;
            var fcfIndex = 0;

            for (var i = 0; i < this.props.is.length; i++) {

                if (this.props.is[i].Year === "Revenue") {
                    RevenueIndex = i;
                }

            }

            var peIndex;
            var psIndex;
            var pbIndex;
            var pfcfIndex;
            var evEbitdaIndex;
            var evFcfIndex;
            var roicIndex;
            var roeIndex;
            var roaIndex;
            //R  : Market Capitalization (this Year), PE Ratio, PS Ratio, PB Ratio, P/FCF Ratio, EV/EBITDA Ratio, EV/FCF Ratio, Return on Capital (ROIC), Return on Equity (ROE), Return on Assets (ROA), Debt / Equity Ratio [inverse for radar], Market Capitalization (cagr for radar)

            for (var i = 0; i < this.props.r.length; i++) {

                if (this.props.r[i].Year === "PE Ratio") {
                    peIndex = i;
                }
                if (this.props.r[i].Year === "PS Ratio") {
                    psIndex = i;
                }
                if (this.props.r[i].Year === "PB Ratio") {
                    pbIndex = i;
                }
                if (this.props.r[i].Year === "P/FCF Ratio") {
                    pfcfIndex = i;
                }
                if (this.props.r[i].Year === "EV/EBITDA") {
                    evEbitdaIndex = i;
                }
                if (this.props.r[i].Year === "EV/FCF") {

                    evFcfIndex = i;
                }
                if (this.props.r[i].Year === "Return on Capital (ROIC)") {
                    roicIndex = i;
                }
                if (this.props.r[i].Year === "Return on Equity (ROE)") {
                    roeIndex = i;
                }
                if (this.props.r[i].Year === "Return on Assets (ROA)") {
                    roaIndex = i;
                }
            }


            //  for (var i = 0; i < this.props.cf.length; i++) {

            //          if (this.props.cf[i].Year === "Free Cash Flow") {
            //   fcfIndex = i;
            //        }

            //   }


            //valuation ratios pe,pb,ps, EV/EBITDA, EV/FCF	

            //another statement           for (var i = 0; i < this.props.is.length; i++) {
            //if (this.props.is[i].Year === "Profit Margin") {
            //  MarginGrowthIndex = i;
            //}
            //}



            var revenueFirst = this.props.is[0][Object.keys(this.props.is[RevenueIndex])[0]];


            //  var revenueFirstInt = parseFloat(revenueFirst.replace(/[^0-9 | ^.]/g, ''));

            var revenueLast = this.props.is[RevenueIndex][currentYear];
            //    var revenueLastInt = parseFloat(revenueLast.replace(/[^0-9 | ^.]/g, ''));

            var revenueTerm = parseInt((currentYear) - (Object.keys(this.props.is[RevenueIndex])[0]));

            //    var revenueCagr1 = (((revenueLastInt / revenueFirstInt) ** (1 / revenueTerm)) - 1) * 100;

            var revenueCagrNew = (((revenueLast / revenueFirst) ** (1 / revenueTerm)) - 1) * 100;
            //
            //   var fcfFirst = this.props.cf[0][Object.keys(this.props.cf[fcfIndex])[0]];
            //   var fcfFirstInt = parseFloat(fcfFirst.replace(/[^0-9 | ^.]/g, ''));

            //   var fcfLast = this.props.cf[fcfIndex][currentYear];
            //   var fcfLastInt = parseFloat(fcfLast.replace(/[^0-9 | ^.]/g, ''));

            // var fcfTerm = parseInt((currentYear) - (Object.keys(this.props.cf[fcfIndex])[0]));

            //            var fcfCagr = (((fcfLastInt / fcfFirstInt) ** (1 / fcfTerm)) - 1) * 100;


            //


            returnArr.push(<div>revenueCagrNew</div>)


            returnArr.push("hello")

            //  returnArr.push(revenueCagr1)

            returnArr.push(this.props.r[peIndex])

            return revenueCagrNew
        }
        catch {
            return '-'
        }


    }

    renderPE() {

        try {

            var currentYear = 2020;

            var peIndex;
            var psIndex;
            var pbIndex;
            var pfcfIndex;
            var evEbitdaIndex;
            var evFcfIndex;
            var roicIndex;
            var roeIndex;
            var roaIndex;
            //R  : Market Capitalization (this Year), PE Ratio, PS Ratio, PB Ratio, P/FCF Ratio, EV/EBITDA Ratio, EV/FCF Ratio, Return on Capital (ROIC), Return on Equity (ROE), Return on Assets (ROA), Debt / Equity Ratio [inverse for radar], Market Capitalization (cagr for radar)

            for (var i = 0; i < this.props.r.length; i++) {

                if (this.props.r[i].Year === "P/E Ratio") {
                    peIndex = i;
                }

            }



            //  returnArr.push(revenueCagr1)

            var ratioObject = this.props.r[peIndex];

            var returnLast = ratioObject[Object.keys(ratioObject)[Object.keys(ratioObject).length - 2]];


            return returnLast;
        }
        catch {
            return '-'
        }


    }


    renderPS() {

        try {

            var currentYear = 2020;

            var ratioIndex;
            //R  : Market Capitalization (this Year), PE Ratio, PS Ratio, PB Ratio, P/FCF Ratio, EV/EBITDA Ratio, EV/FCF Ratio, Return on Capital (ROIC), Return on Equity (ROE), Return on Assets (ROA), Debt / Equity Ratio [inverse for radar], Market Capitalization (cagr for radar)

            for (var i = 0; i < this.props.r.length; i++) {

                if (this.props.r[i].Year === "P/S Ratio") {
                    ratioIndex = i;
                }

            }



            //  returnArr.push(revenueCagr1)

            var ratioObject = this.props.r[ratioIndex];

            var returnLast = ratioObject[Object.keys(ratioObject)[Object.keys(ratioObject).length - 2]];


            return returnLast
        }
        catch {
            return '-'
        }


    }


    renderPB() {

        try {

            var currentYear = 2020;

            var ratioIndex;
            //R  : Market Capitalization (this Year), PE Ratio, PS Ratio, PB Ratio, P/FCF Ratio, EV/EBITDA Ratio, EV/FCF Ratio, Return on Capital (ROIC), Return on Equity (ROE), Return on Assets (ROA), Debt / Equity Ratio [inverse for radar], Market Capitalization (cagr for radar)

            for (var i = 0; i < this.props.r.length; i++) {

                if (this.props.r[i].Year === "P/B Ratio") {
                    ratioIndex = i;
                }

            }



            //  returnArr.push(revenueCagr1)

            var ratioObject = this.props.r[ratioIndex];

            var returnLast = ratioObject[Object.keys(ratioObject)[Object.keys(ratioObject).length - 2]];


            return returnLast
        }
        catch {
            return '-'
        }


    }


    renderPFCF() {

        try {

            var currentYear = 2020;

            var ratioIndex;
            //R  : Market Capitalization (this Year), PE Ratio, PS Ratio, PB Ratio, P/FCF Ratio, EV/EBITDA Ratio, EV/FCF Ratio, Return on Capital (ROIC), Return on Equity (ROE), Return on Assets (ROA), Debt / Equity Ratio [inverse for radar], Market Capitalization (cagr for radar)

            for (var i = 0; i < this.props.r.length; i++) {

                if (this.props.r[i].Year === "P/FCF Ratio") {
                    ratioIndex = i;
                }

            }



            //  returnArr.push(revenueCagr1)

            var ratioObject = this.props.r[ratioIndex];

            var returnLast = ratioObject[Object.keys(ratioObject)[Object.keys(ratioObject).length - 2]];


            return returnLast
        }
        catch {
            return '-'
        }


    }


    renderEVEBITDA() {

        try {

            var currentYear = 2020;

            var ratioIndex;
            //R  : Market Capitalization (this Year), PE Ratio, PS Ratio, PB Ratio, P/FCF Ratio, EV/EBITDA Ratio, EV/FCF Ratio, Return on Capital (ROIC), Return on Equity (ROE), Return on Assets (ROA), Debt / Equity Ratio [inverse for radar], Market Capitalization (cagr for radar)

            for (var i = 0; i < this.props.r.length; i++) {

                if (this.props.r[i].Year === "EV/EBITDA") {
                    ratioIndex = i;
                }

            }



            //  returnArr.push(revenueCagr1)

            var ratioObject = this.props.r[ratioIndex];

            var returnLast = ratioObject[Object.keys(ratioObject)[Object.keys(ratioObject).length - 2]];


            return returnLast
        }
        catch {
            return '-'
        }


    }


    renderEVFCF() {

        try {

            var currentYear = 2020;

            var ratioIndex;
            //R  : Market Capitalization (this Year), PE Ratio, PS Ratio, PB Ratio, P/FCF Ratio, EV/EBITDA Ratio, EV/FCF Ratio, Return on Capital (ROIC), Return on Equity (ROE), Return on Assets (ROA), Debt / Equity Ratio [inverse for radar], Market Capitalization (cagr for radar)

            for (var i = 0; i < this.props.r.length; i++) {

                if (this.props.r[i].Year === "EV/FCF") {
                    ratioIndex = i;
                }

            }



            //  returnArr.push(revenueCagr1)

            var ratioObject = this.props.r[ratioIndex];

            var returnLast = ratioObject[Object.keys(ratioObject)[Object.keys(ratioObject).length - 2]];


            return returnLast;
        }
        catch {
            return '-'
        }


    }

    renderFCFCagr() { }

    renderDE() { }

    /*
       var peIndex;
            var psIndex;
            var pbIndex;
            var pfcfIndex;
            var evEbitdaIndex;
            var evFcfIndex;
            var roicIndex;
            var roeIndex;
            var roaIndex;
    */

    render() {
        return (
            <div class="quickStats-container">

                <div class="row" id="quick">

                    <div class="col">
                        Revenue CAGR
                    </div>
                    <div class="col">
                        {(Math.round(parseInt(this.renderQuick()) * 100) / 100).toFixed(2)}
                    </div>
                </div>


                <div class="row" id="quick">

                    <div class="col">
                        P/E
                    </div>
                    <div class="col">
                        {(Math.round(parseInt(this.renderPE()) * 100) / 100).toFixed(2)}
                    </div>
                </div>


                <div class="row" id="quick">

                    <div class="col">
                        P/S
                    </div>
                    <div class="col">
                        {(Math.round(parseInt(this.renderPS()) * 100) / 100).toFixed(2)}
                    </div>
                </div>





                <div class="row" id="quick">

                    <div class="col">
                        EV/FCF
                    </div>
                    <div class="col">
                        {(Math.round(parseInt(this.renderEVFCF()) * 100) / 100).toFixed(2)}
                    </div>
                </div>



                <div class="row" id="quick">

                    <div class="col">
                        P/FCF
                    </div>
                    <div class="col">
                        {(Math.round(parseInt(this.renderPFCF()) * 100) / 100).toFixed(2)}
                    </div>
                </div>



                <div class="row" id="quick">

                    <div class="col">
                        P/B
                    </div>
                    <div class="col">
                        {(Math.round(parseInt(this.renderPB()) * 100) / 100).toFixed(2)}
                    </div>
                </div>


                <div class="row" id="quick">


                    <div class="col">
                        FCF CAGR
                    </div>
                    <div class="col">
                        {(Math.round(parseInt(this.renderFCFCagr()) * 100) / 100).toFixed(2)}
                    </div>
                </div>


                <div class="row endQuick" id="quick">

                    <div class="col">
                        D/E
                    </div>
                    <div class="col">
                        {(Math.round(parseInt(this.renderDE()) * 100) / 100).toFixed(2)}
                    </div>
                </div>



                <br />
                <br />

            </div>
        )
    }
}