import React from "react";

import Financials from "./Financials";

import { Radar, Line, Bar } from "react-chartjs-2"
import BarChartIcon from '@mui/icons-material/BarChart';

const Chart = (props) => {

    // let dataArgs = [];

    let k;
    for (k of Object.keys(props.chartData)) {

        /*        k : key
         *   obj[k] : value
         */
        if (k === "Year") {

        }
        else {
            // dataArgs.push(props.chartData[k])
        }
    }






    let renderGraphs = (canvas) => {

        try {


            var returnArr = []

            const ctx = canvas.getContext('2d')

            var currentYear = 2020;


            var gradientGreen = ctx.createLinearGradient(0, 0, 0, 300);
            gradientGreen.addColorStop(0, "rgb(147, 157, 170,1)");
            gradientGreen.addColorStop(1, "rgb(147, 157, 170,0.01)");



            var data = {

                labels: [],

                datasets: [
                    {
                        borderWidth: 1,// and not lineWidth,
                        borderColor: 'white',
                        backgroundColor: gradientGreen,

                        label: props.metric,
                        data: [],
                        fill: true,
                        //light green under the line: rgba(95, 220, 157, 1)
                    }

                ]
            }

            let labelsArr = []

            let dataArr = []


            let k;
            for (k of Object.keys(props.chartData)) {

                /*        k : key, year
                 *   obj[k] : value
                 */
                if (k === "Year") {

                }
                else {
                    labelsArr.push(k)
                    dataArr.push(props.chartData[k])
                }
            }


            data.labels = labelsArr;


            data.datasets[0].data = dataArr

            returnArr.push(<Bar data={data} options=
                {{

                    responsive: true,
                    plugins: {
                        tooltip: {
                            mode: 'index',
                            intersect: false
                        }
                    },
                    interaction: {
                        mode: 'x'
                    }

                    ,
                    tooltips: {
                        mode: 'nearest'
                    },

                    aspectRatio: 4,
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            grid: {
                                color: 'rgba(0, 0, 0, 0.5)',
                                borderColor: 'rgba(0, 0, 0, 0.77)',
                                tickColor: 'rgba(0, 0, 0, 0.77)'
                            },
                            pointLabels: {
                                color: 'red'
                            }, ticks: {
                                color: 'white',
                                font: {
                                    size: 15,
                                }
                            },
                        },
                        y: {
                            grid: {
                                color: 'rgba(0, 0, 0, 0.5)',
                                borderColor: 'rgba(0, 0, 0, 0.77)',
                                tickColor: 'rgba(0, 0, 0, 0.77)'
                            },
                            pointLabels: {
                                color: 'white'
                            },
                            ticks: {
                                color: 'white',
                                font: {
                                    size: 15,
                                }
                            },
                            beginAtZero: true
                        }
                    },
                    tension: 0.3

                }}>  </Bar >)

            return returnArr
        }
        catch {

        }
    }

    return (
        <div className='chart-div'>
            {/* {props.metric}

            {JSON.stringify(props.chartData)} */}

            <canvas id="can" hidden="false" className="radar-can">

            </canvas>
            {renderGraphs(document.getElementById("can"))}

        </div>
    )

}

export default Chart