import React from "react";

import DCFInputs from "./DCFInputs";

import DiscountedCashFlows from "./DiscountedCashFlows";
import { Col, Row, Form } from "react-bootstrap";
import DCFContextProvider from "./contexts/DCFContext";

export default class FiveYearCashFlows extends React.Component {



    constructor(props) {
        super(props);
        this.state = {
            initialFCF: this.props.initialFCF,
            fiveYearGrowth: this.props.fiveYearGrowth,
            sixYearGrowth: this.props.sixYearGrowth,
            terminalMultiple: this.props.terminalMultiple,
            discountRate: this.props.discountRate,

            Year1: 'NaN',
            Year2: 'NaN',
            // Year1: fiveBase ** 0,
            //Year2: this.props.initialFCF * ((1 + parseFloat(this.props.fiveYearGrowth)) ** 1),
            Year3: 'NaN',
            Year4: 'NaN',
            Year5: 'NaN',
            Year6: 'NaN',
            Year7: 'NaN',
            Year8: 'NaN',
            Year9: 'NaN',
            Year10: 'NaN',

        };
    }


    helperFunction() {
        //growing cash flows out     
        this.setState()
    }

    handleYear1FCFChanged(event) {

        this.setState({ Year1: event.target.value });
    }
    handleYear2FCFChanged(event) {

        this.setState({ Year2: event.target.value });
    }
    handleYear3FCFChanged(event) {
        this.setState({ Year3: event.target.value });
    }
    handleYear4FCFChanged(event) {
        this.setState({ Year4: event.target.value });
    }
    handleYear5FCFChanged(event) {
        this.setState({ Year5: event.target.value });
    }
    handleYear6FCFChanged(event) {
        this.setState({ Year6: event.target.value });
    }
    handleYear7FCFChanged(event) {
        this.setState({ Year7: event.target.value });
    }
    handleYear8FCFChanged(event) {
        this.setState({ Year8: event.target.value });
    }
    handleYear9FCFChanged(event) {
        this.setState({ Year9: event.target.value });
    }
    handleYear10FCFChanged(event) {
        this.setState({ Year10: event.target.value });
    }

    resetOnClick(event) {
        this.setState({
            Year1: 'NaN',
            Year2: 'NaN',

            Year3: 'NaN',
            Year4: 'NaN',
            Year5: 'NaN',
            Year6: 'NaN',
            Year7: 'NaN',
            Year8: 'NaN',
            Year9: 'NaN',
            Year10: 'NaN',

        })
    }




    render() {
        var currentYear = 2021

        //working <input name="Year2 changeable input"  type="number"  value={this.state.Year2 || this.props.Year2}  onChange={this.handleYear2FCFChanged.bind(this)}
        //></input>

        //this.state.Year2=this.props.Year2;

        return (

            <div className="overflow-container">
                <div className="dcfOverflow" >
                    <h4 className="cfHeader">CF:</h4>
                    <h4 className="dcfHeader"> DCF:</h4>


                    <div className="year1">

                        {currentYear}
                    </div>

                    <div className="year1Arrow">
                        <svg

                            data-icon="angle-double-right"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                            class="svg-inline--fa fa-angle-double-right fa-w-14 fa-5x"
                        >
                            <g class="fa-group">
                                <path
                                    fill="currentColor"
                                    d="M224 273L88.37 409a23.78 23.78 0 0 1-33.8 0L32 386.36a23.94 23.94 0 0 1 0-33.89l96.13-96.37L32 159.73a23.94 23.94 0 0 1 0-33.89l22.44-22.79a23.78 23.78 0 0 1 33.8 0L223.88 239a23.94 23.94 0 0 1 .1 34z"
                                    class="arrow1"
                                ></path>
                                <path
                                    fill="currentColor"
                                    d="M415.89 273L280.34 409a23.77 23.77 0 0 1-33.79 0L224 386.26a23.94 23.94 0 0 1 0-33.89L320.11 256l-96-96.47a23.94 23.94 0 0 1 0-33.89l22.52-22.59a23.77 23.77 0 0 1 33.79 0L416 239a24 24 0 0 1-.11 34z"
                                    class="arrow2"
                                ></path>
                            </g>
                        </svg>
                    </div>
                    <div className="year2Arrow">
                        <svg

                            data-icon="angle-double-right"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                            class="svg-inline--fa fa-angle-double-right fa-w-14 fa-5x"
                        >
                            <g class="fa-group">
                                <path
                                    fill="currentColor"
                                    d="M224 273L88.37 409a23.78 23.78 0 0 1-33.8 0L32 386.36a23.94 23.94 0 0 1 0-33.89l96.13-96.37L32 159.73a23.94 23.94 0 0 1 0-33.89l22.44-22.79a23.78 23.78 0 0 1 33.8 0L223.88 239a23.94 23.94 0 0 1 .1 34z"
                                    class="arrow1"
                                ></path>
                                <path
                                    fill="currentColor"
                                    d="M415.89 273L280.34 409a23.77 23.77 0 0 1-33.79 0L224 386.26a23.94 23.94 0 0 1 0-33.89L320.11 256l-96-96.47a23.94 23.94 0 0 1 0-33.89l22.52-22.59a23.77 23.77 0 0 1 33.79 0L416 239a24 24 0 0 1-.11 34z"
                                    class="arrow2"
                                ></path>
                            </g>
                        </svg>
                    </div>
                    <div className="year3Arrow">
                        <svg

                            data-icon="angle-double-right"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                            class="svg-inline--fa fa-angle-double-right fa-w-14 fa-5x"
                        >
                            <g class="fa-group">
                                <path
                                    fill="currentColor"
                                    d="M224 273L88.37 409a23.78 23.78 0 0 1-33.8 0L32 386.36a23.94 23.94 0 0 1 0-33.89l96.13-96.37L32 159.73a23.94 23.94 0 0 1 0-33.89l22.44-22.79a23.78 23.78 0 0 1 33.8 0L223.88 239a23.94 23.94 0 0 1 .1 34z"
                                    class="arrow1"
                                ></path>
                                <path
                                    fill="currentColor"
                                    d="M415.89 273L280.34 409a23.77 23.77 0 0 1-33.79 0L224 386.26a23.94 23.94 0 0 1 0-33.89L320.11 256l-96-96.47a23.94 23.94 0 0 1 0-33.89l22.52-22.59a23.77 23.77 0 0 1 33.79 0L416 239a24 24 0 0 1-.11 34z"
                                    class="arrow2"
                                ></path>
                            </g>
                        </svg>
                    </div>
                    <div className="year4Arrow">
                        <svg

                            data-icon="angle-double-right"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                            class="svg-inline--fa fa-angle-double-right fa-w-14 fa-5x"
                        >
                            <g class="fa-group">
                                <path
                                    fill="currentColor"
                                    d="M224 273L88.37 409a23.78 23.78 0 0 1-33.8 0L32 386.36a23.94 23.94 0 0 1 0-33.89l96.13-96.37L32 159.73a23.94 23.94 0 0 1 0-33.89l22.44-22.79a23.78 23.78 0 0 1 33.8 0L223.88 239a23.94 23.94 0 0 1 .1 34z"
                                    class="arrow1"
                                ></path>
                                <path
                                    fill="currentColor"
                                    d="M415.89 273L280.34 409a23.77 23.77 0 0 1-33.79 0L224 386.26a23.94 23.94 0 0 1 0-33.89L320.11 256l-96-96.47a23.94 23.94 0 0 1 0-33.89l22.52-22.59a23.77 23.77 0 0 1 33.79 0L416 239a24 24 0 0 1-.11 34z"
                                    class="arrow2"
                                ></path>
                            </g>
                        </svg>
                    </div>
                    <div className="year5Arrow">
                        <svg

                            data-icon="angle-double-right"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                            class="svg-inline--fa fa-angle-double-right fa-w-14 fa-5x"
                        >
                            <g class="fa-group">
                                <path
                                    fill="currentColor"
                                    d="M224 273L88.37 409a23.78 23.78 0 0 1-33.8 0L32 386.36a23.94 23.94 0 0 1 0-33.89l96.13-96.37L32 159.73a23.94 23.94 0 0 1 0-33.89l22.44-22.79a23.78 23.78 0 0 1 33.8 0L223.88 239a23.94 23.94 0 0 1 .1 34z"
                                    class="arrow1"
                                ></path>
                                <path
                                    fill="currentColor"
                                    d="M415.89 273L280.34 409a23.77 23.77 0 0 1-33.79 0L224 386.26a23.94 23.94 0 0 1 0-33.89L320.11 256l-96-96.47a23.94 23.94 0 0 1 0-33.89l22.52-22.59a23.77 23.77 0 0 1 33.79 0L416 239a24 24 0 0 1-.11 34z"
                                    class="arrow2"
                                ></path>
                            </g>
                        </svg>
                    </div>
                    <div className="year6Arrow">
                        <svg

                            data-icon="angle-double-right"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                            class="svg-inline--fa fa-angle-double-right fa-w-14 fa-5x"
                        >
                            <g class="fa-group">
                                <path
                                    fill="currentColor"
                                    d="M224 273L88.37 409a23.78 23.78 0 0 1-33.8 0L32 386.36a23.94 23.94 0 0 1 0-33.89l96.13-96.37L32 159.73a23.94 23.94 0 0 1 0-33.89l22.44-22.79a23.78 23.78 0 0 1 33.8 0L223.88 239a23.94 23.94 0 0 1 .1 34z"
                                    class="arrow1"
                                ></path>
                                <path
                                    fill="currentColor"
                                    d="M415.89 273L280.34 409a23.77 23.77 0 0 1-33.79 0L224 386.26a23.94 23.94 0 0 1 0-33.89L320.11 256l-96-96.47a23.94 23.94 0 0 1 0-33.89l22.52-22.59a23.77 23.77 0 0 1 33.79 0L416 239a24 24 0 0 1-.11 34z"
                                    class="arrow2"
                                ></path>
                            </g>
                        </svg>
                    </div>
                    <div className="year7Arrow">
                        <svg

                            data-icon="angle-double-right"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                            class="svg-inline--fa fa-angle-double-right fa-w-14 fa-5x"
                        >
                            <g class="fa-group">
                                <path
                                    fill="currentColor"
                                    d="M224 273L88.37 409a23.78 23.78 0 0 1-33.8 0L32 386.36a23.94 23.94 0 0 1 0-33.89l96.13-96.37L32 159.73a23.94 23.94 0 0 1 0-33.89l22.44-22.79a23.78 23.78 0 0 1 33.8 0L223.88 239a23.94 23.94 0 0 1 .1 34z"
                                    class="arrow1"
                                ></path>
                                <path
                                    fill="currentColor"
                                    d="M415.89 273L280.34 409a23.77 23.77 0 0 1-33.79 0L224 386.26a23.94 23.94 0 0 1 0-33.89L320.11 256l-96-96.47a23.94 23.94 0 0 1 0-33.89l22.52-22.59a23.77 23.77 0 0 1 33.79 0L416 239a24 24 0 0 1-.11 34z"
                                    class="arrow2"
                                ></path>
                            </g>
                        </svg>
                    </div>
                    <div className="year8Arrow">
                        <svg

                            data-icon="angle-double-right"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                            class="svg-inline--fa fa-angle-double-right fa-w-14 fa-5x"
                        >
                            <g class="fa-group">
                                <path
                                    fill="currentColor"
                                    d="M224 273L88.37 409a23.78 23.78 0 0 1-33.8 0L32 386.36a23.94 23.94 0 0 1 0-33.89l96.13-96.37L32 159.73a23.94 23.94 0 0 1 0-33.89l22.44-22.79a23.78 23.78 0 0 1 33.8 0L223.88 239a23.94 23.94 0 0 1 .1 34z"
                                    class="arrow1"
                                ></path>
                                <path
                                    fill="currentColor"
                                    d="M415.89 273L280.34 409a23.77 23.77 0 0 1-33.79 0L224 386.26a23.94 23.94 0 0 1 0-33.89L320.11 256l-96-96.47a23.94 23.94 0 0 1 0-33.89l22.52-22.59a23.77 23.77 0 0 1 33.79 0L416 239a24 24 0 0 1-.11 34z"
                                    class="arrow2"
                                ></path>
                            </g>
                        </svg>
                    </div>
                    <div className="year9Arrow">
                        <svg

                            data-icon="angle-double-right"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                            class="svg-inline--fa fa-angle-double-right fa-w-14 fa-5x"
                        >
                            <g class="fa-group">
                                <path
                                    fill="currentColor"
                                    d="M224 273L88.37 409a23.78 23.78 0 0 1-33.8 0L32 386.36a23.94 23.94 0 0 1 0-33.89l96.13-96.37L32 159.73a23.94 23.94 0 0 1 0-33.89l22.44-22.79a23.78 23.78 0 0 1 33.8 0L223.88 239a23.94 23.94 0 0 1 .1 34z"
                                    class="arrow1"
                                ></path>
                                <path
                                    fill="currentColor"
                                    d="M415.89 273L280.34 409a23.77 23.77 0 0 1-33.79 0L224 386.26a23.94 23.94 0 0 1 0-33.89L320.11 256l-96-96.47a23.94 23.94 0 0 1 0-33.89l22.52-22.59a23.77 23.77 0 0 1 33.79 0L416 239a24 24 0 0 1-.11 34z"
                                    class="arrow2"
                                ></path>
                            </g>
                        </svg>
                    </div>


                    <div className="year2">
                        {currentYear + 1}
                    </div>


                    <div className="year3">

                        {currentYear + 2}
                    </div>

                    <div className="year4">

                        {currentYear + 3}
                    </div>

                    <div className="year5">

                        {currentYear + 4}
                    </div>

                    <div className="year6">

                        {currentYear + 5}
                    </div>

                    <div className="year7">

                        {currentYear + 6}
                    </div>

                    <div className="year8">

                        {currentYear + 7}
                    </div>

                    <div className="year9">

                        {currentYear + 8}
                    </div>

                    <div className="year10">

                        {currentYear + 9}

                    </div>





                    <form className="formContainer">

                        <div className="year1Input">
                            <input name="Year1 changeable input" class={((this.state.Year1 === "NaN") || (this.state.Year1 === '')) ? 'inputCF' : 'edited'} type="number" placeholder={this.props.Year1} onChange={this.handleYear1FCFChanged.bind(this)}
                            ></input>
                        </div>

                        <div className="year2Input">
                            <input name="Year2 changeable input" class={((this.state.Year2 === "NaN") || (this.state.Year2 === '')) ? 'inputCF' : 'edited'} type="number" placeholder={this.props.Year2} onChange={this.handleYear2FCFChanged.bind(this)}
                            ></input>
                        </div>

                        <div className="year3Input">
                            <input name="Year3 changeable input" class={((this.state.Year3 === "NaN") || (this.state.Year3 === '')) ? 'inputCF' : 'edited'} type="number" placeholder={this.props.Year3} onChange={this.handleYear3FCFChanged.bind(this)}
                            ></input>
                        </div>

                        <div className="year4Input">
                            <input name="Year4 changeable input" class={((this.state.Year4 === "NaN") || (this.state.Year4 === '')) ? 'inputCF' : 'edited'} placeholder={this.props.Year4} onChange={this.handleYear4FCFChanged.bind(this)}
                            ></input>
                        </div>

                        <div className="year5Input">
                            <input name="Year5 changeable input" class={((this.state.Year5 === "NaN") || (this.state.Year5 === '')) ? 'inputCF' : 'edited'} placeholder={this.props.Year5} onChange={this.handleYear5FCFChanged.bind(this)}
                            ></input>
                        </div>

                        <div className="year6Input">
                            <input name="Year6 changeable input" class={((this.state.Year6 === "NaN") || (this.state.Year6 === '')) ? 'inputCF' : 'edited'} type="number" placeholder={this.props.Year6} onChange={this.handleYear6FCFChanged.bind(this)}
                            ></input>
                        </div>

                        <div className="year7Input">
                            <input name="Year7 changeable input" class={((this.state.Year7 === "NaN") || (this.state.Year7 === '')) ? 'inputCF' : 'edited'} type="number" placeholder={this.props.Year7} onChange={this.handleYear7FCFChanged.bind(this)}
                            ></input>
                        </div>

                        <div className="year8Input">
                            <input name="Year8 changeable input" class={((this.state.Year8 === "NaN") || (this.state.Year8 === '')) ? 'inputCF' : 'edited'} type="number" placeholder={this.props.Year8} onChange={this.handleYear8FCFChanged.bind(this)}
                            ></input>
                        </div>

                        <div className="year9Input">
                            <input name="Year9 changeable input" class={((this.state.Year9 === "NaN") || (this.state.Year9 === '')) ? 'inputCF' : 'edited'} type="number" placeholder={this.props.Year9} onChange={this.handleYear9FCFChanged.bind(this)}
                            ></input>
                        </div>

                        <div className="year10Input">
                            <input name="Year10 changeable input" class={((this.state.Year10 === "NaN") || (this.state.Year10 === '')) ? 'input10CF' : 'edited'} placeholder={this.props.Year10} onChange={this.handleYear10FCFChanged.bind(this)}
                            ></input>
                        </div>

                        <div className="resetDiv">

                            <input type="reset" defaultValue="Reset" onClick={this.resetOnClick.bind(this)} class="fa fa-undo" class="resetButton" />

                        </div>



                    </form>


                    <DiscountedCashFlows
                        dcfCase={this.props.dcfCase}
                        tickerEntry={this.props.tickerEntry}

                        Year1={((this.state.Year1 === "NaN") || (this.state.Year1 === '')) ? this.props.Year1 : this.state.Year1}
                        Year2={((this.state.Year2 === "NaN") || (this.state.Year2 === '')) ? this.props.Year2 : this.state.Year2}
                        Year3={((this.state.Year3 === "NaN") || (this.state.Year3 === '')) ? this.props.Year3 : this.state.Year3}
                        Year4={((this.state.Year4 === "NaN") || (this.state.Year4 === '')) ? this.props.Year4 : this.state.Year4}
                        Year5={((this.state.Year5 === "NaN") || (this.state.Year5 === '')) ? this.props.Year5 : this.state.Year5}
                        Year6={((this.state.Year6 === "NaN") || (this.state.Year6 === '')) ? this.props.Year6 : this.state.Year6}
                        Year7={((this.state.Year7 === "NaN") || (this.state.Year7 === '')) ? this.props.Year7 : this.state.Year7}
                        Year8={((this.state.Year8 === "NaN") || (this.state.Year8 === '')) ? this.props.Year8 : this.state.Year8}
                        Year9={((this.state.Year9 === "NaN") || (this.state.Year9 === '')) ? this.props.Year9 : this.state.Year9}
                        Year10={((this.state.Year10 === "NaN") || (this.state.Year10 === '')) ? this.props.Year10 : this.state.Year10}
                        discountRate={this.props.discountRate}> </DiscountedCashFlows>
                </div>



            </div>
        )
        //reember to enter <DisountedCashFlows Year1: , Year2: , ...>
        //    <input value={Year1}></input> default value will be the cash flows projecrted by the props given, but ultimately the input will be editable
        //to send props to discountedCashFlows use if statement

        //if this.state.Year2 == NaN then send this.state.props else (when its not NaN) send this.state.Year2

        // conditional place holder: placeholder={this.props.Year3? this.props.Year3: "loading..."}
    }
}
//                if this.state.Year2 is '' then send this.props.Year2
