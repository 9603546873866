import { useHistory, useLocation } from 'react-router-dom';

import React, { useState, useEffect, useContext, useRef } from "react";
import EmailForm from "./EmailForm"
import Slider, { SliderThumb } from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import LaunchIcon from '@mui/icons-material/Launch';
import './styles/savedValuationsStyles.css'
import Icon from '@mui/material/Icon';
import InputGroup from 'react-bootstrap/InputGroup'
// import Text from 'react-bootstrap/Text'
import FormControl from 'react-bootstrap/FormControl'
import { RouteContext } from './contexts/RouteContext'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TextField from '@mui/material/TextField';

import AddNewValuation from './AddNewValuation'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
// import * as React from 'react';
import Box from '@mui/material/Box';
// import Typography from '@mui/material/Typography';
// import Slider from '@mui/material/Slider';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import { DCFContext } from './contexts/DCFContext'

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { empty } from 'cheerio/lib/api/manipulation';

const SavedValuations = () => {
    const { showSuccessAlert, editSuccessAlert, showErrorAlert, editErrorAlert, disableNavbar, editDisableNavbar } = useContext(DCFContext)



    const history = useHistory();
    const location = useLocation()


    const isDocumentModifiedRef = useRef();
    const [value, setValue] = React.useState(10);
    const [showEdit, setShowEdit] = useState(false);
    const [notes, setNotes] = useState('');
    const [notesIndex, setNotesIndex] = useState(-1);
    const [showAddNewValuation, setShowAddNewValuation] = useState(false);
    const [makePost, setMakePost] = useState('')
    const [tableEntries, setTableEntries] = useState([])
    const [data, setData] = useState({});
    const [isJwt, setIsJwt] = useState(true);

    const [notif, setNotif] = useState(false);

    const [alertLimit, setAlertLimit] = useState(false);

    const [alertCount, setAlertCount] = useState(0);


    function calculateValue(value) {
        return value;
    }

    const callAboutPage = async () => {

        editDisableNavbar(true)



        try {

            const jwtToken = localStorage.getItem('jwtToken')
            const res = await fetch('https://obscure-meadow-68622.herokuapp.com/info', {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    "Content-Type": 'application/json',
                    'Authorization': 'Bearer ' + jwtToken

                },

            });

            const data = await res.json();

            let entryArr = []

            let count = 0;

            for (let i = 0; i < data.valuations.length; i++) {
                entryArr.push({
                    ticker: data.valuations[i].ticker,
                    confidence: data.valuations[i].confidence,
                    date: data.valuations[i].date,
                    fairPrice: data.valuations[i].fairPrice,
                    fifteenMOS: data.valuations[i].fifteenMOS,
                    notes: data.valuations[i].notes,
                    thirtyMOS: data.valuations[i].thirtyMOS,
                    toAlert: data.valuations[i].toAlert,
                    isMarketCap: data.valuations[i].isMarketCap,
                    cfArr: data.valuations[i].cfArr,
                    dcfArr: data.valuations[i].dcfArr,
                    cfArrBull: data.valuations[i].cfArrBull,
                    dcfArrBull: data.valuations[i].dcfArrBull,
                    cfArrBear: data.valuations[i].cfArrBear,
                    dcfArrBear: data.valuations[i].dcfArrBear,
                    discountRateBear: data.valuations[i].discountRateBear,
                    discountRateBull: data.valuations[i].discountRateBull,
                    discountRate: data.valuations[i].discountRate,
                    fiveYearGrowth: data.valuations[i].fiveYearGrowth,
                    sixYearGrowth: data.valuations[i].sixYearGrowth,
                    terminalMultiple: data.valuations[i].terminalMultiple,
                    fiveYearGrowthBull: data.valuations[i].fiveYearGrowthBull,
                    sixYearGrowthBull: data.valuations[i].sixYearGrowthBull,
                    terminalMultipleBull: data.valuations[i].terminalMultipleBull,
                    fiveYearGrowthBear: data.valuations[i].fiveYearGrowthBear,
                    sixYearGrowthBear: data.valuations[i].sixYearGrowthBear,
                    terminalMultipleBear: data.valuations[i].terminalMultipleBear,
                    normalWeight: data.valuations[i].normalWeight,
                    bearWeight: data.valuations[i].bearWeight,
                    bullWeight: data.valuations[i].bullWeight

                })

                if (data.valuations[i].toAlert) {
                    count = count + 1;
                }
            }


            setTableEntries(entryArr)

            setAlertCount(count)


            if (!res.status === 200) {
                const error = new Error(res.error)
                throw error;
            }

        } catch (error) {
            console.log(error)
            history.push('/signin')
        }

        setTimeout(() => {
            editDisableNavbar(false)

        }, 500);

    }


    const handleConfidenceChange = (event, index, newValue) => {

        console.log('checking slider: index: ' + index + ' event.target,value: ' + event.target.value)

        let newTableEntries = [];
        for (let i = 0; i < tableEntries.length; i++) {
            if (i === index) {
                newTableEntries.push(
                    {
                        ticker: tableEntries[i].ticker,
                        date: tableEntries[i].date,
                        fairPrice: tableEntries[i].fairPrice,
                        fifteenMOS: tableEntries[i].fifteenMOS,
                        thirtyMOS: tableEntries[i].thirtyMOS,
                        notes: tableEntries[i].notes,
                        confidence: event.target.value,
                        isMarketCap: tableEntries[i].isMarketCap,
                        toAlert: tableEntries[i].toAlert,
                        cfArr: tableEntries[i].cfArr,
                        dcfArr: tableEntries[i].dcfArr,
                        cfArrBull: tableEntries[i].cfArrBull,
                        dcfArrBull: tableEntries[i].dcfArrBull,
                        cfArrBear: tableEntries[i].cfArrBear,
                        dcfArrBear: tableEntries[i].dcfArrBear,
                        discountRateBear: tableEntries[i].discountRateBear,
                        discountRateBull: tableEntries[i].discountRateBull,
                        discountRate: tableEntries[i].discountRate,
                        fiveYearGrowth: tableEntries[i].fiveYearGrowth,
                        sixYearGrowth: tableEntries[i].sixYearGrowth,
                        terminalMultiple: tableEntries[i].terminalMultiple,
                        fiveYearGrowthBull: tableEntries[i].fiveYearGrowthBull,
                        sixYearGrowthBull: tableEntries[i].sixYearGrowthBull,
                        terminalMultipleBull: tableEntries[i].terminalMultipleBull,
                        fiveYearGrowthBear: tableEntries[i].fiveYearGrowthBear,
                        sixYearGrowthBear: tableEntries[i].sixYearGrowthBear,
                        terminalMultipleBear: tableEntries[i].terminalMultipleBear,
                        normalWeight: tableEntries[i].normalWeight,
                        bearWeight: tableEntries[i].bearWeight,
                        bullWeight: tableEntries[i].bullWeight

                    }
                )
            }
            else {
                newTableEntries.push(tableEntries[i])
            }
        }

        setTableEntries(newTableEntries)


    };
    const editNotes = (index) => {
        console.log('cliked index: ' + index)
        setNotesIndex(index)
        setShowEdit(true)

    };

    const handleDelete = async (index) => {
        const jwtToken = localStorage.getItem('jwtToken')

        const res = await fetch('https://obscure-meadow-68622.herokuapp.com/deletevaluation', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwtToken

            },
            body: JSON.stringify({
                index: index
            })
        })
        const data = await res.json();

        if (!data) {
            console.log('couldnt delete')
        }
        else {
            editSuccessAlert(true);
            setTimeout(() => {
                editSuccessAlert(false);

            }, 1500);

            callAboutPage();

        }

    }

    const handleCollectionEdit = () => {

        editErrorAlert(true);
        setTimeout(() => {
            editErrorAlert(false);

        }, 1500);

    }

    const handleSaveEdit = () => {

        let index = notesIndex;

        let newTableEntries = [];

        console.log('notes check : ', notes)

        for (let i = 0; i < tableEntries.length; i++) {
            if (i === index) {
                newTableEntries.push(
                    {
                        ticker: tableEntries[i].ticker,
                        date: tableEntries[i].date,
                        fairPrice: tableEntries[i].fairPrice,
                        fifteenMOS: tableEntries[i].fifteenMOS,
                        thirtyMOS: tableEntries[i].thirtyMOS,
                        notes: notes,
                        confidence: tableEntries[i].confidence,
                        toAlert: tableEntries[i].toAlert,
                        isMarketCap: tableEntries[i].isMarketCap
                    }
                )
            }
            else {
                newTableEntries.push(tableEntries[i])
            }
        }

        setTableEntries(newTableEntries)
        setShowEdit(false)
        setNotesIndex(-1);
    }

    let openTicker = (path) => {
        history.push('/financials/' + path)

    }
    let toggleNotification = async (index) => {

        // console.log('checking slider: index: ' + index + ' event.target,value: ' + event.target.value)

        // check if allowed count
        let access = false;

        try {

            const jwtToken = localStorage.getItem('jwtToken')
            const res = await fetch('https://obscure-meadow-68622.herokuapp.com/info', {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    "Content-Type": 'application/json',
                    'Authorization': 'Bearer ' + jwtToken

                },

            });

            const data = await res.json();
            console.log('GET CHECK : ', data)
            // setData(data)

            access = data.premiumAccess;

            if (!res.status === 200) {
                const error = new Error(res.error)
                throw error;
            }

        } catch (error) {
            console.log(error)
            // history.push('/signin')
        }


        let toggleTrue = false;

        for (let i = 0; i < tableEntries.length; i++) {
            if (i === index) {
                if (!tableEntries[i].toAlert) {
                    // trying to true
                    toggleTrue = true

                }

            }

        }


        if (!access && alertCount === 3 && toggleTrue) {
            // not allowed

            setAlertLimit(true)

            setTimeout(() => {
                setAlertLimit(false)

            }, 3000);

        }

        else {

            // turning toggle on
            for (let i = 0; i < tableEntries.length; i++) {
                if (i === index) {
                    if (!tableEntries[i].toAlert) {
                        // trying to true
                        setAlertCount(alertCount + 1)

                        // console.log('cliecked')

                        const jwtToken = localStorage.getItem('jwtToken')

                        const res = await fetch('https://obscure-meadow-68622.herokuapp.com/setAlerts', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + jwtToken

                            },
                            body: JSON.stringify({
                                index: index
                            })
                        })

                    }
                    else {
                        setAlertCount(alertCount - 1)

                        const jwtToken = localStorage.getItem('jwtToken')

                        const res = await fetch('https://obscure-meadow-68622.herokuapp.com/stopAlerts', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + jwtToken

                            },
                            body: JSON.stringify({
                                index: index
                            })
                        })


                    }

                }

            }

            let newTableEntries = [];
            for (let i = 0; i < tableEntries.length; i++) {
                if (i === index) {

                    setNotif(true)

                    setTimeout(() => {
                        setNotif(false)

                    }, 1000);

                    // alert('Alerts? ' + !tableEntries[i].toAlert + ' for ' + tableEntries[i].ticker)

                    newTableEntries.push(
                        {
                            ticker: tableEntries[i].ticker,
                            date: tableEntries[i].date,
                            fairPrice: tableEntries[i].fairPrice,
                            fifteenMOS: tableEntries[i].fifteenMOS,
                            thirtyMOS: tableEntries[i].thirtyMOS,
                            notes: tableEntries[i].notes,
                            confidence: tableEntries[i].confidence,
                            isMarketCap: tableEntries[i].isMarketCap,
                            toAlert: !tableEntries[i].toAlert
                        }
                    )
                }
                else {
                    newTableEntries.push(tableEntries[i])
                }
            }

            setTableEntries(newTableEntries)
        }



    }


    const handleSave = async () => {
        //

        console.log('JSON.stringify(tableEntries) check : ', JSON.stringify(tableEntries))

        const jwtToken = localStorage.getItem('jwtToken')

        const res = await fetch('https://obscure-meadow-68622.herokuapp.com/addtable', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwtToken

            },
            body: JSON.stringify(tableEntries)
        })
        const data = await res.json();

        if (!data) {
            console.log('valuation table not sent')
        }
        else {
            alert('valuations updated')
        }

    }

    const tableRef = useRef();

    useEffect(() => {
        tableRef.current = tableEntries;
    }, [tableEntries]);


    const handleSaveUnmount = async (table) => {
        console.log('checking in unmount: ', table)



        const jwtToken = localStorage.getItem('jwtToken')

        const res = await fetch('https://obscure-meadow-68622.herokuapp.com/addtable', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwtToken

            },
            body: JSON.stringify(table)
        })
        const data = await res.json();

        if (!data) {
            console.log('valuation table not sent')
        }
        else {
            console.log('valuation table updated with :  ', table)
        }

    }



    let checkAuth = async () => {

        try {

            const jwtToken = localStorage.getItem('jwtToken')
            const res = await fetch('https://obscure-meadow-68622.herokuapp.com/info', {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    "Content-Type": 'application/json',
                    'Authorization': 'Bearer ' + jwtToken

                },

            });

            const data = await res.json();
            console.log('auth data', data)
            setIsJwt(true)

        }
        catch {
            console.log('auth data error')
            setIsJwt(false)

        }
    }

    useEffect(() => {
        checkAuth()
        // try {
        //     console.log('jwt', localStorage.getItem('jwtToken'))
        //     if (localStorage.getItem('jwtToken') === null || localStorage.getItem('jwtToken') === 'null' || localStorage.getItem('jwtToken') === undefined || localStorage.getItem('jwtToken') === 'undefined' || localStorage.getItem('jwtToken') === '') {
        //         setIsJwt(false)

        //     }
        //     else {
        //         setIsJwt(true)

        //     }
        // }
        // catch {
        //     setIsJwt(false)

        // }

        callAboutPage();

        return () => {

            //             if (tableRef.current.length===0){
            //             make get to see
            //             //get 

            //         if (tableEntries.length===0) {
            //             handleSaveUnmount(tableRef.current)

            //         }
            //             else {
            //                 get is not empty and table ref is empty, so this means done too quickly, so dont hanldesaveunmount
            // }
            //         }


            console.log('tableRef.current check ', tableRef.current);

            handleSaveUnmount(tableRef.current)

        }

    }, []);



    return (

        (isJwt === false) ?
            <div> {history.push('/signin')}</div>

            :

            <div class='saved-valuations-screen'>



                <h1 style={{ color: 'white', marginTop: '20px', marginBottom: '40px', fontWeight: 'bolder' }}>Valuation Dashboard</h1>

                <div class='collection-container'>
                    <div class='row'>
                        <div id='collectionContainer' style={{ width: '200px', marginLeft: '10%' }}>
                            <h4 class='collection-label' style={{ color: 'white', display: 'inline' }}>Collection 1</h4>
                            <EditIcon class='pencilIcon' style={{ marginLeft: '10px', fill: 'green' }} onClick={handleCollectionEdit} ></EditIcon>

                        </div>


                    </div>
                    <br />


                    <div class='valuation-table'  >
                        <tr class='title-headers'>

                            <th class='first-table-header'>Alerts</th>

                            <th class='first-table-header'>Ticker</th>
                            <th>Date Created</th>
                            <th>Fair Price</th>
                            <th>15% MOS Price</th>
                            <th>30% MOS price</th>
                            <th >Notes</th>
                            <th class='last-table-header'>Confidence</th>
                        </tr>

                        {tableEntries.map((entry, index) => {

                            return (<tr>
                                <td>
                                    <NotificationsActiveIcon class='editIcon' style={entry.toAlert ? { fill: "red", boxShadow: '0 0 10px red' } : { fill: '#ff81818c', boxShadow: '0 0 10px red' }} onClick={() => toggleNotification(index)} />

                                </td>
                                <td>{entry.ticker}
                                    <LaunchIcon class='editIcon' style={{ fill: "yellow" }} onClick={() => openTicker(entry.ticker)} />

                                </td>
                                <td>{(entry.date.split('T'))[0]}</td>
                                <td>
                                    {(parseFloat(entry.fairPrice)).toFixed(2) + entry.fairPrice.slice(-1)}
                                    {entry.isMarketCap ? <div style={{ color: 'white', fontStyle: 'italic', display: 'inline', marginLeft: '3px' }}>Market Cap</div> : <div style={{ color: 'white', fontStyle: 'italic', marginLeft: '3px', display: 'inline' }}>Per Share</div>}
                                    <div className='details-btn' onClick={() => history.push('/valuation/' + entry.ticker)}>EXPAND DETAILS</div>
                                </td>
                                <td>{entry.fifteenMOS}</td>
                                <td>{entry.thirtyMOS}</td>
                                <td >
                                    <div class='td-notes' style={{ scrollbarWidth: 'none' }}>
                                        <div >
                                            <div id='notes-text' style={{ maxHeight: '25px', overflow: 'auto', scrollbarWidth: 'none', fontSize: '10px', textAlign: 'left' }}>
                                                {entry.notes}
                                            </div>
                                            <div  >
                                                <EditIcon class='editIcon' style={{ fill: "green" }} onClick={() => editNotes(index)} />

                                            </div>

                                        </div>
                                    </div>
                                </td>
                                <td>



                                    {/* <div class='row' style={{ width: '150px' }}>

                                    {/* <div class='col' style={{ backgroundColor: 'red' }}> */}
                                    {/* <div class="col-6 align-self-center" > */}
                                    <Slider
                                        value={entry.confidence}
                                        min={0}
                                        step={1}
                                        max={100}
                                        scale={calculateValue}
                                        // getAriaValueText={valueLabelFormat}
                                        // valueLabelFormat={valueLabelFormat}
                                        onChange={(event) => handleConfidenceChange(event, index)}
                                        valueLabelDisplay="auto"
                                        aria-labelledby="non-linear-slider"
                                    />
                                    {/* </div> */}

                                    {/* <div class="col-6 align-self-center" > */}
                                    <Typography id="non-linear-slider" gutterBottom style={{ color: 'white' }}>
                                        {calculateValue(entry.confidence)}/100
                                    </Typography>
                                    {/* </div>
                                </div> */}



                                </td>

                                <td>
                                    <DeleteForeverIcon class='editIcon' style={{ fill: "red" }} onClick={() => handleDelete(index)}></DeleteForeverIcon>
                                </td>
                            </tr>)

                        })}


                    </div>
                    <div class="table-bottom-header">
                        {/* <button class={'save-changes-button'} onClick={handleSave}>Save Changes</button> */}

                        <AddCircleIcon class='plusIcon' style={{ fill: "green" }} onClick={() => history.push('/calculators')} ></AddCircleIcon>

                    </div>
                </div>






                <div class='row'>
                    <div class='col-12'>
                        {/* <h4 class='collection-label' style={{ color: 'white' }}>Collection 1</h4> */}
                        <button class='add-collection' onClick={handleCollectionEdit}>
                            Add Collection
                        </button>
                    </div>
                </div>


                {
                    showAddNewValuation ? <AddNewValuation tableEntries={tableEntries} ></AddNewValuation> : <div></div>
                }

                {
                    showEdit &&


                    <div class="row" id="termsID" style={{ "z-index": 199, position: 'relative' }}>

                        <div class="email-form-container" style={{ "z-index": 199, color: 'white', textAlign: 'left', backgroundColor: 'rgb(11, 23, 53)' }}>

                            <Grid container spacing={0} style={{ marginLeft: '95%', marginTop: '2%', color: 'white' }}>
                                <Grid item xs={12}  >
                                    <CancelPresentationIcon class='close-btn' onClick={() => { setShowEdit(false) }} />
                                </Grid>

                            </Grid>




                            <InputGroup style={{ marginTop: "20px" }}>
                                <InputGroup.Text style={{ backgroundColor: '#92ddff21', color: 'white' }}>
                                    <br />
                                    <EditIcon class='pencilIcon' style={{ fill: "yellow" }} />

                                </InputGroup.Text>


                                <TextField
                                    // inputProps={{ className: classes.input }}
                                    id="outlined-basic"
                                    label="Notes"
                                    variant="outlined"
                                    textColor='primary'
                                    InputLabelProps={{ className: 'textfield_label' }}
                                    InputProps={{ className: 'textfield_props' }}
                                    color="primary"
                                    focused
                                    multiline
                                    value={notes}
                                    onChange={(e) => setNotes(e.target.value)}
                                    style={{ width: 'calc(100% - 55px)', height: '100%' }} //    width: calc(100% - 26px);
                                />
                            </InputGroup>



                            <button class='save-new-valuation-button' onClick={() => handleSaveEdit()} style={{ width: '100px', marginLeft: 'calc(50% - 50px)' }}>
                                <Grid container spacing={2}>

                                    <Grid item xs={6} style={{ marginTop: '2px' }}  >
                                        Save
                                    </Grid>

                                    <Grid item xs={6}  >
                                        <EditIcon class='pencilIcon' style={{ fill: "yellow" }} />

                                    </Grid>
                                </Grid>

                            </button>



                        </div>

                    </div>

                }

                {
                    showEdit ? <div class="blurred-background" onClick={() => setShowEdit(false)} style={{
                        "z-index": 9, backgroundColor: 'rgba(0, 0, 0, 0.55)'
                    }}>
                    </div> : <div></div>
                }


                {
                    notif ? <Alert variant="filled" severity="success" style={{ position: 'fixed', top: '0px', marginLeft: '', zIndex: '1000' }}>
                        Alerts updated!
                    </Alert> : <div />
                }

                {
                    showSuccessAlert ? <Alert variant="filled" severity="success" style={{ position: 'fixed', top: '0px', marginLeft: '', zIndex: '1000' }}>
                        Valuation deleted
                    </Alert> : <div />
                }
                {showErrorAlert ? <Alert variant="filled" severity="error" style={{ position: 'fixed', top: '0px', marginLeft: '', zIndex: '1000' }}>Premium access required for multiple collections</Alert>
                    : <div />}

                {alertLimit ? <Alert variant="filled" severity="error" style={{ position: 'fixed', top: '0px', marginLeft: '', zIndex: '1000' }}>You've reached the maximum allowable alerts for the free tier. Get unlimited alerts with Premium Access here</Alert>
                    : <div />}


            </div >


    )
}
export default SavedValuations

