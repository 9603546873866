import React, { useState, useEffect, useContext, useRef } from "react";

import Financials from "./Financials";

import { Radar, Line, Bar } from "react-chartjs-2"
import BarChartIcon from '@mui/icons-material/BarChart';
import Chart from './Chart'
//OFFICIAL metric names 
//CF : Free Cash Flow	, Free Cash Flow Growth, Free Cash Flow Per Share
//IS : Revenue, Net Income, Revenue Growth, Shares Outstanding (Basic), Gross Margin, Operating Margin, Profit Margin, FCF Margin, EPS Growth, EPS (Diluted), Dividend Per Share
//R  : Market Capitalization (this Year), PE Ratio, PS Ratio, PB Ratio, P/FCF Ratio, EV/EBITDA Ratio, EV/FCF Ratio, Return on Capital (ROIC), Return on Equity (ROE), Return on Assets (ROA), Debt / Equity Ratio [inverse for radar], Market Capitalization (cagr for radar)
//BS : Total Current Assets	- Total Liabilities

// Radar chart over time : roic,roe,roa, 1/DtoE, cagr return, margin growth over time, management, employee sentiment
export default class BalanceSheet extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

            bs: this.props.bs,

        };
    }



    getKeys = function () {
        try {
            return Object.keys(this.props.bs[0]);
        }
        catch {

        }
    }

    getHeader = function () {
        try {
            var keys = this.getKeys();

            return keys.map((key, index) => {

                var currentYear = parseInt(keys[keys.length - 2]);

                var firstYear = currentYear - (keys.length - 2)
                //currentYear - (keys.length-2)

                if (key === firstYear.toString()) {
                    return <th >Financials/Years</th>

                }
                else if (key === 'Year') {
                    return <th >{currentYear}</th>

                }
                else {
                    //                    var newKey = parseInt(key) - 1

                    return <th >{key - 1}</th>

                }



            })
        }
        catch {

        }
    }

    getRowsData = function () {
        var items = this.props.bs;
        var keys = this.getKeys();
        return items.map((row, index) => {

            return <tr class="row100 head"><RenderRow key={index} data={row} keys={keys} /></tr>
        })
    }


    handleMouseOver = function () {
        console.log('hovered')
    }

    handleMouseOut = function () {
        console.log('outted')
    }

    render() {

        return (
            <div class="container-table100" class="col-lg">
                <div class="wrap-table100" class="col-lg">
                    <div class="table100 ver6 m-b-110" id="pad">
                        <table data-vertable="ver6">
                            <thead>
                                <tr class="row100 head">{this.getHeader()}</tr>
                            </thead>
                            <tbody>
                                {this.getRowsData()}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}



const RenderRow = (props) => {

    const [showChart, setShowChart] = useState(false);


    if (props.data['Year'] === "Book Value Per Share"
        || props.data['Year'] === "Working Capital"
        || props.data['Year'] === "Net Cash Per Share"
        || props.data['Year'] === "Net Cash / Debt Growth"
        || props.data['Year'] === "Net Cash / Debt"
        || props.data['Year'] === "Debt Growth"
        || props.data['Year'] === "Cash Growth"
        || props.data['Year'] === "Total Debt"
        || props.data['Year'] === "Cash & Cash Equivalents"

    ) {
        return null
    }
    else {
        var currentYear = parseInt(props.keys[props.keys.length - 2]);

        var firstYear = currentYear - (props.keys.length - 2);

        return props.keys.map((key, index) => {
            if (key === firstYear.toString()) {
                //render first key (first key being 2011)
                if (
                    props.data['Year'] === "Current Assets"
                    || props.data['Year'] === "Assets"
                    || props.data['Year'] === "Current Liabilities"
                    || props.data['Year'] === "Liabilities"
                    || props.data['Year'] === "Liabilities and Equity"



                ) { //bolden line items
                    return <th class={"lineItem"} >
                        {props.data['Year']}
                        <div style={{ display: 'inline', height: 'fit-content' }} onMouseOver={() => setShowChart(true)} onMouseLeave={() => setShowChart(false)} >
                            <BarChartIcon class="chart-icon"></BarChartIcon>
                            {showChart ? <Chart metric={props.data['Year']} chartData={props.data}></Chart> : null}
                        </div>
                    </th>

                }
                else {
                    return <td >
                        {props.data['Year']}
                        <div style={{ display: 'inline', height: 'fit-content' }} onMouseOver={() => setShowChart(true)} onMouseLeave={() => setShowChart(false)}>
                            <BarChartIcon class="chart-icon"></BarChartIcon>
                            {showChart ? <Chart metric={props.data['Year']} chartData={props.data}></Chart> : null}
                        </div>
                    </td>
                }
            }
            else if (key === 'Year') {
                //render second last key (second last key being currentYear)
                if (
                    props.data['Year'] === "Current Assets"
                    || props.data['Year'] === "Assets"
                    || props.data['Year'] === "Current Liabilities"
                    || props.data['Year'] === "Liabilities"
                    || props.data['Year'] === "Liabilities and Equity"


                ) { //bolden line items
                    return <th class={"lineItem"}>{Math.round(parseInt(props.data[currentYear]) / 1000000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</th>

                }
                else {
                    return <td >{Math.round(parseInt(props.data[currentYear]) / 1000000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                }
            }

            else {
                var newKey = parseInt(key) - 1;

                if (
                    props.data['Year'] === "Current Assets"
                    || props.data['Year'] === "Assets"
                    || props.data['Year'] === "Current Liabilities"
                    || props.data['Year'] === "Liabilities"
                    || props.data['Year'] === "Liabilities and Equity"



                ) { //bolden line items
                    return <th class={"lineItem"}>{Math.round(parseInt(props.data[newKey]) / 1000000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</th>

                }
                else {
                    return <td >{Math.round(parseInt(props.data[newKey]) / 1000000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                }
            }
        })
    }
}
