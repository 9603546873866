//screenshot capture ratio 1651 x 961

// collapsable: description and industry from a free api?
// logo, marketcap , phone number etc on finnhub api
// current market cap, currentassets-liabilities, EV,  (probably best coming from finhub)

// revenue/rev growth, net income/NI growth, FCF/fcf growth
// quick valuation ratios in a little box like quickfs.net (PE,PB,PS,P/FCF,EV/Ebitda,EV/FCF), median roic, median roe, 10 yr FCF CAGR, 10 yr EPS CAGR, 10yr REVENUE CAGR
// historical PE,PB,PS,PFCF in chart (median line in chart)
// Radar chart over time : roic,roe,roa, 1/DtoE, cagr return,
// margins over time
// shares outstanding (basic), EPS (basic), FCFpS, Dividend per share [basicaly dps/price-->mcap/sharesoutstanding = yield (historic divident yield*)]
//


//inventory turnover etc
//sales to capital (ashwat damodaran)

import { Route } from "react-router-dom/cjs/react-router-dom.min";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

import Financials from "./Financials";

import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';

import Tickers from './tickers.json'

import './App.css'


import React from "react";


import Helmet from 'react-helmet';

import alerts from './styles/alerts.png'
import statements from './styles/statements.png'
import calculator from './styles/calculator.png'
import tiktok from './styles/tiktok.png'

import superinvestorsPreview from './styles/superinvestorsPreview.png'
import superinvestorHoldings from './styles/superinvestorHoldings.png'

import dashboard from './styles/valuationDashboard.png'

import './styles/LaunchPageStyles.css'

import city from './styles/city.jpeg'

import Particles from "react-tsparticles";

import api from './styles/api.png'

import { Link, Switch } from 'react-router-dom';

import { useInView } from "react-intersection-observer";
import { useState, useEffect, useContext, useRef } from "react";

let Home = () => {

    const [redirect, setRedirect] = useState(false)
    const [ticker, setTicker] = useState('')
    const [loadingStatus, setLoadingStatus] = useState(false)

    //  let particlesLoaded = this.particlesLoaded.bind(this);

    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 0.4,
    });

    let particlesInit = (main) => {
        console.log(main);

        // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
    }
    let particlesLoaded = (container) => {
        console.log(container);
    }

    let handleRetriveFinancials = async (bool) => {

        setRedirect("/financials/" + ticker)
        setLoadingStatus(true)

        var url = "https://obscure-meadow-68622.herokuapp.com/getFinancials/" + ticker;

        var response = await fetch(url);
        var fetchedResponse = await response.json();
    }



    let handleAutocompleteInputChange = (e, v) => {
        setTicker(v)

    }

    let _handleKeyDown = (e) => {
        setTicker(e.value)

        if (e.key === 'Enter') {
            setRedirect("/financials/" + ticker)
        }
    }


    let handleAutoCompleteChange = async (event, value) => {


        try {
            setTicker(value.value)
            setRedirect("/financials/" + ticker)
            setLoadingStatus(true)

            var url = "https://obscure-meadow-68622.herokuapp.com/getFinancials/" + ticker;

            var response = await fetch(url);
            var fetchedResponse = await response.json();


        }
        catch {

        }
    }


    if (redirect) {
        return (
            <Redirect push to={redirect} />
        )
    }


    var value = ""
    var label = ""

    var valueLabel = {}

    var options = []

    let k;
    for (k in Tickers) {


        value = Tickers[k].ticker
        label = Tickers[k].ticker + " : " + Tickers[k].title

        valueLabel["value"] = value
        valueLabel["label"] = label
        options.push({ "value": value, "label": label })

        // options.push(valueLabel)
    }

    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        limit: 5,
    });

    return (

        <div>
            {/* <Helmet>
                <title>Stocks, DCF Calculator, 10 years financial data | Value Nation</title>
                <meta name="description" content="Get free access to 10+ years of financial data, make stock valuations using discounted cash flows (DCF) model, and more!"></meta>
            </Helmet> */}


            <div class="particlesContainer">
                <Particles
                    style={{ "z-index": 1 }}
                    id="tsparticles"
                    init={particlesInit}
                    loaded={particlesLoaded}
                    options={{
                        background: {
                            color: {
                                value: "transparent",
                            },
                        },
                        fpsLimit: 60,
                        interactivity: {
                            detectsOn: "canvas",
                            events: {


                                resize: true
                            },
                            modes: {
                                bubble: {
                                    distance: 400,
                                    duration: 2,
                                    opacity: 0.2,
                                    size: 30,
                                },
                                push: {
                                    quantity: 4,
                                },
                                repulse: {
                                    distance: 200,
                                    duration: 0.4,
                                },
                            },
                        },
                        particles: {
                            color: {
                                value: "rgba(255, 228, 255, 0)",
                                opacity: 0.05
                            },
                            links: {
                                color: "#ffffff",
                                distance: 150,
                                enable: true,
                                opacity: 0.04,
                                width: 1,
                            },
                            collisions: {
                                enable: false,
                            },
                            move: {
                                direction: "none",
                                enable: true,
                                outMode: "bounce",
                                random: false,
                                speed: 2,
                                straight: false,
                            },
                            number: {
                                density: {
                                    enable: true,
                                    value_area: 800,
                                },
                                value: 35,
                            },
                            opacity: {
                                value: 0.1,
                            },
                            shape: {
                                type: "circle",
                            },
                            size: {
                                random: true,
                                value: 8,
                            },
                        },
                        detectRetina: true,
                    }}
                />
            </div>

            <h1 className="header" style={{ "z-index": 9 }}> Manage your stock picks with <h1 class="value" style={{ "z-index": 9 }}>V</h1>alue<h1 class="nation" style={{ "z-index": 9 }}>N</h1>ation</h1>
            <h3 style={{ "z-index": 9 }}> A powerful tool where value investors can manage their valuations and track portfolios of superinvestors with proven track records</h3>

            <div className="row">
                <div className="col">
                    <div>
                        <img class="superinvestorsPreview-img" src={superinvestorsPreview}></img>

                    </div>
                </div>

            </div>


            <div className="row">
                <div className="col">
                    <div>
                        <img class="superinvestorHoldings-img" src={superinvestorHoldings}></img>

                    </div>
                </div>

            </div>

            <div class="row" style={{ "z-index": 9 }}>

                <div class="col-lg-4" style={{ "z-index": 9 }}>
                    <div class="statements-container" style={{ "z-index": 9 }}>
                        <img class="statements-img" src={statements}></img>

                        <h2 class="offering-description" style={{ "z-index": 9 }}>


                            Free 10+ years of financial data
                            <br />
                            <br />
                            <h3 class="offering-financials" style={{ "z-index": 9 }}>
                                - Statement of Cash Flows
                            </h3>
                            <h3 class="offering-financials" style={{ "z-index": 9 }}>
                                - Balance Sheet
                            </h3>
                            <h3 class="offering-financials" style={{ "z-index": 1 }}>
                                - Income Statement
                            </h3>

                            <h3 class="offering-financials" style={{ "z-index": 1 }}>

                                - Key Ratios
                            </h3>

                        </h2>
                    </div>
                </div>

                <div class="col-lg-4">

                    <div class="calculator-container">
                        <img class="calculator-img" src={calculator}></img>

                        <h2 class="offering-description">
                            DCF calculator

                            <br />
                            <br />
                            <h3 class="offering-financials">
                                - Terminal Multiple
                            </h3>

                            <h3 class="offering-financials">
                                - Perpituity (coming soon)
                            </h3>

                        </h2>

                    </div>
                </div>


                <div class="col-lg-4">
                    <div className={inView ? "alerts-container--zoom" : "alerts-container"} ref={ref}>

                        <div className={inView ? "alerts-container--zoom" : "alerts-container"} ref={ref}>
                            <img class="alerts-img" src={alerts}></img>

                            <h2 class="offering-description">
                                Manage Valuations

                                <br />
                                <br />

                                <h3 class="offering-financials">
                                    - Get Alerts
                                </h3>

                                <h3 class="offering-financials">
                                    - Save
                                </h3>
                                <h3 class="offering-financials">
                                    - Share
                                </h3>
                            </h2>

                        </div>
                    </div>
                </div>


            </div>

            <div class="row" style={{ "z-index": 9 }}>

                <div class="col" style={{ "z-index": 9 }}>
                    <h3 class="start-h3" style={{ "z-index": 9, fontWeight: 'bolder', color: 'rgb(201, 198, 25)', marginBottom: '10px' }}>
                        Start making calculated investments. Search for a company:
                    </h3>
                </div>
            </div>
            <div class="row" style={{ "z-index": 9 }}>

                <div class="col">
                    <div
                        style={loadingStatus ? {
                            filter: 'blur(2px)'

                        } : undefined}
                        className="financialsDiv"
                    >

                        <div className="ticker-entry" >

                            <Autocomplete
                                onKeyDown={() => _handleKeyDown}

                                onInputChange={e => handleAutocompleteInputChange(e, e.target.value.toString().toUpperCase())}

                                freeSolo
                                options={options}
                                getOptionLabel={(option) => option.label}
                                style={{
                                    width: '97%', backgroundColor: '#c7daff57', zIndex: 9, position: 'relative'

                                }}

                                renderInput={(params) => <TextField {...params} label="Ticker : " variant="outlined" size="small" />}
                                filterOptions={filterOptions}


                                onChange={(event, value) =>

                                    handleAutoCompleteChange(event, value)
                                }

                            />
                        </div>



                    </div>
                </div>


            </div>


            <div>

            </div>

            {/* <br />
                <div class="row">
                    <img style={{ width: '50%', zIndex: '10000' }} src={dashboard}></img>

                </div> */}
            <br />

            <div class="row" style={{ "z-index": 3 }}>
                <h1 class="fintech-header">
                    Fintech Services
                </h1>



            </div>

            <div class="row" style={{ "z-index": 3 }}>


                <div class="cloudDiv">
                    <img class="api-img" src={api}></img>

                    <h3 class="services-offering" >
                        Save thousands in data costs with our API endpoint
                    </h3>
                </div>
            </div>

            <br />

            <div class="row" style={{ "z-index": 3 }}>
                <Link to="/services" class="services-button" style={{ "z-index": 3 }}> Services
                </Link>

            </div>

            <br />


            <div class="row" style={{ "z-index": 3 }}>

                <div class="backdropDiv">

                    <div class="transition-div">
                    </div>

                    <img class="city-image" src={city}></img>


                    <div class="row" style={{ "z-index": 3 }}>
                        <h1 class="explore-header"> Explore Value Nation
                        </h1>
                    </div>

                    <div class="row" style={{ "z-index": 3 }}>


                        <div class="col-4" id='mobileTT'>
                            {/* <Link to="/calculators" class="explore-services-button" style={{ "z-index": 9 }}> Calculators
                                </Link> */}
                            <a href={'https://www.tiktok.com/@valuenation.app'} target="_blank" >
                                <img class="tiktok-image" src={tiktok}></img>
                                <div class='followers'>6.0k</div>

                            </a>
                        </div>

                    </div>


                    <div class="row" id="navButtons">



                        <div class="col-4">
                            <Link to="/research" class="explore-research-button" style={{ "z-index": 9 }}> Research
                            </Link>
                        </div>

                        <div class="col-4">
                            <Link to="/services" class="explore-services-button" style={{ "z-index": 9 }}> Services
                            </Link>
                        </div>

                        <div class="col-4">

                            <Link to="/blog" class="explore-blog-button" style={{ "z-index": 9 }}> Discussions
                            </Link>
                        </div>
                        {/*  */}

                    </div>
                    <div class="row" id="dcfButtons">



                        <div class="col-4">
                            <Link to="/calculators" class="explore-services-button" style={{ "z-index": 9 }}> Calculators
                            </Link>
                        </div>



                    </div>

                    <div class="row" id="misc">

                        contact, terms of service, etc
                    </div>

                </div>

            </div>





        </div>
    )

}
export default Home




