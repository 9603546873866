import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import MyLeaderBoardAd from './MyLeaderBoardAd';
import Alert from '@mui/material/Alert';
import { useContext, useRef } from "react";
import { DCFContext } from './contexts/DCFContext'
import { RouteContext } from './contexts/RouteContext'

import lock from './styles/lock.png'
import InputGroup from 'react-bootstrap/InputGroup'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import FormControl from 'react-bootstrap/FormControl'
import PersonIcon from '@mui/icons-material/Person';
import GoogleLogin from 'react-google-login'
import jwt_decode from 'jwt-decode'
import { } from 'dotenv/config'


const Login = () => {

    const [email, setEmail] = useState('')

    const [password, setPassword] = useState('')


    const history = useHistory();
    const { showSuccessAlert, editSuccessAlert, showErrorAlert, editErrorAlert, editLoginSuccessAlert } = useContext(DCFContext)
    const { subscriptionStatus,updateSubscriptionStatus,subscriptionID, updateSubscriptionID } = useContext(RouteContext)


    let googleSuccess = async (res) => {
        console.log('res check', res)

    }
    let googleFailure = (error) => {
        console.log('Google Signin was unsucessful, try again later')
        console.log('Google Signin error', error)

    }

    const handleGet = async () => {
        try {

            const jwtToken = localStorage.getItem('jwtToken')
            const res = await fetch('https://obscure-meadow-68622.herokuapp.com/info', {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    "Content-Type": 'application/json',
                    'Authorization': 'Bearer ' + jwtToken

                },

            });

            const data = await res.json();

             updateSubscriptionID(data.subscriptionInfo[data.subscriptionInfo.length-1].subscriptionID)

            console.log('data from info check ', data)

            localStorage.setItem('userData', JSON.stringify(data));

            // routeContext set data
            updateSubscriptionStatus('test login')

            if (!res.status === 200) {
                const error = new Error(res.error)
                throw error;
            }

        } catch (error) {
            console.log(error)
            history.push('/')
        }
    }


    const handleLogin = async (e) => {

        e.preventDefault();

        // const { firstName, lastName, email, password, cpassword } = user;

        // DEPLOY : const res = await fetch("https://obscure-meadow-68622.herokuapp.com/register", {
        const res = await fetch("https://obscure-meadow-68622.herokuapp.com/login", {

            // const res = await fetch("http://localhost:5000/register", {

            // const res = await fetch("/subscribe", {
            method: "POST",
            credentials: 'include', // Don't forget to specify this if you need cookies
            // credentials: "same-origin",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({


                email: email,
                password: password,


            })
        });
        const data = await res.json();

        localStorage.setItem('jwtToken', data.token)

        console.log('data.status check: ' + data.status);

        console.log('token check : ', data.token)

        if (data.token === undefined || !data) {

            editErrorAlert(true);
            setTimeout(() => {
                editErrorAlert(false);

            }, 1500);
        }
        else {
            editLoginSuccessAlert(true);
            setTimeout(() => {
                editLoginSuccessAlert(false);

            }, 1500);
            console.log('Login successful')
            history.push('/financials/search')

            handleGet()

        }
    }

    async function handleCallbackResponse(response) {
        console.log('Encoded JWT ID Token: ', response.credential)
        localStorage.setItem('jwtToken', response.credential)

        var userObject = jwt_decode(response.credential)
        console.log('userObject', userObject)
        let firstName = ''
        let lastName = ''
        try {
            firstName = userObject.name.split(' ')[0]
            lastName = userObject.name.split(' ')[1]
        }
        catch {

        }
        let email = userObject.email
        let sub = userObject.sub

        // post / googlelogin

        const res = await fetch("https://obscure-meadow-68622.herokuapp.com/googlelogin", {
            method: "POST",
            headers: {
                "Content-Type": "application/json" //application/json
            },
            body: JSON.stringify({

                firstName: firstName,
                lastName: lastName,
                email: email,
            })
        });
        const data = await res.json();
        console.log('data login res', data)
        handleGet()
        history.push('/research')

    }

    useEffect(() => {
        /* global google */
        google.accounts.id.initialize({
            client_id: process.env.REACT_APP_GOOGLE_CLIENTID,
            callback: handleCallbackResponse
        });
        google.accounts.id.renderButton(
            document.getElementById("signInDiv"),
            { theme: 'outline', size: 'large' }
        );
    }, [])

    return (


        <div class="row" id="emailFormRow">


            <MyLeaderBoardAd style={{ zIndex: 99 }} />

            <img class="lock-img" src={lock}></img>

            <div className='row'>
                <div className='col'>

                    <div style={{ margin: '0 auto', display: 'table', marginLeft: 'calc(50% - 75px)', marginTop: '15px' }}>

                        <div id='signInDiv'></div>

                    </div>
                </div>
            </div>

            <div style={{ color: 'white', marginTop: '15px' }}>
                -- OR --
            </div>


            <div class='login-form' >

                <InputGroup style={{ marginTop: "20px" }}>
                    <InputGroup.Text style={{ backgroundColor: '#92ddff21', color: 'white' }}>
                        <PersonIcon class='pencilIcon' style={{ fill: 'turquoise' }} />

                    </InputGroup.Text>

                    <FormControl

                        placeholder='email'
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        style={{ backgroundColor: '#92ddff21', color: 'white', borderColor: 'white', overflow: 'scroll' }}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type='email'
                    />

                    {/* <input type="email" class="credentials-input" required value={email} onChange={(e) => setEmail(e.target.value)}></input> */}
                    {/* <input class="credentials-input" type='password' required value={password} onChange={(e) => { setPassword(e.target.value); console.log('password check !!!!', password) }}></input> */}


                </InputGroup>
            </div>


            <div class='login-form'>

                <InputGroup style={{ marginTop: "20px" }}>
                    <InputGroup.Text style={{ backgroundColor: '#92ddff21', color: 'white' }}>
                        <LockOpenIcon class='pencilIcon' style={{ fill: "gold" }} />

                    </InputGroup.Text>

                    <FormControl

                        placeholder='password'
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        style={{ backgroundColor: '#92ddff21', color: 'white', borderColor: 'white', overflow: 'scroll' }}
                        value={password}
                        onChange={(e) => { setPassword(e.target.value) }}
                        type='password'
                    />

                    {/* <input type="email" class="credentials-input" required value={email} onChange={(e) => setEmail(e.target.value)}></input> */}
                    {/* <input class="credentials-input" type='password' required value={password} onChange={(e) => { setPassword(e.target.value); console.log('password check !!!!', password) }}></input> */}


                </InputGroup>
            </div>


            <button class="subscribe-button" onClick={handleLogin} style={{ marginLeft: 'calc(50% - 100px)' }}> Login </button>



            <div style={{ color: 'white' }} >
                Don't have an account?
                <button style={{ color: 'yellow' }} onClick={() => history.push('/signup')} > Sign up   </button >
            </div>
            {/*  */}

            {showSuccessAlert ? <Alert variant="filled" severity="success" style={{ position: 'fixed', top: '0px', marginLeft: '', zIndex: '1000' }}>
                Logged out successfully!
            </Alert> : <div />}


            {showErrorAlert ? <Alert variant="filled" severity="error" style={{ position: 'fixed', top: '0px', marginLeft: '', zIndex: '1000' }}>Invalid credentials!</Alert>
                : <div />}


        </div>
    )
}
export default Login








