import React from 'react'
export default class TVInfo extends React.PureComponent {
    constructor(props) {
        super(props);
        this._ref = React.createRef();
    }
    componentDidMount() {
        const script = document.createElement('script');
        script.src = "https://s3.tradingview.com/external-embedding/embed-widget-symbol-info.js"
        script.async = true;
        script.innerHTML = JSON.stringify({
            "symbol": "NASDAQ:" + this.props.ticker,
            "width": 500,
            "locale": "en",
            "colorTheme": "dark",
            "isTransparent": true
        })/* JSON-ENCODED SETTINGS STRING FROM EMBED CODE */
        this._ref.current.appendChild(script);

        console.log('this._ref check : ', this._ref)
        console.log(document.querySelector("#symbol-info-widget > div > div > div.tv-category-header__main-price.js-scroll-container > div > div > div > div.tv-symbol-price-quote__row.js-last-price-block-value-row > div.tv-symbol-price-quote__value.js-symbol-last > span"))
    }
    render() {


        return (
            <div class="tradingview-widget-container" ref={this._ref}>
                <div class="tradingview-widget-container__widget"></div>
                <div class="tradingview-widget-copyright"><a href={"https://www.tradingview.com/symbols/NASDAQ-" + this.props.ticker + '/'} rel="noopener" target="_blank"><span class="blue-text">{this.props.ticker} Price Today</span></a> by TradingView</div>

            </div>
        );
    }

}
/*
<!-- TradingView Widget BEGIN -->
<div class="tradingview-widget-container">
  <div class="tradingview-widget-container__widget"></div>
  <div class="tradingview-widget-copyright"><a href="https://www.tradingview.com/symbols/NASDAQ-AAPL/" rel="noopener" target="_blank"><span class="blue-text">AAPL Price Today</span></a> by TradingView</div>
  <script type="text/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-symbol-info.js" async>
  {
  "symbol": "NASDAQ:AAPL",
  "width": 1000,
  "locale": "en",
  "colorTheme": "dark",
  "isTransparent": false
}
  </script>
</div>
<!-- TradingView Widget END -->
*/



//    //        var url = "https://finnhub.io/api/v1/stock/financials-reported?symbol=aapl&token=c3r0s8aad3i98m4ienk0"
