import React from "react";

import Financials from "./Financials";

import { Radar, Line, Bar } from "react-chartjs-2"

import TradingViewWidget from 'react-tradingview-widget';

import TVInfo from './TVInfo'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import './styles/ratiosGraph.css'
import './styles/dcfStyles.css'

//OFFICIAL metric names 
//CF : Free Cash Flow	, Free Cash Flow Growth, Free Cash Flow Per Share
//IS : Revenue, Net Income, Revenue Growth, Shares Outstanding (Basic), Gross Margin, Operating Margin, Profit Margin, FCF Margin, EPS Growth, EPS (Diluted), Dividend Per Share
//R  : Market Capitalization (this Year), PE Ratio, PS Ratio, PB Ratio, P/FCF Ratio, EV/EBITDA Ratio, EV/FCF Ratio, Return on Capital (ROIC), Return on Equity (ROE), Return on Assets (ROA), Debt / Equity Ratio [inverse for radar], Market Capitalization (cagr for radar)
//BS : Total Current Assets	- Total Liabilities

// Radar chart over time : roic,roe,roa, 1/DtoE, cagr return, margin growth over time, management, employee sentiment
export default class CAGRCalculator extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            r: this.props.r,
            isChecked: false,
            initial: '',
            monthly: '',
            period: '',
            interest: '',
            frequency: 1,
            cagrInitial: '',
            cagrFinal: '',
            cagrLength: '',
            cagrCalculation: 0,
            ciArr: [],
            contributionsArr: []

        };
    }

    toggleCheckboxChange = () => {
        const { handleCheckboxChange, label } = this.props;

        this.setState(({ isChecked }) => (
            {
                isChecked: !isChecked,
            }
        ));

        console.log('check check', this.state.isChecked)

    }

    handleInterestRateChanged(event) {

        this.setState({ interest: event.target.value }, () => this.ciCalculation());
    }
    handleInitialChanged(event) {

        this.setState({ initial: event.target.value }, () => this.ciCalculation());
    }
    handlePeriodChanged(event) {

        this.setState({ period: event.target.value }, () => this.ciCalculation());
    }
    handleFrequencyChanged(event) {

        this.setState({ frequency: event.target.value }, () => this.ciCalculation());
    }
    handleMonthlyChanged(event) {

        this.setState({ monthly: event.target.value }, () => this.ciCalculation());

    }

    handleCagrLengthChanged(event) {


        this.setState({ cagrLength: event.target.value }, () =>
            this.cagrCalculation()

        );
    }
    handleCagrFinalChanged(event) {


        this.setState({ cagrFinal: event.target.value }, () => {
            this.cagrCalculation();
            console.log('final check : ', this.state.cagrFinal);
        }

        );
    }
    handleCagrInitialChanged(event) {

        this.setState({ cagrInitial: event.target.value }, () => {
            this.cagrCalculation();
            console.log('inital check : ', this.state.cagrInitial);
        }
        );
    }

    cagrCalculation = () => {

        let calculation = (Math.round((((parseFloat(this.state.cagrFinal) / parseFloat(this.state.cagrInitial)) ** (1.0 / parseFloat(this.state.cagrLength)) - 1.0) * 100.0) * 100.0) / 100.0).toFixed(2);
        // console.log('calculation', calculation)
        // console.log('numerator', num)

        // let calculation = (((parseFloat(this.state.cagrFinal) / parseFloat(this.state.cagrInitial)) ** (1.0 / parseFloat(this.state.cagrLength)) - 1.0) * 100.0)


        this.setState({ cagrCalculation: calculation })
    }

    ciCalculation = () => {

        let ciCalculationArr = []

        let contributionCalculationArr = [];
        //Total = [ P(1+r/n)^(nt) ] + [ PMT × (((1+r/n)^(nt) - 1) / (r/n)) ]

        for (let i = 0; i < parseInt(this.state.period) + 1; i++) {

            // let principal = ((1 + (this.state.interest / this.state.frequency)) ** (this.state.frequency * i)) * this.state.initial;
            let principal = ((1 + ((this.state.interest / 100) / this.state.frequency)) ** (this.state.frequency * i)) * this.state.initial;

            let future = ((((1 + ((this.state.interest / 100) / this.state.frequency)) ** (this.state.frequency * i)) - 1) / ((this.state.interest / 100) / this.state.frequency)) * (this.state.monthly * 12);

            let entry = principal + future;

            console.log('principal', principal)
            console.log('future', future)

            ciCalculationArr.push(entry)

            let contributions = parseInt(this.state.initial) + (parseInt(i) * parseInt(this.state.monthly) * 12)
            contributionCalculationArr.push(contributions)
        }

        this.setState({ ciArr: ciCalculationArr }, () => {
            console.log('ciArr Check : ', this.state.ciArr)
        })

        this.setState({ contributionsArr: contributionCalculationArr }, () => {
            console.log('ciArr Check : ', this.state.ciArr)
        })

        // set ciArr

        // let calculation = (Math.round((((parseInt(this.state.cagrFinal) / parseInt(this.state.cagrInitial)) ** (1 / parseInt(this.state.cagrLength)) - 1) * 100) * 100) / 100).toFixed(2);
        // this.setState({ ciCalculation: calculation })
    }

    renderGraphs(canvas) {

        try {

            var returnArr = []

            const ctx = canvas.getContext('2d')

            var gradient = ctx.createLinearGradient(0, 0, 0, 300);
            var currentYear = 2020;

            gradient.addColorStop(0, "rgba(0, 255, 0, 1)");
            gradient.addColorStop(1, "rgba(0, 100, 0, 0.05)");

            var ratios = {

                labels: [],
                datasets: [
                    {
                        borderWidth: 3,// and not lineWidth,
                        backgroundColor: gradient,

                        label: 'P/E',
                        data: [],
                        fill: true,
                        //light green under the line: rgba(95, 220, 157, 1)
                    },
                ]
            }

            var labelsArr = []

            var PEArr = []

            var PEIndex = 0;


            for (var j = currentYear; j <= 2030; j++) {

                labelsArr.push(j)
                PEArr.push(3)

            }

            ratios.labels = labelsArr
            ratios.datasets.label = "Ratios over the Year"


            ratios.datasets[0].data = PEArr //or without 0?


            returnArr.push(<Line data={ratios} options=
                {{

                    responsive: true,
                    plugins: {
                        tooltip: {
                            mode: 'index',
                            intersect: false
                        }
                    },
                    interaction: {
                        mode: 'x'
                    }

                    ,
                    tooltips: {
                        mode: 'nearest'
                    },

                    aspectRatio: 4,
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            grid: {
                                color: 'rgba(0, 0, 0, 0.5)',
                                borderColor: 'rgba(0, 0, 0, 0.77)',
                                tickColor: 'rgba(0, 0, 0, 0.77)'
                            },
                            pointLabels: {
                                color: 'yellow'
                            }, ticks: {
                                color: 'rgba(237, 241, 39, 1)',
                                font: {
                                    size: 15,
                                }
                            },
                        },
                        y: {
                            grid: {
                                color: 'rgba(0, 0, 0, 0.5)',
                                borderColor: 'rgba(0, 0, 0, 0.77)',
                                tickColor: 'rgba(0, 0, 0, 0.77)'
                            },
                            pointLabels: {
                                color: 'yellow'
                            },
                            ticks: {
                                color: 'rgba(237, 241, 39, 1)',
                                font: {
                                    size: 15,
                                }
                            },
                            beginAtZero: true
                        }
                    },
                    tension: 0.3

                }}>  </Line >)

            return returnArr
        }
        catch {

        }
    }


    render() {

        const { isChecked } = this.state;

        const options = {
            responsive: true,
            plugins: {
                legend: {
                    position: 'top',
                },
                title: {
                    display: true,
                    text: 'Compound Interest Calculator',
                },
            },
        };

        // const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

        const labels = []
        for (let i = 0; i < this.state.ciArr.length; i++) {
            labels.push(i)
        }

        // const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];


        const data = {
            labels,
            datasets: [
                {
                    label: 'Accumulated Amount',
                    data: labels.map((year) => this.state.ciArr[year]),
                    borderColor: '#00ceff',
                    backgroundColor: 'white',
                },
                {
                    label: 'Contributions',
                    data: labels.map((year) => this.state.contributionsArr[year]),
                    borderColor: '#00ceff3b',
                    backgroundColor: 'rgba(255, 255, 255, 0.3)',
                },
            ],
        };



        return (
            <div class="calculators-container">


                {/* <div class="switch-holder"> */}
                {/* <div class="switch-label">
                <span>Bluetooth</span>
            </div> */}
                <div class="switch-toggle">
                    <input type="checkbox" id="bluetooth" checked={isChecked} onChange={this.toggleCheckboxChange} />
                    <label for="bluetooth"></label>
                </div>
                {/* </div> */}

                {this.state.isChecked ?

                    ///
                    ///
                    ///
                    //
                    //
                    <div class="cagr-calculator" >

                        <div class="row">
                            <div class="col-lg-3" style={{ paddingLeft: '30px' }}>

                                <div class="input-container ic1">
                                    <input id="initalFcf" class="input"
                                        type="number"
                                        placeholder=" "
                                        value={this.state.cagrInitial}
                                        onChange={this.handleCagrInitialChanged.bind(this)}
                                    />
                                    <div class="cut"></div>
                                    <label for="initalFcf" class="placeholder">Initial Amount</label>

                                </div>
                                <div class="input-container ic2">
                                    <input id="oneFiveYearGrowth" class="input" placeholder=" "
                                        type="number"
                                        value={this.state.cagrFinal}
                                        onChange={this.handleCagrFinalChanged.bind(this)}
                                    />
                                    <div class="cut"></div>
                                    <label for="oneFiveYearGrowth" class="placeholder">Final Amount</label>
                                </div>
                                <div class="input-container ic2">
                                    <input id="sixTenYearGrowth" class="input" placeholder=" "
                                        type="number"
                                        value={this.state.cagrLength}
                                        onChange={this.handleCagrLengthChanged.bind(this)}
                                    />
                                    <div class="cut cut-short"></div>
                                    <label for="sixTenYearGrowth" class="placeholder">Length of Time in Years</label>
                                </div>
                            </div>

                            {/* <div class="col-lg-9" > */}
                            <div class="col-lg-9 text-center justify-content-center align-self-center">

                                <div class="row justify-content-center" >
                                    {/* <div class="col-4">
                                        One centered column
                                    </div>
                                    <div class="col-4">
                                        two centered column
                                    </div> */}

                                    <div class='col-4' >

                                        <div class='return-label'>Rate of Return:</div>
                                    </div>
                                    <div class='col-4'>

                                        <div class='cagr-output'>{this.state.cagrCalculation}%</div>
                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>


                    :

                    <div class="ci-calculator" >

                        <div class="row">
                            <div class="col-lg-3" style={{ paddingLeft: '30px' }}>

                                <div class="ci-inputs">
                                    <div class="input-container ic1" style={{ marginTop: '20px' }}>
                                        <input id="initalFcf" class="input"
                                            type="number"
                                            placeholder=" "
                                            value={this.state.inital}
                                            onChange={this.handleInitialChanged.bind(this)}
                                        />
                                        <div class="cut"></div>
                                        <label for="initalFcf" class="placeholder">Initial Investment</label>

                                    </div>
                                    <div class="input-container ic2" style={{ marginTop: '20px' }}>
                                        <input id="oneFiveYearGrowth" class="input" placeholder=" "
                                            type="number"
                                            value={this.state.monthly}
                                            onChange={this.handleMonthlyChanged.bind(this)}
                                        />
                                        <div class="cut"></div>
                                        <label for="oneFiveYearGrowth" class="placeholder">Monthly Contribution</label>
                                    </div>
                                    <div class="input-container ic2" style={{ marginTop: '20px' }}>
                                        <input id="sixTenYearGrowth" class="input" placeholder=" "
                                            type="number"
                                            value={this.state.period}
                                            onChange={this.handlePeriodChanged.bind(this)}
                                        />
                                        <div class="cut cut-short"></div>
                                        <label for="sixTenYearGrowth" class="placeholder">Length of Time in Years</label>
                                    </div>
                                    <div class="input-container ic2" style={{ marginTop: '20px' }}>
                                        <input id="discountRate" class="input" placeholder=" "
                                            type="number"
                                            value={this.state.interest}
                                            onChange={this.handleInterestRateChanged.bind(this)}
                                        />
                                        <div class="cut cut-short"></div>
                                        <label for="discountRate" class="placeholder">Estimated Interest Rate</label>
                                    </div>
                                    {/* <div class="input-container ic2">
                                        <input id="terminalMultiple" class="input" placeholder=" "
                                            type="number"
                                            value={this.state.frequency}
                                            onChange={this.handleFrequencyChanged.bind(this)}
                                        />
                                        <div class="cut cut-short"></div>
                                        <label for="terminalMultiple" class="placeholder">Compound Frequency</label>
                                    </div> */}

                                    <div class='final-amount'>
                                        Final Amount : {parseFloat(this.state.ciArr[this.state.ciArr.length - 1]).toFixed(2)}
                                    </div>

                                </div>
                            </div>

                            <div class="col-lg-9">


                                {this.state.isChecked ? <div>cagr</div> : <div>
                                    <div class="cagr-graph-container" >
                                        <Line options={options} data={data} />


                                    </div>  </div>}


                            </div>

                        </div>
                    </div>}


                {/* <TradingViewWidget symbol="NASDAQ:AAPL" /> */}
                {/*  */}

                {/* <TVInfo></TVInfo> */}
                {/* <TVChart></TVChart>
                <TVProfile></TVProfile> */}

            </div>



        )

    }
}
