
import React, { useEffect, useState, useRef } from 'react'

import Screener from './Screener'
import { useHistory, useLocation } from 'react-router-dom';



import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { Autocomplete } from '@material-ui/lab';
import Tickers from './tickers.json'

import { TextField } from '@material-ui/core';




const Research = () => {

    const screenerRef = useRef()

    var value = ""
    var label = ""

    var valueLabel = {}

    var options = []

    let k;
    for (k in Tickers) {

        /*        k : key
         *   obj[k] : value
         */
        value = Tickers[k].ticker
        label = Tickers[k].ticker + " : " + Tickers[k].title

        valueLabel["value"] = value
        valueLabel["label"] = label
        options.push({ "value": value, "label": label })

        // options.push(valueLabel)
    }

    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        limit: 5,
    });


    const _handleKeyDown = (e, v) => {
        if (e.key === 'Enter') {
            history.push('/financials/' + ticker)
        }
    }

    const handleAutocompleteInputChange = (e, v) => {
        setTicker(v)
    }

    const handleAutoCompleteChange = (event, value) => {


        try {

            console.log('value.value check ', value.value)

            setTicker(value.value)

            history.push('/financials/' + value.value)

        }
        catch {

        }
        //this.handleRetriveFinancials(true)
    }

    const history = useHistory();


    const [ticker, setTicker] = useState('')

    function checkScreenerRef() {
        console.log('checking ref sccreenerRef.current', screenerRef.current)

    }

    return (
        <div>


            <h1 class="baseHeader">
                Search for a company by entering their ticker below.
            </h1>


            <div class="outer">
                <div class="circle"></div>


                <div class="row">
                    <div class="col">

                        <div class="baseAutocompleteContainer" style={{ marginTop: '45%' }}>

                            <Autocomplete

                                onKeyDown={(event, value) =>
                                    _handleKeyDown(event, value)
                                }
                                onInputChange={e => handleAutocompleteInputChange(e, e.target.value.toString().toUpperCase())}

                                freeSolo
                                options={options}
                                getOptionLabel={(option) => option.label}
                                style={{
                                    width: "50%", backgroundColor: '#c7daff57', zIndex: 9
                                }}

                                renderInput={(params) => <TextField {...params} label="Ticker : " variant="outlined" size="small" />}
                                filterOptions={filterOptions}

                                onChange={(event, value) =>
                                    handleAutoCompleteChange(event, value)
                                }


                            />

                        </div>

                    </div>
                </div>

            </div>


            <div class='screener-info'   >

                <h1 style={{ fontWeight: 'bold', marginBottom: '10px' }}>
                    Screener
                </h1>

                <div class='screener-card'>
                    <Screener ref={screenerRef} />

                </div>

                <div>
                    Screener powered by <a style={{ color: 'blue', display: 'inline' }} href='https://www.tradingview.com/'> TradingView </a>
                </div>
            </div>



        </div>

    )
}
export default Research