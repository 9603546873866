import 'bootstrap/dist/css/bootstrap.min.css';
import 'perfect-scrollbar/css/perfect-scrollbar.css'
import './fonts/font-awesome-4.7.0/css/font-awesome.min.css';
import 'animate.css'
import './styles/bootstrapEdit.css'

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom'

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import DCFContextProvider from './contexts/DCFContext';

import { hydrate, render } from "react-dom";


ReactDOM.render(
  <React.StrictMode>

    <Router>
      <BrowserRouter>
        <DCFContextProvider>

          <App />
        </DCFContextProvider>

      </BrowserRouter>

    </Router>

  </React.StrictMode>,
  document.getElementById('root')
);


// const rootElement = document.getElementById("root");

// if (rootElement.hasChildNodes()) {
//   ReactDOM.hydrate(<App />, rootElement);
// } else {
//   ReactDOM.render(<React.StrictMode>

//     <Router>
//       <BrowserRouter>

//         <App />
//       </BrowserRouter>

//     </Router>

//   </React.StrictMode>, rootElement);
// }
//