import React from 'react'
import { MiniChart } from "react-ts-tradingview-widgets";

import { SymbolOverview } from "react-ts-tradingview-widgets";

export default class TVChart extends React.PureComponent {
  constructor(props) {
    super(props);
    this._ref = React.createRef();
  }

  render() {


    return (

      // <MiniChart colorTheme="dark" dateRange='ALL' symbol={this.props.ticker}></MiniChart>

      <SymbolOverview colorTheme="dark"
        autosize
        downColor="#800080"
        borderDownColor="#800080"
        wickDownColor="#800080"
        symbols={[
          [this.props.ticker, this.props.ticker],

        ]}
        height='800'
        isTransparent='true'
      />

    );
  }

}


// export default class TVChart extends React.PureComponent {
//     constructor(props) {
//         super(props);
//         this._ref = React.createRef();
//     }
//     componentDidMount() {
//         const script = document.createElement('script');
//         script.src = "https://s3.tradingview.com/tv.js"
//         script.async = true;
//         script.innerHTML = new window.TradingView.widget({

//             "symbols":
//                 [
//                     "Apple",
//                     "AAPL"
//                 ],



//             "chartOnly": false,
//             "width": 1000,
//             "height": 400,
//             "locale": "en",
//             "colorTheme": "dark",
//             "gridLineColor": "rgba(240, 243, 250, 0)",
//             "fontColor": "#787B86",
//             "isTransparent": false,
//             "autosize": false,
//             "showFloatingTooltip": true,
//             "showVolume": false,
//             "scalePosition": "no",
//             "scaleMode": "Normal",
//             "fontFamily": "Trebuchet MS, sans-serif",
//             "noTimeScale": false,
//             "chartType": "area",
//             "lineColor": "#2962FF",
//             "bottomColor": "rgba(41, 98, 255, 0)",
//             "topColor": "rgba(41, 98, 255, 0.3)",
//             "container_id": "tradingview_cdeb2"
//         });

//         // script.innerHTML = JSON.stringify({
//         //     "symbols":
//         //         [
//         //             "Apple",
//         //             "AAPL"
//         //         ],
//         //     "width": 1000,
//         //     "height": 400,

//         //     "locale": "en",
//         //     "colorTheme": "dark",
//         //     "isTransparent": true,


//         //     "chartOnly": false,
//         //     "width": 1000,
//         //     "height": 400,
//         //     "locale": "en",
//         //     "colorTheme": "dark",
//         //     "gridLineColor": "rgba(240, 243, 250, 0)",
//         //     "fontColor": "#787B86",
//         //     "isTransparent": false,
//         //     "autosize": false,
//         //     "showFloatingTooltip": true,
//         //     "showVolume": false,
//         //     "scalePosition": "no",
//         //     "scaleMode": "Normal",
//         //     "fontFamily": "Trebuchet MS, sans-serif",
//         //     "noTimeScale": false,
//         //     "chartType": "area",
//         //     "lineColor": "#2962FF",
//         //     "bottomColor": "rgba(41, 98, 255, 0)",
//         //     "topColor": "rgba(41, 98, 255, 0.3)",
//         //     "container_id": "tradingview_cdeb2"

//         // })/* JSON-ENCODED SETTINGS STRING FROM EMBED CODE */
//         this._ref.current.appendChild(script);

//         console.log('this._ref check : ', this._ref)
//         console.log(document.querySelector("#symbol-info-widget > div > div > div.tv-category-header__main-price.js-scroll-container > div > div > div > div.tv-symbol-price-quote__row.js-last-price-block-value-row > div.tv-symbol-price-quote__value.js-symbol-last > span"))
//     }
//     render() {


//         return (
//             <div class="tradingview-widget-container" ref={this._ref}>
//                 <div class="tradingview-widget-container__widget"></div>
//                 <div class="tradingview-widget-copyright"><a href={"https://www.tradingview.com/symbols/NASDAQ-" + this.props.ticker + '/'} rel="noopener" target="_blank"><span class="blue-text">{this.props.ticker} Price Today</span></a> by TradingView</div>

//             </div>
//         );
//     }

// }

/*
<!-- TradingView Widget BEGIN -->
<div class="tradingview-widget-container">
  <div id="tradingview_cdeb2"></div>
  <div class="tradingview-widget-copyright"><a href="https://www.tradingview.com/symbols/AAPL/" rel="noopener" target="_blank"><span class="blue-text">Apple</span></a> by TradingView</div>
  <script type="text/javascript" src="https://s3.tradingview.com/tv.js"></script>
  <script type="text/javascript">
  new TradingView.MediumWidget(
  {
  "symbols": [
    [
      "Apple",
      "AAPL"
    ],
    [
      "Google",
      "GOOGL"
    ],
    [
      "Microsoft",
      "MSFT"
    ]
  ],
  "chartOnly": false,
  "width": 1000,
  "height": 400,
  "locale": "en",
  "colorTheme": "dark",
  "gridLineColor": "rgba(240, 243, 250, 0)",
  "fontColor": "#787B86",
  "isTransparent": false,
  "autosize": false,
  "showFloatingTooltip": true,
  "showVolume": false,
  "scalePosition": "no",
  "scaleMode": "Normal",
  "fontFamily": "Trebuchet MS, sans-serif",
  "noTimeScale": false,
  "chartType": "area",
  "lineColor": "#2962FF",
  "bottomColor": "rgba(41, 98, 255, 0)",
  "topColor": "rgba(41, 98, 255, 0.3)",
  "container_id": "tradingview_cdeb2"
}
  );
  </script>
</div>
<!-- TradingView Widget END -->
*/