import React, { createContext, useState } from "react";
import { v4 as uuidv4 } from 'uuid';

// step 1: export const context
export const RouteContext = createContext();

// step 2 : make context provider and export it
const RouteContextProvider = (props) => {


    const [userInfo, setUserInfo] = useState('')
    const [subscriptionStatus, setSubscriptionStatus] = useState('unsubscribed')
    const [subscriptionID, setSubscriptionID] = useState('')

    const updateSubscriptionStatus = (newStatus) => {
        setSubscriptionStatus(newStatus)
    }
    const updateSubscriptionID = (newID) => {
        setSubscriptionID(newID)
    }
    //////////// 
    const [currentRoute, setCurrentRoute] = useState('')
    const [cachedSavedValuations, setCachedSavedValuations] = useState({})

    const updateCachedSavedValuations = (tableEntries) => {
        setCachedSavedValuations(tableEntries)
    }
    ////////////



    return (
        // step 3 return context.provider , and values you'd like to share 
        <RouteContext.Provider value={{ currentRoute, cachedSavedValuations, updateCachedSavedValuations,subscriptionStatus,updateSubscriptionStatus,subscriptionID, updateSubscriptionID}}>
            {props.children}
        </RouteContext.Provider>

    )
}

export default RouteContextProvider
