import React, { useState, useEffect, useContext, useRef } from "react";
import Slider, { SliderThumb } from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import { DCFContext } from './contexts/DCFContext'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
// import Item from '@mui/material/Item';
import Switch from '@mui/material/Switch';
 
import { makeStyles } from "@material-ui/core";
import InputGroup from 'react-bootstrap/InputGroup'
// import Text from 'react-bootstrap/Text'
import FormControl from 'react-bootstrap/FormControl'
import EditIcon from '@mui/icons-material/Edit';
import EventIcon from '@mui/icons-material/Event';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import thirty from './styles/thirty.png'
import fifteen from './styles/fifteen.png'
import BalanceIcon from '@mui/icons-material/Balance';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import Alert from '@mui/material/Alert';
 
 
const useStyles = makeStyles((theme) => ({
   input: {
       color: "#FFF",
   },
}));
 
 
const ValuationDetails = (props) => {
   const classes = useStyles();
   //  const { showSuccessAlert, editSuccessAlert, showErrorAlert, editErrorAlert } = useContext(DCFContext)
 
   const { showErrorAlert, showSuccessAlert, showMore, editShowMore, editSuccessAlert, tickerContext, editErrorAlert } = useContext(DCFContext)
 
   function calculateValue(value) {
       return value;
   }
 
   var today = new Date();
   var dd = String(today.getDate()).padStart(2, '0');
   var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
   var yyyy = today.getFullYear();
 
   today = mm + '/' + dd + '/' + yyyy;
 
 
   const [confidence, setConfidence] = useState(50);
   const [notes, setNotes] = useState('');
   const [ticker, setTicker] = useState(tickerContext);
   const [scale, setScale] = useState('T');
 
   const [showPremiumAlert, setShowPremiumAlert] = useState(false);
 
 
 
   const [fairPrice, setFairPrice] = useState(props.intrinsicValue);
 
   const [isMarketCapChecked, setIsMarketCapChecked] = useState(false);
 
 
   const [tableEntries, setTableEntries] = useState(
       {
           // ticker: 'AAPL',
           // date: '1/1/2021',
           // fairPrice: 25,
           // tenMOS: 10,
           // thirtyMOS: 4,
           // notes: 'apple is this and that',
           // confidence: 70
       }
   );
 
   const styles = theme => ({
       multilineColor: {
           color: 'red'
       }
   });
 
 
   const handleIsMarketCapChange = () => {
       setIsMarketCapChecked(!isMarketCapChecked)
   }
 
   const handleSave = async () => {
 
       console.log('hiiiii ')
 
 
       let access = false;
       let valuationsCount = 0;
 
       try {
 
           const jwtToken = localStorage.getItem('jwtToken')
           const res = await fetch('https://obscure-meadow-68622.herokuapp.com/info', {
               method: 'GET',
               headers: {
                   Accept: 'application/json',
                   "Content-Type": 'application/json',
                   'Authorization': 'Bearer ' + jwtToken
 
               },
 
           });
 
           const data = await res.json();
           console.log('GET CHECK : ', data)
           // setData(data)
 
           access = data.premiumAccess;
           valuationsCount = data.valuations.length;
 
           if (!res.status === 200) {
               const error = new Error(res.error)
               throw error;
           }
 
       } catch (error) {
           console.log(error)
           // history.push('/signin')
       }
 
       // if !isPremium && valuations.length==5
       // not allowed to save any more
 
       if (!access && valuationsCount == 5) {
           console.log('not allowed to save anymore')
 
           setShowPremiumAlert(true)
 
           setTimeout(() => {
               setShowPremiumAlert(false)
           }, 3000);
 
       }
       else {
 
           console.log('doing post')
 
           if (localStorage.getItem('jwtToken') === null || localStorage.getItem('jwtToken') === 'null' || localStorage.getItem('jwtToken') === 'undefined' || localStorage.getItem('jwtToken') === undefined || localStorage.getItem('jwtToken') === '') {
               editErrorAlert(true);
               setTimeout(() => {
                   editErrorAlert(false);
 
               }, 1500);
 
           }
 
           else {
 
               // ticker: 'AAPL',
               // date: '1/1/2021',
               // fairPrice: 25,
               // tenMOS: 10,
               // thirtyMOS: 4,
               // notes: 'apple is this and that',
               // confidence: 70
               let entry = {
                   ticker: ticker,
                   date: today,
                   fairPrice: fairPrice + scale,
                   fifteenMOS: (Math.round((parseInt(fairPrice) - (parseInt(fairPrice) * 0.15)) * 100) / 100) + scale,
                   thirtyMOS: (Math.round((parseInt(fairPrice) - (parseInt(fairPrice) * 0.3)) * 100) / 100) + scale,
                   notes: notes,
                   confidence: confidence,
                   isMarketCap: isMarketCapChecked,
                   toAlert: false
               }
 
               console.log('checking api post : ', entry)
 
               console.log('props.cfArr check', props.cfArr)
               console.log('props.dcfArr check', props.dcfArr)
 
 
               const jwtToken = localStorage.getItem('jwtToken')
 
               const res = await fetch('https://obscure-meadow-68622.herokuapp.com/addvaluation', {
                   method: 'POST',
                   headers: {
                       'Content-Type': 'application/json',
                       'Authorization': 'Bearer ' + jwtToken
 
                   },
                   body: JSON.stringify({
                       ticker: ticker,
                       date: today,
                       fairPrice: fairPrice + scale,
                       fifteenMOS: (Math.round((parseInt(fairPrice) - (parseInt(fairPrice) * 0.15)) * 100) / 100) + scale,
                       thirtyMOS: (Math.round((parseInt(fairPrice) - (parseInt(fairPrice) * 0.3)) * 100) / 100) + scale,
                       notes: notes,
                       confidence: confidence,
                       isMarketCap: isMarketCapChecked,
                       toAlert: false,
                       cfArr: props.cfArr,
                       dcfArr: props.dcfArr,
                       cfArrBull: props.cfArrBull,
                       dcfArrBull: props.dcfArrBull,
                       cfArrBear: props.cfArrBear,
                       dcfArrBear: props.dcfArrBear,
                       discountRateBear: props.discountRateBear,
                       discountRateBull: props.discountRateBull,
                       discountRate: props.discountRate,
                       fiveYearGrowth: props.fiveYearGrowth,
                       sixYearGrowth: props.sixYearGrowth,
                       terminalMultiple: props.terminalMultiple,
                       fiveYearGrowthBull: props.fiveYearGrowthBull,
                       sixYearGrowthBull: props.sixYearGrowthBull,
                       terminalMultipleBull: props.terminalMultipleBull,
                       fiveYearGrowthBear: props.fiveYearGrowthBear,
                       sixYearGrowthBear: props.sixYearGrowthBear,
                       terminalMultipleBear: props.terminalMultipleBear,
                       normalWeight: props.normalWeight,
                       bearWeight: props.bearWeight,
                       bullWeight: props.bullWeight,
                       hiddenValueBull: props.hiddenValueBull,
                       hiddenValueBear: props.hiddenValueBear,
                       hiddenValue: props.hiddenValue,
                       cashReturned: props.cashReturned,
                       cashReturnedBull: props.cashReturnedBull,
                       cashReturnedBear: props.cashReturnedBear
                   })
               })
               const data = await res.json();
 
               if (!data) {
                   console.log('valuation not sent')
               }
               else {
                   editSuccessAlert(true);
                   setTimeout(() => {
                       editSuccessAlert(false);
 
                   }, 1500);
               }
 
               // /addvaluation entry
               //api
               // on success , close form
               //success toast
               editShowMore(false);
 
 
               setTimeout(async () => {
 
 
                   callAboutPage()
 
               }, 1500);
 
           }
       }
       // /getvaluations
   }
 
   const callAboutPage = async () => {
       try {
           // const res = await fetch('/info', {
           //     method: 'GET',
           //     headers: {
           //         Accept: 'application/json',
           //         "Content-Type": 'application/json',
           //     },
           //     credentials: 'include'
 
           // });
           const jwtToken = localStorage.getItem('jwtToken')
           const res = await fetch('https://obscure-meadow-68622.herokuapp.com/info', {
               method: 'GET',
               headers: {
                   Accept: 'application/json',
                   "Content-Type": 'application/json',
                   'Authorization': 'Bearer ' + jwtToken
 
               },
 
           });
 
           const data = await res.json();
           console.log('GET CHECK : ', data)
           // setData(data)
           if (!res.status === 200) {
               const error = new Error(res.error)
               throw error;
           }
 
       } catch (error) {
           console.log(error)
           // history.push('/signin')
       }
   }
 
 
   return (
 
 
       <div style={{ padding: "20px" }}>
 
 
 
           <Grid container spacing={0} style={{ marginLeft: '48%', color: 'white' }}>
               <Grid item xs={12}  >
                   <CancelPresentationIcon class='close-btn' onClick={() => { editShowMore(false) }} />
               </Grid>
 
           </Grid>
 
           <div style={{ color: "white" }}>
 
               <Grid container spacing={0}>
                   <Grid item xs={12}  >
                       New Valuation
                   </Grid>
 
               </Grid>
           </div>
 
           <InputGroup style={{ marginTop: "20px" }}>
               <InputGroup.Text style={{ backgroundColor: '#92ddff21', color: 'white' }}>
                   <AttachMoneyIcon class='pencilIcon' style={{ fill: "#0fd12c" }} />
 
               </InputGroup.Text>
 
 
 
               <TextField
                   // inputProps={{ className: classes.input }}
 
                   id="outlined-basic"
                   label="Ticker"
                   variant="outlined"
                   textColor='primary'
                   InputLabelProps={{ className: 'textfield_label' }}
                   InputProps={{ className: 'textfield_props' }}
                   color="primary"
                   focused
                   value={ticker}
                   onChange={(e) => setTicker(e.target.value)}
                   style={{ width: 'calc(100% - 55px)' }}
               />
 
           </InputGroup>
 
 
           {/* {JSON.stringify(tableEntries)} */}
 
           <InputGroup className="mb-3" style={{ width: '100%', marginTop: '20px' }}>
               <InputGroup.Text style={{ backgroundColor: '#92ddff21', color: 'white' }} id="basic-addon1">
                   Date Created
                   <EventIcon class='pencilIcon' style={{ fill: "#345eeb" }} />
 
               </InputGroup.Text>
               <FormControl
                   disabled
                   placeholder={today}
                   aria-label="Username"
                   aria-describedby="basic-addon1"
                   style={{ backgroundColor: '#92ddff21', color: 'white', borderColor: 'white', overflow: 'scroll' }}
 
               />
           </InputGroup>
 
           <InputGroup style={{ marginTop: "20px" }}>
               <InputGroup.Text style={{ backgroundColor: '#92ddff21', color: 'white' }}>
                   <BalanceIcon class='pencilIcon' style={{ fill: "silver" }} />
 
               </InputGroup.Text>
 
 
 
               <TextField
                   // inputProps={{ className: classes.input }}
                   id="outlined-basic"
                   label="Fair Price"
                   variant="outlined"
                   textColor='primary'
                   InputLabelProps={{ className: 'textfield_label' }}
                   InputProps={{ className: 'textfield_props' }}
                   color="primary"
                   focused
                   value={fairPrice}
                   onChange={(e) => setFairPrice(e.target.value)}
                   style={{ width: 'calc(100% - 55px)' }}
               />
 
           </InputGroup>
 
 
           <div className="priceType">
               <div className={isMarketCapChecked ? "priceInactive" : "priceActive"}>
                   Per share
               </div>
               <Switch checked={isMarketCapChecked} onChange={handleIsMarketCapChange} />
               <div className={isMarketCapChecked ? "priceActive" : "priceInactive"}>
                   Market cap
               </div>
 
           </div>
 
           {isMarketCapChecked ?
               <div style={{ margin: '0 auto', marginTop: '20px', width: 'fit-content' }}>
                   <div className={(scale === 'T') ? "scaleActive" : "scaleInactive"} onClick={() => setScale('T')}>
                       Thousands
                   </div>
                   <div className={(scale === 'M') ? "scaleActive" : "scaleInactive"} onClick={() => setScale('M')}>
                       Millions
                   </div>
                   <div className={(scale === 'B') ? "scaleActive" : "scaleInactive"} onClick={() => setScale('B')}>
                       Billions
                   </div>
               </div>
               : null}
 
 
           <InputGroup className="mb-3" style={{ width: '100%', marginTop: '20px' }}>
               <img class="mos" src={fifteen}></img>
 
               <InputGroup.Text style={{ backgroundColor: '#92ddff21', color: 'white' }} id="basic-addon1">15% mos</InputGroup.Text>
 
               <FormControl
                   disabled
 
                   placeholder={Math.round((parseInt(fairPrice) - (parseInt(fairPrice) * 0.15)) * 100) / 100}
                   aria-label="Username"
                   aria-describedby="basic-addon1"
                   style={{ backgroundColor: '#92ddff21', color: 'white', borderColor: 'white', overflow: 'scroll', width: '10px', textOverflow: 'ellipsis' }}
 
               />
           </InputGroup>
 
 
 
           <InputGroup className="mb-3" style={{ width: '100%', marginTop: '20px' }}>
               <img class="mos" src={thirty}></img>
 
               <InputGroup.Text style={{ backgroundColor: '#92ddff21', color: 'white' }} id="basic-addon1">30% mos</InputGroup.Text>
 
               <FormControl
                   disabled
 
                   placeholder={Math.round((parseInt(fairPrice) - (parseInt(fairPrice) * 0.3)) * 100) / 100}
                   aria-label="Username"
                   aria-describedby="basic-addon1"
                   style={{ backgroundColor: '#92ddff21', color: 'white', borderColor: 'white', overflow: 'scroll', width: '40px' }}
 
               />
           </InputGroup>
 
 
 
           {/* <div > */}
 
           <InputGroup style={{ marginTop: "20px" }}>
               <InputGroup.Text style={{ backgroundColor: '#92ddff21', color: 'white' }}>
                   <br />
                   <EditIcon class='pencilIcon' style={{ fill: "yellow" }} />
 
               </InputGroup.Text>
 
 
               <TextField
                   // inputProps={{ className: classes.input }}
                   id="outlined-basic"
                   label="Notes"
                   variant="outlined"
                   textColor='primary'
                   InputLabelProps={{ className: 'textfield_label' }}
                   InputProps={{ className: 'textfield_props' }}
                   color="primary"
                   focused
                   multiline
                   value={notes}
                   onChange={(e) => setNotes(e.target.value)}
                   style={{ width: 'calc(100% - 55px)', height: '100%' }} //    width: calc(100% - 26px);
               />
           </InputGroup>
           {/* </div> */}
 
 
           <div style={{ marginTop: "20px" }}>
               <Grid container spacing={0}>
 
                   <Grid item xs={4}  >
 
                       <Typography id="non-linear-slider" gutterBottom style={{ color: 'white', marginTop: '5px' }}>
                           Confidence
                       </Typography>
                       <WhatshotIcon class='fireIcon' style={{ fill: "yellow" }} />
 
                   </Grid>
                   <Grid item xs={8}  >
 
                       <Slider
                           value={confidence}
                           min={0}
                           step={1}
                           max={100}
                           scale={calculateValue}
                           // getAriaValueText={valueLabelFormat}
                           // valueLabelFormat={valueLabelFormat}
                           onChange={(event) => setConfidence(event.target.value)}
                           valueLabelDisplay="auto"
                           aria-labelledby="non-linear-slider"
                           style={{ width: '75%' }}
                       />
 
 
                       <Typography id="non-linear-slider" gutterBottom style={{ color: 'white' }}>
                           {calculateValue(confidence)}/100
                       </Typography>
                   </Grid>
               </Grid>
           </div>
 
 
           <button class='save-new-valuation-button' onClick={handleSave}>
               <Grid container spacing={2}>
 
                   <Grid item xs={6} style={{ marginTop: '2px' }}  >
                       Save
                   </Grid>
 
                   <Grid item xs={6}  >
                       <div id="ValuationIcon"   ></div>
 
                   </Grid>
               </Grid>
 
               {/* <div class='row'  > */}
 
 
               {/* </div> */}
           </button>
 
 
           {/* {showErrorAlert ? <Alert variant="filled" severity="success" style={{ position: 'fixed', top: '0px', marginLeft: '', zIndex: '1000' }}>
               Sign in first!
           </Alert> : <div/>} 
*/}
           {showErrorAlert ? <Alert variant="filled" severity="error" style={{ position: 'fixed', top: '100px', marginLeft: '', zIndex: '1000' }}>Sign in to activate the valuation dashboard!</Alert>
               : <div />}
 
           {showPremiumAlert ? <Alert variant="filled" severity="error" style={{ position: 'fixed', top: '0px', marginLeft: '', zIndex: '1000' }}>You've reached the maximum allowable saves for the free tier. Get unlimited saves with Premium Access here</Alert>
               : <div />}
 
       </div >
 
 
   )
}
 
export default ValuationDetails