// import styled from 'styled-components';
import styled from "@emotion/styled/macro";
export const Switch = styled.div`
font-family: "Lucida Grande",Tahoma,Verdana,sans-serif;
    position: relative;
    height: 26px;
    width: 219px;
    background-color: #21354d;
    border-radius: 3px;
    box-shadow: 0 0 10px royalblue;
`;

export const SwitchRadio = styled.input`
  display: none;
`;

export const SwitchSelection = styled.span`
  display: block;
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 0px;
  width: 45px;
  height: 26px;
   border-radius: 3px;
  transition: left 0.25s ease-out;
`;

export const SwitchLabel = styled.label`

  position: relative;
    z-index: 2;
    float: left;
    width: 73px;
    line-height: 26px;
    font-size: 11px;
    color: rgb(168 192 189 / 60%);
    text-align: center;
    cursor: pointer;

  ${SwitchRadio}:checked + &{
    transition: 0.15s ease-out;
    color: #fff;
    background: royalblue;
    border-radius: 5px;
        padding: 0 3px;
}
  }
  
`;
