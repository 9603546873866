// active
// canceled still active , for...days
// unsubscribed (never subscribed before)
// canceled inactive (subscribed before but cancelled and term is over)

import React, { useState, useEffect, useContext, useRef } from "react";
import EmailForm from "./EmailForm"
import { useHistory } from 'react-router-dom';
import { RouteContext } from './contexts/RouteContext'

import Avatar from '@mui/material/Avatar';
import './styles/accountStyles.css'
import Grid from '@mui/material/Grid';
import modIcon from './styles/modIcon.png'
import premiumIcon from './styles/premiumIcon.png'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import EditIcon from '@mui/icons-material/Edit';
import { DCFContext } from './contexts/DCFContext'
import Alert from '@mui/material/Alert';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import Switch from '@mui/material/Switch';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import Premium from './Premium'
// import PayPalBtn from './components/PayPalBtn'
 
const SubTest = () => {
    const [status, setStatus] = useState('unsubscribed');
    const { subscriptionStatus,updateSubscriptionStatus,subscriptionID, updateSubscriptionID } = useContext(RouteContext)
    const [id, setID] = useState('');


    const handleGet = async () => {
        try {

            const jwtToken = localStorage.getItem('jwtToken')
            const res = await fetch('https://obscure-meadow-68622.herokuapp.com/info', {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    "Content-Type": 'application/json',
                    'Authorization': 'Bearer ' + jwtToken

                },

            });

            const data = await res.json();

             updateSubscriptionID(data.subscriptionInfo[data.subscriptionInfo.length-1].subscriptionID)

            console.log('data from info check ', data)

            localStorage.setItem('userData', JSON.stringify(data));

            // routeContext set data
            updateSubscriptionStatus('test login')
            console.log('pre effect',data.subscriptionInfo[data.subscriptionInfo.length-1].subscriptionID)
            setID(data.subscriptionInfo[data.subscriptionInfo.length-1].subscriptionID)
             

            if (!res.status === 200) {
                const error = new Error(res.error)
                throw error;
            }

        } catch (error) {
            console.log(error)
        
        }
    }


    let updateSubscriptionBackend = async (subscriptionID)=>{

        const jwtToken = localStorage.getItem('jwtToken')

        const res = await fetch("https://obscure-meadow-68622.herokuapp.com/updateSubscription", {
    
            method: "POST",
            credentials: 'include', // Don't forget to specify this if you need cookies
            // credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + jwtToken

            },
            body: JSON.stringify({
                subscriptionID: subscriptionID,
            })
        });
        const data = await res.json();
        console.log('data.status check: ' + data.status);

    }
 
   // payments-methods-start
   const paypalSubscribe = (data, actions) => {
       return actions.subscription.create({
           'plan_id': process.env.REACT_APP_PAYPAL_PLAN_ID,
       });
   };
   const paypalOnError = (err) => {
       console.log("Error")
   }
   const paypalOnApprove = async (data, detail) => {
       // call the backend api to store transaction details
       console.log("Payapl approved")
       console.log(data.subscriptionID)
       
       // make post api storeSupscription to store subscription id for that person in obscure meadow
    
       await updateSubscriptionBackend(data.subscriptionID)
       await updateSubscriptionID(data.subscriptionID)
       await handleGet()

        //    /updateSubscription

   };
   
 
   const cancelSubscription = async () => {
     
       try {

        
        console.log('subscriptionID',subscriptionID)
        // let id='I-W9D3R3XFPDS9'

    
        const jwtToken = localStorage.getItem('jwtToken')
        const res = await fetch('https://obscure-meadow-68622.herokuapp.com/cancelSubscription/'+id, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                "Content-Type": 'application/json',
                'Authorization': 'Bearer ' + jwtToken
    
            }
    
        });
    
        const data = await res.json();
    
        console.log('data from info check ', data)
    
    
    }
    catch{
        console.log('error in fetching status')
    }
   }

   
   // payments-methods-end
   
 
   const checkStatus = async () =>{
// /checkStatus
try {
    console.log('subscriptionID',subscriptionID)

    // console.log('subscriptionID check',await handleGet())


    // let id='I-DHU23Y2R49LR'
 
    const jwtToken = localStorage.getItem('jwtToken')
    const res = await fetch('https://obscure-meadow-68622.herokuapp.com/checkStatus/'+id, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            'Authorization': 'Bearer ' + jwtToken

        }

    });

    const data = await res.json();

    console.log('data from info check ', data)


}
catch{
    console.log('error in fetching status')
}
   
   }

    useEffect(() => {
        console.log('routeContext status check : ',subscriptionStatus)
        checkStatus()
        handleGet()
    }, []);


   return (
       <div>
                <Premium
               amount="25.00"
               currency="USD"
               createSubscription={paypalSubscribe}
               onApprove={paypalOnApprove}
               catchError={paypalOnError}
               onError={paypalOnError}
               onCancel={paypalOnError}
           />
      <div style={{ color: 'white' }} onClick={() => cancelSubscription()}>
               Cancel subscription
           </div>
 
           <div style={{ color: 'white' }} onClick={() => checkStatus()}>
               get subscription statuss
           </div>
 

 
       </div>
   )
}
export default SubTest