//a bird in the hand is worth 2 in the bush 

//  make a post (redirect to Account) bottom right

// my posts clicking opens full post (short view with premium icon (shown on hover), mod icon (shown on hover), name, nation [rudis nation])

import React from "react";
import EmailForm from "./EmailForm"
import MyLeaderBoardAd from './MyLeaderBoardAd';

import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import TabContainer from 'react-bootstrap/TabContainer'
import TabContent from 'react-bootstrap/TabContent'

import TabPane from 'react-bootstrap/TabPane'

import TableStyles from './styles/tableStyles.css'

import './styles/discussionStyles.css'
import makePostIcon from './styles/makePostIcon.png'
import peopleIcon from './styles/peopleIcon.png'
import searchDiscussions from './styles/searchDiscussions.png'
import modIcon from './styles/modIcon.png'
import premiumIcon from './styles/premiumIcon.png'

import accountIcon from './styles/accountSidebar.png' //we'll use this for now as temporary AVI

import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { Link } from 'react-router-dom';

import { useState, useEffect, useContext, useRef } from "react";
import { DCFContext } from './contexts/DCFContext'
import Alert from '@mui/material/Alert';


const Blog = (props) => {

    const [nationName, setNationName] = useState("Rudi's nation")
    const [posterName, setOosterName] = useState("Rudi")
    const [avatar, setAvatar] = useState("avatarUrl")
    const { showSuccessAlert, editSuccessAlert, showErrorAlert, editErrorAlert } = useContext(DCFContext)

    const handleDisscussionClick = () => {

        editErrorAlert(true);
        setTimeout(() => {
            editErrorAlert(false);

        }, 1500);

    }

    const fetchCeleb = async (url) =>{
        try{
            const response= await fetch(url);
            const json = await response.json();
            console.log(json);            
        }
        catch(error){
            console.log('error',error)
        }
    }

    useEffect(() => {
        const url="https://obscure-meadow-68622.herokuapp.com/getCeleb/2";
        fetchCeleb(url);
    });

    return (
        <div className="discussion-window">


            <MyLeaderBoardAd style={{ zIndex: 99 }} />


            <div class="top-container">

                <div class="row">
                    <div class="col-sm-6" style={{ width: '50%' }}>
                        {/* <p class="search-discussion-text">Search discussions</p> */}

                        <div class="searchDiscussionContainer">
                            <img class="peopleIcon" src={searchDiscussions}></img>

                            <input class="nation-search" placeholder="search posts"></input>

                        </div>

                    </div>

                    <div class="col-sm-6" style={{ width: '50%' }}>
                        {/* <p class="join-text">Join a nation</p> */}
                        <div class="nationSearchContainer">

                            <img class="peopleIcon" src={peopleIcon}></img>

                            <input class="nation-search" placeholder="search nation ID"></input>
                        </div>

                    </div>


                </div>

            </div>
            <br />
            <div class="featured-posts">
                <h1 class="featured-discussions-header">Featured Discussions</h1>


                <div class="col" id="postContainer">


                    <div class="row" id="post" >


                        <h2 class="post-header">Intrinsic Value (using the DCF calculator)</h2>


                        <p style={{ textAlign: 'left', fontStyle: 'italic', marginTop: '15px' }}>Intrinsic value is the number you get if you can predict all the cash the business can give you between now and judgement day, discounted at the proper discount rate.</p>
                        <br></br>
                        <p style={{ textAlign: 'left', textIndent: '25px', marginTop: '15px' }}>- Warren Buffett</p>

                        <p style={{ textAlign: 'left', textIndent: '25px', marginTop: '35px' }}>As a beginner, the above quote might sound daunting. But with the help of ValueNation's DCF calculator, you’ll be on your way to making calculated investment decisions in seconds. Our DCF calculator abstracts away all the complexities and reduces the process to 4 inputs:
                        </p>
                        <br></br>
                        <p style={{ textAlign: 'left', fontWeight: 'bolder', marginTop: '15px' }}>1) Starting value</p>
                        <br></br>
                        <p style={{ textAlign: 'left', textIndent: '25px', marginTop: '15px' }}>- This value should be the trailing twelve months (TTM) net income, or the TTM free cash flow, or the “Owner’s earnings”
                        </p>
                        <br></br>
                        <p style={{ textAlign: 'left', textIndent: '25px', marginTop: '15px' }}>- This value depends on how the business makes money, over time you’ll get better at identifying which method to use, but a good
                            start is by using the TTM net income.
                        </p>
                        <br></br>
                        <p style={{ textAlign: 'left', fontWeight: 'bolder', marginTop: '15px' }}>2) Growth rate</p>
                        <br></br>
                        <p style={{ textAlign: 'left', fontWeight: 'bolder', marginTop: '15px' }}>3) Terminal value</p>
                        <br></br>
                        <p style={{ textAlign: 'left', textIndent: '25px', marginTop: '15px' }}>- This is the multiplier at which you can expect to sell the business after 10 years (ideally we should project the earnings in perpetuity, but a multiplier will suffice)
                        </p>
                        <br></br>
                        <p style={{ textAlign: 'left', textIndent: '25px', marginTop: '15px' }}>- This value depends on business quality, moat, investor sentiment [highly unreliable], future risk free rate (the interest rate at which you compare all opportunities), etc. Stay tuned for more content explaining each of these in depth.
                        </p>
                        <br></br>
                        <p style={{ textAlign: 'left', fontWeight: 'bolder', marginTop: '15px' }}>4) Discount rate</p>
                        <br></br>
                        <p style={{ textAlign: 'left', textIndent: '25px', marginTop: '15px' }}>- Your required rate of return. Be mindful of the risk free rate (the yield on the US treasury), if the yield is close to 0, like it is now, use 10%, if it’s higher, consider an even higher discount rate
                        </p>
                        <br></br>
                        <p style={{ textAlign: 'left', textIndent: '25px', marginTop: '15px' }}>- There have been PhD theses on what the discount rate should be, introduced complex and beautiful formulas like CAPM. But practitioners like Warren Buffett and Charlie Munger stay away from them, because while the formulas are beautiful and complex, they aren’t grounded in reality.. We’ll have more content on risk and discount rates in the future, stay tuned.
                        </p>
                        <br></br>
                        <p style={{ textAlign: 'left', marginTop: '35px' }}>The output is the intrinsic value, the amount that YOU should pay for the returns that you’re looking for. Investing becomes so much easier when you know <div style={{ fontWeight: 'bolder', display: 'inline' }}>exactly</div> what you’re paying and what you’re getting in return.
                        </p>
                        <br></br>
                        <p style={{ textAlign: 'left', marginTop: '15px' }}>The DCF will also help you conceptualize what the market’s assumptions are for a stock at its current valuation, with this information you can decide whether or not the market’s assumptions are overly exuberant or overly pessimistic.
                        </p>
                        <br></br>
                        <p style={{ textAlign: 'left', marginTop: '15px' }}>You can find the DCF calculator at valuenation.app/dcf or by searching a stock in the searchbar at valuenation.app/research.
                        </p>



                    </div>

                    <div class="row" id="poster" >

                        <div class="col-2" id="avatarCol" >
                            <img class="accountIcon" src={accountIcon}></img>
                        </div>

                        <div class="col-10" id="infoCol" >
                            <div class="row" id="nationInfo">
                                {nationName}

                                <button onClick={handleDisscussionClick} className="inhabit-button"> Join Nation </button>



                            </div>
                            <div class="row" id="personInfo">
                                {posterName}
                                <OverlayTrigger
                                    key="top"
                                    placement="top"

                                    overlay={
                                        <Tooltip>
                                            Moderator
                                        </Tooltip>
                                    }
                                >
                                    <img class="modIcon" src={modIcon}></img>

                                </OverlayTrigger>
                                <OverlayTrigger
                                    key="top"
                                    placement="top"

                                    overlay={
                                        <Tooltip>
                                            Premium User
                                        </Tooltip>
                                    }

                                >
                                    <img class="premiumIcon" src={premiumIcon}></img>

                                </OverlayTrigger>

                            </div>

                        </div>
                    </div>

                </div>

                <MyLeaderBoardAd style={{ zIndex: 99 }} />
                <div class="col" id="postContainer">


                    <div class="row" id="post" >


                        <h2 class="post-header">Time Value of Money and Risk</h2>

                        <p style={{ textAlign: 'left' }}> <div style={{ fontWeight: 'bold', display: 'inline' }}>100 dollars right now is worth more than 100 dollars 10 years from now.</div> In fact, we can calculate exactly how much $100 is worth 10 years from now.. for YOU. Let’s say you had the option to lend money to 3 entities…</p>
                        <br></br>

                        <p style={{ textAlign: 'left', textIndent: '25px', marginTop: '15px' }}> <div style={{ fontStyle: 'italic', color: 'red', display: 'inline', fontWeight: 'bold' }}> RISKY </div> Ryan Howard : Dodgy young man, unstable job, history of bankruptcy.</p>
                        <br></br>

                        <p style={{ textAlign: 'left', textIndent: '25px', marginTop: '15px' }}> <div style={{ fontStyle: 'italic', color: 'yellow', display: 'inline', fontWeight: 'bold' }}> MODERATE RISK </div> Dwight Schrute : Hard worker, honest man, good credit.</p>
                        <br></br>

                        <p style={{ textAlign: 'left', textIndent: '25px', marginTop: '15px' }}> <div style={{ fontStyle: 'italic', color: 'green', display: 'inline', fontWeight: 'bold' }}> RISK FREE </div> The United States Government : A theoretically risk free entity that will always make their interest payment.</p>
                        <br></br>


                        <p style={{ textAlign: 'left', marginTop: '15px' }}>Knowing these (largely subjective) qualities of our 3 options, and the USA’s 10 year treasury yield (1.792% at the time of writing this), we can definitively say, that the present value of $100, 10 years from now, is AT BEST $90.53 (using ValueNation’s DCF Calculator).</p>
                        <br></br>


                        <p style={{ textAlign: 'left', marginTop: '15px' }}>Depending on your understanding of the risks associated with the investment, you assign a <div style={{ fontStyle: 'italic', fontWeight: 'bold', display: 'inline' }}>discount rate</div>. Since Dwight is more risky than the US government, let’s say we can lend him money for 5%. Since Ryan is even risker, let's say we can lend him money for 10%. The worth of $100, 10 years from now, using a 5% discount rate is $61.39. Using a 10% discount rate is $38.55.</p>
                        <br></br>

                        <p style={{ textAlign: 'left', marginTop: '15px' }}>In this manner, you can use a discount rate for equities as a whole (the entire asset class). Equities are more riskier than bonds. In fact there are credit rating firms like Moody’s, that weigh the risks and rate bonds based on thoses risks.</p>
                        <br></br>


                        <p style={{ textAlign: 'left', marginTop: '15px' }}>Knowing that the historical returns of US equities are ~10%, and currently we have near 0 interest rates, we at ValueNation use a fixed 10% discount rate, and weigh our opportunities accordingly. If rates were much higher, like in the 1980s, we would use a higher discount rate.</p>
                        <br></br>
                        <br></br>

                        <p style={{ textAlign: 'left', marginTop: '15px' }}>Our approach involves weighing all our opportunities with the same discount rate and picking the best of the group. The risk comes down to our ability to project the future cash flows of an investment, if we are uncertain of those cash flows, we simply avoid that investment! This approach, paired with the principle of “margin of safety”, and the attitude towards the stock market, outlined by Ben Graham in “The Intelligent Investor” will serve you well. Good luck!</p>
                        <br></br>



                    </div>

                    <div class="row" id="poster" >

                        <div class="col-2" id="avatarCol" >
                            <img class="accountIcon" src={accountIcon}></img>
                        </div>

                        <div class="col-10" id="infoCol" >
                            <div class="row" id="nationInfo">
                                {nationName}

                                <button onClick={handleDisscussionClick} className="inhabit-button"> Join Nation </button>

                            </div>
                            <div class="row" id="personInfo">
                                {posterName}
                                <OverlayTrigger
                                    key="top"
                                    placement="top"

                                    overlay={
                                        <Tooltip>
                                            Moderator
                                        </Tooltip>
                                    }
                                >
                                    <img class="modIcon" src={modIcon}></img>

                                </OverlayTrigger>
                                <OverlayTrigger
                                    key="top"
                                    placement="top"

                                    overlay={
                                        <Tooltip>
                                            Premium User
                                        </Tooltip>
                                    }

                                >
                                    <img class="premiumIcon" src={premiumIcon}></img>

                                </OverlayTrigger>

                            </div>

                        </div>
                    </div>

                </div>

                <MyLeaderBoardAd style={{ zIndex: 99 }} />
                <div class="col" id="postContainer">


                    <div class="row" id="post" >


                        <h2 class="post-header">Market Cap vs Price</h2>

                        <p style={{ textAlign: 'left' }}>Despite communicating the same concept, market cap is the superior metric, here’s why:</p>
                        <br></br>

                        <p style={{ textAlign: 'left', textIndent: '25px', fontStyle: 'italic', marginTop: '15px' }}>Market cap = price x shares outstanding</p>
                        <br></br>

                        <p style={{ textAlign: 'left', textIndent: '25px', marginTop: '15px' }}>When you focus solely on the price you’re missing out on the scale factor. 1 share of Berkshire Hathaway sells for $428,000. 1 share of Microsoft sells for $330. A complete novice would think Berkshire Hataway is more valuable, but obviously it’s not, since it’s market cap is 630B, whereas Microsoft’s market cap is 2.5T. Scale is a huge factor in investing. Economies of scale is a term used to describe the advantages larger corps have over smaller ones in cutting expenses. From a growth perspective, it’s much easier to grow 100M by 20%, than it is to grow 50B by 20%.
                        </p>
                        <br></br>
                        <p style={{ textAlign: 'left', textIndent: '25px', marginTop: '15px' }}>Ultimately market cap allows you to make an apples to apples comparison, something that’s impossible to do by looking at just the price. By paying attention to market caps of different companies, you’ll get a better grasp of the investing landscape. You'll understand why google is worth 1.5T, why iRobot is worth 2B, or why Verizon is worth 200B. You’ll start asking yourself if the company's valuation is reasonable, which will prompt you to check on their financials.
                        </p>
                        <br></br>
                        <p style={{ textAlign: 'left', textIndent: '25px', marginTop: '15px', marginBottom: '15px' }}>To determine if a stock is overvalued or undervalued you need to first know what it’s worth, and that comes from its market cap, or better yet: <div style={{ display: 'inline', fontStyle: 'italic' }}>Enterprise Value </div>, but that’s for another time...
                        </p>



                        {/* 
                            <p>Here we aim to empower the next generation of retail investors with data and tools to help them start making measured investment desicions. No spoonfeeding, no 'buy my $1000 course' scam, just tools & data, free forever.</p>
                            <p>The current  tools  availible to retail investors today look good, but ultimately lead us away from what really matters. Value. The over fixation on price is the ultimate culprit. tools space Value Nation distinguhes itself from other</p>
                            <p>Brinign together all the tools to streamline the investment process. giving you data, valuation , alerts , info etc An amalgomation of all the tools  We aim to streamline the investment process from making</p>
                            <p>Lorem Ipsum</p>

                            The journey of a new retail investor outside of the world of finance starts by googling 'investing' or 'stock market', hopefully they encounter a John Bogle fan who reccomends them to dollar cost average into a low cost index fund, yielding about 8% to 10% annually allowing them to safely retire at 60.
                            If they're unlucky they might encounter someone pitching them a mutual fund, (for that good commission $$$) with an absurd MER for simply buying large cap stocks.
                            If they're super unlucky and easily swayed by internet personalities, they'll encounter a youtube video with a flashy thumbnail and a charasmatic individual telling them to buy a stock because it's, "technicals" are looking good.
                            with a book recommendation by John Bogle a with an absurd MER for simply buying large cap stocks, giving market returns,
                            , increasingly the introductory material is being served by youtube thumbnails with extravagent colours, these sorts of consuming habits disproportionately affect Gen Z
                            With the hyper focus on price apprciation as opposed to business analysis

                            <p>Lorem Ipsum</p>
                            <p>Lorem Ipsum</p>

                            <p>Lorem Ipsum</p>
                            <p>Lorem Ipsum</p>

                            <p>Lorem Ipsum</p>
                            <p>Lorem Ipsum</p>

                            <p>Lorem Ipsum</p>
                            <p>Lorem Ipsum</p> */}

                    </div>

                    <div class="row" id="poster" >

                        <div class="col-2" id="avatarCol" >
                            <img class="accountIcon" src={accountIcon}></img>
                        </div>

                        <div class="col-10" id="infoCol" >
                            <div class="row" id="nationInfo">
                                {nationName}

                                <button onClick={handleDisscussionClick} className="inhabit-button"> Join Nation </button>

                            </div>
                            <div class="row" id="personInfo">
                                {posterName}
                                <OverlayTrigger
                                    key="top"
                                    placement="top"

                                    overlay={
                                        <Tooltip>
                                            Moderator
                                        </Tooltip>
                                    }
                                >
                                    <img class="modIcon" src={modIcon}></img>

                                </OverlayTrigger>
                                <OverlayTrigger
                                    key="top"
                                    placement="top"

                                    overlay={
                                        <Tooltip>
                                            Premium User
                                        </Tooltip>
                                    }

                                >
                                    <img class="premiumIcon" src={premiumIcon}></img>

                                </OverlayTrigger>

                            </div>

                        </div>
                    </div>

                </div>


                <MyLeaderBoardAd style={{ zIndex: 99 }} />
                <div class="col" id="postContainer">


                    <div class="row" id="post" >


                        <h2 class="post-header">Welcome to Value Nation</h2>

                        <p style={{ textAlign: 'left', textIndent: '25px' }}>After taking my investment decisions into my own hands, I quickly realized how easily the retail stock picker can be misled and pressured to speculate on stock prices. If your neighbour ends up 4x'ing their money by 'investing' into a 'revolutionary' company, you cant help but feel the FOMO overcome you. True investing, however, doesn't require you to spend hours a day staring at stock price charts. A farmer doesn't need a daily quote for his piece of land, he's more interested in crop yields, inventory, equipment costs, etc. Similarly, if we adopt a 'business first' approach to stocks, like Warren Buffett, Charlie Munger, or Mohnish Pabrai, we can be confident in our decisions and not be blindsided by our own ignorance.
                            With ValueNation, my goal is to encourage people to make more calculated investments.</p>
                        <br></br>
                        <div style={{ marginTop: '5px' }}></div>
                        <p style={{ textAlign: 'left', textIndent: '25px' }}>A standard research session for me would require getting historical price ratios from Macrotrends, getting standardized financial data dating back 5+ years from Morningstar, and making valuations/tracking investments on google sheets.
                            With ValueNation I can do all that and more, all in one place, saving me time and the headache associated with staring at subpar UI. At it's core, ValueNation is a retail investing tool, made by a retail investor. I hope you find this as valuable as I do!</p>
                        <div style={{ marginTop: '2%' }}></div>

                        {/* 
                            <p>Here we aim to empower the next generation of retail investors with data and tools to help them start making measured investment desicions. No spoonfeeding, no 'buy my $1000 course' scam, just tools & data, free forever.</p>
                            <p>The current  tools  availible to retail investors today look good, but ultimately lead us away from what really matters. Value. The over fixation on price is the ultimate culprit. tools space Value Nation distinguhes itself from other</p>
                            <p>Brinign together all the tools to streamline the investment process. giving you data, valuation , alerts , info etc An amalgomation of all the tools  We aim to streamline the investment process from making</p>
                            <p>Lorem Ipsum</p>

                            The journey of a new retail investor outside of the world of finance starts by googling 'investing' or 'stock market', hopefully they encounter a John Bogle fan who reccomends them to dollar cost average into a low cost index fund, yielding about 8% to 10% annually allowing them to safely retire at 60.
                            If they're unlucky they might encounter someone pitching them a mutual fund, (for that good commission $$$) with an absurd MER for simply buying large cap stocks.
                            If they're super unlucky and easily swayed by internet personalities, they'll encounter a youtube video with a flashy thumbnail and a charasmatic individual telling them to buy a stock because it's, "technicals" are looking good.
                            with a book recommendation by John Bogle a with an absurd MER for simply buying large cap stocks, giving market returns,
                            , increasingly the introductory material is being served by youtube thumbnails with extravagent colours, these sorts of consuming habits disproportionately affect Gen Z
                            With the hyper focus on price apprciation as opposed to business analysis

                            <p>Lorem Ipsum</p>
                            <p>Lorem Ipsum</p>

                            <p>Lorem Ipsum</p>
                            <p>Lorem Ipsum</p>

                            <p>Lorem Ipsum</p>
                            <p>Lorem Ipsum</p>

                            <p>Lorem Ipsum</p>
                            <p>Lorem Ipsum</p> */}

                    </div>

                    <div class="row" id="poster" >

                        <div class="col-2" id="avatarCol" >
                            <img class="accountIcon" src={accountIcon}></img>
                        </div>

                        <div class="col-10" id="infoCol" >
                            <div class="row" id="nationInfo">
                                {nationName}

                                <button onClick={handleDisscussionClick} className="inhabit-button"> Join Nation </button>

                            </div>
                            <div class="row" id="personInfo">
                                {posterName}
                                <OverlayTrigger
                                    key="top"
                                    placement="top"

                                    overlay={
                                        <Tooltip>
                                            Moderator
                                        </Tooltip>
                                    }
                                >
                                    <img class="modIcon" src={modIcon}></img>

                                </OverlayTrigger>
                                <OverlayTrigger
                                    key="top"
                                    placement="top"

                                    overlay={
                                        <Tooltip>
                                            Premium User
                                        </Tooltip>
                                    }

                                >
                                    <img class="premiumIcon" src={premiumIcon}></img>

                                </OverlayTrigger>

                            </div>

                        </div>
                    </div>

                </div>



            </div>

            <MyLeaderBoardAd style={{ zIndex: 99 }} />







            {/* 
                toggle on click
                <div class="row">
                    <div class="toggle-screen">

                        <div class="switch-button">
                            <input class="switch-button-checkbox" type="checkbox"></input>
                            <label class="switch-button-label" for=""><span class="switch-button-label-span"><div class="left-menu">Photo</div></span></label>
                        </div>
                    </div>
                </div> */}


            <button className="make-post-container" onClick={handleDisscussionClick} >

                <OverlayTrigger
                    key="top"
                    placement="top"

                    overlay={
                        <Tooltip>
                            Start a discussion
                        </Tooltip>
                    }

                >

                    <img class="makePostImg" src={makePostIcon}></img>
                </OverlayTrigger>

            </button>


            {showErrorAlert ? <Alert variant="filled" severity="error" style={{ position: 'fixed', top: '0px', marginLeft: '', zIndex: '1000', transition: 'transform 300ms linear', transitionDelay: '0.4s' }}>
                Not availible yet!
            </Alert> : <div />}


        </div>
    )
}
export default Blog
