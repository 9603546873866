import React, { Component } from 'react';
import { render } from 'react-dom';
import { Switch, SwitchLabel, SwitchRadio, SwitchSelection } from './styles.js';
import bull from './styles/bullTest.png'
import bear from './styles/bear.png'

const titleCase = str =>
    str.split(/\s+/).map(w => w[0].toUpperCase() + w.slice(1)).join(' ');

const ClickableLabel = ({ title, onChange, id }) =>
    <SwitchLabel onClick={() => onChange(title)} className={id}>
        <div>
            <div style={{ display: 'inline' }}>
                {titleCase(title)}

            </div>
            <div style={{ display: 'inline' }}>
                {title === 'bull' ? <img style={{ width: '20px', marginLeft: '4px', marginRight: '3px' }} src={bull}></img> : null}
                {title === 'bear' ? <img style={{ width: '20px', marginLeft: '5px', marginRight: '3px' }} src={bear}></img> : null}
                {title === 'normal' ? <img style={{ width: '20px', marginLeft: '4px', marginRight: '3px' }} src={bull}></img> : null}

            </div>

        </div>
        {/* <img src={bull}></img> */}

    </SwitchLabel>;

const ConcealedRadio = ({ value, selected }) =>
    <SwitchRadio type="radio" name="switch" checked={selected === value} />;

export default class ToggleSwitch extends Component {
    state = { selected: this.props.selected };

    handleChange = val => {
        this.setState({ selected: val });
    };

    selectionStyle = () => {
        return {
            left: `${this.props.values.indexOf(this.state.selected) / 3 * 100}%`,
        };
    };

    render() {
        const { selected } = this.state;
        return (
            <Switch>
                {this.props.values.map(val => {
                    return (
                        <span>
                            <ConcealedRadio value={val} selected={selected} />
                            <ClickableLabel title={val} onChange={this.handleChange} />
                        </span>
                    );
                })}
                <SwitchSelection style={this.selectionStyle()} />
            </Switch>
        );
    }
}

