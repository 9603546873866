
// ticker(sortable) name(sortable) %ofPortofoilio(sortable) activityBuyOrSellOrNoChange buyPrice(unsortable) buyPriceVsCurrentPriceChange(sortable) yourValuation(IF DNE put add button circular with light green background huh green border on hover fill in the light breen textcolor is filledinlightgreen or white)

import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { useEffect, useContext, useRef, useState } from "react";
import FiberNewIcon from '@mui/icons-material/FiberNew';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import PieChartIcon from '@mui/icons-material/PieChart';
import PieChartOutlineIcon from '@mui/icons-material/PieChartOutline';
import DonutSmallIcon from '@mui/icons-material/DonutSmall';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import { useHistory, useLocation } from 'react-router-dom';
import TwitterIcon from '@mui/icons-material/Twitter';

export default function SuperinvestorLatestHoldings(props) {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories'); //init sort priceChange

    const [arr, setArr] = React.useState([]); //init sort priceChange
    const [valuationArr, setValuationArr] = React.useState([]); //init sort priceChange

    const history = useHistory();


    useEffect(() => {
        console.log('changed prop', props.holdingsArr)
        setArr(props.holdingsArr)

    }, [props.holdingsArr]);

    useEffect(async () => {
        console.log('array changed', arr)
        let updateArr = []
        let element = {}
        for await (element of arr) {
            let awaitVal = await getValuation(element.holding.ticker)
            updateArr.push(awaitVal)
        }
        setValuationArr(updateArr)

    }, [arr]);
    useEffect(async () => {
        console.log('valuationArr changed', valuationArr)


    }, [valuationArr]);

    let makeValuation = (ticker) => {
        console.log('ticker check super investor', ticker)
        history.push('/financials/' + ticker)
    }

    let getValuation = async (ticker) => {

        const jwtToken = localStorage.getItem('jwtToken')

        let url = 'https://obscure-meadow-68622.herokuapp.com/getUserValuationInfo/' + ticker + '/'

        try {
            const res = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + jwtToken

                }
            })
            const data = await res.json();

            console.log('valuation data', data)



            console.log('ELSE DNE selection CHECK')
            if (data.isMarketCap) {
                console.log('return valuation data', '$' + data.fairPrice + ' Market Cap')

                return '$' + data.fairPrice + ' Market Cap'
            }
            else if (!data.isMarketCap && data.status !== "DNE") {
                console.log('return valuation data', '$' + data.fairPrice + ' Per Share')

                return '$' + data.fairPrice + ' Per Share'
            }
            else {
                console.log('return valuation data', 'nothing')

                return ''
            }

        }
        catch {
            console.log('return valuation data', 'error')

            // exists
            return ''

        }

        // return ticker

    }

    return (
        <div>



            <div>
                {props.holdingsArr.map((element, index) => {
                    return (

                        <div className='holding-container'>
                            <div className='row' style={{ color: 'white' }}>
                                <div className='col-lg-4' style={{ alignSelf: 'center' }}>
                                    <div className='row'>
                                        <div className='col-6' id="tickerStyle">
                                            {element.holding.ticker === undefined ? null : <div>${element.holding.ticker}</div>}

                                        </div>
                                        <div className='col-6'>
                                            <div className='row'>
                                                <div className='col' style={{ color: 'gold' }}>

                                                    {element.holding.name}
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col'>

                                                    <PieChartIcon style={{ fill: 'yellow', display: 'inline', marginRight: '3px', marginBottom: '3px' }} />
                                                    {element.holdingPercentage.toFixed(2)}%
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                    <div className='row'>
                                        {element.change === 'new' ? <div className='new-label'>NEW</div> : null}
                                        {element.change === 'no change' ? <div className='nochange-label' style={{ fontStyle: 'italic' }}>No Change</div> : null}
                                        {element.change === 'sold' ? <div className='sold-label' style={{ fontStyle: 'italic' }}>SOLD</div> : null}
                                        {element.change < 0 ? <div className='negative-addition'>Reduced {element.change.toFixed(2)}% <ArrowCircleDownIcon style={{ fill: 'red' }} /> </div> : null}
                                        {element.change > 0 ? <div className='positive-addition'>Added {element.change.toFixed(2)}% <ArrowCircleUpIcon style={{ fill: 'green' }} /> </div> : null}

                                    </div>

                                </div>
                                <div className='col-lg-4' style={{ alignSelf: 'center' }}>
                                    {(Number.isNaN(element.buyPrice)) ? null :
                                        <div className='row align-items-center'>
                                            <div className='col-3'>
                                                Buy Price
                                                <br />
                                                ${element.buyPrice.toFixed(2)}
                                            </div>
                                            <div className='col-1'>
                                                <div className='arrow-style'>

                                                    <DoubleArrowIcon style={{ fill: '#0800ff' }} />
                                                </div>
                                            </div>

                                            <div className='col-3' style={{ margin: '0 auto' }}>
                                                <div className={((((element.price / element.buyPrice) - 1) * 100) >= 0) ? 'green-change' : 'red-change'}>

                                                    {/* {((((element.price / element.buyPrice) - 1) * 100) >= 0) ? <div>green</div> : <div>red</div>} */}
                                                    {Number.isNaN((((element.price / element.buyPrice) - 1) * 100)) ? <div style={{ backgroundColor: 'transparent' }}></div> : <div>{(((element.price / element.buyPrice) - 1) * 100).toFixed(2)}%</div>
                                                    }
                                                </div>
                                            </div>
                                            <div className='col-1'>
                                                <div className='arrow-style'>
                                                    <DoubleArrowIcon style={{ fill: '#0800ff' }} />
                                                </div>
                                            </div>

                                            <div className='col-3'>
                                                Current Price
                                                <br />
                                                {(element.price === undefined) ? null : <div>${element.price}</div>}

                                            </div>
                                        </div>
                                    }
                                </div>
                                {/* ACTIVITY CHANGE {element.change}  */}

                                <div className='col-lg-4' style={{ alignSelf: 'center' }}>
                                    <div className='row'>
                                        {valuationArr[index] === "" ? <div className='your-add-valuation' onClick={() => makeValuation(element.holding.ticker)}>
                                            make valuation
                                            <LibraryAddIcon style={{ fill: 'green', marginLeft: '3px', marginBottom: '3px' }} />
                                        </div> :
                                            <div className='your-valuation'>
                                                Your Valuation
                                                <hr />
                                                {/* {() => getValuation(element.holding.ticker)} */}
                                                {valuationArr[index]}
                                                {/* {element.yourValuation} */}
                                            </div>
                                        }
                                    </div>
                                    <div className='row'>
                                        <div className='share-twitter-btn'>
                                            share
                                            <TwitterIcon style={{ fill: 'rgb(32 155 240)', display: 'inline', marginLeft: '5px', marginBottom: '3px' }} />                                    </div>

                                    </div>
                                    <div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    )
                })}
            </div>


        </div>
    );
}

