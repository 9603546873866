import { useHistory, useLocation, useParams } from 'react-router-dom';

import { useEffect, useContext, useRef } from "react";



import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';

import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

import Button from 'react-bootstrap/Button';
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import TabContainer from 'react-bootstrap/TabContainer'
import TabContent from 'react-bootstrap/TabContent'

import TabPane from 'react-bootstrap/TabPane'

import TableStyles from './styles/tableStyles.css'

import Accordion from 'react-bootstrap/Accordion'

import Tickers from './tickers.json'
import Particles from "react-tsparticles";

import './App.css'


import React from "react";
import { useState } from "react";
import DCFInputs from "./DCFInputs"
import { Chart } from "chart.js";
import QuickRadar from "./QuickRadar";
import RatiosGraph from "./RatiosGraph";
import Overview from "./Overview";
import BalanceSheet from "./BalanceSheet";
import IncomeStatement from "./IncomeStatement";
import StatementOfCashFlows from "./StatementOfCashFlows";
import QuickStats from "./QuickStats";
import TVInfo from './TVInfo';
import TVChart from './TVChart';
import { CompanyProfile, SymbolInfo } from "react-ts-tradingview-widgets";

import tenk from './styles/tenk.png'
import tenq from './styles/tenq.png'

import Ratios from "./Ratios";

import { DCFContext } from './contexts/DCFContext'


import { Radar, Line, Bar } from "react-chartjs-2"
import WeightedDCF from './WeightedDCF';


const Financials = (props) => {

  const history = useHistory();
  const [preventReRenderTicker, setPreventReRenderTicker] = useState('')

  const [ticker, setTicker] = useState('')
  const [redirect, setRedirect] = useState('')
  // const [tickerEntry, setTickerEntry] = useState('')
  const [loadingStatus, setLoadingStatus] = useState(false)
  const [showFinancials, setShowFinancials] = useState(true)

  const [fetchedState, setFetchedState] = useState()
  const [bs, setBs] = useState([{}])
  const [cf, setCf] = useState([{}])
  const [is, setIs] = useState([{}])
  const [r, setR] = useState([{}])
  const [accordianPreview, setAccordianPreview] = useState(false)
  const [bs_firstMetic, setBs_firstMetic] = useState('')

  const { tickerContext, editTickerContext, showSuccessAlert, editSuccessAlert, showErrorAlert, editErrorAlert } = useContext(DCFContext)


  const { tickerEntry } = useParams()



  const getFinancials = async () => {



    // let tickerEntry = props.match.params.tickerEntry;
    // console.log("tickerEntry null test : " + this.props.match.params.tickerEntry)
    setPreventReRenderTicker(tickerEntry)

    editTickerContext(tickerEntry)


    if (tickerEntry === 'null' || tickerEntry === undefined || tickerEntry === "search" || tickerEntry === "" || tickerEntry === "/") {

    }
    else {

      setTicker(tickerEntry)
      setLoadingStatus(true)
      setShowFinancials(false)

      editTickerContext(tickerEntry)

      var url = "https://obscure-meadow-68622.herokuapp.com/getFinancials/" + tickerEntry;


      try {
        var response = await fetch(url);

        var fetchedResponse = await response.json();

        console.log('fetchedResponse check : ', fetchedResponse)

        setLoadingStatus(false)
        setShowFinancials(true)
        setFetchedState(fetchedResponse)
        setBs(fetchedResponse[1])
        setCf(fetchedResponse[2])
        setR(fetchedResponse[3])
        setIs(fetchedResponse[0])
        setAccordianPreview((fetchedResponse[0])[0].Year)
        setBs_firstMetic(fetchedResponse)

      }
      catch (error) {

        console.log("error: ", error)
        history.push('/error')
      }


    }


  }

  useEffect(() => {

    setTicker(tickerEntry)
    editTickerContext(tickerEntry)
    getFinancials();



  }, []);

  useEffect(() => {

    setTicker(tickerEntry)
    editTickerContext(tickerEntry)
    getFinancials();



  }, [tickerEntry]);


  useEffect(() => {
    console.log('r check', r)
    console.log('bs check', bs)

    console.log('cf check', cf)
    console.log('is check', is)

  }, [r, bs, cf, is]);



  const getEv = () => {

    var ev = 0;
    var evIndex = 0;
    for (var i = 0; i < r.length; i++) {
      if (r[i].Year === "Enterprise Value") {
        evIndex = i;

      }
    }

    let evObj = r[evIndex]

    let k;
    let latestEv = {}
    try {

      for (k of Object.keys(evObj)) {
        // 
        /*        k : key
         *   evObj[k] : value
         */

        if (evObj[k] === null || evObj[k] === "Enterprise Value") {
          continue;
        }
        else {

          //    latestEv = { this.key: evObj[k] }
          latestEv = { [k]: evObj[k] };

        }
      }
    }
    catch {

    }
    return latestEv
  }




  var value = ""
  var label = ""

  var valueLabel = {}

  var options = []

  let k;
  for (k in Tickers) {

    /*        k : key
     *   obj[k] : value
     */
    value = Tickers[k].ticker
    label = Tickers[k].ticker + " : " + Tickers[k].title

    valueLabel["value"] = value
    valueLabel["label"] = label
    options.push({ "value": value, "label": label })

    // options.push(valueLabel)
  }

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 5,
  });




  const _handleKeyDown = (e, v) => {
    if (e.key === 'Enter') {
      history.push('/financials/' + ticker)
      // window.location.reload()
    }
  }


  const handleAutocompleteInputChange = (e, v) => {
    setTicker(v)
  }


  const handleAutoCompleteChange = (event, value) => {


    try {

      console.log('value.value check ', value.value)

      setTicker(value.value)

      history.push('/financials/' + value.value)
      // window.location.reload()

    }
    catch {

    }
    //this.handleRetriveFinancials(true)
  }



  return (



    (tickerEntry === 'null' || tickerEntry === undefined || tickerEntry === "search" || tickerEntry === "" || tickerEntry === "/") ?

      <div class='baseDiv'  >
        <div class="particlesContainer">
          <Particles
            style={{ "z-index": 1 }}
            id="tsparticles"
            // init={particlesInit}
            // loaded={particlesLoaded}
            options={{
              background: {
                color: {
                  value: "transparent",
                },
              },
              fpsLimit: 60,
              interactivity: {
                detectsOn: "canvas",
                events: {


                  resize: true
                },
                modes: {
                  bubble: {
                    distance: 400,
                    duration: 2,
                    opacity: 0.2,
                    size: 30,
                  },
                  push: {
                    quantity: 4,
                  },
                  repulse: {
                    distance: 200,
                    duration: 0.4,
                  },
                },
              },
              particles: {
                color: {
                  value: "rgba(255, 228, 255, 0)",
                  opacity: 0.05
                },
                links: {
                  color: "#ffffff",
                  distance: 150,
                  enable: true,
                  opacity: 0.04,
                  width: 1,
                },
                collisions: {
                  enable: false,
                },
                move: {
                  direction: "none",
                  enable: true,
                  outMode: "bounce",
                  random: false,
                  speed: 2,
                  straight: false,
                },
                number: {
                  density: {
                    enable: true,
                    value_area: 800,
                  },
                  value: 35,
                },
                opacity: {
                  value: 0.1,
                },
                shape: {
                  type: "circle",
                },
                size: {
                  random: true,
                  value: 8,
                },
              },
              detectRetina: true,
            }}
          />
        </div>

        <div class="row">
          <div class="col">

            <h1 class="baseHeader">
              Search for a company by entering their ticker below.
            </h1>
          </div>
        </div>

        <div class="outer">
          <div class="circle"></div>


          <div class="row">
            <div class="col">

              <div class="baseAutocompleteContainer" style={{ marginTop: '45%' }}>

                <Autocomplete

                  onKeyDown={(event, value) =>
                    _handleKeyDown(event, value)
                  }
                  onInputChange={e => handleAutocompleteInputChange(e, e.target.value.toString().toUpperCase())}

                  freeSolo
                  options={options}
                  getOptionLabel={(option) => option.label}
                  style={{
                    width: "50%", backgroundColor: '#c7daff57', zIndex: 9
                  }}

                  renderInput={(params) => <TextField {...params} label="Ticker : " variant="outlined" size="small" />}
                  filterOptions={filterOptions}

                  onChange={(event, value) =>
                    handleAutoCompleteChange(event, value)
                  }


                />

              </div>

            </div>
          </div>

        </div>
      </div>


      :


      <div className="financialsDiv">




        <div style={{ "z-index": "7" }} className={loadingStatus ? 'center-loader' : null}>
          <div className={loadingStatus ? 'pulse-container' : null}>
            <div className={loadingStatus ? 'pulse-bubble pulse-bubble-1' : null}></div>
            <div className={loadingStatus ? 'pulse-bubble pulse-bubble-2' : null}></div>
            <div className={loadingStatus ? 'pulse-bubble pulse-bubble-3' : null}></div>
          </div>
        </div>


        <div
          style={loadingStatus ? {
            filter: 'blur(2px)'

          } : undefined}
          className="financialsDiv"
        >

          <div className="ticker-entry" >



            <Autocomplete

              onKeyDown={(event, value) =>
                _handleKeyDown(event, value)
              }
              onInputChange={e => handleAutocompleteInputChange(e, e.target.value.toString().toUpperCase())}

              freeSolo
              options={options}
              getOptionLabel={(option) => option.label}
              style={{
                width: "33%", position: 'fixed', top: '0px', zIndex: 9, height: '50px'
              }}

              renderInput={(params) => <TextField {...params} label="Search new ticker : " variant="outlined" size="small" />}
              filterOptions={filterOptions}

              onChange={(event, value) =>
                handleAutoCompleteChange(event, value)
              }


            />


            <div id="fixedFinancials" style={{
              width: "33%", backgroundColor: '#c7daff57', position: 'fixed', top: '0px', zIndex: 9
            }}>

              {tickerEntry + ", "}
              <div style={{ display: 'inline', color: '#eaff00' }}>
                {Object.keys(getEv())[0]} EV: $
                {Math.round((getEv()[Object.keys(getEv())[0]]) / 1000000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} M
              </div>

              <a href={'https://www.sec.gov/cgi-bin/browse-edgar?action=getcompany&CIK=' + tickerEntry + '&type=10-k&dateb=&owner=exclude&count=40'} target="_blank" class='forms-container'>
                <img class="tenk-image" src={tenk}></img>
              </a>
              <a href={'https://www.sec.gov/cgi-bin/browse-edgar?action=getcompany&CIK=' + tickerEntry + '&type=10-q&dateb=&owner=exclude&count=40'} target="_blank" class='forms-container'>
                <img class="tenk-image" src={tenq}></img>
              </a>

            </div>





            <div class='row' style={{ marginBottom: '10px', marginTop: '50px' }}>
              <div class="col-lg-4" >
                <div class='row' style={{ marginBottom: '10px' }}>
                  <div class="card" >
                    <div class="container-scroll" style={{ overflow: 'scroll', height: '240px', marginBottom: '10px' }}>
                      {/* <TVInfo ticker={tickerEntry}></TVInfo> */}
                      <SymbolInfo symbol={preventReRenderTicker} colorTheme="dark" autosize isTransparent="true" width="100"></SymbolInfo>

                    </div>
                  </div>
                </div>
                <div class='row'>
                  <div class="card" >
                    <div class="container-scroll-vertical" style={{ overflow: 'scroll', height: '240px', marginBottom: '10px' }}>
                      <CompanyProfile colorTheme="dark" isTransparent="true" symbol={preventReRenderTicker} width="100%"></CompanyProfile>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-8" style={{
                overflow: 'scroll', height: '500px', marginTop: '10px'
              }}>
                <div class="card">
                  <div class="container" style={{ overflow: 'hidden', height: '500px' }}>
                    <TVChart ticker={tickerEntry}></TVChart>
                  </div>
                </div>
              </div>
            </div>


            <div class=" row bottom-buffer"  >
              <div class="col-lg" >
                <div class="card">
                  <div class="container">
                    <QuickStats r={r} is={is} cf={cf} bs={bs}></QuickStats>
                  </div>
                </div>
              </div>

              <div class="col-lg" id='mobile-margin'>
                <div class="card">
                  <div class="container">
                    <QuickRadar r={r} is={is}></QuickRadar>
                  </div>
                </div>

              </div>



            </div>
            <div class=" row bottom-buffer">
              <div class="col-lg" >
                <div class="container">
                  <Accordion defaultActiveKey="1" class="col-lg"  >
                    <Accordion.Item eventKey="0" class="col-lg"  >
                      <Accordion.Header class="col-lg"  >  Overview:  {accordianPreview} </Accordion.Header >
                      <Accordion.Body class="col-lg" id="ov" style={{ background: 'radial-gradient(transparent, black)', padding: '0' }} >

                        <Overview r={r} cf={cf} is={is} bs={bs} class="col-lg" style={{ backgroundColor: "blue" }}></Overview>

                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>

              </div>
            </div>

            <div class=" row bottom-buffer">
              <div class="col-lg">

                <div class="card">
                  <div class="container">
                    <div class="row">
                      <div class="col-lg" >

                        <RatiosGraph r={r}></RatiosGraph>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class=" row bottom-buffer">
              <div class="col-lg">
                <div class="container">
                  <Accordion defaultActiveKey="1" class="col-lg" >
                    <Accordion.Item eventKey="0" class="col-lg" >
                      <Accordion.Header class="col-lg" id="statements"
                      >Financial Statements  </Accordion.Header >
                      <Accordion.Body class="col-lg">

                        <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example" className="mb-3" >
                          <Tab eventKey="home" title="Statement of Cash Flows" style={{ background: 'radial-gradient(transparent, black)' }}>

                            <StatementOfCashFlows cf={cf}></StatementOfCashFlows>


                          </Tab>
                          <Tab eventKey="profile" title="Income Statement" style={{ background: 'radial-gradient(transparent, black)' }}>
                            <IncomeStatement is={is}></IncomeStatement>
                          </Tab>
                          <Tab eventKey="contact" title="Balance Sheet" style={{ background: 'radial-gradient(transparent, black)' }}>
                            <BalanceSheet bs={bs}></BalanceSheet>
                          </Tab>
                          <Tab eventKey="ratio" title="Ratios" style={{ background: 'radial-gradient(transparent, black)' }}>
                            <Ratios r={r}></Ratios>
                          </Tab>
                          {/* <Tab eventKey="contact" title="Contact" disabled>
                              </Tab> */}
                        </Tabs>

                      </Accordion.Body>
                    </Accordion.Item>

                  </Accordion>
                </div>
              </div>
            </div>

            <div class=" row bottom-buffer">
              <div class="col-lg">

                <div class="card">
                  <div class="container">

                    {/* <DCFInputs dcfCase='normal' tickerEntry={props.match.params.tickerEntry}
                      netIncome={is}></DCFInputs>

                    <DCFInputs dcfCase='bull' tickerEntry={props.match.params.tickerEntry}
                      netIncome={is}></DCFInputs>


                    <DCFInputs dcfCase='bear' tickerEntry={props.match.params.tickerEntry}
                      netIncome={is}></DCFInputs> */}

                    <WeightedDCF tickerEntry={tickerEntry} netIncome={is}></WeightedDCF>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>



      </div>


  )

}

export default Financials


// //Overview tab info:

// // collapsable: description and industry from a free api?

// // current market cap, currentassets-liabilities, EV, 
// // revenue/rev growth, net income/NI growth, FCF/fcf growth
// // quick valuation ratios in a little box like quickfs.net (PE,PB,PS,P/FCF,EV/Ebitda,EV/FCF), median roic, median roe, 10 yr FCF CAGR, 10 yr EPS CAGR, 10yr REVENUE CAGR
// // historical PE,PB,PS,PFCF in chart (median line in chart)
// // Radar chart over time : roic,roe,roa, 1/DtoE, cagr return, 
// // margins over time

// // for an async onclick function that is used to fetch, need to render like this: <input type="button" value="retrive" onClick={e => this.handleRetriveFinancials(e)} />

// // Other metrics 
// // hidtorical pb,ps

// import { createFilterOptions } from '@material-ui/lab/Autocomplete';
// import { Autocomplete } from '@material-ui/lab';
// import { TextField } from '@material-ui/core';

// import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

// import Button from 'react-bootstrap/Button';
// import Tab from 'react-bootstrap/Tab'
// import Tabs from 'react-bootstrap/Tabs'
// import TabContainer from 'react-bootstrap/TabContainer'
// import TabContent from 'react-bootstrap/TabContent'

// import TabPane from 'react-bootstrap/TabPane'

// import TableStyles from './styles/tableStyles.css'

// import Accordion from 'react-bootstrap/Accordion'

// import Tickers from './tickers.json'
// import Particles from "react-tsparticles";

// import './App.css'


// import React from "react";
// import { useState } from "react";
// import DCFInputs from "./DCFInputs"
// import { Chart } from "chart.js";
// import QuickRadar from "./QuickRadar";
// import RatiosGraph from "./RatiosGraph";
// import Overview from "./Overview";
// import BalanceSheet from "./BalanceSheet";
// import IncomeStatement from "./IncomeStatement";
// import StatementOfCashFlows from "./StatementOfCashFlows";
// import QuickStats from "./QuickStats";
// import TVInfo from './TVInfo';
// import TVChart from './TVChart';


// import Ratios from "./Ratios";



// import { Radar, Line, Bar } from "react-chartjs-2"

// export default class Financials extends React.Component {

//   constructor(props) {
//     super(props);
//     this.state = {
//       test: "",
//       redirect: this.props.match.params.tickerEntry,
//       redirectTicker: this.props.match.params.tickerEntry,
//       tickerEntry: null,
//       ticker: this.props.match.params.tickerEntry,
//       prevTicker: '',
//       showFinancials: false,
//       showOV: true,
//       showbs: false,
//       showcf: false,
//       showis: false,
//       showKMAM: false,
//       loadingStatus: false,
//       fetchedState: {},
//       accordianPreview: "Loading...",
//       bs: [],
//       cf: [],
//       r: [],
//       is: [],
//       cagr: 0,
//       bs_firstMetic: '',
//       chartType: "line",
//       PEChartData: {
//         labels: ['Boston', 'Toronto', 'New York City', 'San Fransisco'],
//         datasets: [
//           {
//             label: 'population',
//             data: [
//               617000,
//               3000000,
//               5000000,
//               1000000
//             ],
//             backgroundColor: [
//               'rgba(75,192,192,0.6)',
//               'rgba(75,192,192,0.6)',
//               'rgba(75,192,192,0.6)'
//               , 'rgba(75,192,192,0.6)'
//             ]
//           }
//         ]
//       }

//     }
//   }

//   async componentDidMount() {




//     let tickerEntry = this.props.match.params.tickerEntry;
//     // console.log("tickerEntry null test : " + this.props.match.params.tickerEntry)
//     if (tickerEntry === 'null' || tickerEntry === undefined || tickerEntry === "search" || tickerEntry === "" || tickerEntry === "/") {
//       this.setState({
//         redirect: '',
//         ticker: '',
//         tickerEntry: '',
//         loadingStatus: false,
//         showFinancials: true

//       });



//       return <div>
//         base1
//       </div>
//     }
//     else {
//       this.setState({
//         ticker: tickerEntry,
//         tickerEntry: tickerEntry,
//         loadingStatus: true,
//         showFinancials: false

//       });

//       var url = "https://obscure-meadow-68622.herokuapp.com/getFinancials/" + this.state.ticker;


//       try {
//         var response = await fetch(url);

//         var fetchedResponse = await response.json();
//       }
//       catch (error) {
//         // alert('request error!')

//         console.log("error: ", error)
//       }

//       this.setState({
//         loadingStatus: false,
//         showFinancials: true,
//         fetchedState: fetchedResponse,
//         bs: fetchedResponse[1],
//         cf: fetchedResponse[2],
//         r: fetchedResponse[3],
//         is: fetchedResponse[0],
//         accordianPreview: (fetchedResponse[0])[0].Year,


//         bs_firstMetic: fetchedResponse,
//         loadingStatus: false
//       });

//     }

//   }
//   showBalanceSheet = (bool) => {
//     this.setState({
//       showbs: bool,
//       showcf: false,
//       showOV: false,
//       showis: false,
//       showKMAM: false
//     });

//   }
//   showStatementOfCashFlows = (bool) => {
//     this.setState({
//       showcf: bool,
//       showbs: false,
//       showOV: false,
//       showis: false,
//       showKMAM: false
//     });


//   }
//   showIncomeStatement = (bool) => {
//     this.setState({
//       showis: bool,
//       showOV: false,
//       showbs: false,
//       showcf: false,
//       showKMAM: false
//     });
//   }
//   showKeyMetricsAndMargins = (bool) => {
//     this.setState({
//       showKMAM: true,
//       showis: false,
//       showOV: false,
//       showbs: false,
//       showcf: false
//     });
//   }
//   showOverview = (bool) => {
//     this.setState({
//       showOV: bool,
//       showis: false,
//       showbs: false,
//       showcf: false,
//       showKMAM: false
//     });
//   }
//   _showFinancials = (bool) => {
//     this.setState({
//       showFinancials: bool
//     });
//   }
//   handleTickerChanged(event) {

//     this.setState({
//       ticker: event.target.value
//     });
//     // this.setState({ tik: tik });

//   }


//   async handleRetriveFinancialsNew(bool) {



//     this.setState({
//       showFinancials: bool,
//       loadingStatus: true
//     });

//     var url = "https://obscure-meadow-68622.herokuapp.com/getFinancials/" + this.state.ticker;

//     //        var url = "https://finnhub.io/api/v1/stock/financials-reported?symbol=aapl&token=c3r0s8aad3i98m4ienk0"


//     try {
//       var response = await fetch(url);

//       var fetchedResponse = await response.json();
//     }
//     catch (error) {
//       // alert('request error!')

//       console.log("error: ", error)
//     }

//     this.setState({

//       fetchedState: fetchedResponse,
//       bs: fetchedResponse[1],
//       cf: fetchedResponse[2],
//       r: fetchedResponse[3],
//       is: fetchedResponse[0],
//       accordianPreview: (fetchedResponse[0])[0].Year,

//       bs_firstMetic: fetchedResponse,
//       loadingStatus: false
//       //sData_bs.length , sData_bs[0 to i][2020 to j backwards [currentYear-- until currentYear-sData_bs[i].length] {first one always "":""}]
//     });


//   }

//   async handleRetriveFinancials(bool) {

//     if (this.state.ticker === undefined) {

//     }
//     else {
//       this.setState({
//         redirect: "/financials/" + this.state.ticker
//       });
//     }
//     this.setState({
//       showFinancials: bool,
//       loadingStatus: true
//     });

//     var url = "https://obscure-meadow-68622.herokuapp.com/getFinancials/" + this.state.ticker;



//     try {
//       var response = await fetch(url);

//       var fetchedResponse = await response.json();
//     }
//     catch (error) {
//       // alert('request error!')

//       console.log("error: ", error)
//     }

//     this.setState({

//       fetchedState: fetchedResponse,
//       bs: fetchedResponse[1],
//       cf: fetchedResponse[2],
//       r: fetchedResponse[3],
//       is: fetchedResponse[0],
//       accordianPreview: (fetchedResponse[0])[0].Year,

//       //bs: fetchedResponse.sData_bs._results[0],
//       //cf: fetchedResponse.sData_cf._results[0],
//       //r: fetchedResponse.sData_r._results[0],
//       //is: fetchedResponse.sData_is._results[0],
//       //accordianPreview:(fetchedResponse.sData_is._results[0])[0].Year,

//       //working:      bs_firstMetic: fetchedResponse.sData_bs[0][2010]

//       bs_firstMetic: fetchedResponse,
//       loadingStatus: false
//       //sData_bs.length , sData_bs[0 to i][2020 to j backwards [currentYear-- until currentYear-sData_bs[i].length] {first one always "":""}]
//     });
//     //net income , add everything you want to show heer
//     //      netIncome: data.data[0].report.cf[0].value


//   }



//   async handleRetriveFinancialsEnter(bool) {


//     if (this.state.redirect === undefined) {
//       this.setState({
//         redirect: "/financials/" + this.state.ticker
//       });
//     }

//     this.setState({
//       showFinancials: bool,
//       loadingStatus: true
//     });

//     var url = "https://obscure-meadow-68622.herokuapp.com/getFinancials/" + this.state.ticker;

//     //        var url = "https://finnhub.io/api/v1/stock/financials-reported?symbol=aapl&token=c3r0s8aad3i98m4ienk0"


//     try {
//       var response = await fetch(url);

//       var fetchedResponse = await response.json();
//     }
//     catch (error) {
//       // alert('request error!')

//       console.log("error: ", error)
//     }

//     this.setState({

//       fetchedState: fetchedResponse,
//       bs: fetchedResponse[1],
//       cf: fetchedResponse[2],
//       r: fetchedResponse[3],
//       is: fetchedResponse[0],
//       accordianPreview: (fetchedResponse[0])[0].Year,

//       //bs: fetchedResponse.sData_bs._results[0],
//       //cf: fetchedResponse.sData_cf._results[0],
//       //r: fetchedResponse.sData_r._results[0],
//       //is: fetchedResponse.sData_is._results[0],
//       //accordianPreview:(fetchedResponse.sData_is._results[0])[0].Year,

//       //working:      bs_firstMetic: fetchedResponse.sData_bs[0][2010]

//       bs_firstMetic: fetchedResponse,
//       loadingStatus: false
//       //sData_bs.length , sData_bs[0 to i][2020 to j backwards [currentYear-- until currentYear-sData_bs[i].length] {first one always "":""}]
//     });
//     //net income , add everything you want to show heer
//     //      netIncome: data.data[0].report.cf[0].value


//   }
//   handleAutocompleteInputChange = (e, v) => {

//     this.setState({
//       ticker: v,
//     });



//     // this.setState({
//     //   //      ticker: v
//     // })
//     console.log(v)
//   }
//   _handleKeyDown = (e, v) => {



//     if (e.key === 'Enter') {


//       try {
//         this.setState({
//           redirect: "/financials/" + this.state.ticker,
//           tickerEntry: this.state.ticker,
//         })
//         this.handleRetriveFinancialsNew(true)


//         //   this.setState({
//         //                     redirect: "/financials/" + this.state.ticker,
//         //     tickerEntry: this.state.ticker,
//         //     test: "value.value"

//         //   // }, 

//         //this.handleRetriveFinancials(event, true)

//       }
//       catch {

//       }


//     }
//   }



//   handleAutoCompleteChange = (event, value) => {


//     try {
//       this.setState({
//         ticker: value.value
//       }, () => {
//         this.handleRetriveFinancials(event, true)
//       });
//       //this.handleRetriveFinancials(event, true)

//     }
//     catch {

//     }
//     //this.handleRetriveFinancials(true)
//   }

//   getEv() {

//     var ev = 0;
//     var evIndex = 0;
//     for (var i = 0; i < this.state.r.length; i++) {
//       if (this.state.r[i].Year === "Enterprise Value") {
//         evIndex = i;

//       }
//     }

//     let evObj = this.state.r[evIndex]

//     let k;
//     let latestEv = {}
//     try {

//       for (k of Object.keys(evObj)) {
//         // 
//         /*        k : key
//          *   evObj[k] : value
//          */

//         if (evObj[k] === null || evObj[k] === "Enterprise Value") {
//           continue;
//         }
//         else {

//           //    latestEv = { this.key: evObj[k] }
//           latestEv = { [k]: evObj[k] };

//         }
//       }
//     }
//     catch {

//     }
//     return latestEv
//   }

//   render() {


//     var value = ""
//     var label = ""

//     var valueLabel = {}

//     var options = []

//     let k;
//     for (k in Tickers) {

//       /*        k : key
//        *   obj[k] : value
//        */
//       value = Tickers[k].ticker
//       label = Tickers[k].ticker + " : " + Tickers[k].title

//       valueLabel["value"] = value
//       valueLabel["label"] = label
//       options.push({ "value": value, "label": label })

//       // options.push(valueLabel)
//     }

//     const filterOptions = createFilterOptions({
//       matchFrom: 'any',
//       limit: 5,
//     });




//     //bad url
//     if (this.state.redirect === undefined || this.state.redirect === "" || this.state.redirect === "/financials/search" || this.state.redirect === "/search" || this.state.redirect === null) {
//       return <div class="baseDiv">




//         <div class="particlesContainer">
//           <Particles
//             style={{ "z-index": 1 }}
//             id="tsparticles"
//             init={this.particlesInit}
//             loaded={this.particlesLoaded}
//             options={{
//               background: {
//                 color: {
//                   value: "transparent",
//                 },
//               },
//               fpsLimit: 60,
//               interactivity: {
//                 detectsOn: "canvas",
//                 events: {


//                   resize: true
//                 },
//                 modes: {
//                   bubble: {
//                     distance: 400,
//                     duration: 2,
//                     opacity: 0.2,
//                     size: 30,
//                   },
//                   push: {
//                     quantity: 4,
//                   },
//                   repulse: {
//                     distance: 200,
//                     duration: 0.4,
//                   },
//                 },
//               },
//               particles: {
//                 color: {
//                   value: "rgba(255, 228, 255, 0)",
//                   opacity: 0.05
//                 },
//                 links: {
//                   color: "#ffffff",
//                   distance: 150,
//                   enable: true,
//                   opacity: 0.04,
//                   width: 1,
//                 },
//                 collisions: {
//                   enable: false,
//                 },
//                 move: {
//                   direction: "none",
//                   enable: true,
//                   outMode: "bounce",
//                   random: false,
//                   speed: 2,
//                   straight: false,
//                 },
//                 number: {
//                   density: {
//                     enable: true,
//                     value_area: 800,
//                   },
//                   value: 35,
//                 },
//                 opacity: {
//                   value: 0.1,
//                 },
//                 shape: {
//                   type: "circle",
//                 },
//                 size: {
//                   random: true,
//                   value: 8,
//                 },
//               },
//               detectRetina: true,
//             }}
//           />
//         </div>

//         <div class="row">
//           <div class="col">

//             <h1 class="baseHeader">
//               Enter a company ticker to start your research and make valuations.
//             </h1>
//           </div>
//         </div>

//         <div class="outer">
//           <div class="circle"></div>


//           <div class="row">
//             <div class="col">

//               <div class="baseAutocompleteContainer">
//                 <Autocomplete

//                   onKeyDown={(event, value) =>
//                     this._handleKeyDown(event, value)
//                   }
//                   onInputChange={e => this.handleAutocompleteInputChange(e, e.target.value.toString().toUpperCase())}

//                   freeSolo
//                   options={options}
//                   getOptionLabel={(option) => option.label}
//                   style={{
//                     width: "50%", backgroundColor: '#c7daff57'
//                   }}

//                   renderInput={(params) => <TextField {...params} label="Ticker : " variant="outlined" size="small" />}
//                   filterOptions={filterOptions}

//                   onChange={(event, value) =>
//                     this.handleAutoCompleteChange(event, value)
//                   }

//                 //                    onChange=    {this.handleAutoCompleteChange(event, value)}

//                 />

//               </div>

//             </div>
//           </div>

//         </div>
//       </div>

//     }


//     //good url
//     else {


//       return <div><Redirect push to={this.state.redirect} />

//         {console.log('this.state.redirect checking: ', this.state.redirect)}
//         {console.log('this.state.tickerEntry checking: ', this.state.tickerEntry)}

//         <div className="financialsDiv">



//           {this.state.accordianPreview !== "Loading..." && (

//             <div className="financialsDiv">




//               <div style={{ "z-index": "7" }} className={this.state.loadingStatus ? 'center-loader' : null}>
//                 <div className={this.state.loadingStatus ? 'pulse-container' : null}>
//                   <div className={this.state.loadingStatus ? 'pulse-bubble pulse-bubble-1' : null}></div>
//                   <div className={this.state.loadingStatus ? 'pulse-bubble pulse-bubble-2' : null}></div>
//                   <div className={this.state.loadingStatus ? 'pulse-bubble pulse-bubble-3' : null}></div>
//                 </div>
//               </div>


//               <div
//                 style={this.state.loadingStatus ? {
//                   filter: 'blur(2px)'

//                 } : undefined}
//                 className="financialsDiv"
//               >

//                 <div className="ticker-entry" >



//                   <Autocomplete

//                     onKeyDown={(event, value) =>
//                       this._handleKeyDown(event, value)
//                     }
//                     onInputChange={e => this.handleAutocompleteInputChange(e, e.target.value.toString().toUpperCase())}

//                     freeSolo
//                     options={options}
//                     getOptionLabel={(option) => option.label}
//                     style={{
//                       width: "33%", backgroundColor: '#c7daff57', position: 'fixed', top: '0px', zIndex: 9
//                     }}

//                     renderInput={(params) => <TextField {...params} label="Ticker : " variant="outlined" size="small" />}
//                     filterOptions={filterOptions}

//                     onChange={(event, value) =>
//                       this.handleAutoCompleteChange(event, value)
//                     }

//                   //                    onChange=    {this.handleAutoCompleteChange(event, value)}

//                   />
//                   <div id="fixedFinancials" style={{
//                     width: "33%", backgroundColor: '#c7daff57', position: 'fixed', top: '0px', zIndex: 9
//                   }}>

//                     {(this.state.redirect.split("/"))[2] + ", "}
//                     <div style={{ display: 'inline', color: '#eaff00' }}>
//                       {Object.keys(this.getEv())[0]} EV: $
//                       {Math.round((this.getEv()[Object.keys(this.getEv())[0]]) / 1000000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} M
//                     </div>
//                   </div>

//                 </div>




//                 {/* <div class='row'>
//                   <div class="col-lg-4" >
//                     <div class="card">
//                       <div class="container" style={{ overflow: 'scroll' }}>
//                         <TVInfo ticker={this.state.ticker}></TVInfo>
//                       </div>
//                     </div>
//                   </div>

//                   <div class="col-lg-8" >
//                     <div class="card">
//                       <div class="container" style={{ overflow: 'scroll' }}>
//                         <TVChart ticker={this.state.ticker}></TVChart>
//                       </div>
//                     </div>
//                   </div>
//                 </div> */}


//                 <div class=" row bottom-buffer">
//                   <div class="col-lg" >
//                     <div class="card">
//                       <div class="container">
//                         <QuickStats r={this.state.r} is={this.state.is} cf={this.state.cf} bs={this.state.bs}></QuickStats>
//                       </div>
//                     </div>
//                   </div>

//                   <div class="col-lg" >
//                     <div class="card">
//                       <div class="container">
//                         <QuickRadar r={this.state.r} is={this.state.is}></QuickRadar>
//                       </div>
//                     </div>

//                   </div>



//                 </div>
//                 <div class=" row bottom-buffer">
//                   <div class="col-lg" >
//                     <div class="container">
//                       <Accordion defaultActiveKey="1" class="col-lg"  >
//                         <Accordion.Item eventKey="0" class="col-lg"  >
//                           <Accordion.Header class="col-lg"  >  Overview:  {this.state.accordianPreview} </Accordion.Header >
//                           <Accordion.Body class="col-lg" id="ov"  >

//                             <Overview r={this.state.r} cf={this.state.cf} is={this.state.is} bs={this.state.bs} class="col-lg" style={{ backgroundColor: "blue" }}></Overview>

//                           </Accordion.Body>
//                         </Accordion.Item>
//                       </Accordion>
//                     </div>

//                   </div>
//                 </div>

//                 <div class=" row bottom-buffer">
//                   <div class="col-lg">

//                     <div class="card">
//                       <div class="container">
//                         <div class="row">
//                           <div class="col-lg" >

//                             <RatiosGraph r={this.state.r}></RatiosGraph>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>

//                 <div class=" row bottom-buffer">
//                   <div class="col-lg">
//                     <div class="container">
//                       <Accordion defaultActiveKey="1" class="col-lg" >
//                         <Accordion.Item eventKey="0" class="col-lg" >
//                           <Accordion.Header class="col-lg" id="statements"
//                           >Financial Statemends</Accordion.Header >
//                           <Accordion.Body class="col-lg">

//                             <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example" className="mb-3" >
//                               <Tab eventKey="home" title="Statement of Cash Flows">

//                                 <StatementOfCashFlows cf={this.state.cf}></StatementOfCashFlows>


//                               </Tab>
//                               <Tab eventKey="profile" title="Income Statement">
//                                 <IncomeStatement is={this.state.is}></IncomeStatement>
//                               </Tab>
//                               <Tab eventKey="contact" title="Balance Sheet" >
//                                 <BalanceSheet bs={this.state.bs}></BalanceSheet>
//                               </Tab>
//                               <Tab eventKey="ratio" title="Ratios" >
//                                 <Ratios r={this.state.r}></Ratios>
//                               </Tab>
//                               {/* <Tab eventKey="contact" title="Contact" disabled>
//                               </Tab> */}
//                             </Tabs>

//                           </Accordion.Body>
//                         </Accordion.Item>

//                       </Accordion>
//                     </div>
//                   </div>
//                 </div>

//                 <div class=" row bottom-buffer">
//                   <div class="col-lg">

//                     <div class="card">
//                       <div class="container">

//                         <DCFInputs netIncome={this.state.is}></DCFInputs>
//                       </div>
//                     </div>
//                   </div>
//                 </div>

//                 <div class=" row bottom-buffer">



//                 </div>
//               </div>

//             </div>



//           )}


//           {this.state.accordianPreview === "Loading..." && (


//             <div className="financialsDiv">


//               <div style={{ "z-index": "7" }} className={this.state.loadingStatus ? 'center-loader' : null}>
//                 <div className={this.state.loadingStatus ? 'pulse-container' : null}>
//                   <div className={this.state.loadingStatus ? 'pulse-bubble pulse-bubble-1' : null}></div>
//                   <div className={this.state.loadingStatus ? 'pulse-bubble pulse-bubble-2' : null}></div>
//                   <div className={this.state.loadingStatus ? 'pulse-bubble pulse-bubble-3' : null}></div>
//                 </div>
//               </div>




//               <div
//                 style={this.state.loadingStatus ? {
//                   filter: 'blur(2px)'

//                 } : undefined}
//                 className="financialsDiv"
//               >

//                 <RatiosGraph r={this.state.r}></RatiosGraph>


//               </div>

//             </div>
//           )}
//         </div>







//         );




//       </div>

//     }

//   }
// }
