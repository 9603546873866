import Main from './styles/main.css'
import Util from './styles/util.css'

import React, { useState, useEffect, useContext, useRef } from "react";

import Financials from "./Financials";

import { Radar, Line, Bar } from "react-chartjs-2"

import BarChartIcon from '@mui/icons-material/BarChart';
import Chart from './Chart'
//OFFICIAL metric names 
//CF : Free Cash Flow	, Free Cash Flow Growth, Free Cash Flow Per Share
//IS : Revenue, Net Income, Revenue Growth, Shares Outstanding (Basic), Gross Margin, Operating Margin, Profit Margin, FCF Margin, EPS Growth, EPS (Diluted), Dividend Per Share
//R  : Market Capitalization (this Year), PE Ratio, PS Ratio, PB Ratio, P/FCF Ratio, EV/EBITDA Ratio, EV/FCF Ratio, Return on Capital (ROIC), Return on Equity (ROE), Return on Assets (ROA), Debt / Equity Ratio [inverse for radar], Market Capitalization (cagr for radar)
//BS : Total Current Assets	- Total Liabilities

// Radar chart over time : roic,roe,roa, 1/DtoE, cagr return, margin growth over time, management, employee sentiment
export default class IncomeStatement extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

            is: props.is,
            test: "testing"
        };
    }

    getKeys = function () {
        try {
            return Object.keys(this.props.is[0]);
        }
        catch {

        }
    }

    getHeader = function () {
        try {

            var keys = this.getKeys();



            var currentYear = parseInt(keys[keys.length - 2]);

            return keys.map((key, index) => {



                // start year : Object.keys(this.state.is[0])[0]
                //Object.keys(this.state.is[0])[0]

                //var currentYear = 2021;
                //var currentYear = Object.keys(props.is[0])[(props.is.length)]

                var firstYear = currentYear - (keys.length - 2)

                if (key === firstYear.toString()) {
                    return <th >Financials/Years</th>

                }
                else if (key === 'Year') {
                    return <th >{currentYear}</th>

                }
                else {
                    //                    var newKey = parseInt(key) - 1

                    return <th >{key - 1}</th>

                }



            })


        }
        catch {

        }
    }

    getRowsData = function () {

        var newItems = this.props.is;

        console.log('newItems is check : ', newItems)


        var isItems = [];

        for (var i = 0; i < newItems.length; i++) {
            if (newItems[i].Year === "Revenue Growth") {
                break
            }
            else {
                isItems.push(newItems[i])
            }

        }
        console.log('isItems is check : ', isItems)


        var keys = this.getKeys();
        return isItems.map((row, index) => {
            return <tr class="row100 head"><RenderRow key={index} data={row} keys={keys} /></tr>
        })
    }

    render() {

        console.log('this.props.is check ', this.props.is)

        return (
            < div class="container-table100" class="col-lg" >

                <div class="wrap-table100" class="col-lg">
                    <div class="table100 ver6 m-b-110">
                        <table data-vertable="ver6">
                            <thead>
                                <tr class="row100 head">{this.getHeader()}</tr>
                            </thead>
                            <tbody>
                                {this.getRowsData()}

                                {/* {this.getRowsData()} */}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div >
        )
    }
}



const RenderRow = (props) => {

    var currentYear = parseInt(props.keys[props.keys.length - 2]);
    const [showChart, setShowChart] = useState(false);


    if (props.data['Year'] === "EBIT Margin"
        || props.data['Year'] === "EBIT"
        || props.data['Year'] === "EBITDA Margin"
        || props.data['Year'] === "EBITDA"
        || props.data['Year'] === "Effective Tax Rate"
        || props.data['Year'] === "Free Cash Flow Margin"
        || props.data['Year'] === "Profit Margin"
        || props.data['Year'] === "Operating Margin"
        || props.data['Year'] === "Gross Margin"
        || props.data['Year'] === "Dividend Growth"
        || props.data['Year'] === "Dividend Per Share"
        || props.data['Year'] === "Free Cash Flow Per Share"
        || props.data['Year'] === "EPS Growth"
        || props.data['Year'] === "EPS (Diluted)"
        || props.data['Year'] === "EPS (Basic)"
        || props.data['Year'] === "Shares Outstanding (Basic)"
        || props.data['Year'] === "Shares Change"
        || props.data['Year'] === "Shares Outstanding (Diluted)"
        || props.data['Year'] === "Net Income Growth"
        || props.data['Year'] === "Net Income Common"
    ) {
        return null
    }
    else {

        try {


            //var currentYear = 2021

            var firstYear = currentYear - (props.keys.length - 2);

            return props.keys.map((key, index) => {
                if (key === firstYear.toString()) {
                    //render first key (first key being 2011)
                    if (
                        props.data['Year'] === "Gross Profit"
                        || props.data['Year'] === "Revenue"
                        || props.data['Year'] === "Operating Income"
                        || props.data['Year'] === "Pretax Income"
                        || props.data['Year'] === "Net Income"
                    ) { //bolden line items
                        return <th class={"lineItem"}>
                            {props.data['Year']}
                            <div style={{ display: 'inline', height: 'fit-content' }} onMouseOver={() => setShowChart(true)} onMouseLeave={() => setShowChart(false)} >
                                <BarChartIcon class="chart-icon"></BarChartIcon>
                                {showChart ? <Chart metric={props.data['Year']} chartData={props.data}></Chart> : null}
                            </div>
                        </th>

                    }
                    else {
                        return <td >
                            {props.data['Year']}
                            <div style={{ display: 'inline', height: 'fit-content' }} onMouseOver={() => setShowChart(true)} onMouseLeave={() => setShowChart(false)}>
                                <BarChartIcon class="chart-icon"></BarChartIcon>
                                {showChart ? <Chart metric={props.data['Year']} chartData={props.data}></Chart> : null}
                            </div>
                        </td>
                    }
                }
                else if (key === 'Year') {
                    //render second last key (second last key being currentYear)
                    if (
                        props.data['Year'] === "Gross Profit"
                        || props.data['Year'] === "Revenue"
                        || props.data['Year'] === "Operating Income"
                        || props.data['Year'] === "Pretax Income"
                        || props.data['Year'] === "Net Income"
                    ) { //bolden line items
                        return <th class={"lineItem"}>{(Math.round(parseInt(props.data[currentYear]) / 1000000)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</th>

                    }
                    else {
                        return <td >{Math.round(parseInt(props.data[currentYear]) / 1000000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>

                    }
                }

                else {
                    var newKey = parseInt(key) - 1;

                    if (
                        props.data['Year'] === "Gross Profit"
                        || props.data['Year'] === "Revenue"
                        || props.data['Year'] === "Operating Income"
                        || props.data['Year'] === "Pretax Income"
                        || props.data['Year'] === "Net Income"
                    ) { //bolden line items
                        return <th class={"lineItem"}>{Math.round(parseInt(props.data[newKey]) / 1000000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</th>

                    }
                    else {
                        return <td >{Math.round(parseInt(props.data[newKey]) / 1000000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                        //return <td >5</td>

                    }
                }
            })
        }
        catch {
            return null
        }
    }
}









