import React, { useState, useEffect, useContext } from "react";

import { useHistory } from 'react-router-dom';
import { RouteContext } from './contexts/RouteContext'

import register from './styles/register.png'
import MyLeaderBoardAd from './MyLeaderBoardAd';
import InputGroup from 'react-bootstrap/InputGroup'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import FormControl from 'react-bootstrap/FormControl'
import PersonIcon from '@mui/icons-material/Person';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LockIcon from '@mui/icons-material/Lock';
import { DCFContext } from './contexts/DCFContext'
import Alert from '@mui/material/Alert';
import jwt_decode from 'jwt-decode'
import { } from 'dotenv/config'
const Register = () => {

    const history = useHistory();

    const [email, setEmail] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [password, setPassword] = useState('')
    const [cpassword, setCpassword] = useState('')

    const { showSuccessAlert, editSuccessAlert, showErrorAlert, editErrorAlert, editRegisterSuccessAlert, showRegisterSuccessAlert } = useContext(DCFContext)

    const { subscriptionStatus,updateSubscriptionStatus } = useContext(RouteContext)

    const handleGet = async () => {
        try {

            const jwtToken = localStorage.getItem('jwtToken')
            const res = await fetch('https://obscure-meadow-68622.herokuapp.com/info', {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    "Content-Type": 'application/json',
                    'Authorization': 'Bearer ' + jwtToken

                },

            });

            const data = await res.json();

            console.log('data from info check ', data)

            localStorage.setItem('userData', JSON.stringify(data));

            // routecontext set
            updateSubscriptionStatus('test reg')

            if (!res.status === 200) {
                const error = new Error(res.error)
                throw error;
            }

        } catch (error) {
            console.log(error)
            history.push('/')
        }
    }

    const handleRegister = async (e) => {
        e.preventDefault();

        // const { firstName, lastName, email, password, cpassword } = user;


        // DEPLOY : const res = await fetch("https://obscure-meadow-68622.herokuapp.com/register", {
        const res = await fetch("https://obscure-meadow-68622.herokuapp.com/register", {

            // const res = await fetch("http://localhost:5000/register", {

            // const res = await fetch("/subscribe", {
            method: "POST",
            headers: {
                "Content-Type": "application/json" //application/json
            },
            body: JSON.stringify({

                firstName: firstName,
                lastName: lastName,
                email: email,
                password: password,
                cpassword: cpassword

            })
        });
        const data = await res.json();

        try {
            console.log('data.error check: ' + data.error);


            if (data.error.length > 0) {
                editErrorAlert(true);
                setTimeout(() => {
                    editErrorAlert(false);

                }, 1500);

            }
            else {
                editErrorAlert(true);
                setTimeout(() => {
                    editErrorAlert(false);

                }, 1500)

                console.log('here here')
                handleGet()

                history.push('/signin')



            }

        }
        catch {
            // window.alert('Registered successfully!')
            console.log('here here catch')

            editRegisterSuccessAlert(true);
            setTimeout(() => {
                editRegisterSuccessAlert(false);

            }, 4500)

            console.log('here here')
            history.push('/signin')



        }

    }


    async function handleCallbackResponse(response) {
        console.log('Encoded JWT ID Token: ', response.credential)
        localStorage.setItem('jwtToken', response.credential)

        var userObject = jwt_decode(response.credential)
        console.log('userObject', userObject)
        let firstName = ''
        let lastName = ''
        try {
            firstName = userObject.name.split(' ')[0]
            lastName = userObject.name.split(' ')[1]
        }
        catch {

        }
        let email = userObject.email
        let sub = userObject.sub

        // post / googlelogin

        const res = await fetch("https://obscure-meadow-68622.herokuapp.com/googlelogin", {
            method: "POST",
            headers: {
                "Content-Type": "application/json" //application/json
            },
            body: JSON.stringify({

                firstName: firstName,
                lastName: lastName,
                email: email,
            })
        });
        const data = await res.json();
        console.log('data login res', data)
        handleGet()
        history.push('/research')

    }

    useEffect(() => {
        console.log('proper check password', password);
    }, [password]);

    useEffect(() => {
        /* global google */
        google.accounts.id.initialize({
            client_id: process.env.REACT_APP_GOOGLE_CLIENTID,
            callback: handleCallbackResponse
        });
        google.accounts.id.renderButton(
            document.getElementById("signInDiv"),
            { theme: 'outline', size: 'large' }
        );
    }, [])


    return (
        <div class="row" id="emailFormRow" style={{ marginTop: '50px' }}>




            <MyLeaderBoardAd style={{ zIndex: 99 }} />


            <img class="lock-img" src={register}></img>

            <div className='row'>
                <div className='col'>

                    <div style={{ margin: '0 auto', display: 'table', marginLeft: 'calc(50% - 75px)', marginTop: '15px' }}>

                        <div id='signInDiv'></div>

                    </div>
                </div>
            </div>

            <div style={{ color: 'white', marginTop: '15px' }}>
                -- OR --
            </div>


            <div class='login-form' >

                <InputGroup style={{ marginTop: "20px" }}>
                    <InputGroup.Text style={{ backgroundColor: '#92ddff21', color: 'white' }}>
                        <PersonIcon class='pencilIcon' style={{ fill: 'turquoise' }} />

                    </InputGroup.Text>

                    <FormControl

                        placeholder='first name'
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        style={{ backgroundColor: '#92ddff21', color: 'white', borderColor: 'white', overflow: 'scroll' }}
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}

                    />

                    {/* <input type="email" class="credentials-input" required value={email} onChange={(e) => setEmail(e.target.value)}></input> */}
                    {/* <input class="credentials-input" type='password' required value={password} onChange={(e) => { setPassword(e.target.value); console.log('password check !!!!', password) }}></input> */}


                </InputGroup>
            </div>

            <div class='login-form' >

                <InputGroup style={{ marginTop: "20px" }}>
                    <InputGroup.Text style={{ backgroundColor: '#92ddff21', color: 'white' }}>
                        <PersonIcon class='pencilIcon' style={{ fill: 'turquoise' }} />

                    </InputGroup.Text>

                    <FormControl

                        placeholder='last name'
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        style={{ backgroundColor: '#92ddff21', color: 'white', borderColor: 'white', overflow: 'scroll' }}
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                    />

                    {/* <input type="email" class="credentials-input" required value={email} onChange={(e) => setEmail(e.target.value)}></input> */}
                    {/* <input class="credentials-input" type='password' required value={password} onChange={(e) => { setPassword(e.target.value); console.log('password check !!!!', password) }}></input> */}


                </InputGroup>
            </div>

            <div class='login-form' >

                <InputGroup style={{ marginTop: "20px" }}>
                    <InputGroup.Text style={{ backgroundColor: '#92ddff21', color: 'white' }}>
                        <AlternateEmailIcon class='pencilIcon' style={{ fill: 'white' }} />

                    </InputGroup.Text>

                    <FormControl

                        placeholder='email'
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        style={{ backgroundColor: '#92ddff21', color: 'white', borderColor: 'white', overflow: 'scroll' }}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />

                    {/* <input type="email" class="credentials-input" required value={email} onChange={(e) => setEmail(e.target.value)}></input> */}
                    {/* <input class="credentials-input" type='password' required value={password} onChange={(e) => { setPassword(e.target.value); console.log('password check !!!!', password) }}></input> */}


                </InputGroup>
            </div>

            <div class='login-form' >

                <InputGroup style={{ marginTop: "20px" }}>
                    <InputGroup.Text style={{ backgroundColor: '#92ddff21', color: 'white' }}>
                        <LockIcon class='pencilIcon' style={{ fill: 'gold' }} />

                    </InputGroup.Text>

                    <FormControl

                        placeholder='password'
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        style={{ backgroundColor: '#92ddff21', color: 'white', borderColor: 'white', overflow: 'scroll' }}
                        value={password}
                        onChange={(e) => { setPassword(e.target.value); console.log('password check !!!!', password) }}
                        type="password"
                    />

                    {/* <input type="email" class="credentials-input" required value={email} onChange={(e) => setEmail(e.target.value)}></input> */}
                    {/* <input class="credentials-input" type='password' required value={password} onChange={(e) => { setPassword(e.target.value); console.log('password check !!!!', password) }}></input> */}


                </InputGroup>
            </div>

            <div class='login-form' >

                <InputGroup style={{ marginTop: "20px" }}>
                    <InputGroup.Text style={{ backgroundColor: '#92ddff21', color: 'white' }}>
                        <LockIcon class='pencilIcon' style={{ fill: 'gold' }} />

                    </InputGroup.Text>

                    <FormControl

                        placeholder='confirm password'
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        style={{ backgroundColor: '#92ddff21', color: 'white', borderColor: 'white', overflow: 'scroll' }}
                        value={cpassword} onChange={(e) => setCpassword(e.target.value)}
                        type="password"
                    />

                    {/* <input type="email" class="credentials-input" required value={email} onChange={(e) => setEmail(e.target.value)}></input> */}
                    {/* <input class="credentials-input" type='password' required value={password} onChange={(e) => { setPassword(e.target.value); console.log('password check !!!!', password) }}></input> */}


                </InputGroup>
            </div>


            <button class="subscribe-button" style={{ marginLeft: 'calc(50% - 100px)', marginBottom: '100px' }} onClick={handleRegister}> Register </button>
            {/* <div style={{ position: 'absolute', top: '280px' }}>

                <div class="row" >

                    <div class="col-sm-3" id="firstName">
                        First Name
                    </div>
                    <div class="col-sm-9">
                        <input class="credentials-input" value={firstName} onChange={(e) => setFirstName(e.target.value)}></input>
                    </div>
                </div>

                <div class="row" >

                    <div class="col-sm-3" id="lastName" >
                        Last Name
                    </div>
                    <div class="col-sm-9">
                        <input class="credentials-input" value={lastName} onChange={(e) => setLastName(e.target.value)} ></input>
                    </div>
                </div>

                <div class="row" >

                    <div class="col-sm-3" id="email"  >
                        Email
                    </div>


                    <div class="col-sm-9">
                        <form action="/action_page.php">

                            <input type="email" class="credentials-input" required value={email} onChange={(e) => setEmail(e.target.value)}></input>

                        </form>

                    </div>
                </div>


                <div class="row" >

                    <div class="col-sm-3" id="email"  >
                        Password
                    </div>


                    <div class="col-sm-9">
                        <form action="/action_page.php">

                            <input class="credentials-input" type='password' required value={password} onChange={(e) => { setPassword(e.target.value); console.log('password check !!!!', password) }}></input>

                        </form>

                    </div>
                </div>



                <div class="row" >

                    <div class="col-sm-3" id="email"  >
                        Confirm Password
                    </div>


                    <div class="col-sm-9">
                        <form action="/action_page.php">

                            <input class="credentials-input" type='password' required value={cpassword} onChange={(e) => setCpassword(e.target.value)}></input>

                        </form>

                    </div>
                </div>

                <div class="row">
                    <div class="col">

                        <button class="subscribe-button" onClick={handleRegister}> Register </button>
                    </div>
                </div>
            </div> */}





            {showSuccessAlert ? <Alert variant="filled" severity="success" style={{ position: 'fixed', top: '0px', marginLeft: '', zIndex: '1000' }}>
                Account registered successfully! You can now login...
            </Alert> : <div />}


            {showErrorAlert ? <Alert variant="filled" severity="error" style={{ position: 'fixed', top: '0px', marginLeft: '', zIndex: '1000' }}>Unable to register!</Alert>
                : <div />}



        </div>
    )
}
export default Register







