
import React, { Component  } from 'react'

class MyLeaderBoardAd extends Component {

    componentDidMount() {
     (window.adsbygoogle = window.adsbygoogle || []).push({})
    }

   render () {
    return(
        <div>

<ins class="adsbygoogle"
                style = { {display:"inline-block",width:"728px",height:"90px"} }
     data-ad-client="ca-pub-7834320931107655"
     data-ad-slot="1093547710"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>

        </div>)
    }
}

export default MyLeaderBoardAd