import React, { createContext, useState, useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';
 
// step 1: export const context
export const DCFContext = createContext();
 
// step 2 : make context provider and export it
const DCFContextProvider = (props) => {
 
 
   const [showMore, setShowMore] = useState(false)
   const [showSuccessAlert, setShowSuccessAlert] = useState(false)
   const [showErrorAlert, setShowErrorAlert] = useState(false)
   const [showRegisterSuccessAlert, setShowRegisterSuccessAlert] = useState(false)
   const [showLoginSuccessAlert, setShowLoginSuccessAlert] = useState(false)
 
   const [tickerContext, setTickerContext] = useState('')
 
   const [disableNavbar, setDisableNavbar] = useState(false)
 
   const [bearIntrinsicValue, setBearIntrinsicValue] = useState(0)
   const [bullIntrinsicValue, setBullIntrinsicValue] = useState(0)
   const [normalIntrinsicValue, setNormalIntrinsicValue] = useState(0)
 
 
   const [cases, setCases] = useState({
       bull: 0,
       bear: 0,
       normal: 0
   })
 
 
   // const addBook = (title, author) => {
   //     let newBook = { title: 'empty', author: 'empty', id: 2 }
   //     setBooks([...books, newBook])
   // }
   // const removeBook = (id) => {
   //     setBooks(books.filter(book => book.id !== id));
   // }
 
   const editShowMore = (newShowMore) => {
       setShowMore(newShowMore)
   }
   const editDisableNavbar = (newDisableNavbar) => {
       setDisableNavbar(newDisableNavbar)
   }
   const editSuccessAlert = (newShowSuccessAlert) => {
       setShowSuccessAlert(newShowSuccessAlert)
   }
   const editErrorAlert = (newShowErrorAlert) => {
       setShowErrorAlert(newShowErrorAlert)
   }
   const editRegisterSuccessAlert = (newShowSuccessAlert) => {
       setShowRegisterSuccessAlert(newShowSuccessAlert)
   }
   const editLoginSuccessAlert = (newShowSuccessAlert) => {
       setShowLoginSuccessAlert(newShowSuccessAlert)
   }
   const editTickerContext = (newTicker) => {
       console.log('newTicker check : ', newTicker)
       setTickerContext(newTicker)
   }
   const editBearIntrinsicValueContext = (newVal) => {
       console.log('bear check : ', newVal)
       setBearIntrinsicValue(newVal)
       console.log('context bear check : ', bearIntrinsicValue)
       sessionStorage.setItem('bear', newVal)
 
   }
   const editBullIntrinsicValueContext = (newVal) => {
       console.log('bull check : ', newVal)
       setBullIntrinsicValue(newVal)
       console.log('context bull check : ', bullIntrinsicValue)
       sessionStorage.setItem('bull', newVal)
 
   }
   const editNormalIntrinsicValueContext = (newVal) => {
       console.log('normal check : ', newVal)
       setNormalIntrinsicValue(newVal)
 
       console.log('context norml check : ', normalIntrinsicValue)
 
       sessionStorage.setItem('normal', newVal)
   }
 
   const returnWeight = () => {
 
       console.log('return weight check : ', normalIntrinsicValue + bullIntrinsicValue + bearIntrinsicValue)
       return normalIntrinsicValue + bullIntrinsicValue + bearIntrinsicValue
   }
 
   const [weightedDCF, setWeightedDCF] = useState(0);
 
   useEffect(() => {
 
       console.log('normal check useEffect: ', normalIntrinsicValue)
       console.log('bullIntrinsicValue check useEffect: ', bullIntrinsicValue)
       console.log('bearIntrinsicValue check useEffect : ', bearIntrinsicValue)
 
       setWeightedDCF(bearIntrinsicValue + bullIntrinsicValue + normalIntrinsicValue)
 
   }, [bearIntrinsicValue, bullIntrinsicValue, normalIntrinsicValue]);
 
   return (
       // step 3 return context.provider , and values you'd like to share
       <DCFContext.Provider value={{ weightedDCF, showMore, editShowMore, showSuccessAlert, editSuccessAlert, showErrorAlert, editErrorAlert, tickerContext, editTickerContext, disableNavbar, editDisableNavbar, bearIntrinsicValue, editBearIntrinsicValueContext, bullIntrinsicValue, editBullIntrinsicValueContext, normalIntrinsicValue, editNormalIntrinsicValueContext, returnWeight, cases, editRegisterSuccessAlert, showRegisterSuccessAlert, editLoginSuccessAlert, showLoginSuccessAlert }}>
           {props.children}
           {console.log('checking context state: ' + showMore)}
           {console.log('checking tickercontext state: ' + tickerContext)}
           {console.log('provider context check bull: ' + bullIntrinsicValue)}
           {console.log('provider context check bearr: ' + bearIntrinsicValue)}
 
       </DCFContext.Provider>
 
   )
}
 
export default DCFContextProvider
