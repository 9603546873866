import { useParams } from 'react-router-dom'
import { useEffect, useContext, useRef, useState } from "react";
import superinvestors from './superinvestor.json'
import { useHistory, useLocation } from 'react-router-dom';
import SuperinvestorLatestTable from './SuperinvestorLatestTable'
import { RouteContext } from './contexts/RouteContext'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import PieChartIcon from '@mui/icons-material/PieChart';
import ToggleSwitch from './ToggleSwitch'
const ValuationExpanded = () => {

    const { ticker } = useParams()

    const [loadingStatus, setLoadingStatus] = useState(true);
    const [detailedValuation, setDetailedValuation] = useState({});
    const [price, setPrice] = useState('');
    const [marketCap, setMarketCap] = useState('');
    const [currency, setCurrency] = useState('');
    const [isChecked, setIsChecked] = useState(false);



    let getPrice = async (ticker) => {

        let url = 'https://obscure-meadow-68622.herokuapp.com/getInterestedStock/' + ticker
        try {
            const res = await fetch(url)
            const data = await res.json();
            console.log('Price CHECK  : ', data)
            setPrice(data.price)
            setMarketCap(data.marketCap)
            setCurrency(data.currency)

        }
        catch {
            console.log('price fetch error')

        }
    }

    let getDetails = async (ticker) => {

        // fetch
        try {

            const jwtToken = localStorage.getItem('jwtToken')
            const res = await fetch('https://obscure-meadow-68622.herokuapp.com/info', {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    "Content-Type": 'application/json',
                    'Authorization': 'Bearer ' + jwtToken

                },

            });

            const data = await res.json();
            console.log('GET CHECK now loop : ', data)

            for (var i = 0; i < data.valuations.length; i++) {
                if (data.valuations[i].ticker = ticker) {
                    setDetailedValuation(data.valuations[i])
                }
            }

            // setData(data)
            if (!res.status === 200) {
                const error = new Error(res.error)
                throw error;
            }

        } catch (error) {
            console.log(error)
            // history.push('/signin')
        }

        setLoadingStatus(false)

    }

    useEffect(() => {

        setLoadingStatus(true)


        getDetails(ticker)
        getPrice(ticker)


        // getValuations()

        // getValuationByTicker('AAPL')
        // setSuperInvestor({
        //     name:
        // })
    }, []);

    return (
        <div className='valuation-details-container'>

            <div style={{ "z-index": "7" }} className={loadingStatus ? 'center-loader' : null}>
                <div className={loadingStatus ? 'pulse-container' : null}>
                    <div className={loadingStatus ? 'pulse-bubble pulse-bubble-1' : null}></div>
                    <div className={loadingStatus ? 'pulse-bubble pulse-bubble-2' : null}></div>
                    <div className={loadingStatus ? 'pulse-bubble pulse-bubble-3' : null}></div>
                </div>
            </div>


            check
            detailedValuation.ticker

            {detailedValuation.ticker}
            {detailedValuation.date}
            price check <br></br>
            {price}

            <div className='price-container'>
                <div className='row'>
                    <div className='col-md-4'>
                        <div className='ticker-style'>

                            <div className='row'>
                                <div className='col'>
                                    <div style={{
                                        fontFamily: 'Barlow Condensed',
                                        float: 'left',
                                        fontStyle: 'italic',
                                        fontSize: '15px'
                                    }}>
                                        Ticker
                                        <AttachMoneyIcon style={{
                                            'fill': 'rgb(101 160 98)',
                                            'margin-bottom': '5px',
                                            'margin-left': '5px'

                                        }} />

                                    </div>
                                </div>
                            </div>
                            <div className='row'>

                                <div className='col' style={{
                                    'font-family': 'Barlow Condensed',
                                    'font-size': '25px'
                                }}>
                                    {ticker}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='price-style'>
                            <div className='row'>
                                <div className='col'>
                                    <div style={{
                                        fontFamily: 'Barlow Condensed',
                                        float: 'left',
                                        fontStyle: 'italic',
                                        fontSize: '15px'
                                    }}>
                                        Price
                                        <LocalOfferIcon style={{
                                            'fill': 'rgb(101 160 98)',
                                            'margin-bottom': '5px',
                                            'margin-left': '5px'
                                        }} />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>

                                <div className='col' style={{
                                    'font-family': 'Barlow Condensed',
                                    'font-size': '25px'
                                }}>
                                    {price}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='marketcap-style'>

                            <div className='row'>
                                <div className='col'>
                                    <div style={{
                                        fontFamily: 'Barlow Condensed',
                                        float: 'left',
                                        fontStyle: 'italic',
                                        fontSize: '15px'
                                    }}>
                                        Market Cap
                                        <PieChartIcon style={{
                                            'fill': 'rgb(101 160 98)',
                                            'margin-bottom': '5px',
                                            'margin-left': '5px'

                                        }} />

                                    </div>
                                </div>
                            </div>
                            <div className='row'>

                                <div className='col' style={{
                                    'font-family': 'Barlow Condensed',
                                    'font-size': '25px'
                                }}>
                                    {parseInt(marketCap).toLocaleString()}
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <div className='assumptions-container' style={{ marginTop: '15px' }}>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='ticker-style'>

                            <div className='row'>
                                <div className='col'>
                                    <div style={{
                                        fontFamily: 'Barlow Condensed',
                                        float: 'left',
                                        fontStyle: 'italic',
                                        fontSize: '15px'
                                    }}>
                                        Ticker
                                        <AttachMoneyIcon style={{
                                            'fill': 'rgb(101 160 98)',
                                            'margin-bottom': '5px',
                                            'margin-left': '5px'

                                        }} />

                                    </div>
                                </div>
                            </div>
                            <div className='row'>

                                <div className='col' style={{
                                    'font-family': 'Barlow Condensed',
                                    'font-size': '25px'
                                }}>
                                    {ticker}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='price-style'>
                            <div className='row'>
                                <div className='col'>
                                    <div style={{
                                        fontFamily: 'Barlow Condensed',
                                        float: 'left',
                                        fontStyle: 'italic',
                                        fontSize: '15px'
                                    }}>
                                        Price
                                        <LocalOfferIcon style={{
                                            'fill': 'rgb(101 160 98)',
                                            'margin-bottom': '5px',
                                            'margin-left': '5px'
                                        }} />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>

                                <div className='col' style={{
                                    'font-family': 'Barlow Condensed',
                                    'font-size': '25px'
                                }}>
                                    {price}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='marketcap-style'>

                            <div className='row'>
                                <div className='col'>
                                    <div style={{
                                        fontFamily: 'Barlow Condensed',
                                        float: 'left',
                                        fontStyle: 'italic',
                                        fontSize: '15px'
                                    }}>
                                        Market Cap
                                        <PieChartIcon style={{
                                            'fill': 'rgb(101 160 98)',
                                            'margin-bottom': '5px',
                                            'margin-left': '5px'

                                        }} />

                                    </div>
                                </div>
                            </div>
                            <div className='row'>

                                <div className='col' style={{
                                    'font-family': 'Barlow Condensed',
                                    'font-size': '25px'
                                }}>
                                    {parseInt(marketCap).toLocaleString()}
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <div className='row'>
                <div class="switch-toggle">
                    <input type="checkbox" id="bluetooth" checked={isChecked} onChange={() => setIsChecked(!isChecked)} />
                    <label for="bluetooth"></label>
                </div>
            </div>

            <ToggleSwitch values={['bear', 'normal', 'bull']} selected="days" />,

            {/* price, ticker, marketcap */}

            {/* assumptions | upside/downside         |    confidence car speed type chart */}

            {/* grapph of cf bullcg bearcf dcf bulldcf beardcf */}
            {/* notes */}

            {/* export button to export to integrations excel, sheets */}
            {/* share button */}


        </div>
    )
}
export default ValuationExpanded