// v color: #0D5FCD
// n color: #07EDA1
 
// UNIVERSAL LOADING THAT BLURS THE WHOLE SCREEN AND PUTS LOADING UNTIL INFO FETCHED
 
import "./App.css";
import "./styles/cardStyles.css"
import { useInView } from "react-intersection-observer";
 
import DCFInputs from "./DCFInputs";
import MyLeaderBoardAd from './MyLeaderBoardAd';
import CAGRCalculator from './CAGRCalculator';
import Error from './Error'
import Register from './Register'
import Login from './Login'
import About from './About'
import WeightedDCF from './WeightedDCF'
import Superinvestors from './Superinvestors'
import Home from './Home'
import ValuationExpanded from './ValuationExpanded'
import DiscountedCashFlows from "./DiscountedCashFlows.js";
import FiveYearCashFlows from "./FiveYearCashFlows";
import Financials from "./Financials";
import Sidebar from './Sidebar'
import SubTest from './SubTest'
// import './assets/css/fonts.css';
 
import React from "react";
import { Navbar } from "react-bootstrap";
import { Route, Switch } from "react-router-dom/cjs/react-router-dom.min";
 
import LaunchPage from './LaunchPage'
import Services from './Services'
import Account from './Account'
import Blog from './Blog'
import Screener from './Screener'
import Research from './Research'
 
import SavedValuations from './SavedValuations'
import RouteContextProvider from "./contexts/RouteContext";
import ReactGA from 'react-ga';
import SuperinvestorProfile from "./SuperinvestorProfile";
import Alerts from './Alerts'
 
import { useState, useEffect, useContext } from "react";
 
function App() {
 
 
 //overview page needs to have TTM metrics, radar, chart?
 
 ReactGA.initialize('G-KBTP6M20F2');
 
 
 
 
 return (
 
   <div className="newParentDiv">
      <RouteContextProvider>

     {/* <MyLeaderBoardAd></MyLeaderBoardAd> */}
 
     <Route exact path="/home">
       {ReactGA.pageview(window.location.pathname + window.location.search)}
       <div style={{ "z-index": "1" }} className="fill-window">
         {/* <MyLeaderBoardAd></MyLeaderBoardAd> */}
 
         <Home>Launch Page</Home>
       </div>
 
     </Route>
 
     <Route exact path="/">
       {ReactGA.pageview(window.location.pathname + window.location.search)}
       <div style={{ "z-index": "1" }} className="fill-window">
         {/* <MyLeaderBoardAd></MyLeaderBoardAd> */}
 
         <LaunchPage>Launch Page</LaunchPage>
       </div>
 
     </Route>
 
       <Route path="/services">
         <Services></Services>
       </Route>
 
       {/* <Route path="/research">
           <div style={{ "z-index": "1" }} className="fill-window">
 
             <Financials></Financials>
           </div>
 
         </Route> */}
 
       <Route path="/account">
         <Account></Account>
       </Route>
 
 
       <Route exact path="/valuation">
         <SavedValuations></SavedValuations>
       </Route>
       <Route path="/valuation/:ticker" component={ValuationExpanded} />
 
 
 
 
       <Route path="/calculators">
         <div class='dcf'>
           <MyLeaderBoardAd />
           <br />
           <h1 style={{ fontWeight: 'bolder', color: 'white', marginBottom: '40px' }}>Calculators</h1>
           <div class='calculators-page-dcf'>
             <WeightedDCF tickerEntry={''} ></WeightedDCF>
           </div>
           <CAGRCalculator></CAGRCalculator>
         </div>
       </Route>
 
       <Route path="/signup">
         <Register />
       </Route>
 
       <Route path="/signin">
         <Login />
       </Route>
       <Route path="/about">
         <About />
       </Route>
 
       <Route path="/financials/:tickerEntry" component={Financials} />
 
 
       <Route path="/superinvestors/:investor/:initID" component={SuperinvestorProfile} />
       <Route exact path="/superinvestors" component={Superinvestors} />
 
 
       <Route path="/subTest" component={SubTest} />
 
 
 
     {/* <Route path="/superinvestors">
         <Superinvestors></Superinvestors>
       </Route> */}
 
     <Route path="/blog">
       <Blog></Blog>
     </Route>
 
 
     <Route path="/error">
       <Error></Error>
     </Route>
 
     <Route path="/research">
       <Research />
       {/* <div style={{ position: 'absolute', marginLeft: '20%', marginTop: '30px' }}>
           <Screener></Screener>
         </div> */}
     </Route>
 
 
 
     {/* <Route exact path="/research/:tickerEntry" component={Financials} /> */}
 
 
 
 
 
     {/* <Switch>
 
         <Route exact path="/financials/:tickerEntry" component={Financials} />
       </Switch> */}
 
     <Sidebar></Sidebar>
 
 
 
 
     <Alerts />
     </RouteContextProvider>

 
   </div >
 
 )
}
 
 
export default App
