import React from "react";
import EmailForm from "./EmailForm"
import { useHistory } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import './styles/accountStyles.css'
import Grid from '@mui/material/Grid';
import modIcon from './styles/modIcon.png'
import premiumIcon from './styles/premiumIcon.png'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import EditIcon from '@mui/icons-material/Edit';
import { useState, useEffect, useContext, useRef } from "react";
import { DCFContext } from './contexts/DCFContext'
import Alert from '@mui/material/Alert';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import Switch from '@mui/material/Switch';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import Premium from './Premium'
import { RouteContext } from './contexts/RouteContext'

// import PayPalBtn from './components/PayPalBtn'
 
const Account = () => {
 
   const history = useHistory();
   const { subscriptionStatus,updateSubscriptionStatus } = useContext(RouteContext)

   const handleLogout = () => {
       //alert
       localStorage.setItem('jwtToken', null)

    // routecontext set user data to null
    updateSubscriptionStatus('unsubscribed')
 
       editSuccessAlert(true);
       setTimeout(() => {
           editSuccessAlert(false);
 
       }, 1500);
 
       history.push('/')
   }
 
   const { showSuccessAlert, editSuccessAlert, showErrorAlert, editErrorAlert } = useContext(DCFContext)
 
   const [isJwt, setIsJwt] = useState(true);
   const [firstName, setFirstName] = useState('');
   const [lastName, setLastName] = useState('');
 
   const handleEditAvatar = () => {
 
       editErrorAlert(true);
       setTimeout(() => {
           editErrorAlert(false);
 
       }, 1500);
 
   }
 
   let checkAuth = async () => {
 
       try {
 
           const jwtToken = localStorage.getItem('jwtToken')
           const res = await fetch('https://obscure-meadow-68622.herokuapp.com/info', {
               method: 'GET',
               headers: {
                   Accept: 'application/json',
                   "Content-Type": 'application/json',
                   'Authorization': 'Bearer ' + jwtToken
 
               },
 
           });
 
           const data = await res.json();
           console.log('auth data', data)
           setFirstName(data.firstName)
           setLastName(data.lastName)
 
           setIsJwt(true)
 
       }
       catch {
           console.log('auth data error')
           setIsJwt(false)
 
       }
   }
 
   useEffect(() => {
       console.log('checking routeContext for subscription status',subscriptionStatus)
 
       checkAuth()
 
       // try {
       //     console.log('jwt', localStorage.getItem('jwtToken'))
       //     if (localStorage.getItem('jwtToken') === null || localStorage.getItem('jwtToken') === 'null' || localStorage.getItem('jwtToken') === undefined || localStorage.getItem('jwtToken') === 'undefined' || localStorage.getItem('jwtToken') === '') {
       //         setIsJwt(false)
 
       //     }
       //     else {
       //         setIsJwt(true)
 
       //     }
       // }
       // catch {
       //     setIsJwt(false)
 
       // }
 
 
 
   }, []);
 
 
   return (
       (isJwt === false) ?
           <div> {history.push('/signin')}</div>
 
           :
           <div>
 
               < div class='account-page' >
 
                   <div class='avatar-container'
                   ></div>
 
                   <Grid container spacing={2} style={{ marginTop: '100px' }} >
                       <Grid item xs={12} >
                           <Avatar style={{ marginLeft: 'calc(50% - 75px)', width: '150px', height: '150px', marginBottom: '10px', fontSize: '80px' }}>
                               {firstName.charAt(0)}
                               {lastName.charAt(0)}
 
                           </Avatar>
                           {/* <div style={{ color:'white' }}>Edit</div> */}
 
                           <button class='save-new-valuation-button' style={{ width: '100px', height: '40px', padding: '5px' }} onClick={handleEditAvatar}>
                               <Grid container spacing={2}>
 
                                   <Grid item xs={6} style={{ marginTop: '2px' }}  >
                                       Edit
                                   </Grid>
 
                                   <Grid item xs={6}  >
                                       <EditIcon class='pencilIcon' style={{ fill: "yellow" }} />
 
                                   </Grid>
                               </Grid>
 
                               {/* <div class='row'  > */}
 
 
                               {/* </div> */}
                           </button>
 
                       </Grid>
 
 
                   </Grid>
 
                   <Grid container spacing={2}
                       alignItems="center"
                       justifyContent="center"
                       style={{ marginRight: '0%', marginTop: '30px' }}
                   >
 
 
                       <Grid item xs={6}  >
 
                           <div style={{ color: 'white', marginBottom: '10px' }}>Moderator</div>
 
 
                           <OverlayTrigger
                               key="top"
                               placement="top"
 
                               overlay={
                                   <Tooltip>
                                       You are not a mod
                                   </Tooltip>
                               }
 
                           >
                               <img class="notModIcon" style={{ width: '30px' }} src={modIcon}></img>
 
                           </OverlayTrigger>
 
                       </Grid>
 
 
                       <Grid item xs={6}   >
                           <div style={{ color: 'white', marginBottom: '10px' }}>Premium </div>
 
                           <OverlayTrigger
                               key="top"
                               placement="top"
 
                               overlay={
                                   <Tooltip>
                                       You are not a premium user
                                   </Tooltip>
                               }
 
                           >
                               <img class="notPremiumIcon" style={{ width: '30px' }} src={premiumIcon}></img>
 
                           </OverlayTrigger>
                       </Grid>
                   </Grid>
 
 
                   <div class='user-info' style={{ color: '#fcffd1', marginTop: '150px' }}>
                       Signed in as :
                       {' '}
                       {firstName}
                       {' '}
                       {lastName}
 
                   </div>
                   <br />
                   <div class='email-info' style={{ color: '#fcffd1', marginTop: '50px', marginBottom: '100px' }}>
                       <div class='row'>
                           <div class='col-6'>
                               <MarkEmailUnreadIcon class='email-notif' style={{ fill: 'green' }}></MarkEmailUnreadIcon>
                               {' '}
                               {' '}
 
                               Email Alerts :
                               {' '}
                           </div>
                           <div class='col-6' style={{ marginTop: '8px' }}>
                               <Switch defaultChecked color="warning" />
 
                           </div>
                       </div>
 
                   </div>
 
                   <br />
                   {/* <div class='email-info' style={{ color: '#fcffd1', marginTop: '50px', marginBottom: '100px' }}>
                       <div class='row'>
                           <div class='col-6'>
                               <PhoneIphoneIcon class='email-notif' style={{ fill: 'green' }}></PhoneIphoneIcon>
                               {' '}
                               {' '}
 
                               Phone Alerts :
                               {' '}
                           </div>
                           <div class='col-6' style={{ marginTop: '10px' }}>
                               <Switch color="warning" />
 
                           </div>
                       </div>
 
                   </div> */}
 
                   {/* <Avatar style={{ width: '40px', height: '50px' }}>H</Avatar> */}
                   <PowerSettingsNewIcon onClick={handleLogout} class='logout-btn' ></PowerSettingsNewIcon>
                   {/*  */}
 
 
               </div>
 
 
 
               {
                   showSuccessAlert ? <Alert variant="filled" severity="success" style={{ position: 'fixed', top: '0px', marginLeft: '', zIndex: '1000' }}>
                       Logged out successfully!
                   </Alert> : <div />
               }
 
 
               {
                   showErrorAlert ? <Alert variant="filled" severity="error" style={{ position: 'fixed', top: '0px', marginLeft: '', zIndex: '1000' }}>
                       Not availible yet!
                   </Alert> : <div />
               }
 
 
 
           </div >
   )
}
export default Account