import React, { useState, useEffect, useContext } from "react";

import { useHistory } from 'react-router-dom';
import { DCFContext } from './contexts/DCFContext'
import Alert from '@mui/material/Alert';

const Alerts = () => {
    const { showSuccessAlert, editSuccessAlert, showErrorAlert, editErrorAlert, editRegisterSuccessAlert, editLoginSuccessAlert, showRegisterSuccessAlert, showLoginSuccessAlert } = useContext(DCFContext)

    return (

        <>
            {showRegisterSuccessAlert ? <Alert variant="filled" severity="success" style={{ position: 'fixed', top: '0px', marginLeft: '', zIndex: '1000' }}>
            Account registered successfully! You can now login...
            </Alert> : null}

            {showLoginSuccessAlert ? <Alert variant="filled" severity="success" style={{ position: 'fixed', top: '0px', marginLeft: '', zIndex: '1000' }}>
                Logged in successfully!
            </Alert> : null}


            {/* {showRegisterSuccessAlert ? <Alert variant="filled" severity="success" style={{ position: 'fixed', top: '0px', marginLeft: '', zIndex: '1000' }}>
                Registered successfully!
            </Alert> : <div />}

            {showRegisterSuccessAlert ? <Alert variant="filled" severity="success" style={{ position: 'fixed', top: '0px', marginLeft: '', zIndex: '1000' }}>
                Registered successfully!
            </Alert> : <div />}

            {showRegisterSuccessAlert ? <Alert variant="filled" severity="success" style={{ position: 'fixed', top: '0px', marginLeft: '', zIndex: '1000' }}>
                Registered successfully!
            </Alert> : <div />} */}
        </>
    )
}
export default Alerts