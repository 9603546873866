import Main from './styles/main.css'
import Util from './styles/util.css'

import React, { useState, useEffect, useContext, useRef } from "react";

import Financials from "./Financials";

import { Radar, Line, Bar } from "react-chartjs-2"
import BarChartIcon from '@mui/icons-material/BarChart';
import Chart from './Chart'


//OFFICIAL metric names 
//CF : Free Cash Flow	, Free Cash Flow Growth, Free Cash Flow Per Share
//IS : Revenue, Net Income, Revenue Growth, Shares Outstanding (Basic), Gross Margin, Operating Margin, Profit Margin, FCF Margin, EPS Growth, EPS (Diluted), Dividend Per Share
//R  : Market Capitalization (this Year), PE Ratio, PS Ratio, PB Ratio, P/FCF Ratio, EV/EBITDA Ratio, EV/FCF Ratio, Return on Capital (ROIC), Return on Equity (ROE), Return on Assets (ROA), Debt / Equity Ratio [inverse for radar], Market Capitalization (cagr for radar)
//BS : Total Current Assets	- Total Liabilities

// Radar chart over time : roic,roe,roa, 1/DtoE, cagr return, margin growth over time, management, employee sentiment
export default class StatementOfCashFlows extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

            cf: this.props.cf,
        };
    }






    getKeys = function () {
        try {
            return Object.keys(this.props.cf[0]);
        }
        catch {

        }
    }

    getHeader = function () {
        try {

            var keys = this.getKeys();



            var currentYear = parseInt(keys[keys.length - 2]);

            return keys.map((key, index) => {



                // start year : Object.keys(this.state.is[0])[0]
                //Object.keys(this.state.is[0])[0]

                //var currentYear = 2021;
                //var currentYear = Object.keys(props.is[0])[(props.is.length)]

                var firstYear = currentYear - (keys.length - 2)

                if (key === firstYear.toString()) {
                    return <th >Financials/Years</th>

                }
                else if (key === 'Year') {
                    return <th >{currentYear}</th>

                }
                else {
                    //                    var newKey = parseInt(key) - 1

                    return <th >{key - 1}</th>

                }



            })


        }
        catch {

        }
    }

    getRowsData = function () {



        var newItems = this.props.cf;

        var cfItems = [];

        for (var i = 0; i < newItems.length; i++) {
            if (newItems[i].Year === "Free Cash Flow") {
                break
            }
            else {
                cfItems.push(newItems[i])
            }

        }

        var keys = this.getKeys();
        return cfItems.map((row, index) => {
            return <tr class="row100 head"><RenderRow key={index} data={row} keys={keys} /></tr>
        })
    }


    render() {

        return (
            < div class="container-table100" class="col-lg" >

                <div class="wrap-table100" class="col-lg">
                    <div class="table100 ver6 m-b-110">
                        <table data-vertable="ver6">
                            <thead>
                                <tr class="row100 head">{this.getHeader()}</tr>
                            </thead>
                            <tbody>
                                {this.getRowsData()}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div >
        )
    }
}

const RenderRow = (props) => {
    const [showChart, setShowChart] = useState(false);

    var currentYear = parseInt(props.keys[props.keys.length - 2]);


    if (props.data['Year'] === "EBIT Margin"
        || props.data['Year'] === "EBIT"
        || props.data['Year'] === "EBITDA Margin"
        || props.data['Year'] === "EBITDA"
        || props.data['Year'] === "Effective Tax Rate"
        || props.data['Year'] === "Free Cash Flow Margin"
        || props.data['Year'] === "Profit Margin"
        || props.data['Year'] === "Operating Margin"
        || props.data['Year'] === "Gross Margin"
        || props.data['Year'] === "Dividend Growth"
        || props.data['Year'] === "Dividend Per Share"
        || props.data['Year'] === "Free Cash Flow Per Share"
        || props.data['Year'] === "EPS Growth"
        || props.data['Year'] === "EPS (Diluted)"
        || props.data['Year'] === "EPS (Basic)"
        || props.data['Year'] === "Shares Outstanding (Basic)"
        || props.data['Year'] === "Shares Change"
        || props.data['Year'] === "Shares Outstanding (Diluted)"
        || props.data['Year'] === "Net Income Growth"
        || props.data['Year'] === "Net Income Common"
    ) {
        return null
    }
    else {

        try {


            //var currentYear = 2021

            var firstYear = currentYear - (props.keys.length - 2);

            return props.keys.map((key, index) => {


                if (key === firstYear.toString()) {
                    //render first key (first key being 2011)
                    if (
                        props.data['Year'] === "Free Cash Flow"
                        || props.data['Year'] === "Cash from Financing"
                        || props.data['Year'] === "Cash from Investments"
                        || props.data['Year'] === "Cash From Operations"
                        || props.data['Year'] === "Net Income"
                    ) { //bolden line items
                        return <th class={"lineItem"} >
                            {props.data['Year']}
                            <div style={{ display: 'inline', height: 'fit-content' }} onMouseOver={() => setShowChart(true)} onMouseLeave={() => setShowChart(false)} >
                                <BarChartIcon class="chart-icon"></BarChartIcon>
                                {showChart ? <Chart metric={props.data['Year']} chartData={props.data}></Chart> : null}
                            </div>
                        </th>
                    }
                    else {
                        return <td >
                            {props.data['Year']}
                            <div style={{ display: 'inline', height: 'fit-content' }} onMouseOver={() => setShowChart(true)} onMouseLeave={() => setShowChart(false)}>
                                <BarChartIcon class="chart-icon"></BarChartIcon>
                                {showChart ? <Chart metric={props.data['Year']} chartData={props.data}></Chart> : null}
                            </div>
                        </td>
                    }
                }
                else if (key === 'Year') {
                    //render second last key (second last key being currentYear)
                    if (
                        props.data['Year'] === "Free Cash Flow"
                        || props.data['Year'] === "Cash from Financing"
                        || props.data['Year'] === "Cash from Investments"
                        || props.data['Year'] === "Cash From Operations"
                        || props.data['Year'] === "Net Income"
                    ) { //bolden line items
                        return <th class={"lineItem"}>{Math.round(parseInt(props.data[currentYear]) / 1000000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</th>

                    }
                    else {
                        return <td >{Math.round(parseInt(props.data[currentYear]) / 1000000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>

                    }
                }

                else {
                    var newKey = parseInt(key) - 1;

                    if (
                        props.data['Year'] === "Free Cash Flow"
                        || props.data['Year'] === "Cash from Financing"
                        || props.data['Year'] === "Cash from Investments"
                        || props.data['Year'] === "Cash From Operations"
                        || props.data['Year'] === "Net Income"
                    ) { //bolden line items
                        return <th class={"lineItem"}>{Math.round(parseInt(props.data[newKey]) / 1000000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</th>

                    }
                    else {
                        return <td >{Math.round(parseInt(props.data[newKey]) / 1000000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                        //return <td >5</td>

                    }
                }
            })
        }
        catch {
            return null
        }
    }
}

/* orignaanlly only this:

            <table>
                {this.renderCF()}
            </table>


*/




/* working renderCF()


    renderCF() {

        var hideItem = false;
        var renCf = [];
        var currentYear = 2020;
        var showHistory = currentYear - 10;
        var lineItemFlag = false
        var row = document.createElement
        var Years = Object.keys(this.props.cf[1]).length;
        var endCf = false;


        try {

            for (var i = -1; i < this.props.cf.length; i++) {


                if (i === -1) {
                    renCf.push(<th>YearS</th >);

                }
                else {

                    //line items
                    if (this.props.cf[i].Year === "Operating Cash Flow") {
                        renCf.push(<th>{this.props.cf[i].Year}</th >);
                        lineItemFlag = true;

                    }
                    else if (this.props.cf[i].Year === "Investing Cash Flow") {
                        renCf.push(<th>{this.props.cf[i].Year}</th >);
                        lineItemFlag = true;

                    }
                    else if (this.props.cf[i].Year === "Financing Cash Flow") {
                        renCf.push(<th>{this.props.cf[i].Year}</th >);
                        lineItemFlag = true;

                    }
                    else if (this.props.cf[i].Year === "Free Cash Flow") {
                        renCf.push(<th>{this.props.cf[i].Year}</th >);
                        lineItemFlag = true;

                    }
                    else if (this.props.cf[i].Year === "Free Cash Flow Growth") {
                        endCf = true;
                    }
                    else {
                        if (endCf === true) {
                            continue;
                        }
                        else {
                            renCf.push(<td>{this.props.cf[i].Year}</td >);
                        }
                    }
                }


                for (var j = Object.keys(this.props.cf[1])[0]; j <= currentYear; j++) {
                    if (i === -1) {
                        renCf.push(<th>{j}</th >);
                    }


                    else {
                        if (endCf === true) {
                            continue;
                        }
                        else {
                            //line item check
                            if (lineItemFlag === true) {
                                renCf.push(<th>{this.props.cf[i][j]}</th >);

                            }
                            else {

                                renCf.push(<td>{this.props.cf[i][j]}</td >);
                            }
                        }
                    }
                }
                renCf.push(<tr class="row100"></tr>)

                lineItemFlag = false;
                hideItem = false;
            }

            return renCf;
        }

        catch {

        }
    }
*/





/* orginial table


            <div class="limiter">
                <div class="container-table100">
                    <div class="wrap-table100">








                        <div class="table100 ver6 m-b-110">
                            <table data-vertable="ver6">
                                <tr class="row100 head">
                                    <th class="column100 column1" data-column="column1"></th>
                                    <th class="column100 column2" data-column="column2">Sunday</th>
                                    <th class="column100 column3" data-column="column3">Monday</th>
                                    <th class="column100 column4" data-column="column4">Tuesday</th>
                                    <th class="column100 column5" data-column="column5">Wednesday</th>
                                    <th class="column100 column6" data-column="column6">Thursday</th>
                                    <th class="column100 column7" data-column="column7">Friday</th>
                                    <th class="column100 column8" data-column="column8">Saturday</th>
                                </tr>
                                <tr class="row100">
                                    <td class="column100 column1" data-column="column1">Lawrence Scott</td>
                                    <td class="column100 column2" data-column="column2">8:00 AM</td>
                                    <td class="column100 column3" data-column="column3">--</td>
                                    <td class="column100 column4" data-column="column4">--</td>
                                    <td class="column100 column5" data-column="column5">8:00 AM</td>
                                    <td class="column100 column6" data-column="column6">--</td>
                                    <td class="column100 column7" data-column="column7">5:00 PM</td>
                                    <td class="column100 column8" data-column="column8">8:00 AM</td>
                                </tr>

                                <tr class="row100">
                                    <td class="column100 column1" data-column="column1">Jane Medina</td>
                                    <td class="column100 column2" data-column="column2">--</td>
                                    <td class="column100 column3" data-column="column3">5:00 PM</td>
                                    <td class="column100 column4" data-column="column4">5:00 PM</td>
                                    <td class="column100 column5" data-column="column5">--</td>
                                    <td class="column100 column6" data-column="column6">9:00 AM</td>
                                    <td class="column100 column7" data-column="column7">--</td>
                                    <td class="column100 column8" data-column="column8">--</td>
                                </tr>

                                <tr class="row100">
                                    <td class="column100 column1" data-column="column1">Billy Mitchell</td>
                                    <td class="column100 column2" data-column="column2">9:00 AM</td>
                                    <td class="column100 column3" data-column="column3">--</td>
                                    <td class="column100 column4" data-column="column4">--</td>
                                    <td class="column100 column5" data-column="column5">--</td>
                                    <td class="column100 column6" data-column="column6">--</td>
                                    <td class="column100 column7" data-column="column7">2:00 PM</td>
                                    <td class="column100 column8" data-column="column8">8:00 AM</td>
                                </tr>

                                <tr class="row100">
                                    <td class="column100 column1" data-column="column1">Beverly Reid</td>
                                    <td class="column100 column2" data-column="column2">--</td>
                                    <td class="column100 column3" data-column="column3">5:00 PM</td>
                                    <td class="column100 column4" data-column="column4">5:00 PM</td>
                                    <td class="column100 column5" data-column="column5">--</td>
                                    <td class="column100 column6" data-column="column6">9:00 AM</td>
                                    <td class="column100 column7" data-column="column7">--</td>
                                    <td class="column100 column8" data-column="column8">--</td>
                                </tr>

                                <tr class="row100">
                                    <td class="column100 column1" data-column="column1">Tiffany Wade</td>
                                    <td class="column100 column2" data-column="column2">8:00 AM</td>
                                    <td class="column100 column3" data-column="column3">--</td>
                                    <td class="column100 column4" data-column="column4">--</td>
                                    <td class="column100 column5" data-column="column5">8:00 AM</td>
                                    <td class="column100 column6" data-column="column6">--</td>
                                    <td class="column100 column7" data-column="column7">5:00 PM</td>
                                    <td class="column100 column8" data-column="column8">8:00 AM</td>
                                </tr>

                                <tr class="row100">
                                    <td class="column100 column1" data-column="column1">Sean Adams</td>
                                    <td class="column100 column2" data-column="column2">--</td>
                                    <td class="column100 column3" data-column="column3">5:00 PM</td>
                                    <td class="column100 column4" data-column="column4">5:00 PM</td>
                                    <td class="column100 column5" data-column="column5">--</td>
                                    <td class="column100 column6" data-column="column6">9:00 AM</td>
                                    <td class="column100 column7" data-column="column7">--</td>
                                    <td class="column100 column8" data-column="column8">--</td>
                                </tr>

                                <tr class="row100">
                                    <td class="column100 column1" data-column="column1">Rachel Simpson</td>
                                    <td class="column100 column2" data-column="column2">9:00 AM</td>
                                    <td class="column100 column3" data-column="column3">--</td>
                                    <td class="column100 column4" data-column="column4">--</td>
                                    <td class="column100 column5" data-column="column5">--</td>
                                    <td class="column100 column6" data-column="column6">--</td>
                                    <td class="column100 column7" data-column="column7">2:00 PM</td>
                                    <td class="column100 column8" data-column="column8">8:00 AM</td>
                                </tr>

                                <tr class="row100">
                                    <td class="column100 column1" data-column="column1">Mark Salazar</td>
                                    <td class="column100 column2" data-column="column2">8:00 AM</td>
                                    <td class="column100 column3" data-column="column3">--</td>
                                    <td class="column100 column4" data-column="column4">--</td>
                                    <td class="column100 column5" data-column="column5">8:00 AM</td>
                                    <td class="column100 column6" data-column="column6">--</td>
                                    <td class="column100 column7" data-column="column7">5:00 PM</td>
                                    <td class="column100 column8" data-column="column8">8:00 AM</td>
                                </tr>
                            </table>
                        </div>







                    </div>
                </div>
            </div>
*/







/*trial

   var hideItem = false;
        var renCf = [];
        var currentYear = 2020;
        var showHistory = currentYear - 10;
        var lineItemFlag = false
        var row = document.createElement
        //  var Years = Object.keys(this.props.cf[1]).length;
        var endCf = false;




        var countCol = 0;
        var thClass = "column100 column" + countCol
        var dataCol = "column" + countCol

        try {

            for (var i = -1; i < this.props.cf.length; i++) {

                if (i === -1) {
                    renCf.push(<th class={"column100 column" + (i + 2)} data-column={"column" + (i + 2)}></th>)
                }

                else {

                    //line items
                    if (this.props.cf[i].Year === "Operating Cash Flow") {
                        renCf.push(<th class={"column100 column" + (i + 2)} data-column={"column" + (i + 2)}>{this.props.cf[i].Year}</th >);
                        lineItemFlag = true;

                    }
                    else if (this.props.cf[i].Year === "Investing Cash Flow") {
                        renCf.push(<th class={"column100 column" + (i + 2)} data-column={"column" + (i + 2)}>{this.props.cf[i].Year}</th >);
                        lineItemFlag = true;

                    }
                    else if (this.props.cf[i].Year === "Financing Cash Flow") {
                        renCf.push(<th class={"column100 column" + (i + 2)} data-column={"column" + (i + 2)}>{this.props.cf[i].Year}</th >);
                        lineItemFlag = true;

                    }
                    else if (this.props.cf[i].Year === "Free Cash Flow") {
                        renCf.push(<th class={"column100 column" + (i + 2)} data-column={"column" + (i + 2)}>{this.props.cf[i].Year}</th >);
                        lineItemFlag = true;

                    }
                    else if (this.props.cf[i].Year === "Free Cash Flow Growth") {
                        endCf = true;
                    }
                    else {
                        if (endCf === true) {
                            continue;
                        }
                        else {
                            renCf.push(<td class={"column100 column" + (i + 2)} data-column={"column" + (i + 2)}>{this.props.cf[i].Year}</td >);
                        }
                    }
                }


                for (var j = Object.keys(this.props.cf[1])[0]; j <= currentYear; j++) {
                    if (i === -1) {
                        renCf.push(<th class={"column100 column" + (i + 2)} data-column={"column" + (i + 2)}>{j}</th >);
                    }


                    else {
                        if (endCf === true) {
                            continue;
                        }
                        else {
                            //line item check
                            if (lineItemFlag === true) {
                                renCf.push(<th class={"column100 column" + (i + 2)} data-column={"column" + (i + 2)}>{this.props.cf[i][j]}</th >);

                            }
                            else {

                                renCf.push(<td class={"column100 column" + (i + 2)} data-column={"column" + (i + 2)}>{this.props.cf[i][j]}</td >);
                            }
                        }
                    }
                }
                renCf.push(<tr class="row100"></tr>)

                lineItemFlag = false;
                hideItem = false;
            }

            return renCf;
        }

        catch {

        }


        */