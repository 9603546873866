import React from "react";

import Financials from "./Financials";

import { Radar, Line, Bar } from "react-chartjs-2"

import './styles/ratiosGraph.css'

//OFFICIAL metric names 
//CF : Free Cash Flow	, Free Cash Flow Growth, Free Cash Flow Per Share
//IS : Revenue, Net Income, Revenue Growth, Shares Outstanding (Basic), Gross Margin, Operating Margin, Profit Margin, FCF Margin, EPS Growth, EPS (Diluted), Dividend Per Share
//R  : Market Capitalization (this Year), PE Ratio, PS Ratio, PB Ratio, P/FCF Ratio, EV/EBITDA Ratio, EV/FCF Ratio, Return on Capital (ROIC), Return on Equity (ROE), Return on Assets (ROA), Debt / Equity Ratio [inverse for radar], Market Capitalization (cagr for radar)
//BS : Total Current Assets	- Total Liabilities

// Radar chart over time : roic,roe,roa, 1/DtoE, cagr return, margin growth over time, management, employee sentiment
export default class RatiosGraph extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            r: this.props.r,
        };
    }


    renderGraphs(canvas) {

        try {


            var returnArr = []

            const ctx = canvas.getContext('2d')

            var currentYear = 2022;


            var gradientGreen = ctx.createLinearGradient(0, 0, 0, 300);
            gradientGreen.addColorStop(0, "rgba(0, 255, 0, 1)");
            gradientGreen.addColorStop(1, "rgba(0, 100, 0, 0.05)");

            var gradientBlue = ctx.createLinearGradient(0, 0, 0, 300);
            gradientBlue.addColorStop(0, "rgba(0, 0, 255, 1)");
            gradientBlue.addColorStop(1, "rgba(0, 0, 100, 0.05)");

            var gradientOrange = ctx.createLinearGradient(0, 0, 0, 300); //186, 148, 22
            gradientOrange.addColorStop(0, "rgba(186, 148, 22, 1)");
            gradientOrange.addColorStop(1, "rgba(93, 74, 11, 0.05)");

            var gradientPurple = ctx.createLinearGradient(0, 0, 0, 300); //18, 158, 161
            gradientPurple.addColorStop(0, "rgba(18, 158, 160, 1)");
            gradientPurple.addColorStop(1, "rgba(9, 79, 80, 0.05)");



            var ratios = {

                labels: [],

                datasets: [
                    {
                        borderWidth: 3,// and not lineWidth,
                        backgroundColor: gradientGreen,

                        label: 'P/E',
                        data: [],
                        fill: true,
                        //light green under the line: rgba(95, 220, 157, 1)
                    },

                    {
                        borderWidth: 3,// and not lineWidth,
                        backgroundColor: gradientBlue,
                        label: "P/S",
                        fill: true,
                        data: []
                    },
                    {
                        borderWidth: 3,// and not lineWidth,
                        backgroundColor: gradientOrange,
                        label: "P/B",
                        fill: true,
                        data: []
                    },
                    {
                        borderWidth: 3,// and not lineWidth,
                        backgroundColor: gradientPurple,
                        label: "P/FCF",
                        fill: true,
                        data: []
                    }
                ]
            }

            var labelsArr = []

            var PEArr = []
            var PSArr = []
            var PBArr = []
            var PFCFArr = []

            var PEIndex = 0;
            var PSIndex = 0;
            var PBIndex = 0;
            var PFCFIndex = 0;

            for (var i = 0; i < this.props.r.length; i++) {

                if (this.props.r[i].Year === "P/E Ratio") {
                    PEIndex = i;
                }
                if (this.props.r[i].Year === "P/S Ratio") {
                    PSIndex = i;
                }
                if (this.props.r[i].Year === "P/B Ratio") {
                    PBIndex = i;
                }
                if (this.props.r[i].Year === "P/FCF Ratio") {
                    PFCFIndex = i;
                }
            }

            for (var j = Object.keys(this.props.r[3])[0]; j <= currentYear; j++) {

                labelsArr.push(j)
                PEArr.push(this.props.r[PEIndex][j])
                PSArr.push(this.props.r[PSIndex][j])
                PBArr.push(this.props.r[PBIndex][j])
                PFCFArr.push(this.props.r[PFCFIndex][j])

            }

            ratios.labels = labelsArr
            ratios.datasets.label = "Ratios over the Year"


            ratios.datasets[0].data = PEArr //or without 0?
            ratios.datasets[1].data = PSArr //or without 0?
            ratios.datasets[2].data = PBArr //or without 0?
            ratios.datasets[3].data = PFCFArr //or without 0?

            returnArr.push(<Line data={ratios} options=
                {{

                    responsive: true,
                    plugins: {
                        tooltip: {
                            mode: 'index',
                            intersect: false
                        }
                    },
                    interaction: {
                        mode: 'x'
                    }

                    ,
                    tooltips: {
                        mode: 'nearest'
                    },

                    aspectRatio: 4,
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            grid: {
                                color: 'rgba(0, 0, 0, 0.5)',
                                borderColor: 'rgba(0, 0, 0, 0.77)',
                                tickColor: 'rgba(0, 0, 0, 0.77)'
                            },
                            pointLabels: {
                                color: 'red'
                            }, ticks: {
                                color: 'rgba(237, 241, 39, 1)',
                                font: {
                                    size: 15,
                                }
                            },
                        },
                        y: {
                            grid: {
                                color: 'rgba(0, 0, 0, 0.5)',
                                borderColor: 'rgba(0, 0, 0, 0.77)',
                                tickColor: 'rgba(0, 0, 0, 0.77)'
                            },
                            pointLabels: {
                                color: 'yellow'
                            },
                            ticks: {
                                color: 'rgba(237, 241, 39, 1)',
                                font: {
                                    size: 15,
                                }
                            },
                            beginAtZero: true
                        }
                    },
                    tension: 0.3

                }}>  </Line >)

            return returnArr
        }
        catch {

        }
    }


    render() {


        return (

            <div class="graph-container" >
                <canvas id="can" hidden="false" className="radar-can">

                </canvas>
                {this.renderGraphs(document.getElementById("can"))}

            </div>

        )
    }
}
