import React from "react";
import './styles/radar.css'
import Financials from "./Financials";

import { Radar, Line, Bar } from "react-chartjs-2"


//radar sideways bar chart toggle
// solvency , liquidity , overall debt health

//OFFICIAL metric names 
//CF : Free Cash Flow	, Free Cash Flow Growth, Free Cash Flow Per Share
//IS : Revenue, Net Income, Revenue Growth, Shares Outstanding (Basic), Gross Margin, Operating Margin, Profit Margin, FCF Margin, EPS Growth, EPS (Diluted), Dividend Per Share
//R  : Market Capitalization (this Year), PE Ratio, PS Ratio, PB Ratio, P/FCF Ratio, EV/EBITDA Ratio, EV/FCF Ratio, Return on Capital (ROIC), Return on Equity (ROE), Return on Assets (ROA), Debt / Equity Ratio [inverse for radar], Market Capitalization (cagr for radar)
//BS : Total Current Assets	- Total Liabilities

// Radar chart over time : roic,roe,roa, 1/DtoE, cagr return, margin growth over time, management, employee sentiment
export default class QuickRadar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            r: this.props.r,
            is: this.props.is,
        };
    }

    median(array) {
        array.sort(function (a, b) {
            return a - b;
        });
        var middle = array.length / 2;
        return middle % 1 ? array[middle - 0.5] : (array[middle - 1] + array[middle]) / 2;
    }


    renderRadar() {
        try {

            var currentYear = 2020;
            var returnArr = []

            var radarData = {
                labels: [
                    'ROA',
                    'ROIC',
                    'ROE',
                    'Leverage Standing',
                    'Historic returns',
                    'Margin Growth',
                ],
                datasets: [{
                    label: 'Quality Metrics',
                    data: [],
                    fill: true,
                    backgroundColor: 'rgba(37, 237, 37, 0.6)',


                }]
            };

            //      parseFloat((this.state.r[12][2020]) )/ 100.0;


            //quilty metric on hover vs rating shown on radar
            var ROAIndex = 0;
            var ROICIndex = 0;
            var ROEIndex = 0;
            var DEIndex = 0;
            var MCIndex = 0;
            var MarginGrowthIndex = 0;

            for (var i = 0; i < this.props.r.length; i++) {

                if (this.props.r[i].Year === "Return on Assets") {
                    ROAIndex = i;
                }
                if (this.props.r[i].Year === "Return on Invested Capital") {
                    ROICIndex = i;
                }
                if (this.props.r[i].Year === "Return on Equity") {
                    ROEIndex = i;
                }
                if (this.props.r[i].Year === "Debt / Equity") {
                    DEIndex = i;
                }
                if (this.props.r[i].Year === "Market Capitalization") {
                    MCIndex = i;
                }
            }
            for (var i = 0; i < this.props.is.length; i++) {
                if (this.props.is[i].Year === "Profit Margin") {
                    MarginGrowthIndex = i;
                }
            }



            var MCFirst = this.props.r[0][Object.keys(this.props.r[MCIndex])[0]];

            var MCFirstInt = parseFloat(MCFirst);


            var MCLast = this.props.r[MCIndex][currentYear];
            var MCLastInt = parseFloat(MCLast);



            var termMC = parseInt((currentYear) - (Object.keys(this.props.r[MCIndex])[0]));


            var MarginFirst = (parseFloat(this.props.is[MarginGrowthIndex][Object.keys(this.props.is[MarginGrowthIndex])[0]]));
            var MarginLast = (parseFloat(this.props.is[MarginGrowthIndex][currentYear]));


            var termMargin = parseInt((currentYear) - (Object.keys(this.props.is[MarginGrowthIndex])[0]));





            //getting median roic roe roa

            /*
            var tempRoa = [];
            var tempRoe = [];
            var tempRoic = [];
        
            for (var j = Object.keys(this.state.r[ROEIndex])[0]; j <= currentYear; j++) {
        
              tempRoa.push(parseFloat((this.state.r[ROAIndex][j])));
              tempRoe.push(parseFloat((this.state.r[ROEIndex][j])))
              tempRoic.push(parseFloat((this.state.r[j][j])))
        
            }
            var sortRoa = tempRoa.sort((a, b) => {
              return a - b;
            });
            var sortRoe = tempRoe.sort((a, b) => {
              return a - b;
            });;
            var sortRoic = tempRoic.sort((a, b) => {
              return a - b;
            });;
        
        
            console.log("logging sorted roa: " + sortRoa)
        
            */

            /*
                  function median(values) {
                    if (values.length === 0) return 0;
            
                    values.sort(function (a, b) {
                      return a - b;
                    });
            
                    var half = Math.floor(values.length / 2);
            
                    if (values.length % 2)
                      return values[half];
            
                    return (values[half - 1] + values[half]) / 2.0;
                  }
            
            */
            //  var roa = this.median(sortRoa)
            //  var roic = this.median(sortRoic)
            //  var roe = this.median(sortRoe)




            let k;
            let roaEvalArr = [];
            let roaObj = this.props.r[ROAIndex]
            for (k of Object.keys(roaObj)) {

                /*        k : key
                 *   obj[k] : value
                 */
                roaEvalArr.push(roaObj[k])
            }
            roaEvalArr.pop()


            let roicEvalArr = [];
            let roicObj = this.props.r[ROICIndex]
            for (k of Object.keys(roicObj)) {

                /*        k : key
                 *   obj[k] : value
                 */
                roicEvalArr.push(roicObj[k])
            }
            roicEvalArr.pop()

            let roeEvalArr = [];
            let roeObj = this.props.r[ROEIndex]
            for (k of Object.keys(roeObj)) {

                /*        k : key
                 *   obj[k] : value
                 */
                roeEvalArr.push(roeObj[k])
            }
            roeEvalArr.pop()


            let roa = parseInt(this.median(roaEvalArr) * 100);

            var roic = parseInt(this.median(roicEvalArr) * 100);

            var roe = parseInt(this.median(roeEvalArr) * 100);




            var mcCagr = (((MCLastInt / MCFirstInt) ** (1 / termMC)) - 1) * 100;

            var DE = parseFloat(this.props.r[DEIndex][currentYear]);

            var marginCagr = (((MarginLast / MarginFirst) ** (1 / termMargin)) - 1) * 100;




            if (MarginFirst <= 0 && MarginLast > 0) {

                marginCagr = 10000 //really big (big accomplishment to go from unprofitable to profitable)
            }

            /*
                        for (var j = Object.keys(this.state.r[0])[0]; j <= currentYear; j++) {
            
                                // for (var j = Object.keys(this.state.r[3])[0]; j <= currentYear; j++) { // p/s Years
                          
                                  labelsArr.push(j)
                                  PEArr.push(this.state.r[PEIndex][j])
                                  PSArr.push(this.state.r[PSIndex][j])
                                  PBArr.push(this.state.r[PBIndex][j])
                                  PFCFArr.push(this.state.r[PFCFIndex][j])
                          
                                }
            */

            // if statements for rating
            var roaRating = 0;
            var roicRating = 0;
            var roeRating = 0;
            var mcCagrRating = 0;
            var deRating = 0;
            var marginCagrRating = 0;



            switch (true) {
                case (roa <= 2.5):
                    roaRating = 1;
                    break;

                case ((roa > 2.5) && (roa <= 5)):
                    roaRating = 2;
                    break;

                case ((roa > 5) && (roa <= 7)):
                    roaRating = 3;
                    break;

                case ((roa > 7) && (roa <= 9)):
                    roaRating = 4.5;
                    break;

                case ((roa > 9) && (roa <= 11)):
                    roaRating = 6;
                    break;

                case ((roa > 11) && (roa <= 13)):
                    roaRating = 7;
                    break;

                case ((roa > 13) && (roa <= 15)):
                    roaRating = 8;
                    break;

                case ((roa > 15) && (roa <= 20)):
                    roaRating = 8.5;
                    break;

                case ((roa > 20) && (roa <= 25)):
                    roaRating = 9;
                    break;

                case roa > 25:
                    roaRating = 10;
                    break;

                default:
                    roaRating = 0

            }

            switch (true) {
                case (roe <= 2.5):
                    roeRating = 1;
                    break;

                case ((roe > 2.5) && (roe <= 5)):
                    roeRating = 2;
                    break;

                case ((roe > 5) && (roe <= 7)):
                    roeRating = 3;
                    break;

                case ((roe > 7) && (roe <= 9)):
                    roeRating = 4.5;
                    break;

                case ((roe > 9) && (roe <= 11)):
                    roeRating = 6;
                    break;

                case ((roe > 11) && (roe <= 13)):
                    roeRating = 7;
                    break;

                case ((roe > 13) && (roe <= 15)):
                    roeRating = 8;
                    break;

                case ((roe > 15) && (roe <= 20)):
                    roeRating = 8.5;
                    break;

                case ((roe > 20) && (roe <= 25)):
                    roeRating = 9;
                    break;

                case roe > 25:
                    roeRating = 10;
                    break;

                default:
                    roeRating = 0

            }

            switch (true) {
                case (roic <= 2.5):
                    roicRating = 1;
                    break;

                case ((roic > 2.5) && (roic <= 5)):
                    roicRating = 2;
                    break;

                case ((roic > 5) && (roic <= 7)):
                    roicRating = 3;
                    break;

                case ((roic > 7) && (roic <= 9)):
                    roicRating = 4.5;
                    break;

                case ((roic > 9) && (roic <= 11)):
                    roicRating = 6;
                    break;

                case ((roic > 11) && (roic <= 13)):
                    roicRating = 7;
                    break;

                case ((roic > 13) && (roic <= 15)):
                    roicRating = 8;
                    break;

                case ((roic > 15) && (roic <= 20)):
                    roicRating = 8.5;
                    break;

                case ((roic > 20) && (roic <= 25)):
                    roicRating = 9;
                    break;

                case roic > 25:
                    roicRating = 10;
                    break;

                default:
                    roicRating = 0

            }

            // 13-17 = 8, 10-13 6
            switch (true) {
                case (mcCagr <= 2.5):
                    mcCagrRating = 1;
                    break;

                case ((mcCagr > 2.5) && (mcCagr <= 5)):
                    mcCagrRating = 2;
                    break;

                case ((mcCagr > 5) && (mcCagr <= 7)):
                    mcCagrRating = 3.5;
                    break;

                case ((mcCagr > 7) && (mcCagr <= 9)):
                    mcCagrRating = 4.5;
                    break;

                case ((mcCagr > 9) && (mcCagr <= 11)):
                    mcCagrRating = 6;
                    break;

                case ((mcCagr > 11) && (mcCagr <= 13)):
                    mcCagrRating = 7.5;
                    break;

                case ((mcCagr > 13) && (mcCagr <= 15)):
                    mcCagrRating = 8;
                    break;

                case ((mcCagr > 15) && (mcCagr <= 17)):
                    mcCagrRating = 8.5;
                    break;

                case ((mcCagr > 17) && (mcCagr <= 20)):
                    mcCagrRating = 9;
                    break;

                case mcCagr > 20:
                    mcCagrRating = 10;
                    break;

                default:
                    mcCagrRating = 0

            }
            switch (true) {
                case (DE <= 0.0):
                    deRating = 10;
                    break;

                case ((DE > 0) && (DE <= 1)):
                    deRating = 10;
                    break;

                case ((DE > 1) && (DE <= 1.2)):
                    deRating = 7.5;
                    break;

                case ((DE > 1) && (DE <= 1.5)):
                    deRating = 6;
                    break;

                case ((DE > 1.5) && (DE <= 2)):
                    deRating = 4.5;
                    break;

                case ((DE > 2) && (DE <= 3)):
                    deRating = 2;
                    break;

                case DE > 3:
                    deRating = 1;
                    break;

                default:
                    deRating = 0

            }

            //prdo = 36.5, fb = 7.29, acn = 0.286, aapl = -2.82, sjm = 1.174, gis = 1.094, v = -0.186
            switch (true) {
                case (marginCagr <= 0.0):
                    marginCagrRating = 0;
                    break;

                case ((marginCagr > 0.0) && (marginCagr <= 1.0)):
                    marginCagrRating = 1;
                    break;

                case ((marginCagr > 1.0) && (marginCagr <= 3.0)):
                    marginCagrRating = 3;
                    break;

                case ((marginCagr > 3.0) && (marginCagr <= 5.0)):
                    marginCagrRating = 5;
                    break;

                case ((marginCagr > 5.0) && (marginCagr <= 7.0)):
                    marginCagrRating = 7;
                    break;

                case ((marginCagr > 7.0) && (marginCagr <= 10.0)):
                    marginCagrRating = 8.5;
                    break;

                case marginCagr > 10.0:
                    marginCagrRating = 10;
                    break;

                default:
                    marginCagrRating = 0

            }

            //margin rating : 5 ->10 rating , 2cagr -> 7 rating , 0 cagr -> 5 rating , -1 to -2 cagr -> 3 rating , -2 and more cagr -> 1

            // roic roe roa above 10 = 7 rating, 15 = 8.5 rating , 20+ = 10 rating
            radarData.datasets[0].data.push(roaRating) //roa
            radarData.datasets[0].data.push(roicRating) //roic
            radarData.datasets[0].data.push(roeRating) //roe
            radarData.datasets[0].data.push(deRating) //debt/equity
            radarData.datasets[0].data.push(mcCagrRating) //historical returns
            radarData.datasets[0].data.push(marginCagrRating)







            returnArr.push(<Radar data={radarData}

                width={"100%"}
                options={{
                    responsive: true,
                    aspectRatio: 4,
                    maintainAspectRatio: false,
                    // using ChartJS 3 
                    fontSize: 50
                    ,
                    size: 50,

                    plugins: {
                        tooltip: {
                            mode: 'nearest',
                        }
                    },
                    scales: {
                        r: {
                            beginAtZero: true,
                            grid: {
                                color: 'rgba(0, 0, 0, 0.5)',
                                beginAtZero: true,
                                min: 0


                            },
                            pointLabels: {
                                color: 'rgba(237, 241, 39, 1)',
                                font: {
                                    size: 15,
                                }
                            },
                            ticks: {
                                color: 'white',
                                backdropColor: 'rgba(255, 255, 0, 0)', // should render black behind the text
                                beginAtZero: true,
                                min: 0



                            }
                        }
                    }

                    , plugins: {
                        legend: {
                            labels: {
                                // This more specific font property overrides the global property
                                font: {
                                    size: 20
                                }
                            }
                        }
                    }


                }}></Radar >)

            return returnArr
        }
        catch {

        }
    }


    render() {


        return (
            <div class="radar-container" >
                <canvas id="canRadar" hidden="false"></canvas>
                {this.renderRadar()}
            </div>

        )
    }
}
