import React, { useState, useEffect, useContext, useRef } from "react";
import Slider, { SliderThumb } from '@mui/material/Slider';
import Typography from '@mui/material/Typography';

const AddNewValuation = ({ tableEntries }) => {

    function calculateValue(value) {
        return value;
    }

    const [confidence, setConfidence] = useState(50);
    const [notes, setNotes] = useState('yoo');
    const [ticker, setTicker] = useState('ticker');
    const [fairPrice, setFairPrice] = useState(0);


    return (
        <div>
            {JSON.stringify(tableEntries)}

            <div class='row' >
                <input value={ticker} onChange={(e) => setTicker(e.target.value)}></input>
            </div>
            <div class='row' >
                <div > date created</div>
            </div>
            <div class='row' >
                <input value={fairPrice} onChange={(e) => setFairPrice(e.target.value)}></input>
            </div>
            <div class='row' >
                <div > 10% mos  </div>
            </div>
            <div class='row' >
                <div > 30% mos </div>
            </div>


            <div class='row' >
                <input value={notes} onChange={(e) => setNotes(e.target.value)}></input>
            </div>


            <div class='row' style={{ width: '150px' }}>

                <div class="col-6 align-self-center" >

                    <Slider
                        value={confidence}
                        min={0}
                        step={1}
                        max={100}
                        scale={calculateValue}
                        // getAriaValueText={valueLabelFormat}
                        // valueLabelFormat={valueLabelFormat}
                        onChange={(event) => setConfidence(event.target.value)}
                        valueLabelDisplay="auto"
                        aria-labelledby="non-linear-slider"
                    />
                </div>

                <div class="col-6 align-self-center" >
                    <Typography id="non-linear-slider" gutterBottom style={{ color: 'white' }}>
                        {calculateValue(confidence)}/100
                    </Typography>
                </div>
            </div>





        </div>
    )
}
export default AddNewValuation
