/*
*/
import React from "react";
import './styles/EmailForm.css'

export default class EmailForm extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            email: '',
            fn: '',
            ln: ''
        }
    }

    updateEmail = (e) => {
        this.setState({
            email: e.target.value
        });
    }

    updateFN = (e) => {
        this.setState({
            fn: e.target.value
        });
    }

    updateLN = (e) => {
        this.setState({
            ln: e.target.value
        });
    }

    handleSubscribe = async (e) => {
        e.preventDefault();
        const res = await fetch("https://obscure-meadow-68622.herokuapp.com/subscribe", {

            // const res = await fetch("/subscribe", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "firstName": this.state.fn,
                "lastName": this.state.ln,
                "email": this.state.email
            })
        });
        const data = await res.json();

        console.log('data.status check: ' + data.status);

        if (data.status === 422 || !data) {
            window.alert("Invalid Registration")
        }
        else {
            window.alert("Subscribed!")
        }
    }

    render() {


        return (
            <div class="row" id="emailFormRow">

                <div class="row" >

                    <div class="col-sm-3" id="firstName">
                        First Name
                    </div>
                    <div class="col-sm-9">
                        <input class="credentials-input" value={this.state.fn} onChange={this.updateFN}></input>
                    </div>
                </div>

                <div class="row" >

                    <div class="col-sm-3" id="lastName" >
                        Last Name
                    </div>
                    <div class="col-sm-9">
                        <input class="credentials-input" value={this.state.ln} onChange={this.updateLN} ></input>
                    </div>
                </div>

                <div class="row" >

                    <div class="col-sm-3" id="email"  >
                        Email
                    </div>
                    <div class="col-sm-9">
                        <form action="/action_page.php">

                            <input type="email" class="credentials-input" required value={this.state.email} onChange={this.updateEmail}></input>

                        </form>

                    </div>
                </div>

                <div class="row">
                    <div class="col">

                        <button class="subscribe-button" onClick={this.handleSubscribe}> Subscribe </button>
                    </div>
                </div>

            </div>
        )
    }
}