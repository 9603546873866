//              <Link to="/login" class="api-key-button" style={{ "z-index": 3 }}> Generate Api Key  </Link >

import React from "react";
import './styles/ServicesPageStyle.css'
import dataImg from './styles/hex.png'
import checkmark from './styles/checkmark.png'
import Particles from "react-tsparticles";

import Accordion from 'react-bootstrap/Accordion';
import EmailForm from "./EmailForm";

import servicesLogo from './styles/servicesLogo.png'

export default class Services extends React.Component {



    constructor(props) {
        super(props);


        this.state = {
            hover: false,
            showEmailForm: false,
            showTerms: false,
            showNotes: false,
            name: '',
            emailAddress: '',
            message: '',
            showA: false

        }
    }

    handleBlurClick = () => {
        this.setState({
            showNotes: !this.state.showA,
            showTerms: !this.state.showA,
            showEmailForm: !this.state.showA

        });
    }

    toggleShowA = () => {
        this.setState({
            showA: !this.state.showA
        });
    }


    updateEmailAddr = (e) => {
        this.setState({
            emailAddress: e.target.value
        });
    }

    updateMessage = (e) => {
        this.setState({
            message: e.target.value
        });
    }

    updateName = (e) => {
        this.setState({
            name: e.target.value
        });
    }

    handleContact = async (e) => {
        e.preventDefault();

        console.log("contact email sent")
        const res = await fetch("https://obscure-meadow-68622.herokuapp.com/contact", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "name": this.state.name,
                "emailAddress": this.state.emailAddress,
                "message": this.state.message
            })
        });
        const data = await res.json();

        if (data.status === 422 || !data) {
            window.alert("Invalid Registration")
        }
        else {
            window.alert("Message Sent!")
        }
    }


    toggleHover = () => {
        this.setState({ hover: !this.state.hover })
    }

    getInitialState = () => {
        return { hover: false }
    }

    handleEmail = () => {
        this.setState({
            showEmailForm: true
        });
    }

    handleTerms = () => {
        this.setState({
            showTerms: true
        });
    }

    handleNotes = () => {
        this.setState({
            showNotes: true
        });
    }

    handleCloseEmailForm = () => {
        this.setState({
            showEmailForm: false,
            showTerms: false,
            showNotes: false
        });

    }


    render() {

        var linkStyle;
        if (this.state.hover) {
            linkStyle = { backgroundColor: 'transparent' }
        } else {
            linkStyle = { backgroundColor: 'transparent' }
        }

        return (
            <div className="services-window" >




                <div class="particlesContainer" style={{ "z-index": 1 }}
                >
                    <Particles
                        style={{ "z-index": 1 }}
                        id="tsparticles"
                        init={this.particlesInit}
                        loaded={this.particlesLoaded}
                        options={{
                            background: {
                                color: {
                                    value: "transparent",
                                },
                            },
                            fpsLimit: 60,
                            interactivity: {
                                detectsOn: "canvas",
                                events: {


                                    resize: true
                                },
                                modes: {
                                    bubble: {
                                        distance: 400,
                                        duration: 2,
                                        opacity: 0.2,
                                        size: 30,
                                    },
                                    push: {
                                        quantity: 4,
                                    },
                                    repulse: {
                                        distance: 200,
                                        duration: 0.4,
                                    },
                                },
                            },
                            particles: {
                                color: {
                                    value: "rgba(255, 228, 255, 0)",
                                    opacity: 0.05
                                },
                                links: {
                                    color: "#ffffff",
                                    distance: 150,
                                    enable: true,
                                    opacity: 0.04,
                                    width: 1,
                                },
                                collisions: {
                                    enable: false,
                                },
                                move: {
                                    direction: "none",
                                    enable: true,
                                    outMode: "bounce",
                                    random: false,
                                    speed: 2,
                                    straight: false,
                                },
                                number: {
                                    density: {
                                        enable: true,
                                        value_area: 800,
                                    },
                                    value: 35,
                                },
                                opacity: {
                                    value: 0.1,
                                },
                                shape: {
                                    type: "circle",
                                },
                                size: {
                                    random: true,
                                    value: 8,
                                },
                            },
                            detectRetina: true,
                        }}
                    />
                </div>







                <img class="services-logo" src={servicesLogo}></img>




                <div class="row" id="services-row" style={{ "z-index": "8" }} style={this.state.showEmailForm || this.state.showTerms || this.state.showNotes ? {
                    filter: 'blur(4px)'

                } : undefined}>

                    <div class="col-lg-6" style={{ "z-index": "8" }}>
                        <div className="data-services-card" style={{ "z-index": "8" }}>

                            <div class="row">
                                <h2 class="data-services-header">Data Services</h2>
                            </div>


                            <h3 class="data-intro-h3"> Save thousands in costs by using our api endpoint for financial data  </h3>







                            <div class="row">

                                <Accordion defaultActiveKey="1"  >
                                    <Accordion.Item eventKey="0" id="services-accordian">
                                        <Accordion.Header     >  The Problem </Accordion.Header >
                                        <Accordion.Body    >
                                            <h2 style={{ marginBottom: '4%', color: '#1ed64f' }}>Commercial Data Costs</h2>

                                            If you want to get a hold of fundamental data for commerical use, you're going to have to pay a pretty penny.

                                            The leading vendors offer solutions starting at thousands of dollars per month, for PERSONAL use, let alone commercial use!



                                            <h2 style={{ marginBottom: '4%', marginTop: '8%', color: '#1ed64f' }}>Standarding Financial Data Is Difficult</h2>
                                            If you've tried scraping the SEC's EDGAR database you'd quickly find out how differently each company reports their financial statements.
                                            Common metrics such as "Revenue" can be described in many ways: Revenue, Revenues, Sales, Total Sales, Total Revenues, etc, these inconsistancies can be found across companies and across reporting periods.
                                            Before the advent of XBRL taxonomies, financial reporting was a huge hassle to standardize, thanks to XBRL, these inconsistancies have been mitigated. Standardizing financial data is extremly important in financial analysis, allowing us to make an apples-to-apples comparisian.
                                            There are free solutions like Yahoo Finance, but their data only goes back 4 years. They're also incredibly difficult to scale and meant for individuals.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>

                            </div>


                            <div class="row">

                                <Accordion defaultActiveKey="1"  >
                                    <Accordion.Item eventKey="0" id="services-accordian">
                                        <Accordion.Header     >  The Solution </Accordion.Header >
                                        <Accordion.Body    >

                                            <h2 style={{ marginBottom: '4%', marginTop: '2%', color: '#1ed64f' }} >An Affordable API for commercial usage</h2>

                                            We've made an api, specifically for startups/groups and individuals who need standardized fundamental data for commercial uses. With our offering, you can test your data and monetize it without the exorbitant fees and unnecessary overhead that comes with traditional solutions.
                                            Our offering is great for early stage startups that want a quick solution to monetize their product during its beta testing phase.


                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>

                            </div>


                            <div class="row">

                                <Accordion defaultActiveKey="1"  >
                                    <Accordion.Item eventKey="0" id="services-accordian">
                                        <Accordion.Header     >  Our Approach </Accordion.Header >
                                        <Accordion.Body    >

                                            We source our data straight from the SEC and standardized it by leveraging XBRL taxonomies.
                                            By testing various patterns and modifying our approach with each iteration,
                                            we've built a system that can standardize the financials of a large amount companies that file with the SEC.
                                            Our end product is an amalgomation of the income statement, balance sheet, statement of cash flows, and key ratios, dating all the way back to 2008.
                                            <br></br>
                                            <p style={{ fontSize: '10px', marginTop: '2%' }}> *There are still some caveats that need to be addressed, for more info visit the "notes on data" section, where we highlight these anomolies to the best of our ability. </p>


                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>

                            </div>

                            <div class="row" style={{ "z-index": 3 }}>
                                <button class="api-key-button" onClick={this.handleEmail} style={{ "z-index": 3, marginTop: '10%', marginBottom: '10%' }}>Generate API Key</button>
                            </div>
                            <div style={{ "z-index": 3, marginBottom: '7%' }}>
                                Want a more personalized solution with metrics like inventory/assets/recievables turnover? Let us know below and we'll try out best to incorporate it for you!
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6" style={{ "z-index": "7" }}>
                        <div className="dev-services-card" style={{ "z-index": "7" }}>

                            <div class="row" style={{ "z-index": "7" }}>
                                <h2 class="dev-services-header">Digital Services</h2>
                            </div>

                            <h3 class="dev-intro-h3"> We can help you create a well rounded product </h3>

                            <br />

                            <div class="row" >
                                <div class="col-2" >

                                    <img class="checkmarkImg1" src={checkmark}></img>
                                </div>
                                <div class="col-10" >

                                    <p class="misc-services"> Integrate payments to help monetize your products and services.
                                    </p>
                                </div>

                            </div>
                            <br />
                            <br />
                            {/* Organically scale your social media following with strategic content and targeted outreach*/}
                            {/* Contact us to learn about how we leveraged "followers" data and "trending sounds" to grow our audience 100% m/m in our first year  */}
                            <div class="row" >
                                <div class="col-2" >

                                    <img class="checkmarkImg1" src={checkmark}></img>
                                </div>

                                <div class="col-10" >

                                    <p class="misc-services"> Boost customer engagement and facilitate outreach by setting up automated email campaigns
                                    </p>
                                </div>

                            </div>

                            <br />
                            <br />

                            <div class="row" >
                                <div class="col-2" >
                                    <img class="checkmarkImg1" src={checkmark}></img>

                                </div>

                                <div class="col-10" >
                                    <p class="misc-services"> Scale your business with SEO and high conversion landing pages

                                    </p>
                                </div>

                            </div>

                            <br />
                            <br />
                            <div class="row" >
                                <div class="col-2" >
                                    <img class="checkmarkImg1" src={checkmark}></img>

                                </div>

                                <div class="col-10" >
                                    <p class="misc-services">  Misc services: link previews, UI/UX design, automate business processes, and more (contact for consultation)

                                    </p>
                                </div>

                            </div>



                        </div>
                    </div>







                    <div class="testInput" style={{ "z-index": 99 }}>




                        <div class="contactUs-container" style={{ "z-index": 8 }}>

                            <h3 class="customSolution-header"> Need a custom solution? Shoot us a message.  </h3>

                            <div class="row" id="messagePersonInfo" style={{ "z-index": 9 }}>
                                <div class="col" id="nameInput" style={{ marginLeft: "15%" }}>
                                    <div class="name-input">
                                        <div class="input-container ic1" style={{ "z-index": 9 }}>
                                            <input id="nameId" class="inputService"
                                                placeholder="Name"
                                                style={{ "z-index": 9 }}
                                                value={this.state.name}
                                                onChange={this.updateName}

                                            />

                                        </div>
                                    </div>

                                </div>
                                <div class="col" id="emailInput">
                                    <div class="email-input">
                                        <div class=".input-containerService ic1Service" style={{ "z-index": 9 }}>
                                            <input id="emailId" class="inputService"
                                                placeholder="Email"
                                                style={{ "z-index": 9 }}
                                                value={this.state.emailAddress}
                                                onChange={this.updateEmailAddr}

                                            />


                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="row" id="messagePersonBody">
                                <div class="col">
                                    <textarea id="messageId" placeholder="Message" class="message-input" value={this.state.message} onChange={this.updateMessage}></textarea>
                                </div>
                            </div>


                            <div class="row" >
                                <div class="col">
                                    <button class="send-button" onClick={this.handleContact} style={{ "z-index": 3 }}>Send</button>

                                </div>
                            </div>



                        </div>



                    </div>

                </div>


                <div class="row" id="servicesGradient" style={{
                    zIndex: 9, position: 'relative'
                }}>
                    <div class="row" >
                        <div class="col-6" id="terms">


                            <p class="term-text" onClick={this.handleTerms}>terms of service / use</p>
                        </div>

                        <div class="col-6" id="terms">
                            <p class="term-text" onClick={this.handleNotes}>notes on data</p>
                        </div>
                    </div>
                </div>




                {
                    this.state.showTerms &&


                    <div class="row" id="termsID" style={{ "z-index": 199, position: 'relative' }}>

                        <div class="email-form-container" style={{ "z-index": 199, color: 'white', textAlign: 'left' }}>

                            <p style={{ "z-index": 199, position: 'relative' }}>
                                Please read these terms of service ("terms", "terms of service") carefully before using our website (the "service") operated by ValueNation ("us", 'we", "our").
                            </p>
                            <p style={{ "z-index": 199, position: 'relative', fontWeight: 'bold' }}>
                                Conditions of Use
                            </p>
                            <p style={{ "z-index": 199, position: 'relative' }}>
                                We will provide their services to you, which are subject to the conditions stated below in this document. Every time you visit this website, use its services or make a purchase, you accept the following conditions. This is why we urge you to read them carefully.
                            </p>

                            <p style={{ "z-index": 199, position: 'relative', fontWeight: 'bold' }}>
                                Copyright
                            </p>
                            <p style={{ "z-index": 199, position: 'relative' }}>

                                Content published on this website (digital downloads, images, texts, graphics, logos) is the property of ValueNation and/or its content creators and protected by international copyright laws. The entire compilation of the content found on this website is the exclusive property of ValueNation, with copyright authorship for this compilation by ValueNation.
                            </p>


                            <p style={{ "z-index": 199, position: 'relative', fontWeight: 'bold' }}>
                                Communications
                            </p>
                            <p style={{ "z-index": 199, position: 'relative' }}>

                                The entire communication with us is electronic. Every time you send us an email or visit our website, you are going to be communicating with us. You hereby consent to receive communications from us. If you subscribe to the news on our website, you are going to receive regular emails from us. We will continue to communicate with you by posting news and notices on our website and by sending you emails. You also agree that all notices, disclosures, agreements and other communications we provide to you electronically meet the legal requirements that such communications be in writing.

                            </p>

                            <p style={{ "z-index": 199, position: 'relative', fontWeight: 'bold' }}>
                                Applicable Law
                            </p>
                            <p style={{ "z-index": 199, position: 'relative' }}>

                                By visiting this website, you agree that the laws of Canada, without regard to principles of conflict laws, will govern these terms of service, or any dispute of any sort that might come between ValueNation and you, or its business partners and associates.
                            </p>


                            <p style={{ "z-index": 199, position: 'relative', fontWeight: 'bold' }}>
                                Disputes
                            </p>
                            <p style={{ "z-index": 199, position: 'relative' }}>

                                Any dispute related in any way to your visit to this website or to products you purchase from us shall be arbitrated by state or federal court of Canada and you consent to exclusive jurisdiction and venue of such courts.
                            </p>


                            <p style={{ "z-index": 199, position: 'relative', fontWeight: 'bold' }}>
                                Comments, Reviews, and Emails
                            </p>
                            <p style={{ "z-index": 199, position: 'relative' }}>

                                Visitors may post content as long as it is not obscene, illegal, defamatory, threatening, infringing of intellectual property rights, invasive of privacy or injurious in any other way to third parties. Content has to be free of software viruses, political campaign, and commercial solicitation.

                                We reserve all rights (but not the obligation) to remove and/or edit such content. When you post your content, you grant ValueNation non-exclusive, royalty-free and irrevocable right to use, reproduce, publish, modify such content throughout the world in any media.
                            </p>



                            <p style={{ "z-index": 199, position: 'relative', fontWeight: 'bold' }}>
                                License and Site Access
                            </p>

                            <p style={{ "z-index": 199, position: 'relative' }}>

                                We grant you a limited license to access and make personal use of this website. You are not allowed to download or modify it. This may be done only with written consent from us.
                            </p>


                            <p style={{ "z-index": 199, position: 'relative', fontWeight: 'bold' }}>
                                User Account
                            </p>
                            <p style={{ "z-index": 199, position: 'relative', fontWeight: 'bold' }}>

                                If you are an owner of an account on this website, you are solely responsible for maintaining the confidentiality of your private user details (username and password). You are responsible for all activities that occur under your account or password.
                                <br></br>
                                We reserve all rights to terminate accounts, edit or remove content and cancel orders in their sole discretion.
                            </p>



                            <button class="close-EmailForm" onClick={this.handleCloseEmailForm} style={{ "z-index": 199 }}> X </button>
                        </div>


                        <div class="blurred-background" onClick={this.handleCloseEmailForm} style={{ "z-index": 1 }}>
                        </div>


                    </div>

                }



                {
                    this.state.showNotes &&


                    <div class="row" id="termsID" style={{ "z-index": 199, position: 'relative', overflow: 'scroll' }}>

                        <div class="email-form-container" style={{ "z-index": 199, overflow: 'scroll' }}>

                            <p style={{ "z-index": 199, textAlign: 'left', overflow: 'scroll', color: "white", padding: '20px', paddingTop: '40px' }}>

                                We source our data from The U.S. Securities and Exchange Commission (SEC). By leveraging XBRL taxonomies, we group "company concepts" that best incompass the underlying "line item" in financial statements. For example, the "Revenue" line item, found in the income statemnt can be represented by the following XBRL concepts: "Revenues", "RevenueFromContractWithCustomerExcludingAssessedTax", "SalesRevenueNet", etc. These concepts incompass majority of the companies that file with the SEC, they are also consistant across years. We've tested our methodology with 30 companies in varying industries and have addressed every edge case within our sample. In the future we plan to run an exhaustive test to better flag any anomolies, but for now we rely on your help, so please dont hesistate to report any discrepancies with our data with the report button.

                                <br></br>
                                <br></br>
                                Some caveats: Financials for holding companies, insurance companies and banks have yet to be properly standardized.

                                <br></br>
                                <br></br>
                                Our data should be considered supplementary information to assist your investing decisions. Always check with the source, we'll provide a link to the appropriate filings for each company in your research tab.
                                <br></br>
                                <br></br>
                                Disclaimer: ValueNation is not liable for any damages that arise from the use of our data & content. Opinions expressed within the content are solely the author’s and do not reflect the opinions and beliefs of ValueNation or its affiliates.



                            </p>

                            <button class="close-EmailForm" onClick={this.handleCloseEmailForm} style={{ "z-index": 199 }}> X </button>
                        </div>


                        <div class="blurred-background" onClick={this.handleCloseEmailForm} style={{ "z-index": 1 }}>
                        </div>


                    </div>

                }




                {this.state.showEmailForm}







                {
                    this.state.showEmailForm &&


                    <div class="row" style={{ "z-index": 99 }}>

                        <div class="email-form-container" style={{ "z-index": 99 }}>

                            <h4 class="coming-soon">
                                Our API will be available soon! Drop your credentials below and we'll let you know when it's up.
                            </h4>

                            <EmailForm style={{ "z-index": 99 }}></EmailForm>
                            <button class="close-EmailForm" onClick={this.handleCloseEmailForm} style={{ "z-index": 99 }}> X </button>
                        </div>

                        <div class="blurred-background" onClick={this.handleCloseEmailForm} style={{ "z-index": 1 }}>
                        </div>


                    </div>

                }
            </div >
        )
    }
}