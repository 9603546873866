import React, { useState, useContext, useEffect } from 'react'

import { Col, Row } from "react-bootstrap";

import IntrinsicValue from "./IntrinsicValue";
import { DCFContext } from './contexts/DCFContext'
import ValuationDetails from './ValuationDetails'
import Alert from '@mui/material/Alert';

const DiscountedCashFlows = (props) => {

    const { showMore, editShowMore, tickerContext, editTickerContext } = useContext(DCFContext)
    const { showSuccessAlert, editSuccessAlert, showErrorAlert, editErrorAlert } = useContext(DCFContext)
    const { bearIntrinsicValue, editBearIntrinsicValueContext, bullIntrinsicValue, editBullIntrinsicValueContext, normalIntrinsicValue, editNormalIntrinsicValueContext } = useContext(DCFContext)


    var dYear1 = props.Year1 / ((1 + parseFloat(props.discountRate)) ** 1)
    var dYear2 = props.Year2 / ((1 + parseFloat(props.discountRate)) ** 2)
    var dYear3 = props.Year3 / ((1 + parseFloat(props.discountRate)) ** 3)
    var dYear4 = props.Year4 / ((1 + parseFloat(props.discountRate)) ** 4)
    var dYear5 = props.Year5 / ((1 + parseFloat(props.discountRate)) ** 5)
    var dYear6 = props.Year6 / ((1 + parseFloat(props.discountRate)) ** 6)
    var dYear7 = props.Year7 / ((1 + parseFloat(props.discountRate)) ** 7)
    var dYear8 = props.Year8 / ((1 + parseFloat(props.discountRate)) ** 8)
    var dYear9 = props.Year9 / ((1 + parseFloat(props.discountRate)) ** 9)
    var dYear10 = props.Year10 / ((1 + parseFloat(props.discountRate)) ** 10)


    const [bear, setBear] = useState(0)
    const [bull, setBull] = useState(0)
    const [normal, setNormal] = useState(0)



    useEffect(() => {
        if (props.dcfCase === "normal") {
            console.log('normal called')
            setNormal((Math.round((dYear1 + dYear2 + dYear3 + dYear4 + dYear5 + dYear6 + dYear7 + dYear8 + dYear9 + dYear10) * 100) / 100).toFixed(2))
            editNormalIntrinsicValueContext((Math.round((dYear1 + dYear2 + dYear3 + dYear4 + dYear5 + dYear6 + dYear7 + dYear8 + dYear9 + dYear10) * 100) / 100).toFixed(2))
        }
        if (props.dcfCase === "bear") {
            console.log('bear called')

            setBear((Math.round((dYear1 + dYear2 + dYear3 + dYear4 + dYear5 + dYear6 + dYear7 + dYear8 + dYear9 + dYear10) * 100) / 100).toFixed(2))
            editBearIntrinsicValueContext((Math.round((dYear1 + dYear2 + dYear3 + dYear4 + dYear5 + dYear6 + dYear7 + dYear8 + dYear9 + dYear10) * 100) / 100).toFixed(2))
        }
        if (props.dcfCase === "bull") {
            console.log('bull called')

            setBull((Math.round((dYear1 + dYear2 + dYear3 + dYear4 + dYear5 + dYear6 + dYear7 + dYear8 + dYear9 + dYear10) * 100) / 100).toFixed(2))
            editBullIntrinsicValueContext((Math.round((dYear1 + dYear2 + dYear3 + dYear4 + dYear5 + dYear6 + dYear7 + dYear8 + dYear9 + dYear10) * 100) / 100).toFixed(2))
        }

    }, []);



    // const [showMore, setShowMore] = useState(false)

    const valuationClick = () => {
        editShowMore(true)
        console.log('tickk check : ', props.tickerEntry)

        editTickerContext(props.tickerEntry)

        console.log('tickerContext check : ', tickerContext)

    }

    return (
        <div >


            <div className="year1Discounted">

                {(Math.round(dYear1 * 100) / 100).toFixed(2)}

            </div>

            <div className="year2Discounted">
                {(Math.round(dYear2 * 100) / 100).toFixed(2)}
            </div>

            <div className="year3Discounted">
                {(Math.round(dYear3 * 100) / 100).toFixed(2)}
            </div>

            <div className="year4Discounted">
                {(Math.round(dYear4 * 100) / 100).toFixed(2)}
            </div>

            <div className="year5Discounted">
                {(Math.round(dYear5 * 100) / 100).toFixed(2)}
            </div>

            <div className="year6Discounted">
                {(Math.round(dYear6 * 100) / 100).toFixed(2)}
            </div>

            <div className="year7Discounted">
                {(Math.round(dYear7 * 100) / 100).toFixed(2)}
            </div>


            <div className="year8Discounted">
                {(Math.round(dYear8 * 100) / 100).toFixed(2)}
            </div>

            <div className="year9Discounted">
                {(Math.round(dYear9 * 100) / 100).toFixed(2)}
            </div>

            <div className="year10Discounted">
                {(Math.round(dYear10 * 100) / 100).toFixed(2)}
            </div>

            <div class='row'>
                <div class='col' style={{ backgroundColor: 'yellow' }}>
                    <div className=" intrinsicValue" style={{ marginLeft: '2.5%' }}>
                        Intrinsic Value: {(Math.round((dYear1 + dYear2 + dYear3 + dYear4 + dYear5 + dYear6 + dYear7 + dYear8 + dYear9 + dYear10) * 100) / 100).toFixed(2)}

                        {props.dcfCase === "bull" ? editBullIntrinsicValueContext((Math.round((dYear1 + dYear2 + dYear3 + dYear4 + dYear5 + dYear6 + dYear7 + dYear8 + dYear9 + dYear10) * 100) / 100).toFixed(2)) : null}
                        {props.dcfCase === "bear" ? editBearIntrinsicValueContext((Math.round((dYear1 + dYear2 + dYear3 + dYear4 + dYear5 + dYear6 + dYear7 + dYear8 + dYear9 + dYear10) * 100) / 100).toFixed(2)) : null}
                        {props.dcfCase === "normal" ? editNormalIntrinsicValueContext((Math.round((dYear1 + dYear2 + dYear3 + dYear4 + dYear5 + dYear6 + dYear7 + dYear8 + dYear9 + dYear10) * 100) / 100).toFixed(2)) : null}

                        {props.dcfCase} checkkkk
                        {/* checkkkk{props.dcfCase} */}
                        <br></br>
                        bull: {bullIntrinsicValue}
                        bear: {bearIntrinsicValue}

                    </div >
                </div>

                <div class='col' style={{ backgroundColor: 'red' }}>
                    <div id="ValuationIcon" class=" sidebar-img-btn saveValuation" style={{ marginLeft: '30%' }} onClick={() => { valuationClick() }}></div>
                </div>

            </div>


            {showMore ? <div class='add-valuation-form' style={{ "z-index": 10 }}>
                <ValuationDetails
                    intrinsicValue={(Math.round((dYear1 + dYear2 + dYear3 + dYear4 + dYear5 + dYear6 + dYear7 + dYear8 + dYear9 + dYear10) * 100) / 100).toFixed(2)}
                    style={{ "z-index": 10 }}></ValuationDetails>

            </div>

                : <div></div>}

            {showMore ? <div class="blurred-background" onClick={() => editShowMore(false)} style={{
                "z-index": 9, backgroundColor: 'rgba(0, 0, 0, 0.55)'
            }}>
            </div> : <div></div>}

            {showSuccessAlert ? <Alert variant="filled" severity="success" style={{ position: 'fixed', top: '0px', marginLeft: '', zIndex: '1000' }}>
                Valuation updated successfully!
            </Alert> : <div />}

            {/* {showErrorAlert ? <Alert severity="error" style={{ position: 'fixed', top: '0px', marginLeft: '', zIndex: '1000' }}>This is an error message!</Alert>
                : <div />} */}



        </div >
    ) //highlight changed FCFs in CSS 
}

export default DiscountedCashFlows