import { useParams } from 'react-router-dom'
import { useEffect, useContext, useRef, useState } from "react";
import superinvestors from './superinvestor.json'
import { useHistory, useLocation } from 'react-router-dom';
import SuperinvestorLatestTable from './SuperinvestorLatestTable'
import { RouteContext } from './contexts/RouteContext'
// import chunk from 'lodash.groupby';
// import { groupBy } from 'lodash.groupby'
import * as _ from 'lodash';
import SuperinvestorLatestHoldings from './SuperinvestorLatestHoldings';
import SuperinvestorPreviousHoldings from './SuperinvestorPreviousHoldings'
import createHistory from 'history/createBrowserHistory'
import {
    Link

} from "react-router-dom";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';

// import { get, isEmpty, find, filter, has, debounce, groupBy } from 'lodash';

// ticker(sortable) name(sortable) %ofPortofoilio(sortable) activityBuyOrSellOrNoChange buyPrice(unsortable) buyPriceVsCurrentPriceChange(sortable) yourValuation(IF DNE put add button circular with light green background huh green border on hover fill in the light breen textcolor is filledinlightgreen or white)

const SuperinvestorProfile = () => {

    const history = useHistory();
    const historyCreate = createHistory();

    const { investor } = useParams()
    const { initID } = useParams()

    const [superinvestor, setSuperInvestor] = useState({});
    const [loadingStatus, setLoadingStatus] = useState(true);
    const [latestQuarterHoldings, setLatestQuarterHoldings] = useState([]);
    const [pastQuarters, setPastQuarters] = useState([]);
    const [showQuarter, setShowQuarter] = useState(0);
    const [valuations, setValuations] = useState([]);
    const [reportingDate, setReportingDate] = useState('');
    const [reportNumber, setReportNumber] = useState(parseInt((window.location.href).split('/')[(window.location.href).split('/').length - 1]));
    const [investorInfo, setInvestorInfo] = useState({});
    const [changeReport, setChangeReport] = useState('');



    const { cachedSavedValuations } = useContext(RouteContext)


    let getHoldings = async (cik, currentID, previousID) => {

        var urlCurrent = "https://obscure-meadow-68622.herokuapp.com/getThirteenFFromDb/" + cik + '/' + currentID;
        var urlPrevious = "https://obscure-meadow-68622.herokuapp.com/getThirteenFFromDb/" + cik + '/' + previousID;


        try {

            var responseCurrent = await fetch(urlCurrent);

            var fetchedResponseCurrent = await responseCurrent.json();

            console.log('fetchedResponseCurrent check : ', fetchedResponseCurrent)

            setReportingDate(fetchedResponseCurrent.reportDate)
            let currentYearHoldings = fetchedResponseCurrent.holdings;

            var responsePrevious = [];
            var fetchedResponsePrevious = {};
            try {
                responsePrevious = await fetch(urlPrevious);

                fetchedResponsePrevious = await responsePrevious.json();

                console.log('fetchedResponsePrevious check : ', fetchedResponsePrevious)

            }
            catch {
                fetchedResponsePrevious = { holdings: [{ name: '-', value: 1, shares: 1, cusip: "02079K107", ticker: 'GOOG' }] }
            }

            let prevYearHoldings = fetchedResponsePrevious.holdings;

            console.log('currentYearHoldingscurrentYearHoldings check : ', currentYearHoldings)


            // var response = await fetch(url);

            // var fetchedResponse = await response.json();

            // console.log('fetchedResponse check : ', fetchedResponse)

            let filings = [currentYearHoldings, prevYearHoldings];


            ///////// current quarter /////////

            // let currentYearHoldings = filings[0].holdings
            // let prevYearHoldings = filings[1].holdings

            // let change = 
            let carryOver = false;

            let coveredStocks = 0;

            let change = 0;

            let currentYearHoldingsNamesArr = []
            let prevYearHoldingsNamesArr = []

            let final = []

            for (var i = 0; i < currentYearHoldings.length; i++) {
                currentYearHoldingsNamesArr.push(currentYearHoldings[i].name)
                for (var j = 0; j < prevYearHoldings.length; j++) {


                    // if it exists in latest filing but not in prev years filing, then it's a new postion

                    coveredStocks = coveredStocks + 1;

                    if (prevYearHoldings[j].cusip === currentYearHoldings[i].cusip) {
                        carryOver = true;
                        change = currentYearHoldings[i].shares / prevYearHoldings[j].shares;

                        console.log('checking change', change)

                        if (change === 1) {
                            currentYearHoldings[i].change = 0  //changed this from j to i
                            final.push({
                                'holding': currentYearHoldings[i],
                                'change': 'no change'
                            })
                        }
                        if (change > 1) {
                            currentYearHoldings[i].change = ((change - 1) * 100) //changed this from j to i

                            final.push({
                                'holding': currentYearHoldings[i],
                                'change': ((change - 1) * 100)
                            })


                        }
                        if (change < 1) {
                            currentYearHoldings[i].change = ((change - 1) * -100) * -1

                            final.push({
                                'holding': currentYearHoldings[i],
                                'change': ((change - 1) * -100) * -1
                            })


                        }
                        // check if fully exited
                        break;
                    }
                    else {
                        if (coveredStocks === prevYearHoldings.length) {

                            currentYearHoldings[i].change = 'new' //changed this from j to i

                            final.push({
                                'holding': currentYearHoldings[i],
                                'change': 'new'
                            })

                            console.log(currentYearHoldings[i].name + ' is a new position')
                        }

                    }

                }
                coveredStocks = 0;
            }

            for (var i = 0; i < prevYearHoldings.length; i++) {
                prevYearHoldingsNamesArr.push(prevYearHoldings[i].name)
            }

            const fullySold = prevYearHoldingsNamesArr.filter((x) => !currentYearHoldingsNamesArr.includes(x))
            console.log('not in common', fullySold)

            for (var i = 0; i < fullySold.length; i++) {
                final.push({
                    'holding': {
                        'name': fullySold[i],
                        'shares': 0,
                        'value': 0
                    }
                    ,
                    'change': 'sold'
                })
            }

            let priceArr = []
            let element = {}
            let valuation = {}
            let totalPortfolioValue = 0


            for await (element of final) {
                let response = await getPriceAndTickerFromCusip(element.holding.cusip)
                element.price = await response.split(',')[1]
                element.holding.ticker = await response.split(',')[0]

                element.buyPrice = (parseFloat(element.holding.value) * 1000) / parseFloat(element.holding.shares)

                totalPortfolioValue = parseFloat(totalPortfolioValue) + parseFloat(element.holding.value)
            }


            ///////// previous quarters /////////
            console.log('filings check', filings)
            let filing = {}



            console.log('totalPortfolioValue', totalPortfolioValue)
            for await (element of final) {
                element.holdingPercentage = await parseFloat(element.holding.value) * 100 / await parseFloat(totalPortfolioValue)
                // element.yourValuation = await getValuationByTicker(element.holding.ticker)

            }


            console.log('set state', await final)

            let sortedFinal = _.sortBy(await final, [function (o) { return o.holdingPercentage; }]);


            setLatestQuarterHoldings(sortedFinal.reverse())


            setPastQuarters(filings)
            console.log('post append check', filings)

            setLoadingStatus(false)


        }
        catch (error) {

            console.log("error: ", error)
            // history.push('/error')
        }



    }


    let getTicker = async (cusip) => {
        console.log('cusip init', cusip)

        let ticker = ''

        // cusip to ticker
        var cusipUrl = "https://obscure-meadow-68622.herokuapp.com/getTickerFromCusip/" + cusip
        try {
            var cusipResponse = await fetch(cusipUrl);

            var cusipFetchedResponse = await cusipResponse.json();

            console.log('cusipFetchedResponse check : ', cusipFetchedResponse)

            ticker = cusipFetchedResponse.ticker

            return ticker

        }
        catch (error) {
            console.log("couldnt fetch cusip ", error)
        }

    }

    let getPrice = async (cusip) => {

        console.log('cusip init', cusip)

        let ticker = ''

        // cusip to ticker
        var cusipUrl = "https://obscure-meadow-68622.herokuapp.com/getTickerFromCusip/" + cusip
        try {
            var cusipResponse = await fetch(cusipUrl);

            var cusipFetchedResponse = await cusipResponse.json();

            console.log('cusipFetchedResponse check : ', cusipFetchedResponse)

            ticker = cusipFetchedResponse.ticker


        }
        catch (error) {
            console.log("couldnt fetch cusip ", error)
        }

        // ticker to price
        var tickerUrl = await "https://obscure-meadow-68622.herokuapp.com/getInterestedStock/" + await ticker

        // http://localhost:5000/sendAlerts/FB
        try {
            var tickerResponse = await fetch(tickerUrl);

            var tickerFetchedResponse = await tickerResponse.json();

            console.log('tickerFetchedResponse check : ', tickerFetchedResponse)


            return tickerFetchedResponse.price


        }
        catch (error) {

            console.log("error: ", error)
        }


    }

    let getPriceAndTickerFromCusip = async (cusip) => {

        console.log('cusip init', cusip)

        let ticker = ''
        let price = '-'
        // cusip to ticker
        var cusipUrl = "https://obscure-meadow-68622.herokuapp.com/getPriceAndTickerFromCusip/" + cusip
        try {
            var cusipResponse = await fetch(cusipUrl);

            var cusipFetchedResponse = await cusipResponse.json();

            console.log('cusipFetchedResponse check : ', cusipFetchedResponse)

            ticker = cusipFetchedResponse.ticker
            price = cusipFetchedResponse.price


        }
        catch (error) {
            console.log("couldnt fetch cusip ", error)
        }

        console.log('returning cus: ', ticker + ',' + price)

        return ticker + ',' + price

    }

    let getValuationByTicker = async (ticker) => {
        const jwtToken = localStorage.getItem('jwtToken')

        let url = 'https://obscure-meadow-68622.herokuapp.com/getUserValuationInfo/' + ticker + '/'


        try {
            const res = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + jwtToken

                }
            })
            const data = await res.json();

            console.log('valuation data', data)



            console.log('ELSE DNE selection CHECK')
            if (data.isMarketCap) {

                return '$' + data.fairPrice + ' Market Cap'
            }
            else if (!data.isMarketCap && data.status !== "DNE") {

                return '$' + data.fairPrice + ' Per Share'
            }
            else {
                return ''
            }

        }
        catch {
            // exists
            return ''

        }


        // console.log('data check new', data)

    }

    let getValuations = async () => {
        var url = 'https://obscure-meadow-68622.herokuapp.com/info'

        const jwtToken = localStorage.getItem('jwtToken')

        // http://localhost:5000/sendAlerts/FB
        try {
            const response = await fetch('https://obscure-meadow-68622.herokuapp.com/info', {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    "Content-Type": 'application/json',
                    'Authorization': 'Bearer ' + jwtToken

                },

            });

            var fetchedResponse = await response.json();

            console.log('fetched valuation response check : ', fetchedResponse)

            setValuations(fetchedResponse.valuations)


        }
        catch (error) {

            console.log("error: ", error)
        }

    }

    let getInvestorInfo = async (investorName) => {

        let fullName = investorName.split("_")[0] + " " + investorName.split("_")[1]

        let superinvestorTemp = {}
        for (superinvestorTemp of superinvestors) {
            if (superinvestorTemp.investor === fullName) {
                setInvestorInfo(superinvestorTemp)
            }
        }
    }

    useEffect(() => {

        setLoadingStatus(true)

        let investorName = investor.split("_")[0] + " " + investor.split("_")[1]

        for (var i = 0; i < superinvestors.length; i++) {
            console.log('investorName', investorName)
            if (superinvestors[i].investor === investorName) {
                console.log('cik check fund check :', superinvestors[i].name)
                getHoldings(superinvestors[i].cik, parseInt(initID), parseInt(initID) + 1)
            }
        }

        getInvestorInfo(investorName)

        // getValuations()

        // getValuationByTicker('AAPL')
        // setSuperInvestor({
        //     name:
        // })
    }, []);

    useEffect(() => {

        setLoadingStatus(true)

        let investorName = investor.split("_")[0] + " " + investor.split("_")[1]

        for (var i = 0; i < superinvestors.length; i++) {
            console.log('investorName', investorName)
            if (superinvestors[i].investor === investorName) {
                console.log('cik check fund check :', superinvestors[i].name)
                getHoldings(superinvestors[i].cik, parseInt(initID), parseInt(initID) + 1)
            }
        }

        setChangeReport(initID)

        // getValuations()

        // getValuationByTicker('AAPL')
        // setSuperInvestor({
        //     name:
        // })
    }, [reportNumber, initID]);

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'firstName', headerName: 'First name', width: 130 },
        { field: 'lastName', headerName: 'Last name', width: 130 },
        {
            field: 'age',
            headerName: 'Age',
            type: 'number',
            width: 90,
        },
        {
            field: 'fullName',
            headerName: 'Full name',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 160,
            valueGetter: (params) =>
                `${params.row.firstName || ''} ${params.row.lastName || ''}`,
        },
    ];

    const rows = [
        { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
        { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
        { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
        { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
        { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
        { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
        { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
        { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
        { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
    ];



    let incrementNav = () => {
        setShowQuarter(showQuarter + 1)
    }

    let decrementNav = () => {

        if (showQuarter === 0) {
            console.log('already at latest')
        }
        else {
            setShowQuarter(showQuarter - 1)
        }
    }

    let prevReport = (newReportNumber) => {
        console.log('newReportNumber check ', newReportNumber)
        console.log('investor check ', investor)
        history.push('/superinvestors/' + investor + '/' + newReportNumber)
        setReportNumber(newReportNumber)
    }

    let nextReport = (newReportNumber) => {
        console.log('newReportNumber check ', newReportNumber)
        console.log('investor check ', investor)
        history.push('/superinvestors/' + investor + '/' + newReportNumber)
        setReportNumber(newReportNumber)
    }

    let handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            history.push('/superinvestors/' + investor + '/' + changeReport)
        }
    }

    let handleChange = (e) => {
        // if (e.target.value === initID || e.target.value === '') {

        // }
        // else {

        setChangeReport(e.target.value)
        // history.push('/superinvestors/' + investor + '/' + e.target.value)

        // }
    }

    return (
        <div >

            <div className="profile-container" style={{ marginTop: '40px' }}>
                <div className="row">
                    <div className='col-6'>
                        <div style={{ color: 'white' }}>

                            Investor : {investor.split('_')[0] + " " + investor.split('_')[1]}
                        </div>

                    </div>
                    <div className='col-6'>
                        <div style={{ color: 'white' }}>
                            Reporting Date : {reportingDate}
                        </div>
                    </div>
                </div>





            </div>

            <div className="report-nav-container">
                <div className="reportNav">

                    <div className="row">

                        <div className='col-4 removePaddingRight'>
                            <div style={{ color: 'white' }} onClick={() => { prevReport(parseInt(initID) - 1) }}>

                                <ArrowLeftIcon style={{ marginLeft: '15px', fontSize: '40px' }}></ArrowLeftIcon>
                            </div>
                        </div>
                        <div className='col-4 removePadding' style={{ paddingLeft: '0 !important', paddingRight: '0 !important', alignSelf: 'center' }}>

                            <input className="navInput" value={changeReport} onKeyDown={(e) => handleKeyDown(e)} onChange={(e) => handleChange(e)} >
                            </input>
                        </div>
                        <div className='col-4 removePadding'>

                            <div style={{ color: 'white' }} onClick={() => { prevReport(parseInt(initID) + 1) }}>
                                <ArrowRightIcon style={{ fontSize: '40px' }}></ArrowRightIcon>
                            </div>
                        </div>
                    </div>
                </div>

            </div>




            {/* <Link to={'/superinvestors/Mohnish_Pabrai/' + (parseInt(reportNumber) + 1)}> PREVIOUS LINK </Link> */}

            {/* <Link style={{ color: 'white' }} to="/superinvestors/Michael_Burry/1">NEXT</Link> */}

            {/* <button style={{ color: 'white' }} onClick={() => { history.push('/superinvestors/Michael_Burry/1') }}> NEXT </button> */}

            <div style={{ "z-index": "7" }} className={loadingStatus ? 'center-loader' : null}>
                <div className={loadingStatus ? 'pulse-container' : null}>
                    <div className={loadingStatus ? 'pulse-bubble pulse-bubble-1' : null}></div>
                    <div className={loadingStatus ? 'pulse-bubble pulse-bubble-2' : null}></div>
                    <div className={loadingStatus ? 'pulse-bubble pulse-bubble-3' : null}></div>
                </div>
            </div>



            <div style={{ color: 'white' }}>





                <div className='holdings-container'  >
                    <div className='holdings-table'>

                    </div>
                    <div className='filings-nav-leftArrow' onClick={() => { decrementNav() }}>

                    </div>

                    <div className='report-date'>

                    </div>

                    <div className='filings-nav-rightArrow' onClick={() => { incrementNav() }}>

                    </div>
                </div>

            </div>



            {/* <div style={{ width: '70%', marginLeft: '15%' }}>
                <SuperinvestorLatestTable holdingsArr={latestQuarterHoldings}></SuperinvestorLatestTable>
            </div> */}
            {(parseInt(initID) === 0) ? <div className="holdings-list" style={loadingStatus ? { filter: 'blur(5px)' } : { filter: 'blur(0px)' }}>
                <SuperinvestorLatestHoldings reportNumber={initID} holdingsArr={latestQuarterHoldings}></SuperinvestorLatestHoldings>
            </div> : <div className="holdings-list" style={loadingStatus ? { filter: 'blur(5px)' } : { filter: 'blur(0px)' }}>
                <SuperinvestorPreviousHoldings reportNumber={initID} holdingsArr={latestQuarterHoldings}></SuperinvestorPreviousHoldings>
            </div>}




        </div>
    )
}
export default SuperinvestorProfile