import React from 'react'
export default class Screener extends React.PureComponent {
    constructor(props) {
        super(props);
        this._ref = React.createRef();
    }
    componentDidMount() {
        const script = document.createElement('script');
        script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-screener.js'
        script.async = true;
        script.innerHTML = JSON.stringify({
            "width": "1100",
            "height": "523",
            "defaultColumn": "overview",
            "defaultScreen": "most_capitalized",
            "showToolbar": true,
            "locale": "en",
            "market": "us",
            "colorTheme": "dark"
        })/* JSON-ENCODED SETTINGS STRING FROM EMBED CODE */
        this._ref.current.appendChild(script);
    }
    render() {
        return (
            <div class="tradingview-widget-container" ref={this._ref}>
                <div class="tradingview-widget-container__widget"></div>

            </div>
        );
    }

}

//  <div class="tradingview-widget-container">
//   <div class="tradingview-widget-container__widget"></div>
//   <div class="tradingview-widget-copyright"><a href="https://www.tradingview.com/screener/" rel="noopener" target="_blank"><span class="blue-text">Stock Screener</span></a> by TradingView</div>
//   <script type="text/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-screener.js" async>
//   {
//   "width": "1100",
//   "height": "523",
//   "defaultColumn": "overview",
//   "defaultScreen": "most_capitalized",
//   "showToolbar": true,
//   "locale": "en",
//   "market": "us",
//   "colorTheme": "light"
// }
//   </script>
// </div>
