//growth percentage icon https://supersalon.com/wp-content/uploads/icon-percentincrese.png
// disocunt rate icon https://image.shutterstock.com/image-vector/discount-offer-tag-icon-shopping-260nw-1208529259.jpg
//https://www.ibanding.my/wp-content/uploads/2019/11/Discount.png
//https://starpng.com/public/uploads/preview/discount-tag-percentage-png-11574578659vyps3zmim2.png
//terminal , downloaded png

import React from "react";
import { useState } from "react";
import Financials from "./Financials";
import FiveYearCashFlows from "./FiveYearCashFlows";
import DiscountedCashFlows from "./DiscountedCashFlows"

import DCFContextProvider from "./contexts/DCFContext";

import HelpIcon from '@mui/icons-material/Help';

import './styles/dcfStyles.css'
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";


//export default class DCFInputs extends React.Component {

export const exportValues = 200;

export default class CustomerForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {


            initialFCF: this.props.netIncome,
            fiveYearGrowth: '',
            sixYearGrowth: '',
            discountRate: 10, //hover over discount rate explainng why 10% 
            terminalMultiple: 15, // hover over terminal multiple explain how mohnish pabrai recomends nothing over 15 when looking 10 Years ahead

        };
    }

    componentDidMount() {
        this.setState({
            initialFCF: this.props.netIncome
        });
        console.log("component did mount net income: " + this.state.netIncome)
    }
    handleInitialFcfChanged(event) {
        //      var dcf = this.state.dcf;
        //        dcf.initialFCF = event.target.value;

        this.setState({ initialFCF: event.target.value });
    }
    handleFiveYearGrowthChanged(event) {
        //   var dcf = this.state.dcf;
        //  dcf.fiveYearGrowth = event.target.value;

        this.setState({ fiveYearGrowth: event.target.value });
    }
    handleSixYearGrowthChanged(event) {
        //  var dcf = this.state.dcf;
        // dcf.sixYearGrowth = event.target.value;

        this.setState({ sixYearGrowth: event.target.value });
    }
    handleDiscountRateChanged(event) {
        //        var dcf = this.state.dcf;
        //      dcf.discountRate = event.target.value;

        this.setState({ discountRate: event.target.value });
    }
    handleterminalMultipleChanged(event) {
        //   var dcf = this.state.dcf;
        //  dcf.terminalMultiple = event.target.value;

        this.setState({ terminalMultiple: event.target.value });
    }
    render() {
        return (
            <div class="row">


                <div class="col-lg-3" >

                    <div class="form">
                        <h4 style={{ color: 'white', marginTop: '15px', fontWeight: 'bolder' }}>Valuation Calculator</h4>
                        <div class="input-container ic1">
                            <input id="initalFcf" class="input"
                                type="number"
                                placeholder=" "
                                value={this.state.netIncome}
                                onChange={this.handleInitialFcfChanged.bind(this)}
                            />
                            <div class="cut"></div>
                            <label for="initalFcf" class="placeholder">Initial FCF</label>

                            <div>

                                <OverlayTrigger
                                    key="top"
                                    placement="top"

                                    overlay={
                                        <Tooltip>
                                            The starting value. Depending on the business, you can use the Trailing twelve Months earnings per share, Trailing twelve Months free cash flow per share, or the 'owner's earnings'
                                        </Tooltip>
                                    }

                                >
                                    <HelpIcon class='help-icon'></HelpIcon>

                                </OverlayTrigger>

                            </div>

                        </div>
                        <div class="input-container ic2">
                            <input id="oneFiveYearGrowth" class="input" placeholder=" "
                                type="number"
                                value={this.state.fiveYearGrowth}
                                onChange={this.handleFiveYearGrowthChanged.bind(this)}
                            />
                            <div class="cut"></div>
                            <label for="oneFiveYearGrowth" class="placeholder">1-5 Year growth rate (%)</label>

                            <div>

                                <OverlayTrigger
                                    key="top"
                                    placement="top"

                                    overlay={
                                        <Tooltip>
                                            Growth rate between year 1 to year 5
                                        </Tooltip>
                                    }

                                >
                                    <HelpIcon class='help-icon'></HelpIcon>

                                </OverlayTrigger>

                            </div>

                        </div>
                        <div class="input-container ic2">
                            <input id="sixTenYearGrowth" class="input" placeholder=" "
                                type="number"
                                value={this.state.sixYearGrowth}
                                onChange={this.handleSixYearGrowthChanged.bind(this)}
                            />
                            <div class="cut cut-short"></div>
                            <label for="sixTenYearGrowth" class="placeholder">6-10 Year growth rate (%)</label>

                            <div>
                                <OverlayTrigger
                                    key="top"
                                    placement="top"

                                    overlay={
                                        <Tooltip>
                                            Growth rate between year 6 to year 10
                                        </Tooltip>
                                    }

                                >
                                    <HelpIcon class='help-icon'></HelpIcon>

                                </OverlayTrigger>                            </div>

                        </div>
                        <div class="input-container ic2">
                            <input id="discountRate" class="input" placeholder=" "
                                type="number"
                                value={this.state.discountRate}
                                onChange={this.handleDiscountRateChanged.bind(this)}
                            />
                            <div class="cut cut-short"></div>
                            <label for="discountRate" class="placeholder">Discount Rate (%)</label>

                            <div>
                                <OverlayTrigger
                                    key="top"
                                    placement="top"

                                    overlay={
                                        <Tooltip>
                                            The rate of return you're looking for. (10% is good given the current environment)
                                        </Tooltip>
                                    }

                                >
                                    <HelpIcon class='help-icon'></HelpIcon>

                                </OverlayTrigger>

                            </div>

                        </div>
                        <div class="input-container ic2">
                            <input id="terminalMultiple" class="input" placeholder=" "
                                type="number"
                                value={this.state.terminalMultiple}
                                onChange={this.handleterminalMultipleChanged.bind(this)}
                            />
                            <div class="cut cut-short"></div>
                            <label for="terminalMultiple" class="placeholder">Terminal Multiple</label>

                            <div>

                                <OverlayTrigger
                                    key="top"
                                    placement="top"

                                    overlay={
                                        <Tooltip>
                                            The multiple at which you can sell the business in year 10. (For beginners: use the median historical P/E Ratio, don't go over 35x)
                                        </Tooltip>
                                    }

                                >
                                    <HelpIcon class='help-icon'></HelpIcon>

                                </OverlayTrigger>
                            </div>

                        </div>
                    </div>


                </div>

                <div class="col-lg-9" style={{ marginTop: '15px' }}>
                    <div class="yearsDiv">
                        <DCFContextProvider>
                            <FiveYearCashFlows

                                dcfCase={this.props.dcfCase}
                                tickerEntry={this.props.tickerEntry}

                                initialFCF={this.props.netIncome}


                                fiveYearGrowth={parseInt(this.state.fiveYearGrowth) / 100}
                                sixYearGrowth={parseInt(this.state.sixYearGrowth) / 100}
                                terminalMultiple={this.state.terminalMultiple}
                                discountRate={parseInt(this.state.discountRate) / 100}




                                Year1={this.state.initialFCF * ((1 + parseFloat(this.state.fiveYearGrowth) / 100) ** 0)}

                                Year2={this.state.initialFCF * ((1 + parseFloat(this.state.fiveYearGrowth) / 100) ** 1)}
                                Year3={this.state.initialFCF * ((1 + parseFloat(this.state.fiveYearGrowth) / 100) ** 2)}
                                Year4={this.state.initialFCF * ((1 + parseFloat(this.state.fiveYearGrowth) / 100) ** 3)}
                                Year5={this.state.initialFCF * ((1 + parseFloat(this.state.fiveYearGrowth) / 100) ** 4)}

                                Year6={(this.state.initialFCF * ((1 + parseFloat(this.state.fiveYearGrowth) / 100) ** 4)) * ((1 + parseFloat(this.state.sixYearGrowth / 100)) ** 1)}
                                Year7={(this.state.initialFCF * ((1 + parseFloat(this.state.fiveYearGrowth) / 100) ** 4)) * ((1 + parseFloat(this.state.sixYearGrowth / 100)) ** 2)}
                                Year8={(this.state.initialFCF * ((1 + parseFloat(this.state.fiveYearGrowth) / 100) ** 4)) * ((1 + parseFloat(this.state.sixYearGrowth / 100)) ** 3)}
                                Year9={(this.state.initialFCF * ((1 + parseFloat(this.state.fiveYearGrowth) / 100) ** 4)) * ((1 + parseFloat(this.state.sixYearGrowth / 100)) ** 4)}
                                Year10={(this.state.initialFCF * ((1 + parseFloat(this.state.fiveYearGrowth) / 100) ** 4)) * ((1 + parseFloat(this.state.sixYearGrowth / 100)) ** 5) + (parseFloat(this.state.terminalMultiple - 1) * ((this.state.initialFCF * ((1 + parseFloat(this.state.fiveYearGrowth / 100)) ** 4)) * ((1 + parseFloat(this.state.sixYearGrowth / 100)) ** 5)))}
                            ></FiveYearCashFlows>
                        </DCFContextProvider>
                    </div>
                </div>




            </div >
        );
    }
}

//                    initialFCF={((this.state.initialFCF !== this.props.netIncome)) ? this.props.Year1 : this.state.Year1}






/* working old rendition



import React from "react";
import { useState } from "react";

import Financials from "./Financials";
import FiveYearCashFlows from "./FiveYearCashFlows";

//export default class DCFInputs extends React.Component {

export const exportValues = 200;

export default class CustomerForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {


            initialFCF: this.props.netIncome,
            fiveYearGrowth: '',
            sixYearGrowth: '',
            discountRate: 0.1, //hover over discount rate explainng why 10%
            terminalMultiple: 15, // hover over terminal multiple explain how mohnish pabrai recomends nothing over 15 when looking 10 Years ahead

        };
    }

    componentDidMount() {
        this.setState({
            initialFCF: this.props.netIncome
        });
        console.log("component did mount net income: " + this.state.netIncome)
    }
    handleInitialFcfChanged(event) {
        //      var dcf = this.state.dcf;
        //        dcf.initialFCF = event.target.value;

        this.setState({ initialFCF: event.target.value });
    }
    handleFiveYearGrowthChanged(event) {
        //   var dcf = this.state.dcf;
        //  dcf.fiveYearGrowth = event.target.value;

        this.setState({ fiveYearGrowth: event.target.value });
    }
    handleSixYearGrowthChanged(event) {
        //  var dcf = this.state.dcf;
        // dcf.sixYearGrowth = event.target.value;

        this.setState({ sixYearGrowth: event.target.value });
    }
    handleDiscountRateChanged(event) {
        //        var dcf = this.state.dcf;
        //      dcf.discountRate = event.target.value;

        this.setState({ discountRate: event.target.value });
    }
    handleterminalMultipleChanged(event) {
        //   var dcf = this.state.dcf;
        //  dcf.terminalMultiple = event.target.value;

        this.setState({ terminalMultiple: event.target.value });
    }
    render() {
        return (
            <div>
                Initial FCF
                <input
                    type="number"
                    placeholder={this.props.netIncome}
                    value={this.state.netIncome}
                    onChange={this.handleInitialFcfChanged.bind(this)}
                />
                <br></br>
                1-5 Year growth rate
                <input
                    type="number"
                    value={this.state.fiveYearGrowth}
                    onChange={this.handleFiveYearGrowthChanged.bind(this)}
                ></input>
                <br></br>
                6-10 Year growth rate
                <input
                    type="number"
                    value={this.state.sixYearGrowth}
                    onChange={this.handleSixYearGrowthChanged.bind(this)}
                ></input>
                <br></br>
                Discount Rate
                <input
                    type="number"
                    value={this.state.discountRate}
                    onChange={this.handleDiscountRateChanged.bind(this)}
                ></input>
                <br></br>
                Terminal Multiple
                <input
                    type="number"
                    value={this.state.terminalMultiple}
                    onChange={this.handleterminalMultipleChanged.bind(this)}
                ></input>
                <FiveYearCashFlows

                    initialFCF={this.props.netIncome}


                    fiveYearGrowth={this.state.fiveYearGrowth}
                    sixYearGrowth={this.state.sixYearGrowth}
                    terminalMultiple={this.state.terminalMultiple}
                    discountRate={this.state.discountRate}




                    Year1={this.state.initialFCF * ((1 + parseFloat(this.state.fiveYearGrowth)) ** 0)}

                    Year2={this.state.initialFCF * ((1 + parseFloat(this.state.fiveYearGrowth)) ** 1)}
                    Year3={this.state.initialFCF * ((1 + parseFloat(this.state.fiveYearGrowth)) ** 2)}
                    Year4={this.state.initialFCF * ((1 + parseFloat(this.state.fiveYearGrowth)) ** 3)}
                    Year5={this.state.initialFCF * ((1 + parseFloat(this.state.fiveYearGrowth)) ** 4)}

                    Year6={(this.state.initialFCF * ((1 + parseFloat(this.state.fiveYearGrowth)) ** 4)) * ((1 + parseFloat(this.state.sixYearGrowth)) ** 1)}
                    Year7={(this.state.initialFCF * ((1 + parseFloat(this.state.fiveYearGrowth)) ** 4)) * ((1 + parseFloat(this.state.sixYearGrowth)) ** 2)}
                    Year8={(this.state.initialFCF * ((1 + parseFloat(this.state.fiveYearGrowth)) ** 4)) * ((1 + parseFloat(this.state.sixYearGrowth)) ** 3)}
                    Year9={(this.state.initialFCF * ((1 + parseFloat(this.state.fiveYearGrowth)) ** 4)) * ((1 + parseFloat(this.state.sixYearGrowth)) ** 4)}
                    Year10={(this.state.initialFCF * ((1 + parseFloat(this.state.fiveYearGrowth)) ** 4)) * ((1 + parseFloat(this.state.sixYearGrowth)) ** 5) + (parseFloat(this.state.terminalMultiple - 1) * ((this.state.initialFCF * ((1 + parseFloat(this.state.fiveYearGrowth)) ** 4)) * ((1 + parseFloat(this.state.sixYearGrowth)) ** 5)))}
                ></FiveYearCashFlows>

            </div >
        );
    }
}
//                    initialFCF={((this.state.initialFCF !== this.props.netIncome)) ? this.props.Year1 : this.state.Year1}

*/