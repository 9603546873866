import React, { useState, useEffect, useContext, useRef } from "react";

import Financials from "./Financials";

import { Radar, Line, Bar } from "react-chartjs-2"
import BarChartIcon from '@mui/icons-material/BarChart';
import Chart from './Chart'


//OFFICIAL metric names 
//CF : Free Cash Flow	, Free Cash Flow Growth, Free Cash Flow Per Share
//IS : Revenue, Net Income, Revenue Growth, Shares Outstanding (Basic), Gross Margin, Operating Margin, Profit Margin, FCF Margin, EPS Growth, EPS (Diluted), Dividend Per Share
//R  : Market Capitalization (this Year), PE Ratio, PS Ratio, PB Ratio, P/FCF Ratio, EV/EBITDA Ratio, EV/FCF Ratio, Return on Capital (ROIC), Return on Equity (ROE), Return on Assets (ROA), Debt / Equity Ratio [inverse for radar], Market Capitalization (cagr for radar)
//BS : Total Current Assets	- Total Liabilities

// Radar chart over time : roic,roe,roa, 1/DtoE, cagr return, margin growth over time, management, employee sentiment
export default class Overview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            r: this.props.r,
            cf: this.props.cf,
            is: this.props.is,
            bs: this.props.bs,
        };
    }


    renderOV() {

        //OFFICIAL metric names 
        //CF : Free Cash Flow	, Free Cash Flow Growth, Free Cash Flow Per Share
        //IS : Revenue, Net Income, Revenue Growth, Shares Outstanding (Basic), Gross Margin, Operating Margin, Profit Margin, FCF Margin, EPS Growth, EPS (Diluted), Dividend Per Share
        //R  : Market Capitalization (this Year), PE Ratio, PS Ratio, PB Ratio, P/FCF Ratio, EV/EBITDA Ratio, EV/FCF Ratio, Return on Capital (ROIC), Return on Equity (ROE), Return on Assets (ROA), Debt / Equity Ratio [inverse for radar], Market Capitalization (cagr for radar)
        //BS : Total Current Assets	- Total Liabilities
        // graph: pe,pb,ps, pfcf

        // RENDER ORDER
        // radar: roic,roe, roa, 1/(d/e), cagr
        // quick: p/e now, p/b now, p/s now, ev/ebitda now, ev/fcf now , median roic, median roe, median roa, 10 Year cagr fcf/eps/revenue, debt equity
        // bulk : Rev , rev growth, fcf fcfgrowth, netincome, eps, eps growth , margins
        // dcf

        try {

            var hideItem = false;
            var currentYear = 2020;
            var showHistory = currentYear - 10;
            var lineItemFlag = false
            var row = document.createElement
            //    var Years = Object.keys(this.state.cf[1]).length;
            var endCf = false;

            var renOV = [];

            var keyMetric = false;

            var RevenueIndex = -1; //is
            var RevenueGrowthIndex = -1; //is
            var FreeCashFlowIndex = -1//cf
            var FreeCashFlowGrowthIndex = -1//cf
            var NetIncomeIndex = -1//is
            var EPSDilutedIndex = -1//is
            var EPSGrowthIndex = -1//is
            var DividendPerShareIndex = -1//is
            var GrossMarginIndex = -1//is
            var OperatingMarginIndex = -1//is
            var ProfitMarginIndex = -1//is
            var FCFMarginIndex = -1//is

            var NoEPSGrowth = false;
            var NoFCFGrowth = false;
            var NoDividend = false;

            var countItems = 0;

            //OFFICIAL metric names 
            //CF : Free Cash Flow	, Free Cash Flow Growth, Free Cash Flow Per Share
            //IS : Revenue, Net Income, Revenue Growth, Shares Outstanding (Basic), Gross Margin, Operating Margin, Profit Margin, FCF Margin, EPS Growth, EPS (Diluted), Dividend Per Share
            //R  : Market Capitalization (this Year), PE Ratio, PS Ratio, PB Ratio, P/FCF Ratio, EV/EBITDA Ratio, EV/FCF Ratio, Return on Capital (ROIC), Return on Equity (ROE), Return on Assets (ROA), Debt / Equity Ratio [inverse for radar], Market Capitalization (cagr for radar)
            //BS : Total Current Assets	- Total Liabilities
            for (var i = 0; i < this.props.cf.length; i++) {
                if (this.props.cf[i].Year === "Free Cash Flow") {
                    FreeCashFlowIndex = i;
                    countItems++
                }
                if (this.props.cf[i].Year === "Free Cash Flow Growth") {
                    FreeCashFlowGrowthIndex = i;
                    countItems++

                }
                else {
                    NoFCFGrowth = true;
                }
            }


            for (var i = 0; i < this.props.is.length; i++) {

                if (this.props.is[i].Year === "Revenue") {
                    RevenueIndex = i;
                    countItems++

                }
                if (this.props.is[i].Year === "Revenue Growth") {
                    RevenueGrowthIndex = i;
                    countItems++

                }

                if (this.props.is[i].Year === "Net Income") {
                    NetIncomeIndex = i;
                    countItems++
                }
                if (this.props.is[i].Year === "EPS (Diluted)") {
                    EPSDilutedIndex = i;
                    countItems++
                }
                if (this.props.is[i].Year === "EPS Growth") {
                    EPSGrowthIndex = i;
                    countItems++
                }
                else {
                    NoEPSGrowth = true;
                }
                if (this.props.is[i].Year === "Dividend Per Share") {
                    DividendPerShareIndex = i;
                    countItems++
                }
                else {
                    NoDividend = true;
                }
                if (this.props.is[i].Year === "Gross Margin") {

                    GrossMarginIndex = i;
                    countItems++
                }
                if (this.props.is[i].Year === "Operating Margin") {
                    OperatingMarginIndex = i;
                    countItems++
                }
                if (this.props.is[i].Year === "Profit Margin") {
                    ProfitMarginIndex = i;
                    countItems++
                }
                if (this.props.is[i].Year === "FCF Margin") {
                    FCFMarginIndex = i;
                    countItems++
                }

            }



            //cf loop check for: Free Cash Flow Margin

            //custom i which is the amount of information you want to show

            for (var i = -1; i < (countItems + 1); i++) {


                if (i === -1) {
                    renOV.push(<th>YearSs</th >);
                }



                // in the order you want to push set by if's
                if (i === 0) { //first thing to show
                    renOV.push(<td>{this.props.is[RevenueIndex].Year}</td >); //in this case we want to show pfcf before ps
                }
                if (i === 1) {
                    renOV.push(<td>{this.props.is[RevenueGrowthIndex].Year}</td >);
                }
                if (i === 2) {
                    renOV.push(<td>{this.props.cf[FreeCashFlowIndex].Year}</td >);
                }
                if ((i === 3) && (NoFCFGrowth === true)) {
                    continue;
                }
                else {
                    if (i === 3) {
                        renOV.push(<td>{this.props.cf[FreeCashFlowGrowthIndex].Year}</td >);
                    }
                }
                if (i === 4) {
                    renOV.push(<td>{this.props.is[NetIncomeIndex].Year}</td >);
                }
                if (i === 5) {
                    renOV.push(<td>{this.props.is[EPSDilutedIndex].Year}</td >);
                }
                if ((i === 6) && (NoEPSGrowth === true)) {
                    continue;
                }
                else {
                    if (i === 6) {
                        renOV.push(<td>{this.props.is[EPSGrowthIndex].Year}</td >);
                    }
                }
                if ((i === 7) && (NoDividend === true)) {
                    continue;
                }
                else {
                    if (i === 7) {
                        renOV.push(<td>{this.props.is[DividendPerShareIndex].Year}</td >);
                    }
                }
                if (i === 8) {
                    renOV.push(<td>{this.props.is[GrossMarginIndex].Year}</td >);
                }
                if (i === 9) {
                    renOV.push(<td>{this.props.is[OperatingMarginIndex].Year}</td >);
                }
                if (i === 10) {
                    renOV.push(<td>{this.props.is[ProfitMarginIndex].Year}</td >);
                }
                if (i === 11) {
                    renOV.push(<td>{this.props.is[FCFMarginIndex].Year}</td >);
                }




                for (var j = Object.keys(this.props.cf[1])[0]; j <= currentYear; j++) {

                    if (i === -1) {
                        renOV.push(<th>{j}</th >);
                    }
                    //          if (i === 1) {
                    //          renOV.push(<td>{this.state.r[PFCFRatioIndex][j]}</td >);

                    //      }
                    if (i === 0) { //first thing to show
                        renOV.push(<td>{this.props.is[RevenueIndex][j]}</td >); //in this case we want to show pfcf before ps
                    }
                    if (i === 1) {
                        renOV.push(<td>{this.props.is[RevenueGrowthIndex][j]}</td >);
                    }
                    if (i === 2) {
                        renOV.push(<td>{this.props.cf[FreeCashFlowIndex][j]}</td >);
                    }

                    if ((i === 3) && (NoFCFGrowth === true)) {
                        continue;
                    }
                    else {
                        if (i === 3) {
                            renOV.push(<td>{this.props.cf[FreeCashFlowGrowthIndex][j]}</td >);
                        }
                    }
                    if (i === 4) {
                        renOV.push(<td>{this.props.is[NetIncomeIndex][j]}</td >);
                    }
                    if (i === 5) {
                        renOV.push(<td>{this.props.is[EPSDilutedIndex][j]}</td >);
                    }
                    if ((i === 6) && (NoEPSGrowth === true)) {
                        continue;
                    }
                    else {
                        if (i === 6) {
                            renOV.push(<td>{this.props.is[EPSGrowthIndex][j]}</td >);
                        }
                    }
                    if ((i === 7) && (NoDividend === true)) {
                        continue;
                    }
                    else {
                        if (i === 7) {
                            renOV.push(<td>{this.props.is[DividendPerShareIndex][j]}</td >);
                        }
                    }
                    if (i === 8) {
                        renOV.push(<td>{this.props.is[GrossMarginIndex][j]}</td >);
                    }
                    if (i === 9) {
                        renOV.push(<td>{this.props.is[OperatingMarginIndex][j]}</td >);
                    }
                    if (i === 10) {
                        renOV.push(<td>{this.props.is[ProfitMarginIndex][j]}</td >);
                    }
                    if (i === 11) {
                        renOV.push(<td>{this.props.is[FCFMarginIndex][j]}</td >);
                    }


                }
                renOV.push(<tr></tr>)

                keyMetric = false;
            }

            return renOV

        }

        catch {

        }

    }


    getKeys = function () {
        try {
            return Object.keys(this.props.is[0]);
        }
        catch {

        }
    }

    getHeader = function () {
        try {
            var keys = this.getKeys();
            var currentYear = parseInt(keys[keys.length - 2]);

            return keys.map((key, index) => {


                var firstYear = currentYear - (keys.length - 2)
                //currentYear - (keys.length-2)

                if (key === firstYear.toString()) {
                    return <th >Financials/Years</th>

                }
                else if (key === 'Year') {
                    return <th >{currentYear}</th>

                }
                else {
                    //                    var newKey = parseInt(key) - 1

                    return <th >{key - 1}</th>

                }



            })
        }
        catch {

        }
    }

    getRowsData = function () {




        // var items = this.props.is;

        // for (var i = 0; i < this.props.cf.length; i++) {
        //     items.push(this.props.cf[i])
        // }

        // console.log("total appended: ", items)



        var appendedArr = this.props.is;

        for (var i = 0; i < this.props.cf.length; i++) {
            if (this.props.cf[i].Year === "Net Income") {
                continue;
            }
            else {
                //appendedArr.push(this.props.cf[i])

            }
        }



        //rev,rev growth, fcf, fcf growth, net income , eps , eps growth, dividend, gopf margins

        var items = [];
        for (var i = 0; i < appendedArr.length; i++) {
            if (appendedArr[i].Year === "Revenue") {
                items.push(appendedArr[i])
            }
            if (appendedArr[i].Year === "Revenue Growth") {
                items.push(appendedArr[i])
            }
            if (appendedArr[i].Year === "Free Cash Flow") {
                items.push(appendedArr[i])
            }
            if (appendedArr[i].Year === "Free Cash Flow Growth") {
                items.push(appendedArr[i])
            }
            if (appendedArr[i].Year === "Net Income") {
                items.push(appendedArr[i])
            }
            if (appendedArr[i].Year === "EPS (Diluted)") {
                items.push(appendedArr[i])
            }
            if (appendedArr[i].Year === "EPS Growth") {
                items.push(appendedArr[i])
            }

            if (appendedArr[i].Year === "Dividend Per Share") {
                items.push(appendedArr[i])
            }
            if (appendedArr[i].Year === "Gross Margin") {
                items.push(appendedArr[i])
            }
            if (appendedArr[i].Year === "Operating Margin") {
                items.push(appendedArr[i])
            }
            if (appendedArr[i].Year === "Profit Margin") {
                items.push(appendedArr[i])
            }
            if (appendedArr[i].Year === "FCF Margin") {
                items.push(appendedArr[i])
            }
            if (appendedArr[i].Year === "Repurchase/Issuance of Stock") {
                items.push(appendedArr[i])
            }

        }



        var sortedArr = []

        var g = ['Revenue', 'Revenue Growth', 'Free Cash Flow', 'Free Cash Flow Growth', 'Net Income', 'EPS (Diluted)', 'EPS Growth', 'Dividend Per Share', 'Gross Margin', 'Operating Margin', 'Profit Margin', 'FCF Margin', "Repurchase/Issuance of Stock"];

        for (var i = 0; i < g.length; i++) {
            for (var j = 0; j < items.length; j++) {
                if (items[j].Year === g[i]) {
                    sortedArr.push(items[j])
                }

            }
        }


        var keys = this.getKeys();
        return sortedArr.map((row, index) => {

            return <tr class="row100 head"><RenderRow key={index} data={row} keys={keys} /></tr>
        })
    }


    render() {


        return (
            <div class="container-table100" class="col-lg"  >
                <div class="wrap-table100" class="col-lg"  >
                    <div class="table100 ver6 m-b-110"   >
                        <table data-vertable="ver6"  >
                            <thead>
                                <tr class="row100 head">{this.getHeader()}</tr>
                            </thead>
                            <tbody>
                                {this.getRowsData()}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}



const RenderRow = (props) => {

    const [showChart, setShowChart] = useState(false);

    //Revenue
    //Revenue Growth
    //Net Income
    //EPS (Diluted)
    //EPS Growth
    //Dividend Per Share
    //Gross Margin
    //Operating Margin
    //Profit Margin
    //FCF Margin

    var currentYear = parseInt(props.keys[props.keys.length - 2]);


    if ((props.data['Year'] === "Revenue")
        || (props.data['Year'] === "Revenue Growth")
        || (props.data['Year'] === "Net Income")
        || (props.data['Year'] === "EPS (Diluted)") //
        || (props.data['Year'] === "EPS Growth")
        || (props.data['Year'] === "Dividend Per Share")
        || (props.data['Year'] === "Profit Margin")
        || (props.data['Year'] === "Operating Margin")
        || (props.data['Year'] === "Gross Margin")
        || (props.data['Year'] === "FCF Margin")
        || (props.data['Year'] === "Dividend Per Share")
        || (props.data['Year'] === "Free Cash Flow")
        || (props.data['Year'] === "Free Cash Flow Growth")
        || (props.data['Year'] === "Repurchase/Issuance of Stock")

    ) {

        var firstYear = currentYear - (props.keys.length - 2);

        return props.keys.map((key, index) => {



            if (key === firstYear.toString()) {
                //if (key === "2013") {

                //render first key (first key being 2011)



                return <td >
                    {props.data['Year']}
                    <div style={{ display: 'inline', height: 'fit-content' }} onMouseOver={() => setShowChart(true)} onMouseLeave={() => setShowChart(false)} >
                        <BarChartIcon class="chart-icon"></BarChartIcon>
                        {showChart ? <Chart metric={props.data['Year']} chartData={props.data}></Chart> : null}
                    </div>
                </td>

                ///                return <td >{props.data['Year']}</td>

            }
            else if (key === 'Year') {
                //render second last key (second last key being currentYear)


                if (props.data['Year'] === "Revenue" || props.data['Year'] === 'Free Cash Flow' || props.data['Year'] === 'Net Income') {
                    return <td >{Math.round(parseInt(props.data[currentYear]) / 1000000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>

                }

                else if (props.data['Year'] === "Repurchase/Issuance of Stock") {
                    return <td >{Math.round(Math.round(parseInt(props.data[newKey])) / 1000000)}</td>

                }

                else if (props.data['Year'] === "Free Cash Flow Growth" || props.data['Year'] === 'Revenue Growth' || props.data['Year'] === 'EPS Growth') {
                    return <td >{(Math.round(props.data[currentYear] * 100) / 100).toFixed(2)}%</td>

                }
                else if (props.data['Year'] === "Gross Margin" || props.data['Year'] === 'Operating Margin' || props.data['Year'] === 'Profit Margin' || props.data['Year'] === 'FCF Margin') {
                    return <td >{(Math.round((props.data[currentYear] * 100) * 100) / 100).toFixed(2)}%</td>

                }
                else if (props.data['Year'] === "Dividend Per Share") {
                    return <td >{(Math.round((props.data[currentYear] * -1) * 100) / 100).toFixed(2)}</td>

                }
                else {
                    return <td >{props.data[currentYear]}</td>

                }


            }

            else {
                var newKey = parseInt(key) - 1;



                if (props.data['Year'] === "Revenue" || props.data['Year'] === 'Free Cash Flow' || props.data['Year'] === 'Net Income') {
                    return <td >{Math.round(parseInt(props.data[newKey]) / 1000000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>

                }
                else if (props.data['Year'] === "Free Cash Flow Growth" || props.data['Year'] === 'Revenue Growth' || props.data['Year'] === 'EPS Growth') {
                    return <td >{(Math.round(props.data[newKey] * 100) / 100).toFixed(2)}%</td>

                }
                else if (props.data['Year'] === "Gross Margin" || props.data['Year'] === 'Operating Margin' || props.data['Year'] === 'Profit Margin' || props.data['Year'] === 'FCF Margin') {
                    return <td >{(Math.round((props.data[newKey] * 100) * 100) / 100).toFixed(2)}%</td>

                }
                else if (props.data['Year'] === "Dividend Per Share") {
                    return <td >{(Math.round((props.data[newKey] * -1) * 100) / 100).toFixed(2)}</td>

                }
                else if (props.data['Year'] === "Repurchase/Issuance of Stock") {
                    return <td >{Math.round(Math.round(parseInt(props.data[newKey])) / 1000000)}</td>

                }
                else {
                    return <td >{props.data[newKey]}</td>

                }

            }
        })
    }
    else {
        return null;
    }
}



//cf
//Free Cash Flow 
//Free Cash Flow Growth
//is
