import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';


const About = () => {



    const history = useHistory();

    const [data, setData] = useState({});

    const callAboutPage = async () => {
        try {
            const res = await fetch('/info', {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    "Content-Type": 'application/json'
                },
                credentials: 'include'
            });

            const data = await res.json();
            console.log('data from success about page : ', data)
            setData(data)
            if (!res.status === 200) {
                const error = new Error(res.error)
                throw error;
            }

        } catch (error) {
            console.log(error)
            history.push('/signin')
        }
    }

    useEffect(() => {
        callAboutPage();

    }, [])



    return (
        <div class="row" id="emailFormRow">
            {JSON.stringify(data)}
        </div>
    )
}
export default About
















